import { dialCodeRegexp } from '@root/constants/regexpPatterns';
import { CountryAliases } from '@root/interfaces/appConfig';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import { replaceAccentCharacters } from '@utils/string';

const escapeRegex = (string: string) => {
  return string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
};

export const findOptionByAlias = (
  option: BrOptionProps,
  aliasArr: CountryAliases[],
  searchString: string,
) => {
  return aliasArr.find(
    ({ value, aliases }) =>
      value.toLowerCase() === option.value.toLowerCase() &&
      aliases.some((alias) =>
        new RegExp(escapeRegex(searchString.toLowerCase())).test(alias.toLowerCase()),
      ),
  );
};

export default findOptionByAlias;

export const onSearchByDialCodeAndAlias = (
  option: BrOptionProps,
  searchQuery: string,
  countryAliases: CountryAliases[] | undefined,
) => {
  const normalizedQueryString = replaceAccentCharacters(searchQuery.toLowerCase());
  const normalizedSourceString = replaceAccentCharacters(option.text.toLowerCase());
  const isQueryStartsFromDialCode = dialCodeRegexp.test(searchQuery);
  if (isQueryStartsFromDialCode) {
    return Boolean(option.addonTextLeft?.includes(searchQuery));
  }
  return Boolean(
    normalizedSourceString.includes(normalizedQueryString) ||
      (countryAliases &&
        findOptionByAlias(option, countryAliases, normalizedQueryString)),
  );
};
