import BrSkeleton from '@components/common/BrSkeleton';

const AutoRechargeSkeleton = () => {
  return (
    <div className="flex flex-col max-w-[520px]">
      <div className="flex flex-col mb-middle">
        <BrSkeleton className="w-full h-[22px] rounded-default" />
      </div>
      <div className="overflow-hidden flex flex-grow-0 mb-xxxlarge space-x-middle h-[32px]">
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
      </div>

      <div className="space-y-middle">
        <div className="flex flex-col space-y-middle ">
          <BrSkeleton className="w-[54px] m-auto h-[32px] rounded-default" />
          <BrSkeleton className="w-[128px] mx-auto h-[22px] rounded-default" />
        </div>
      </div>
      <BrSkeleton className="w-full mx-auto h-[22px] rounded-default  mb-xlarge mt-xsmall" />
    </div>
  );
};

export default AutoRechargeSkeleton;
