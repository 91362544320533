import { useState } from 'react';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';
import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { checkIsSupportedCard } from '../PaymentCardSelect/helpers';

interface Params {
  paymentCards?: PaymentCardClass[];
  handleId?: string;
  disabledPaymentMethods?: PaymentCardTypeShort[];
  disabledPaymentCardsIssuers?: PaymentCardIssuerKeys[];
}

const usePreselectPaymentCardLogic = (props: Params) => {
  const {
    paymentCards,
    handleId,
    disabledPaymentCardsIssuers,
    disabledPaymentMethods,
  } = props;

  const paymentCardsWithSupportedInfo = paymentCards?.map((card) => {
    return {
      data: card,
      isSupported: checkIsSupportedCard({
        card,
        disabledPaymentCardsIssuers,
        disabledPaymentMethods,
      }),
    };
  });

  const [prevInitialHandleId, setPrevInitialHandleId] = useState<string | undefined>();
  const [prevPaymentCards, setPrevPaymentCards] = useState(paymentCards);
  const [selectedCardId, setSelectedCardId] = useState<string | undefined>();

  // this check is necessary for preventing infinite rerenders, since state is updated during component's render
  if (prevPaymentCards !== paymentCards) {
    setPrevPaymentCards(paymentCards);
    if (paymentCards && paymentCards.length === 0) {
      setSelectedCardId(undefined);
    }
    if (paymentCards && paymentCards.length > 1) {
      const notExpiredCard = paymentCards.find((card) => !card.isExpired);
      if (notExpiredCard) {
        setSelectedCardId(notExpiredCard.id);
      } else {
        // select any first card
        setSelectedCardId(paymentCards[0].id);
      }
    }
    if (paymentCards?.length === 1) {
      setSelectedCardId(paymentCards[0].id);
    }
  }

  // TODO: revise
  if (prevInitialHandleId !== handleId && paymentCards) {
    setPrevInitialHandleId(handleId);
    const lastUsedCard = paymentCards?.find(
      (card) => !card.isExpired && card.id === handleId,
    );
    // set last used card id as selected
    if (lastUsedCard) {
      setSelectedCardId(handleId);
    }
  }

  return { selectedCardId, setSelectedCardId, paymentCardsWithSupportedInfo };
};

export default usePreselectPaymentCardLogic;
