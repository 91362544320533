import { Carrier } from '@root/interfaces/contract/dtcImtuK2/Config';

class ImtuCarrierClass {
  code = '-1';

  name = '';

  logoUrl = '';

  constructor(data?: Carrier) {
    if (data) {
      this.code = data.code;
      this.name = data.display_name;
      this.logoUrl = data.image_url;
    }
  }
}

export default ImtuCarrierClass;
