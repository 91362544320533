import React from 'react';
import { Img } from 'react-image';

import Flag from '@components/common/Flag';

import { getCarrier80LogoUrl } from '@helpers/imtuCarriers';

import CountryCode from '@root/interfaces/CountryCode';

import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

type Size = 'xSmall' | 'Small' | 'Medium' | 'Large';

interface Props {
  size: Size;
  carrierCode: string;
  countryCode?: CountryCode;
}

const BASE_CLASS = 'br-carrier';

const sizeToClsMap: Record<Size, string> = {
  Large: 'large',
  Medium: 'medium',
  Small: 'small',
  xSmall: 'xsmall',
};

const BrCarrier: React.FC<Props> = (props) => {
  const { size, carrierCode, countryCode } = props;

  const wrapperClassNames = normalizeStringCompound([
    BASE_CLASS,
    `${BASE_CLASS}-${sizeToClsMap[size]}`,
  ]);

  return (
    <div className={wrapperClassNames}>
      <Img
        className="border-0 rounded-full overflow-hidden flex-shrink-0 self-center"
        src={getCarrier80LogoUrl(carrierCode)}
      />
      {countryCode && size === 'Large' && (
        <Flag
          className="w-xlarge h-xlarge absolute bottom-0 right-0 overflow-hidden rounded-full flex-shrink-0 self-center"
          code={countryCode}
        />
      )}
    </div>
  );
};

export default BrCarrier;
