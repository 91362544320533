import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { SkeletonProps } from '@root/interfaces/components/BrSkeleton';

import './styles.scss';

const BrSkeleton: FC<React.PropsWithChildren<SkeletonProps>> = (props) => {
  const { className } = props;

  const containerClassNames = normalizeStringCompound([
    'br-skeleton',
    className || 'w-full',
  ]);

  return <div className={containerClassNames} />;
};

export default BrSkeleton;
