import React from 'react';
import { useTranslation } from 'react-i18next';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';

import { imtuPlanTxnOrderStatusMap } from '@root/constants/mobileTopUp/imtuStatuses';

import { getCallingApiErrorMessage } from '@services/axios/helpers';
import BrOrderStatus from '@components/common/BrOrderStatus';

interface Props {
  txnStatus: ImtuStatus;
  txnErrorCode?: string | null;
}

const Status: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { txnStatus, txnErrorCode } = props;

  const { t } = useTranslation();

  const statusTextMap: {
    [statusKey in ImtuStatus]: {
      title: string;
      subTitle: React.ReactNode;
    };
  } = {
    success: {
      title: t('success'),
      subTitle: t('top-up sent'),
    },
    queued: {
      title: t('order queued'),
      subTitle: t('we are processing your order now'),
    },
    failed: {
      title: t('sorry'),
      subTitle: (
        <>
          {t('your order is unsuccessful')}
          <br />
          {txnErrorCode ? t(getCallingApiErrorMessage(txnErrorCode)) : ''}
        </>
      ),
    },
    // action is not supported so the case is irrelevant
    scheduled: {
      title: t('order scheduled'),
      subTitle: t('top-up scheduled'),
    },
    // action is not supported so the case is irrelevant
    refunded: {
      title: t('order refunded'),
      subTitle: t('top-up refunded'),
    },
  };

  return (
    <div className="capitalize">
      <BrOrderStatus
        status={imtuPlanTxnOrderStatusMap[txnStatus]}
        title={statusTextMap[txnStatus].title}
        subTitle={statusTextMap[txnStatus].subTitle}
      />
    </div>
  );
};

export default Status;
