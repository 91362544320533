import React, { useRef, useState } from 'react';

import { MenuProps } from '@root/interfaces/components/BrContextMenu';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { normalizeStringCompound } from '@utils/string';

import Menu from './Menu';

interface Props<T> extends MenuProps<T> {
  value?: T;
  isDefaultOpen?: boolean;
  children?: React.ReactNode | ((isOpen: boolean) => React.ReactNode);
}

const ContextualMenuWrapper = <T,>(props: Props<T>) => {
  const { value, children, isDefaultOpen = false, onItemSelect, ...menuProps } = props;

  const [isOpen, setIsOpen] = useState<boolean>(isDefaultOpen);

  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleItemSelect = (id: T) => {
    closeMenu();
    onItemSelect?.(id);
  };

  useOnClickOutside(closeMenu, menuRef);

  return (
    <div className="relative" ref={menuRef}>
      <button className="flex" onClick={handleToggle}>
        {typeof children === 'function' ? children(isOpen) : children}
      </button>

      <div
        className={normalizeStringCompound([
          'absolute right-0 z-10 mt-small duration-300 origin-top-right ease-out-back',
          isOpen ? 'scale-100 opacity-100' : 'scale-0 opacity-0',
        ])}
      >
        <Menu {...menuProps} onItemSelect={handleItemSelect} value={value} />
      </div>
    </div>
  );
};

export default ContextualMenuWrapper;
