import { ReactNode, useState } from 'react';

const useModal = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactNode>(null);
  const [modalHeader, setModalHeader] = useState<ReactNode>(null);
  const [hasCloseButton, setHasCloseButton] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return {
    isModalOpen,
    hasCloseButton,
    setHasCloseButton,
    modalContent,
    modalHeader,
    showModal,
    closeModal,
    setModalContent,
    setModalHeader,
  };
};

export default useModal;
