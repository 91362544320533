import { useTranslation } from 'react-i18next';

import BrCard from '@components/common/BrCard';

import { formatPhone } from '@helpers/phone';

import ImtuTransaction from '@root/models/ImtuTransaction';

import RecentTxn from './components/RecentTxn';

interface Props {
  txns: ImtuTransaction[];
  onItemClick(id: string): void;
}

const RecentActivitySection: React.FC<Props> = (props) => {
  const { txns, onItemClick } = props;

  const { t } = useTranslation();

  return (
    <BrCard>
      <div className="px-small">
        <div className="text-headers/header-4">{t('My top up activity')}</div>
        <div className="space-y-middle mt-default">
          {txns.map((txn, idx) =>
            txn.recipientAmount && txn.carrier?.name && txn.recipientCurrency ? (
              // TODO: replace to <ListItem /> with style Contact activity: https://www.figma.com/design/K8DBHEsr9QXbXIATpi0us1/BR6-**-Design-System?node-id=382-14803&node-type=frame&t=qGj1ltt3IZT0iTHr-0
              <RecentTxn
                key={txn.id}
                promoText={txn.carrierPromos?.[0].title}
                phone={formatPhone(txn.recipientPhoneNumber, txn.recipientCountryCode)}
                senderAmount={txn.senderAmount}
                senderCurrency={txn.senderCurrency}
                recipientAmount={txn.recipientAmount}
                recipientCurrency={txn.recipientCurrency}
                carrierName={txn.carrier?.name}
                recipientCountryCode={txn.recipientCountryCode}
                onClickProps={{ onClick: onItemClick, id: txn.id }}
                hasSeparateLine={txns.length - 1 !== idx}
              />
            ) : null,
          )}
        </div>
      </div>
    </BrCard>
  );
};

export default RecentActivitySection;
