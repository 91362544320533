import { makeAutoObservable } from 'mobx';

class Store {
  cvvCode?: string;

  constructor() {
    makeAutoObservable(this);
  }

  setCvvCode = (cvvCode: string) => {
    this.cvvCode = cvvCode;
  };
}

export default Store;
