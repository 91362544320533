import { flushSync } from 'react-dom';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrButton from '@components/common/BrButton';

import BrCard from '@components/common/BrCard';
import BrCountrySelect, { BrCountrySelectRef } from '@components/common/BrCountrySelect';
import BrInput from '@components/common/BrInput';
import { Flag } from '@components/common';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';
import { animationComplete } from '@components/common/BrDrawer';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';
import { onSearchByDialCodeAndAlias } from '@components/common/BrSelect/helpers';

import { getCountryNameByCode } from '@services/countryList';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import { getSortByKeyFn } from '@utils/array';
import { normalizeStringCompound } from '@utils/string';

import { CountryAliases } from '@root/interfaces/appConfig';
import { BrOptionProps, BrSelectRenderProp } from '@root/interfaces/components/BrSelect';

import PhoneForm from './components/PhoneForm';
import Title from '../../components/Title';

interface Props extends StepModule {
  phone?: string;
  countryCode: CountryCode;
  countries: CountryCode[];
  countryAliases?: CountryAliases[];
  onFinish(args: { recipientPhoneNumber: string }): void;
  onCountryChange?(code: CountryCode): void;
}

/**
 * Well this component doesn't use any store or complex logic like containers do
 * but to keep things consistent let's assume this is a container.
 * The other solution is to put it to Imtu components.
 * @param props
 * @constructor
 */
const Phone: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    phone,
    countryCode,
    dataTestPrefix,
    onFinish,
    countries,
    onCountryChange,
    countryAliases,
  } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const brCountrySelectRef = useRef<BrCountrySelectRef>(null);
  const wrapperRef = useRef<HTMLDivElement>();
  const submitButtonRef = useRef<{ submit?(): void } | null>(null);

  const [isContinueBtnDisabled, setIsContinueBtnDisabled] = useState(true);
  const [phoneNum, setPhoneNum] = useState(phone);

  const handleFinish = (phoneNumber: string) => {
    if (phoneNumber) {
      onFinish({ recipientPhoneNumber: phoneNumber });
    }
  };

  const handleRemoteSubmit = () => {
    submitButtonRef.current?.submit?.();
  };

  const handleOnSearch = (option: BrOptionProps, searchQuery: string) => {
    return onSearchByDialCodeAndAlias(option, searchQuery, countryAliases);
  };

  const handleRecipientPhoneInputChange = ({ phoneNumber }: { phoneNumber: string }) => {
    setPhoneNum(phoneNumber.replaceAll('+', ''));
  };

  const selectData = [
    {
      id: '1',
      label: t('All'),
      options: countries
        .map((code) => {
          return {
            value: code,
            text: `${getCountryNameByCode(code)}` ?? '',
            addonTextLeft: `+${getPhoneDialCodeByCountryCode(code)}`,
          };
        })
        .sort(getSortByKeyFn('text')),
    },
  ];

  const [isCountryBtnClicked, setIsCountryBtnClicked] = useState(false);

  const inputWrapperRef = useRef<HTMLDivElement>(null);

  const selectButtonAddonBefore = countryCode && (
    <Flag
      className="w-[16px] h-[16px] overflow-hidden rounded-full flex-shrink-0 mx-middle self-center"
      code={countryCode as CountryCode}
    />
  );

  const continueBtnTooltipText = () => {
    switch (true) {
      case !phoneNum?.length:
        return t('Please provide a phone number');
      case isContinueBtnDisabled:
        return t('Please provide a valid phone number');
      default:
        return undefined;
    }
  };

  const isDesktopResolution = useIsDesktop();

  const isOpened = isCountryBtnClicked && isDesktopResolution && wrapperRef.current;

  const wrapperStyles = {
    gridTemplateColumns: `${wrapperRef.current?.clientWidth}px 1fr`,
  };

  const wrapperRefCallback = async (node: HTMLDivElement | null) => {
    if (node) {
      wrapperRef.current = node;
      await animationComplete(node);
      if (!isCountryBtnClicked) {
        inputRef.current?.blur();
      }
    }
  };

  const wrapperClassNames = normalizeStringCompound([
    'grid mb-middle [grid-template-columns:88px_1fr] [transition:grid-template-columns_0.25s_ease-out]',
  ]);

  const handleCountryChange = async (code: CountryCode) => {
    if (isDesktopResolution && brCountrySelectRef.current?.dropdownRef) {
      await animationComplete(brCountrySelectRef.current?.dropdownRef);
    } else if (brCountrySelectRef.current?.drawerRef) {
      await animationComplete(brCountrySelectRef.current?.drawerRef);
    }
    onCountryChange?.(code as CountryCode);
    setIsContinueBtnDisabled(true);
    setIsCountryBtnClicked(false);
  };

  const handleOnClickOutside = async () => {
    if (isDesktopResolution && brCountrySelectRef.current?.dropdownRef) {
      await animationComplete(brCountrySelectRef.current?.dropdownRef);
    } else if (brCountrySelectRef.current?.drawerRef) {
      await animationComplete(brCountrySelectRef.current?.drawerRef);
    }
    setIsCountryBtnClicked(false);
  };

  const handleOnValidation = (isValid: boolean) => {
    setIsContinueBtnDisabled(!isValid);
  };

  const renderCustomSelectBtnInput: BrSelectRenderProp = (params) => {
    const { isOpened: isOpen, onClick, searchValue, onSearch } = params;

    const handleOnClick = async () => {
      if (isOpen) {
        await onClick();
        if (isDesktopResolution) {
          if (brCountrySelectRef.current?.dropdownRef) {
            await animationComplete(brCountrySelectRef.current?.dropdownRef);
          }
        } else if (brCountrySelectRef.current?.drawerRef) {
          await animationComplete(brCountrySelectRef.current?.drawerRef);
        }
      }
      if (isDesktopResolution) {
        inputRef.current?.focus();
      } else {
        inputRef.current?.blur();
      }
      flushSync(() => {
        setIsCountryBtnClicked((s) => !s);
      });
      if (isDesktopResolution) {
        if (wrapperRef.current) {
          await animationComplete(wrapperRef.current);
        }
      }
      if (!isOpen) {
        onClick();
      }
    };

    return (
      <div ref={inputWrapperRef}>
        <BrInput
          iconRight={searchValue && isOpened ? 'CloseXSolid' : 'chevron-down-outline'}
          ref={inputRef}
          addonBefore={selectButtonAddonBefore}
          onWhat="surface"
          value={searchValue}
          onChange={onSearch}
          className="[&_input]:!px-0 [&_input]:indent-middle"
          placeholder={t('Enter a country')}
          onClick={handleOnClick}
        />
      </div>
    );
  };

  const wrapperProps = {
    ref: wrapperRefCallback,
    className: wrapperClassNames,
    style: isOpened ? wrapperStyles : undefined,
  };

  const ignoredRefs = [inputWrapperRef];

  return (
    <BrCard className="!overflow-visible space-y-middle">
      <Title text={t("add recipient's phone number")} />
      <div {...wrapperProps}>
        <BrCountrySelect
          ref={brCountrySelectRef}
          data={selectData}
          hasSearch
          value={countryCode}
          onWhat="surface"
          onChange={handleCountryChange}
          onSearch={handleOnSearch}
          placeholder={t('Enter a country')}
          ignoredElemsRefs={ignoredRefs}
          onClickOutside={handleOnClickOutside}
          drawerTitleText={t("Select recipient's country")}
        >
          {renderCustomSelectBtnInput}
        </BrCountrySelect>

        <div className="min-w-0">
          <PhoneForm
            onChange={handleRecipientPhoneInputChange}
            phone={phone || getPhoneDialCodeByCountryCode(countryCode)}
            ref={submitButtonRef}
            countryCode={countryCode}
            onSubmit={handleFinish}
            className="pl-small min-w-0"
            onValidation={handleOnValidation}
          />
        </div>
      </div>

      <BrBottomControlsWrapper className="bg-color/primary">
        <BrTooltipWrapper
          cfg={{
            place: 'bottom',
            content: continueBtnTooltipText(),
          }}
        >
          <BrButton
            onClick={handleRemoteSubmit}
            text={t('Continue')}
            className="w-full"
            dataTestId={`${dataTestPrefix}-next-btn`}
            disabled={isContinueBtnDisabled}
          />
        </BrTooltipWrapper>
      </BrBottomControlsWrapper>
    </BrCard>
  );
};

export default Phone;
