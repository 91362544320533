import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useWizard from '@root/hooks/useWizard';
import useDidMount from '@root/hooks/useDidMount';
import useDidUpdate from '@root/hooks/useDidUpdate';
import useStore from '@root/hooks/useStore';

import { AppConfigStore, UserAccountStore } from '@root/stores';
import ImtuHistoryStore from '@root/stores/ImtuHistoryStore';

import { Logo, Modal } from '@components/common';
import BrSpinner from '@components/common/BrSpinner';

import ROUTE_PATHS from '@root/routes/paths';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import PaymentCardClass from '@root/models/PaymentCardClass';

import {
  ImtuTransactionQueryParams,
  parseImtuTransactionQueryParams,
} from '@helpers/urlQuery';

import { normalizeStringCompound } from '@utils/string';

import Country from './containers/Country';
import Phone from './containers/Phone';
import Carrier from './containers/Carriers';
import Plan from './containers/Plans';
import Summary from './containers/Summary';
import RecentContactsSection from './containers/Country/components/RecentContactsSection';
import RecentActivitySection from './containers/Country/components/RecentActivitySection';

import { ImtuStep } from './constants';

import Store from './Store';
import SendImtuTransactionStore from './SendImtuTransactionStore';
import ImtuConfigStore from './ImtuConfigStore';
import ImtuPrefillStore from './ImtuPrefillStore';
import ImtuPromoInfoStore from './ImtuPromoInfoStore';
import ImtuPromosV2Store from './ImtuPromosV2Store';

import ImtuTxnInfoSection from './components/ImtuTxnInfoSection';
import ImtuPromosSectionV2 from './components/ImtuPromosSectionV2';

import { getFilteredV2Promos } from './helpers';

interface Props {
  basePath: string;
}

export interface StepModule {
  title: string;
  component: React.ReactNode;
}

const DATA_TEST_ID_PREFIX = 'mtu';

const InternationalMobileTopUp: FC<PropsWithChildren<Props>> = (props) => {
  const { basePath } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const store = useLocalStore(() => new Store());
  const imtuPrefillStore = useLocalStore(() => new ImtuPrefillStore());
  const imtuPurchaseStore = useLocalStore(() => new SendImtuTransactionStore());
  const imtuConfigStore = useLocalStore(() => new ImtuConfigStore());
  const imtuPromoInfoStore = useLocalStore(() => new ImtuPromoInfoStore());
  const imtuHistoryStore = useLocalStore(() => new ImtuHistoryStore());
  const imtuPromosV2Store = useLocalStore(() => new ImtuPromosV2Store());
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const {
    currentStepIndex,
    redirectToStep,
    stepPaths,
    availableSteps,
    redirectToNextStep,
  } = useWizard({
    basePath,
    steps: ImtuStep,
  });

  const doPrefill = async (params: ImtuTransactionQueryParams) => {
    await imtuPrefillStore.setDataFromQueryParams(params);
    store.setCountryCode(imtuPrefillStore.countryCode);
    store.setRecipientPhone(imtuPrefillStore.recipientPhone);
    store.setCarrier(imtuPrefillStore.carrier);
    store.setProduct(imtuPrefillStore.product);

    if (store.product) {
      redirectToStep(ImtuStep.Summary);
    } else if (store.carrier) {
      redirectToStep(ImtuStep.Plan);
    } else if (store.recipientPhone) {
      redirectToStep(ImtuStep.Carrier);
    } else if (store.countryCode) {
      redirectToStep(ImtuStep.Phone);
    }
  };

  useDidMount(async () => {
    await Promise.all([
      imtuConfigStore.fetchConfig(),
      imtuHistoryStore.getTransactions(),
      imtuPromosV2Store.fetchPromos(),
    ]);

    const prefillData = parseImtuTransactionQueryParams();
    if (prefillData) {
      await doPrefill(prefillData);
    }
  });

  useDidUpdate(() => {
    if (!appConfigStore.availableMainFeaturesCfg.isImtuAvailable) {
      store.showImtuAvailabilityModal();
    }
  }, [appConfigStore.availableMainFeaturesCfg.isImtuAvailable]);

  const handlePromoApply = async (promoCode: string) => {
    if (store.product?.code && store.recipientPhone) {
      await imtuPromoInfoStore.getPromoInfo({
        promoId: promoCode,
        offerId: store.product.code,
        recipientPhone: store.recipientPhone,
      });
    }
  };

  const handlePromoChange = () => {
    imtuPromoInfoStore.reset();
  };

  useEffect(() => {
    imtuPromoInfoStore.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.product?.code, store.recipientPhone]);

  const promosToDisplay = useMemo(() => {
    if (store.countryCode) {
      return getFilteredV2Promos({
        promos: imtuPromosV2Store.promosV2,
        countryCode: store.countryCode,
        carrierCode: store.carrier?.code,
      });
    }
    return imtuPromosV2Store.promosV2;
  }, [imtuPromosV2Store.promosV2, store.countryCode, store.carrier?.code]);

  const handleImtuAvailabilityModalClose = () => {
    store.hideImtuAvailabilityModal();
  };

  /**
   * Used to remove all the data filled after the destination step on step edit
   * @param stepToEdit
   */
  const resetStoreToStep = (stepToEdit: ImtuStep) => {
    // cleanup all the data selected after the destination step.
    // this operation is not automated so there might be a better solution based on step order and step values.
    if (stepToEdit < ImtuStep.Plan) {
      store.setProduct(undefined);
    }
    if (stepToEdit < ImtuStep.Carrier) {
      store.setCarrier(undefined);
    }
    if (stepToEdit < ImtuStep.Phone) {
      store.setRecipientPhone(undefined);
    }
  };

  const handleEditStepAction = (stepToEdit: ImtuStep) => {
    switch (true) {
      case stepToEdit === ImtuStep.Country:
        store.setProduct(undefined);
        break;
      case stepToEdit === ImtuStep.Carrier:
        store.setCarrier(undefined);
        break;
      case stepToEdit === ImtuStep.Plan:
        store.setProduct(undefined);
        break;
      default:
        break;
    }
    redirectToStep(stepToEdit, true);
  };

  useDidUpdate(() => {
    resetStoreToStep(currentStepIndex);
  }, [currentStepIndex]);

  // ███████╗████████╗███████╗██████╗ ███████╗ ███████╗████████╗ █████╗ ██████╗ ████████╗
  // ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██╔══██╗╚══██╔══╝
  // ███████╗   ██║   █████╗  ██████╔╝███████╗ ███████╗   ██║   ███████║██████╔╝   ██║
  // ╚════██║   ██║   ██╔══╝  ██╔═══╝ ╚════██║ ╚════██║   ██║   ██╔══██║██╔══██╗   ██║
  // ███████║   ██║   ███████╗██║     ███████║ ███████║   ██║   ██║  ██║██║  ██║   ██║
  // ╚══════╝   ╚═╝   ╚══════╝╚═╝     ╚══════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝

  const handleCountryStepFinish = (code?: CountryCode) => {
    if (code === store.countryCode || !code) {
      store.setCountryCode(undefined);
      return undefined;
    }
    if (code) {
      store.setCountryCode(code);
      redirectToNextStep();
    }
    return undefined;
  };

  const handlePhoneStepFinish = ({
    recipientPhoneNumber,
  }: {
    recipientPhoneNumber: string;
  }) => {
    store.setRecipientPhone(recipientPhoneNumber);
    redirectToNextStep();
  };

  const handleCarrierStepFinish = ({ carrier }: { carrier: ImtuCarrierClass }) => {
    store.setCarrier(carrier);
    redirectToNextStep();
  };

  const handlePlanStepFinish = ({ product }: { product: ImtuProductClass }) => {
    store.setProduct(product);
    redirectToNextStep();
  };

  const handleRecentItemSelect = async (txnId: string) => {
    const txn = imtuHistoryStore.transactions.find((txnItem) => {
      return txnItem.id === txnId;
    });
    if (txn) {
      await doPrefill({
        phoneNumber: txn.recipientPhoneNumber,
        carrierId: txn.carrier?.code,
        productId: txn.product?.code,
      });
    }
  };

  const handleSummaryStepFinish = async ({
    paymentInfo,
    cvvCode,
  }: {
    paymentInfo: PaymentCardClass;
    cvvCode: string;
  }) => {
    store.setPaymentInfo(paymentInfo);
    store.setCvv(cvvCode);

    try {
      if (store.product && store.recipientPhone && store.paymentInfo) {
        await imtuPurchaseStore.sendTransaction({
          recipientPhone: store.recipientPhone,
          product: store.product,
          paymentInfo: store.paymentInfo,
          userCountryCode: userAccountStore.userCountryOfOrigin,
          promoCode: imtuPromoInfoStore.promoInfo?.promoId,
        });
      }
    } finally {
      history.push(`${ROUTE_PATHS.IMTU_RECEIPT}/${imtuPurchaseStore.txnId}`);
    }
  };

  const handleOnCountryChange = (code: CountryCode) => {
    if (code === store.countryCode) {
      store.setCountryCode(undefined);
      redirectToStep(ImtuStep.Country);
      return undefined;
    }
    if (code !== store.countryCode) {
      store.setRecipientPhone(undefined);
      store.setCountryCode(code);
    }
    return undefined;
  };

  const isRecentActivitySectionsVisible = currentStepIndex < ImtuStep.Phone;
  const isPromotionsSectionVisible = currentStepIndex < ImtuStep.Carrier;

  const handleOnFastAccessBtnClick = () => {
    store.setIsFastAccessSectionInDropdownClicked(true);
  };

  const stepModules: StepModule[] = [];
  stepModules[ImtuStep.Country] = {
    title: t('mobile top-up'),
    component: (
      <Country
        countries={imtuConfigStore.availableCountries}
        countryAliases={appConfigStore.countryAliasesCfg}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        featuredCountries={appConfigStore.imtuFeaturedCountries}
        onFinish={handleCountryStepFinish}
        selectedCountryCode={store.countryCode}
        onFastAccessBtnClick={handleOnFastAccessBtnClick}
        isFastAccessSectionInDropdownVisible={!store.isFastAccessSectionInDropdownClicked}
      />
    ),
  };

  stepModules[ImtuStep.Phone] = {
    title: t('phone number'),
    component: store.countryCode ? (
      <Phone
        phone={store.recipientPhone}
        countryAliases={appConfigStore.countryAliasesCfg}
        countries={imtuConfigStore.availableCountries}
        countryCode={store.countryCode}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handlePhoneStepFinish}
        onCountryChange={handleOnCountryChange}
      />
    ) : null,
  };

  stepModules[ImtuStep.Carrier] = {
    title: t('pick a carrier'),
    component: (
      <Carrier
        selectedCarrier={store.carrier}
        recipientPhone={store.recipientPhone || ''}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handleCarrierStepFinish}
      />
    ),
  };

  stepModules[ImtuStep.Plan] = {
    title: t('pick a plan'),
    component: store.carrier && (
      <Plan
        selectedProduct={store.product}
        recipientPhone={store.recipientPhone || ''}
        carrier={store.carrier}
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handlePlanStepFinish}
      />
    ),
  };

  stepModules[ImtuStep.Summary] = {
    title: t('top-up summary'),
    component: (
      <Summary
        selectedCardId={store.paymentInfo?.handleId}
        isLoading={imtuPurchaseStore.isLoading || userAccountStore.isLoading}
        amountText={
          imtuPromoInfoStore.promoInfo?.priceTotalDiscountedText ||
          store.product?.senderTotalAmountText ||
          ''
        }
        dataTestPrefix={DATA_TEST_ID_PREFIX}
        onFinish={handleSummaryStepFinish}
        userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
      />
    ),
  };

  // ███████╗████████╗███████╗██████╗ ███████╗ ███████╗███╗   ██╗██████╗
  // ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝ ██╔════╝████╗  ██║██╔══██╗
  // ███████╗   ██║   █████╗  ██████╔╝███████╗ █████╗  ██╔██╗ ██║██║  ██║
  // ╚════██║   ██║   ██╔══╝  ██╔═══╝ ╚════██║ ██╔══╝  ██║╚██╗██║██║  ██║
  // ███████║   ██║   ███████╗██║     ███████║ ███████╗██║ ╚████║██████╔╝
  // ╚══════╝   ╚═╝   ╚══════╝╚═╝     ╚══════╝ ╚══════╝╚═╝  ╚═══╝╚═════╝

  const stepWrapperClassNames = normalizeStringCompound([
    'flex flex-col',
    currentStepIndex === ImtuStep.Country ? 'space-y-middle' : undefined,
    currentStepIndex === ImtuStep.Phone ? 'space-y-middle' : undefined,
    [ImtuStep.Carrier, ImtuStep.Plan].some((step) => {
      return step === currentStepIndex;
    })
      ? 'space-y-small'
      : undefined,
    currentStepIndex === ImtuStep.Summary
      ? 'space-y-small md:space-y-default lg:space-y-xlarge'
      : undefined,
  ]);

  return (
    <>
      {imtuPurchaseStore.isLoading && <BrSpinner variant="Processing order" />}
      {!imtuPrefillStore.isLoading ? (
        <div className={stepWrapperClassNames}>
          {/* Information on the filled fields */}
          <>
            {store.countryCode && (
              <ImtuTxnInfoSection
                countryCode={store.countryCode}
                recipientPhoneNumber={store.recipientPhone}
                carrier={store.carrier}
                product={store.product}
                promoInfo={imtuPromoInfoStore.promoInfo}
                isLoading={imtuPromoInfoStore.isLoading}
                currentStepIndex={currentStepIndex}
                onPromoApply={handlePromoApply}
                onPromoChange={handlePromoChange}
                onEditStepSelect={handleEditStepAction}
              />
            )}
          </>
          {/* Current step module */}
          <Switch>
            {availableSteps.map((step) => {
              const module = stepModules[step];
              return (
                <Route
                  key={stepPaths[step].toLowerCase()}
                  path={`${basePath}${stepPaths[step]}`}
                  render={() => {
                    if (!module.component) {
                      redirectToNextStep();
                    }
                    return module.component;
                  }}
                />
              );
            })}
          </Switch>
          {isRecentActivitySectionsVisible && (
            <>
              {imtuHistoryStore.recentContactsTxns?.length ? (
                <RecentContactsSection
                  txns={imtuHistoryStore.recentContactsTxns}
                  onItemSelect={handleRecentItemSelect}
                />
              ) : null}
              {imtuHistoryStore.recentTxns?.length ? (
                <RecentActivitySection
                  txns={imtuHistoryStore.recentTxns}
                  onItemClick={handleRecentItemSelect}
                />
              ) : null}
            </>
          )}

          {/* Promotions if available */}
          {promosToDisplay.length > 0 && isPromotionsSectionVisible && (
            <div className="space-y-middle">
              <div className="text-headers/header-4 text-on-colors/on-primary first-letter:capitalize mt-small">
                {t('promotions you might like')}
              </div>
              <ImtuPromosSectionV2 data={promosToDisplay} />
            </div>
          )}
        </div>
      ) : null}

      {/* Imtu availability modal */}
      <Modal
        isOpen={store.isImtuAvailabilityModalVisible}
        onRequestClose={handleImtuAvailabilityModalClose}
        header={<Logo className="w-24" />}
      >
        {t(
          'We are sorry. At this time BOSS Revolution does not provide this service in your country.',
        )}
      </Modal>
    </>
  );
};

export default observer(InternationalMobileTopUp);
