import { makeAutoObservable } from 'mobx';
import { CallingPlanProductType } from '@root/interfaces/contract/dtcImtuCallingPlans/Offers';

export interface CallingPlanProductData {
  productId: string;
  price: Number;
  discountPrice: Number;
  title: string;
  description: string;
  type: CallingPlanProductType;
}

class CallingPlanProduct {
  productId: string;

  price: Number;

  discountPrice: Number;

  title: string;

  description: string;

  type: CallingPlanProductType;

  constructor(data: CallingPlanProductData) {
    makeAutoObservable(this);

    this.productId = data.productId;
    this.price = data.price;
    this.discountPrice = data.discountPrice;
    this.title = data.title;
    this.description = data.description;
    this.type = data.type;
  }
}

export default CallingPlanProduct;
