import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';

import { showToast } from '@services/toasts';

import { StepModule } from '@root/interfaces/StepModules';

import ImtuProductClass from '@root/models/ImtuProductClass';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import Store from './Store';
import ImtuProductsStore from './ImtuProductsStore';

import ProductsList from './components/ProductsList';
import OffersListSkeleton from './components/OffersListSkeleton';

interface Props extends StepModule {
  selectedProduct?: ImtuProductClass;
  recipientPhone: string;
  carrier: ImtuCarrierClass;
  onFinish(args: { product: ImtuProductClass }): void;
}

const UI_MESSAGES = {
  PLEASE_SELECT_PRODUCT: 'Please select a plan', // t('Please select a plan')
};

const Plans: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { selectedProduct, recipientPhone, carrier, onFinish } = props;

  const { t } = useTranslation();

  const store = useLocalStore(
    () =>
      new Store({
        product: selectedProduct,
      }),
  );
  const imtuProductsStore = useLocalStore(() => new ImtuProductsStore());

  const handleFinish = () => {
    if (store.product) {
      onFinish({
        product: store.product,
      });
    } else {
      showToast.warning(UI_MESSAGES.PLEASE_SELECT_PRODUCT);
    }
  };

  useDidMount(async () => {
    await imtuProductsStore.fetchImtuProducts({
      recipientPhone,
      carrierCode: carrier.code,
    });
    if (!selectedProduct) {
      if (imtuProductsStore.products?.length === 1) {
        store.setProduct(imtuProductsStore.products[0]);
        handleFinish();
      }
    }
  });

  const handleProductSelect = (value: string) => {
    const product = imtuProductsStore.products?.find((item) => {
      return item.code === value;
    });
    if (product) {
      store.setProduct(product);
      handleFinish();
    }
  };

  return (
    <>
      <h5 className="text-body/primary/demibold mb-small first-letter:capitalize">
        {t('{{carrierName}} top-up plans', { carrierName: carrier.name })}
      </h5>
      <div className="grid auto-rows-fr gap-4">
        {imtuProductsStore.isDataReady ? (
          <>
            {imtuProductsStore.products?.length ? (
              <ProductsList
                data={imtuProductsStore.products}
                selectedItemId={store.product?.code}
                onItemSelect={handleProductSelect}
              />
            ) : (
              <div className="first-letter:uppercase">
                {t('no plans available for this carrier')}
              </div>
            )}
          </>
        ) : (
          <OffersListSkeleton />
        )}
      </div>
    </>
  );
};

export default observer(Plans);
