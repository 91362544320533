import { makeAutoObservable } from 'mobx';

interface CallingPlanContactData {
  phoneNumber: string;
  isEditableOnce: boolean;
  isEditable: boolean;
}

class CallingPlanContact {
  phoneNumber: string;

  isEditableOnce: boolean;

  isEditable: boolean;

  constructor(data: CallingPlanContactData) {
    makeAutoObservable(this);

    this.phoneNumber = data.phoneNumber;
    this.isEditableOnce = data.isEditableOnce;
    this.isEditable = data.isEditable;
  }
}

export default CallingPlanContact;
