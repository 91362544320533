import BrBadge from '@components/common/BrBadge';

import Circle from './Circle';

interface Props {
  productTitle: string;
  productType?: string; // we don't have this info for now
  className?: string;
}

const TopSection = (props: Props) => {
  const { productTitle, productType } = props;

  return (
    <div className="flex relative items-center justify-center px-[36px] h-[48px]">
      {productType && (
        <BrBadge classType="neutral" styleType="less-contrast" text={productType} />
      )}
      <div className="text-body/callout/default">{productTitle}</div>
      <Circle classNames="absolute left-0 -translate-x-1/2" />
      <Circle classNames="absolute right-0 translate-x-1/2" />
    </div>
  );
};

export default TopSection;
