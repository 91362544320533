import React, { FC, PropsWithChildren } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/common';

import { IconNames } from '@root/interfaces/components/Icon';
import { TestIdDataAttr } from '@root/interfaces/components/Common';
import { normalizeStringCompound } from '@utils/string';
import BrBadge from '@components/common/BrBadge';

export interface AccountOptionButtonProps extends TestIdDataAttr {
  title: string;
  iconName?: IconNames;
  component?: React.ReactNode;
  subTitle?: React.ReactNode;
  isNew?: boolean;
  link?: string;
  onClick?: () => void;
  className?: string;
}

const AccountOptionButton: FC<PropsWithChildren<AccountOptionButtonProps>> = (props) => {
  const {
    title,
    isNew,
    onClick,
    link,
    iconName,
    component,
    className,
    dataTestId,
  } = props;
  const { t } = useTranslation();

  const history = useHistory();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (link) {
      history.push(link);
    } else {
      throw Error(t('Link was not provided'));
    }
  };

  const icon = () => {
    if (iconName) {
      return <Icon name={iconName} width={20} height={20} />;
    }

    if (component) {
      return component;
    }

    return null;
  };

  const classNames = normalizeStringCompound([
    'flex items-center px-default py-xxlarge space-x-small border border-support-colors/lines  rounded-default bg-color/primary hover:border-color/secondary-variant active:border-color/secondary transition',
    className,
  ]);

  const renderInner = (
    <>
      <div className="flex justify-center flex-shrink-0 items-center w-[40px] h-[40px] rounded-full bg-color/secondary-variant text-white overflow-hidden">
        {icon()}
      </div>
      <div className="text-left items-center">
        <div className="text-headers/header-6">{title}</div>
      </div>
      {isNew && (
        <BrBadge
          classType="black"
          styleType="default"
          className="ml-small"
          text={`${t('New')} 🎉`}
        />
      )}
    </>
  );

  return onClick || link ? (
    <button className={classNames} onClick={handleClick} data-test-id={dataTestId}>
      {renderInner}
    </button>
  ) : (
    <div className={classNames}>{renderInner}</div>
  );
};

export default AccountOptionButton;
