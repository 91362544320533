import { Img } from 'react-image';

import { getCountryNameByCode } from '@services/countryList';

import CountryCode from '@root/interfaces/CountryCode';

import { convertDestinationUrlToIconName } from './helpers';

import Flag from '../../Flag';
import Icon from '../../Icon';

export interface DestinationProps {
  countryCode: CountryCode;
  description?: string;
  descriptionIcon?: string;
  carrierImages?: { src: string; alt: string }[];
}

const Destination = (props: DestinationProps) => {
  const { countryCode, description, carrierImages, descriptionIcon } = props;

  const iconName = convertDestinationUrlToIconName(descriptionIcon);

  return (
    <div className="flex items-start">
      <div className="flex-1 flex items-center space-x-small">
        <Flag
          className="w-xlarge h-xlarge flex-shrink-0 overflow-hidden rounded-full"
          code={countryCode}
        />
        <div className="flex space-x-xsmall">
          <span className="text-body/footnote/default">
            {getCountryNameByCode(countryCode)}
          </span>
          {description && (
            <sup className="text-body/caption-2/default top-0">{description}</sup>
          )}
        </div>
        {iconName && <Icon className="w-middle" name={iconName} />}
      </div>
      {Boolean(carrierImages?.length) && (
        <ul className="flex flex-1 flex-wrap justify-end gap-xsmall pl-small">
          {carrierImages?.map((image) => (
            <li className="w-auto" key={image.src}>
              <Img className="h-xlarge" src={image.src} alt={image.alt} />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Destination;
