import React, { PropsWithChildren } from 'react';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';

const BottomSectionContainer: React.FC<PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  return (
    <div className="flex flex-col text-left">
      <div>
        <BrBottomControlsWrapper className="mt-middle">
          {children}
        </BrBottomControlsWrapper>
      </div>
    </div>
  );
};

export default BottomSectionContainer;
