import React from 'react';

import { normalizeStringCompound } from '@utils/string';

type ExtraField = {
  title: string;
  valueText: string;
};

interface Props {
  data: ExtraField[];
  className?: string;
}

const ExtraFieldsList: React.FunctionComponent<Props> = (props: Props) => {
  const { data, className } = props;

  return (
    <div className={normalizeStringCompound([className, 'space-y-small'])}>
      {data.map((field) => {
        return (
          <div
            key={`${field.title}-${field.valueText}`}
            className="flex items-center justify-between pr-xxlarge text-body/callout/default text-support-colors/highlights"
          >
            <div>{field.title}</div>
            <div>{field.valueText}</div>
          </div>
        );
      })}
    </div>
  );
};

export default ExtraFieldsList;
