import React from 'react';
import BrSkeleton from '@components/common/BrSkeleton';

const CarrierSkeleton = () => {
  return <BrSkeleton className="w-full mx-auto h-[96px] rounded-default mt-xsmall" />;
};

const CarriersListSkeleton = () => {
  return (
    <>
      <CarrierSkeleton />
      <CarrierSkeleton />
      <CarrierSkeleton />
    </>
  );
};

export default CarriersListSkeleton;
