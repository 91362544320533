/* eslint-disable @typescript-eslint/indent */
import React, { ComponentProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BASE_PATHS from '@root/routes/paths';

import { callingPlanTxnOrderStatusMap } from '@root/constants/CallingPlans/callingPlansStatuses';

import CallingPlanTxn from '@root/models/CallingPlans/CallingPlanTxn';
import ImtuTransaction from '@root/models/ImtuTransaction';

import BrModal from '@components/common/BrModal';
import BrFavoriteNumbers from '@components/modules/BrFavoriteNumbers';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrOrderStatus from '@components/common/BrOrderStatus';
import BrButton from '@components/common/BrButton';
import BrCard from '@components/common/BrCard';
import BrCallingPlanCompletedOrder from '@components/common/BrCallingPlanCompletedOrder';
import { useIsMobile } from '@components/common/MediaQueryMatchers';
import BrDrawer from '@components/common/BrDrawer';

import { filterOutEmptyItems } from '@utils/array';

import { getCallingApiErrorMessage } from '@services/axios/helpers';

import CountryCode from '@root/interfaces/CountryCode';
import useModal from '@root/hooks/useModal';
import HowToCallModalContent from './HowToCallModalContent';

interface Props {
  txn: CallingPlanTxn;
  imtuTxn?: ImtuTransaction;
  userCountryOfOrigin: CountryCode;
  receiptFavoriteNumbersCfg?: ComponentProps<typeof BrFavoriteNumbers>;
}

const Status: React.FunctionComponent<Props> = (props: Props) => {
  const { txn, imtuTxn, receiptFavoriteNumbersCfg, userCountryOfOrigin } = props;
  const { t } = useTranslation();
  const history = useHistory();

  const statusTitle = useMemo(() => {
    switch (txn.status) {
      case 'success':
        return t('Congratulations!');
      case 'queued':
        return t('Order queued');
      default:
        return t('Transaction failed');
    }
  }, [txn.status, t]);

  const descriptionText = useMemo(() => {
    switch (txn.status) {
      case 'success':
        return t('Manage your active orders in the Calling Plans');
      case 'queued':
        return (
          <>
            <div className="whitespace-nowrap">{t('Your order is queued.')}</div>
            <span className="whitespace-nowrap">{t('Thanks for your patience!')}</span>
          </>
        );
      default:
        return (
          <>
            <div className="whitespace-nowrap">{t('Your order was failed.')}</div>
            <span className="whitespace-nowrap">{t('Sorry for inconvenience.')}</span>
          </>
        );
    }
  }, [txn.status, t]);

  const statusSubTitle = useMemo(() => {
    switch (true) {
      case txn.status === 'success':
        return txn.callingPlanInfo.isSubscriptionActive
          ? t('Your subscription is created!')
          : t('Your order is completed!');
      case txn.status === 'queued':
        return (
          <>
            <div className="whitespace-nowrap">{t('We are on it!')}</div>
            <span className="whitespace-nowrap">
              {t('Please check transaction history')}
            </span>
          </>
        );
      case Boolean(txn.errorReason):
        return <div>{getCallingApiErrorMessage(txn.errorReason || '')}</div>;
      default:
        return (
          <>
            <div>{t('There was a problem completing your transaction.')}</div>
            <span className="whitespace-nowrap">{t('Please try again later.')}</span>
          </>
        );
    }
  }, [txn.status, txn.errorReason, t]);

  const {
    isModalOpen,
    modalContent,
    modalHeader,
    closeModal,
    setModalHeader,
    setModalContent,
    showModal,
  } = useModal();

  const handleFinishClick = () => {
    history.push(BASE_PATHS.ACCOUNT);
  };

  const isMobile = useIsMobile();

  const handlerShowHowToCallModalClick = () => {
    setModalHeader(t('How to make a call'));
    setModalContent(<HowToCallModalContent userCountryOfOrigin={userCountryOfOrigin} />);
    showModal();
  };
  const handleRedirectToCallingPlansClick = () => {
    history.push(BASE_PATHS.CALLING_PLANS);
  };

  const handleOnViewReceiptClick =
    txn.status === 'success'
      ? () => {
          history.push(`${BASE_PATHS.CALLING_PLAN_RECEIPT}/${txn.id}`);
        }
      : undefined;

  const products = filterOutEmptyItems([
    {
      title: txn.callingPlanInfo.title,
      status: txn.status,
    },
    /* eslint-disable @typescript-eslint/indent */
    imtuTxn
      ? {
          title: imtuTxn?.product?.name || t('Mobile Top-Up'), // imtu comes without name while pending
          status: imtuTxn?.status,
        }
      : undefined,
    /* eslint-enable @typescript-eslint/indent */
  ]);

  const isSubscribed =
    callingPlanTxnOrderStatusMap[txn.status] === 'Success' &&
    txn.callingPlanInfo.isSubscriptionActive;

  const orderStatus =
    (isSubscribed && 'Subscribed') || callingPlanTxnOrderStatusMap[txn.status];

  return (
    <div className="flex flex-col space-y-default">
      <BrOrderStatus status={orderStatus} title={statusTitle} subTitle={statusSubTitle} />
      <BrCard>
        <BrCallingPlanCompletedOrder
          onViewReceiptClick={handleOnViewReceiptClick}
          isProcessing={txn.status === 'queued'}
          descriptionText={descriptionText}
          products={products}
        >
          {receiptFavoriteNumbersCfg && (
            <BrFavoriteNumbers {...receiptFavoriteNumbersCfg} />
          )}
        </BrCallingPlanCompletedOrder>
      </BrCard>

      <div className="flex flex-col space-y-small">
        {txn.status !== 'failed' && (
          <>
            <BrButton
              cmpType="text"
              hasIconLeft
              iconName="call-outline"
              onClick={handlerShowHowToCallModalClick}
              text={t('See how to make a call')}
            />
            <BrButton
              cmpType="text"
              hasIconLeft
              iconName="plans-outline"
              onClick={handleRedirectToCallingPlansClick}
              text={t('View other Calling Plans')}
            />
          </>
        )}
        <BrBottomControlsWrapper>
          {txn.status === 'success' && (
            <BrButton
              text={t("I'm done")}
              className="w-full"
              onClick={handleFinishClick}
            />
          )}
          {txn.status === 'failed' && (
            <BrButton
              text={t('Start again')}
              className="w-full"
              onClick={handleRedirectToCallingPlansClick}
            />
          )}
        </BrBottomControlsWrapper>
        {isMobile ? (
          <BrDrawer title={String(modalHeader)} isOpen={isModalOpen} onClose={closeModal}>
            {modalContent}
          </BrDrawer>
        ) : (
          <BrModal
            isOpen={isModalOpen}
            onClose={closeModal}
            header={modalHeader}
            modalClassNames="max-w-[600px]"
          >
            {modalContent}
          </BrModal>
        )}
      </div>
    </div>
  );
};

export default Status;
