import { useEffect, useState } from 'react';

const useVirtualKeyboardHeight = () => {
  const [virtualKeyboardHeight, setVirtualKeyboardHeight] = useState<number>();

  useEffect(() => {
    const layoutViewportHeight = document.documentElement.clientHeight;

    const handleVisualViewportResize = () => {
      const visualViewportHeight = window.visualViewport?.height;
      if (visualViewportHeight) {
        setVirtualKeyboardHeight(layoutViewportHeight - visualViewportHeight);
      }
    };

    window.visualViewport?.addEventListener('resize', handleVisualViewportResize);

    return () => {
      window.visualViewport?.removeEventListener('resize', handleVisualViewportResize);
    };
  }, []);

  return virtualKeyboardHeight;
};

export default useVirtualKeyboardHeight;
