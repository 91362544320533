import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

class Store {
  selectedCountryCode?: CountryCode;

  selectedPlanId?: string;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedCountryCode(countryCode?: CountryCode) {
    this.selectedCountryCode = countryCode;
  }

  setSelectedPlanId(planId: string) {
    this.selectedPlanId = planId;
  }
}

export default Store;
