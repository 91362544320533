import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { UserAccountStore } from '@root/stores';

import useStore from '@root/hooks/useStore';

import ContextualMenuWrapper from '@components/common/BrContextMenu';
import { MenuItemProps } from '@components/common/BrMenu/MenuItem';
import BrButton from '@components/common/BrButton';
import { Flag } from '@components/common';

import CountryCode from '@root/interfaces/CountryCode';
import { Languages } from '@root/interfaces/Languages';

import { getAvailableLanguageOptions } from '@helpers/language';

const FALLBACK_COUNTRY_CODE = 'US';

const LocaleToCountryCodeMap: Record<Languages, CountryCode> = {
  en: 'US',
  es: 'ES',
  fr: 'FR',
};

const UserLanguageSelect: React.FunctionComponent = () => {
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const languageOptions = getAvailableLanguageOptions(
    userAccountStore.userCountryOfOrigin,
  );
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language as Languages | undefined;
  const data = languageOptions.reduce<MenuItemProps[]>((acc, langOpt) => {
    const option = {
      id: langOpt.value,
      title: langOpt.nativeName,
      description:
        i18n.language === langOpt.value ? t('Preferred language') : t(langOpt.text),
      countryCode: LocaleToCountryCodeMap[langOpt.value],
    };

    if (option.id === i18n.language) {
      return [option, ...acc];
    }
    return [...acc, option];
  }, []);

  const currentLanguageCountryCode = currentLanguage
    ? LocaleToCountryCodeMap[currentLanguage]
    : FALLBACK_COUNTRY_CODE;

  const handleLanguageSelect = (id: Languages) => {
    userAccountStore.changeLanguage(id);
  };

  return (
    // TODO: the implementation of this component should be reconsidered. There is nesting button inside another button and an access to isOpen state requires additional changes
    <ContextualMenuWrapper
      value={currentLanguage}
      data={data}
      onItemSelect={handleLanguageSelect}
    >
      {(isOpen) => {
        return (
          <div className="ml-auto py-small flex items-center">
            <Flag
              className="self-center overflow-hidden rounded-full w-xlarge h-xlarge mr-xsmall"
              code={currentLanguageCountryCode}
            />
            <BrButton
              cmpStyle="circle"
              cmpType="text"
              size="small"
              iconName={isOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
            />
          </div>
        );
      }}
    </ContextualMenuWrapper>
  );
};

export default observer(UserLanguageSelect);
