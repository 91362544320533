import { normalizeStringCompound } from '@utils/string';

interface Props {
  text: string;
  className?: string;
}

const Title = (props: Props) => {
  const { text, className } = props;

  return (
    <div
      className={normalizeStringCompound([
        'text-body/primary/demibold first-letter:capitalize',
        className,
      ])}
    >
      {text}
    </div>
  );
};

export default Title;
