import React from 'react';
import { useTranslation } from 'react-i18next';

import { BrCardPromotionProps } from '@root/interfaces/components/BrCardPromotion';

import { getCountryNameByCode } from '@services/countryList';

import { normalizeStringCompound } from '@utils/string';

import SmallContent from './components/SmallContent';
import LargeContent from './components/LargeContent';
import LargeContentWithDetails from './components/LargeContentWithDetails';

import './styles.scss';

const sizeClsNamesMap: Record<BrCardPromotionProps['size'], string> = {
  'Small': 'small',
  'Large': 'large',
  'Large with details': 'large-details',
};

const BASE_CLASS = 'br-card-promotion';

const BrCardPromotion: React.FC<BrCardPromotionProps> = (props) => {
  const {
    size,
    state = 'Default',
    imageUrl,
    carrierCode,
    carrierName,
    countryCode,
    promoTextShort = '',
    promoTextLong = '',
    promoTextExtra = '',
    onClickProps,
  } = props;

  const { t } = useTranslation();

  const wrapperClassNames = normalizeStringCompound([
    BASE_CLASS,
    `${BASE_CLASS}-${sizeClsNamesMap[size]}`,
    size === 'Small' ? 'space-y-middle' : undefined,
  ]);

  const countryName = t(getCountryNameByCode(countryCode));

  const handleClick = () => {
    if (onClickProps) {
      onClickProps.onClick(onClickProps.id);
    }
  };

  return (
    <button className={wrapperClassNames} onClick={handleClick}>
      {size === 'Small' && (
        <SmallContent
          imageUrl={imageUrl}
          carrierName={carrierName}
          countryName={countryName}
          promoTextLong={promoTextLong}
          carrierCode={carrierCode}
        />
      )}
      {size === 'Large' && (
        <LargeContent
          state={state}
          imageUrl={imageUrl}
          carrierName={carrierName}
          countryName={countryName}
          promoTextLong={promoTextLong}
          carrierCode={carrierCode}
          countryCode={countryCode}
          promoTextShort={promoTextShort}
        />
      )}
      {size === 'Large with details' && (
        <LargeContentWithDetails
          imageUrl={imageUrl}
          state={state}
          carrierName={carrierName}
          countryName={countryName}
          promoTextLong={promoTextLong}
          carrierCode={carrierCode}
          countryCode={countryCode}
          promoTextShort={promoTextShort}
          promoTextExtra={promoTextExtra}
        />
      )}
    </button>
  );
};

export default BrCardPromotion;
