import React from 'react';
import BrSkeleton from '@components/common/BrSkeleton';

const ReceiptSkeleton = () => {
  return (
    <div className="flex flex-col items-center space-y-default">
      <BrSkeleton className="w-[250px] h-[21px] rounded-default" />
      <BrSkeleton className="w-[200px] h-[50px] rounded-default" />
      <BrSkeleton className="w-full h-[400px] rounded-default" />
    </div>
  );
};

export default ReceiptSkeleton;
