import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import BrOrderStatus from '@components/common/BrOrderStatus';

interface Props {
  className?: string;
}

const ReceiptHeader = (props: Props) => {
  const { className } = props;

  const { t } = useTranslation();

  return (
    <div className={normalizeStringCompound(['text-center ', className])}>
      <BrOrderStatus
        status={t('Receipt')}
        title={t('Thank you')}
        subTitle={t('Your receipt')}
      />
    </div>
  );
};

export default ReceiptHeader;
