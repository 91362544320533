import BrSkeleton from '@components/common/BrSkeleton';

const RechargeSkeleton = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <BrSkeleton className="w-[128px] mx-auto h-[22px] rounded-default  mb-xlarge mt-xsmall" />
      <BrSkeleton className="w-[95px] mx-auto h-[54px] rounded-default  mb-xlarge mt-xsmall" />
      <BrSkeleton className="w-[128px] mx-auto h-[22px] rounded-default  mb-xlarge mt-xsmall" />
      <div className="overflow-hidden max-w-[520px] flex flex-grow-0 mb-xxxlarge space-x-middle h-[32px]">
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
        <BrSkeleton className="min-w-[88px] rounded-small" />
      </div>
    </div>
  );
};

export default RechargeSkeleton;
