/* eslint-disable @typescript-eslint/indent */
import { FC, PropsWithChildren } from 'react';

import './styles.scss';
import { normalizeStringCompound } from '@utils/string';
import BrBadge, { BrBadgeProps } from '../BrBadge';

const BASIC_CLASS = 'br-card';

export interface BrCardProps {
  variant?:
    | 'default'
    | 'with-image-bg'
    | 'without-paddings'
    | 'featured'
    | 'without-bg'
    | 'no-bg-paddings'
    | 'with-liner'
    | 'new-featured'
    | 'small';

  featuredText?: string;
  bgUrl?: string;
  className?: string;
  fallbackImageUrl?: string;
  badgeCfg?: BrBadgeProps[];
}

const BrCard: FC<PropsWithChildren<BrCardProps>> = ({
  children,
  featuredText,
  bgUrl,
  variant = 'default',
  className,
  fallbackImageUrl,
  badgeCfg,
}) => {
  const classNames = normalizeStringCompound([
    `${BASIC_CLASS} ${BASIC_CLASS}-${variant}`,
    className,
  ]);
  const style = {
    backgroundImage: [
      bgUrl && `url(${bgUrl})`,
      fallbackImageUrl && `url(${fallbackImageUrl})`,
    ]
      .filter(Boolean)
      .join(', '),
  };

  return (
    <>
      {variant === 'new-featured' ? (
        <div className={normalizeStringCompound([classNames, className])}>
          <div className="br-card--image" style={style}>
            <div className="br-card--gradient">
              <div className="space-x-small flex">
                {badgeCfg?.map((badge, index) => (
                  <BrBadge key={badge.text} {...badge} />
                ))}
              </div>
            </div>
          </div>
          {featuredText && (
            <span className="br-card--featured-label">{featuredText}</span>
          )}
          {children}
        </div>
      ) : (
        <div className={normalizeStringCompound([classNames, className])} style={style}>
          {featuredText && (
            <span className="br-card--featured-label">{featuredText}</span>
          )}
          {variant === 'with-liner' ? (
            <div className="br-card--linear-wrapper">{children}</div>
          ) : (
            children
          )}
        </div>
      )}
    </>
  );
};

export default BrCard;
