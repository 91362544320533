export const IMTU_PHONE_NUMBER = 'phoneNumber';
export const IMTU_CARRIER_ID = 'carrierId';
export const IMTU_PRODUCT_ID = 'productId';

export const AUTH_DTC_EMAIL = 'dtcEmail';
export const AUTH_MIGRATION_EMAIL = 'migrationEmail';
export const AUTH_PHONE_NUMBER = 'phoneNumber';
export const AUTH_SMS_TOKEN = 'smsToken';
export const AUTH_REDIRECT_URL = 'postLoginRedirectUrl';

export const AUTH_DEFAULT_COUNTRY = 'authDefaultCountry';

export const MODAL_TYPE = 'modal';
