import React from 'react';

import BrSkeleton from '@components/common/BrSkeleton';

// To be updated since we're in a transition design
const CountrySkeleton = () => {
  return (
    <div className="flex flex-col space-y-default">
      <BrSkeleton className="w-[128px] h-[21px] rounded-default" />
      <BrSkeleton className="h-[45px] rounded-default" />
    </div>
  );
};

export default CountrySkeleton;
