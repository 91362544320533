import { makeAutoObservable } from 'mobx';

import CallingRechargeTransactionClass from '@root/models/CallingRechargeTransactionClass';

import { getRechargeHistory } from '@services/api/v1/calling';

import delay from '@utils/delay';

const TXN_DETAILS_REQUEST_DELAY = 1000;
const TXN_DETAILS_MAX_ATTEMPTS = 30; // for theoretical edge case when transaction doesn't appear

class RechargeTxnDetailsLooperStore {
  txn?: CallingRechargeTransactionClass;

  constructor() {
    makeAutoObservable(this);
  }

  setTxn(txn: CallingRechargeTransactionClass) {
    this.txn = txn;
  }

  updateTxnStatusInLoop = async (
    txnId: string,
    attemptsCounter = 0,
  ): Promise<undefined> => {
    try {
      const rechargeHistoryRes = await getRechargeHistory();

      const txnSearchRes = rechargeHistoryRes.find((txn) => {
        return txn.id === txnId;
      });
      if (txnSearchRes) {
        this.setTxn(txnSearchRes);
      } else {
        throw new Error('retry');
      }

      return undefined;
    } catch (err) {
      if (attemptsCounter > TXN_DETAILS_MAX_ATTEMPTS) {
        return undefined;
      }

      await delay(TXN_DETAILS_REQUEST_DELAY);
      return this.updateTxnStatusInLoop(txnId, attemptsCounter + 1);
    }
  };
}

export default RechargeTxnDetailsLooperStore;
