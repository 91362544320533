import CountryCode from '@root/interfaces/CountryCode';
import {
  BrOptionProps,
  OptGroupProps,
  isOptGroupProps,
} from '@root/interfaces/components/BrSelect';

import BrDropdownItem from '../BrDropdownItem';
import Title from './Title';

interface Props {
  data: (BrOptionProps | OptGroupProps)[];
  selectedOption?: BrOptionProps;
  onOptionSelect?(option: BrOptionProps): void;
}

const Countries = (props: Props) => {
  const { data, onOptionSelect, selectedOption } = props;

  const renderDropdownItem = (option: BrOptionProps) => {
    return (
      <BrDropdownItem
        key={option.value}
        onClick={() => onOptionSelect?.(option)}
        cmpVariant="No Padding"
        hasIconLeft
        iconLeftCountryCode={option.value as CountryCode}
        text={option.text}
        secondaryText={option.addonTextLeft}
        iconRightClassNames="text-color/secondary-variant"
        hasIconRight={selectedOption?.value === option.value}
        iconRightName="checkmark-circle"
      />
    );
  };

  return (
    <>
      {data.map((option) => {
        if (isOptGroupProps(option)) {
          return (
            <>
              {option.label && (
                <Title className="pt-default" text={option.label as string} />
              )}
              {option.options.map(renderDropdownItem)}
            </>
          );
        }
        return renderDropdownItem(option);
      })}
    </>
  );
};

export default Countries;
