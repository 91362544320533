import { FunctionComponent } from 'react';

import { convertDestinationUrlToIconName } from '@components/common/BrDestinations/Destination/helpers';

import { CallingPlanFootnote } from '@root/models/CallingPlans/CallingPlan';

import Icon from '../Icon';

import Destination, { DestinationProps } from './Destination';

interface Props {
  data: DestinationProps[];
  footnotes?: CallingPlanFootnote[];
  className?: string;
}

const Destinations: FunctionComponent<Props> = (props) => {
  const { data, footnotes, className } = props;

  const isFootNotesVisible = Boolean(
    footnotes?.filter((footnote) => footnote.text.trim() !== '').length,
  );

  return (
    <div className={className}>
      <ul className="space-y-small">
        {data.map((item) => (
          <li key={item.countryCode}>
            <Destination {...item} />
          </li>
        ))}
      </ul>
      {isFootNotesVisible && (
        <div className="mt-small">
          <ul className="text-body/caption-2/default space-y-xsmall text-support-colors/highlights">
            {footnotes?.map((footnote) => {
              const iconName = convertDestinationUrlToIconName(footnote.iconUrl);

              return (
                <li className="flex items-center space-x-xsmall" key={footnote.text}>
                  {iconName && (
                    <Icon
                      className="h-middle mr-y-xsmall"
                      name={iconName}
                      width={12}
                      height={12}
                    />
                  )}
                  <span>{footnote.text}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Destinations;
