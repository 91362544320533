import { Fragment } from 'react';

import { OptionListProps } from '@root/interfaces/components/Select';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import Option from './Option';

type RenderItem = {
  option: BrOptionProps;
  isActive: boolean;
  onSelect: (option: BrOptionProps) => void;
};

type Props = OptionListProps & {
  height?: number;
  options: BrOptionProps[];
  selectedOption?: BrOptionProps;
  handleOnOptionSelect?(option: BrOptionProps): Promise<undefined>;
  renderItem?(item: RenderItem, itemIdx: number): JSX.Element;
};

const OptionList: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { options, dataTestId, selectedOption, onSelect, renderItem } = props;
  // TODO: add support of OptGroup
  return (
    <>
      {options.map((item, index) => {
        const isActive = selectedOption?.value === item.value;

        return (
          <Fragment key={item.value}>
            {renderItem ? (
              renderItem(
                {
                  option: item,
                  isActive,
                  onSelect,
                },
                index,
              )
            ) : (
              // TODO: replace to BrDropdownItem
              <Option
                key={item.value}
                onClick={onSelect}
                dataTestId={`${dataTestId}-${item.value}`}
                isActive={isActive}
                {...item}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default OptionList;
