import React, { FC } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { Route, Switch, useHistory } from 'react-router-dom';

import ROUTE_PATHS from '@root/routes/paths';

import useWizard from '@root/hooks/useWizard';
import useDidMount from '@root/hooks/useDidMount';
import useStore from '@root/hooks/useStore';

import CountryCode from '@root/interfaces/CountryCode';

import { UserAccountStore } from '@root/stores';
import CallingRechargeStore from '@root/stores/CallingRechargeStore';

import {
  CallingPlanPurchaseParams,
  ComboPlanPurchaseParams,
} from '@services/api/v1/dtcCallingPlans';
import { showToast } from '@services/toasts';
import { logMessage } from '@services/logging';

import BrSpinner from '@components/common/BrSpinner';

import Setup from './containers/Setup';
import Summary from './containers/Summary';

import CallingPlansPurchaseStore from './CallingPlansPurchaseStore';
import CallingPlansStore from './CallingPlansStore';

import { Steps } from './constants';

import Store from './Store';

import { get3DsCallingPlansParamsFromQuery, getCallingPlanIdFromQuery } from './helpers';

interface Props {
  basePath: string;
}

export interface StepModule {
  component: React.ReactNode;
}

const CallingPlans: FC<React.PropsWithChildren<Props>> = (props) => {
  const { basePath } = props;

  const history = useHistory();

  const store = useLocalStore(() => new Store());
  const callingPlansStore = useLocalStore(() => new CallingPlansStore());
  const callingRechargeStore = useLocalStore(() => new CallingRechargeStore());
  const callingPlansPurchaseStore = useLocalStore(() => new CallingPlansPurchaseStore());
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const { stepPaths, availableSteps, redirectToStep } = useWizard({
    basePath,
    steps: Steps,
  });

  useDidMount(async () => {
    await callingPlansStore.fetchPlans();

    const params3Ds = get3DsCallingPlansParamsFromQuery();
    if (params3Ds) {
      try {
        await callingPlansPurchaseStore.doPurchase(
          params3Ds,
          userAccountStore.userCountryOfOrigin,
        );
      } finally {
        if (callingPlansPurchaseStore.purchaseRes) {
          history.push(
            `${ROUTE_PATHS.CALLING_PLAN_COMPLETED_ORDER}/${callingPlansPurchaseStore.purchaseRes.txnId}`,
          );
        }
      }
    }

    const callingPlanId = getCallingPlanIdFromQuery();
    if (callingPlanId) {
      const callingPlanToSelect = callingPlansStore.userSelectablePlans.find((plan) => {
        return plan.planId === callingPlanId;
      });
      if (callingPlanToSelect) {
        store.setSelectedPlanId(String(callingPlanId));
        redirectToStep(Steps.Summary);
      } else {
        logMessage(
          `Calling Plans deep linking: planId ${callingPlanId} not found for user ${userAccountStore.profile.phone}`,
        );
      }
    }
  });

  const handleCountryCodeSelect = (countryCode: CountryCode) => {
    store.setSelectedCountryCode(
      countryCode === store.selectedCountryCode ? undefined : countryCode,
    );
  };

  const handlePlanSelect = (planId: string) => {
    store.setSelectedPlanId(planId);
    const selectedPlan = store.selectedPlanId
      ? callingPlansStore.getSelectedPlanById(store.selectedPlanId)
      : undefined;
    if (selectedPlan?.isActive) {
      redirectToStep(Steps.Manage);
    } else if (selectedPlan && !selectedPlan?.isActive) {
      redirectToStep(Steps.Summary);
    }
  };

  const handlePlanEdit = () => {
    redirectToStep(Steps.Setup);
  };

  const handlePurchaseButtonClick = async (
    params: CallingPlanPurchaseParams | ComboPlanPurchaseParams,
    arNewValue?: number,
  ) => {
    try {
      if (arNewValue) {
        await callingRechargeStore.getCallingInfo();
      }
      let arParams;
      if (arNewValue && callingRechargeStore.paymentInfo) {
        arParams = {
          amount: arNewValue * callingRechargeStore.paymentInfo?.divisor,
          currency: callingRechargeStore.paymentInfo?.currency,
          currency_divisor: callingRechargeStore.paymentInfo?.divisor,
        };
      }
      await callingPlansPurchaseStore.doPurchase(
        {
          ...params,
          ...(arParams ? { arParams } : undefined),
        },
        userAccountStore.userCountryOfOrigin,
      );
    } finally {
      if (callingPlansPurchaseStore.purchaseRes) {
        history.push(
          `${ROUTE_PATHS.CALLING_PLAN_COMPLETED_ORDER}/${callingPlansPurchaseStore.purchaseRes.txnId}`,
        );
      }
    }
  };

  const handleCancelSubscriptionClick = async (params: {
    planId: string;
    productId: string;
    txnId: string;
  }) => {
    try {
      await callingPlansPurchaseStore.cancelCallingPlanSubscription({
        productId: params.productId,
        txnId: params.txnId,
      });
      history.push(`${ROUTE_PATHS.CALLING_PLAN_CANCEL_SUB_SUCCESS}/${params.planId}`);
    } catch (err) {
      showToast.error(err.message);
    }
  };

  const selectedPlan = store.selectedPlanId
    ? callingPlansStore.getSelectedPlanById(store.selectedPlanId)
    : undefined;

  const stepModules: StepModule[] = [];

  stepModules[Steps.Setup] = {
    component: (
      <Setup
        destinationCountries={callingPlansStore.destinationCountryCodes}
        activePlans={callingPlansStore.activePlans}
        suggestedPlans={callingPlansStore.suggestedPlans}
        availablePlans={callingPlansStore.availablePlans}
        nonPurchasablePlans={callingPlansStore.nonPurchasablePlans}
        selectedCountryCode={store.selectedCountryCode}
        onCountrySelect={handleCountryCodeSelect}
        onPlantSelect={handlePlanSelect}
      />
    ),
  };

  // The same component with on the different page
  stepModules[Steps.Manage] = {
    component: selectedPlan && (
      <Summary
        // 🚨 TODO: obtain last used payment card handleId from the txns history
        // selectedCardHandleId={handleId}
        selectedPlan={selectedPlan}
        userCountryOfOrigin="US"
        onPurchaseButtonClick={handlePurchaseButtonClick}
        onPlanEdit={handlePlanEdit}
        onCancelSubscriptionButtonClick={handleCancelSubscriptionClick}
      />
    ),
  };

  stepModules[Steps.Summary] = {
    component: selectedPlan && (
      <Summary
        // 🚨 TODO: obtain last used payment card handleId from the txns history
        // selectedCardHandleId={handleId}
        selectedPlan={selectedPlan}
        userCountryOfOrigin="US"
        onPurchaseButtonClick={handlePurchaseButtonClick}
        onPlanEdit={handlePlanEdit}
        onCancelSubscriptionButtonClick={handleCancelSubscriptionClick}
      />
    ),
  };

  return (
    <>
      {(callingPlansPurchaseStore.isLoading ||
        callingRechargeStore.isAnythingLoading) && (
        <BrSpinner variant="Processing order" />
      )}
      {callingPlansPurchaseStore.isCancelingSub && <BrSpinner variant="Loading" />}
      <Switch>
        {availableSteps.map((step) => {
          const module = stepModules[step];

          return (
            <Route
              key={stepPaths[step].toLowerCase()}
              path={`${basePath}${stepPaths[step]}`}
              render={() => module.component}
            />
          );
        })}
      </Switch>
    </>
  );
};

export default observer(CallingPlans);
