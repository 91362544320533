import firebase from 'firebase';
import 'firebase/remote-config';

import { logError } from '@services/logging';

import {
  CountryAliasesMap,
  CountryMainFeaturesMap,
  ImtuFeaturedCountriesMap,
} from '@root/interfaces/appConfig';

const CONFIG_REQUEST_TTL = process.env.NODE_ENV === 'production' ? 5 * 60 * 1000 : 0;

// The name is defined in the Firebase remote config
const KNOWN_CONFIG_PARAMETERS = {
  COUNTRY_MAIN_FEATURES_MAP: 'countryMainFeaturesMap',
  COUNTRY_ALIASES_MAP: 'countryAliasesMap',
  IMTU_FEATURED_COUNTRIES_MAP: 'imtuFeaturedCountriesMap',
};

const ERROR_TYPE_PREFIX = 'Remote config error';

// The structure is defined in the Firebase remote config
export interface RemoteConfigResponse {
  countryMainFeaturesMap?: CountryMainFeaturesMap;
  countryAliasesMap?: CountryAliasesMap;
  imtuFeaturedCountriesMap?: ImtuFeaturedCountriesMap;
}

class RemoteConfigApi {
  app: firebase.app.App;

  constructor({ app }: { app: firebase.app.App }) {
    this.app = app;
  }

  getConfig = async (): Promise<RemoteConfigResponse> => {
    firebase.remoteConfig(
      this.app,
    ).settings.minimumFetchIntervalMillis = CONFIG_REQUEST_TTL;
    await firebase.remoteConfig(this.app).fetchAndActivate();
    const countryMainFeaturesMapConfigResponse = firebase
      .remoteConfig(this.app)
      .getValue(KNOWN_CONFIG_PARAMETERS.COUNTRY_MAIN_FEATURES_MAP);

    const countryAliasesMapConfigResponse = firebase
      .remoteConfig(this.app)
      .getValue(KNOWN_CONFIG_PARAMETERS.COUNTRY_ALIASES_MAP);

    const imtuFeaturedCountriesMapConfigResponse = firebase
      .remoteConfig(this.app)
      .getValue(KNOWN_CONFIG_PARAMETERS.IMTU_FEATURED_COUNTRIES_MAP);

    let countryMainFeaturesMap;
    let countryAliasesMap;
    let imtuFeaturedCountriesMap;
    try {
      countryMainFeaturesMap = JSON.parse(
        countryMainFeaturesMapConfigResponse.asString(),
      ) as CountryMainFeaturesMap;
    } catch (err) {
      logError({
        error: Error(
          `${ERROR_TYPE_PREFIX}: cannot parse ${KNOWN_CONFIG_PARAMETERS.COUNTRY_MAIN_FEATURES_MAP}`,
        ),
        errorInfo: countryMainFeaturesMapConfigResponse.asString(),
      });
    }

    try {
      countryAliasesMap = JSON.parse(
        countryAliasesMapConfigResponse.asString(),
      ) as CountryAliasesMap;
    } catch (err) {
      logError({
        error: Error(
          `${ERROR_TYPE_PREFIX}: cannot parse ${KNOWN_CONFIG_PARAMETERS.COUNTRY_ALIASES_MAP}`,
        ),
        errorInfo: countryAliasesMapConfigResponse.asString(),
      });
    }

    try {
      imtuFeaturedCountriesMap = JSON.parse(
        imtuFeaturedCountriesMapConfigResponse.asString(),
      ) as ImtuFeaturedCountriesMap;
    } catch (err) {
      logError({
        error: Error(
          `${ERROR_TYPE_PREFIX}: cannot parse ${KNOWN_CONFIG_PARAMETERS.IMTU_FEATURED_COUNTRIES_MAP}`,
        ),
        errorInfo: imtuFeaturedCountriesMapConfigResponse.asString(),
      });
    }

    const res = {
      countryMainFeaturesMap,
      countryAliasesMap,
      imtuFeaturedCountriesMap,
    };

    return res;
  };
}

export default RemoteConfigApi;
