import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { TextWithClassNames } from '@components/common/TextDecorators';

interface Props {
  expirationDate?: Date;
  minutesLeft?: number;
}

const PlanExpirationContent: React.FC<Props> = (props) => {
  const { expirationDate, minutesLeft } = props;

  const { t } = useTranslation();

  return (
    <>
      {expirationDate && (
        <TextWithClassNames
          text={t('Expires on {bold-text}{{date}}{/bold-text}', {
            date: format(expirationDate, 'PPP'),
          })}
          markerName="bold-text"
          classNames="text-body/footnote/demibold"
        />
      )}
      {expirationDate && minutesLeft && ' | '}
      {minutesLeft && (
        <>
          {minutesLeft} {t('minutes remaining')}
        </>
      )}
    </>
  );
};

export default PlanExpirationContent;
