import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { LoginStore, UserAccountStore } from '@root/stores';

import useStore from '@root/hooks/useStore';

import ROUTE_PATHS from '@root/routes/paths';

import { SUPPORT_URL_TEMPLATES } from '@root/constants/storeUrls';

import { IconNames } from '@root/interfaces/components/Icon';

import ContextualMenuWrapper from '@components/common/BrContextMenu';
import BrContacts from '@components/common/BrContacts';

import { liveChatStart } from '@services/liveChat';
import { trackLogout } from '@services/analytics';

import { getFallbackLocaleForLegalUrls } from '@helpers/locale';

const UserAvatarMenu: React.FunctionComponent = () => {
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const loginStore: LoginStore = useStore('LoginStore');

  const { t } = useTranslation();
  const history = useHistory();

  const handleChatLinkClick = () => {
    liveChatStart();
  };

  const handleLogoutClick = async () => {
    await loginStore.sessionLogOut();
    trackLogout(userAccountStore.financeProfile?.brCustomerId || ''); // TODO: replace bmbCustomerId to profile.id after completely switch to DTC backend
    history.replace(ROUTE_PATHS.LOGIN);
  };

  const fallbackLocale = getFallbackLocaleForLegalUrls(
    userAccountStore.currentLanguageLocale,
  );

  const data: {
    id: string;
    path?: string;
    iconLeft: IconNames;
    title: string;
    isNative?: boolean;
    target?: string;
    onClick?(): void;
  }[] = [
    {
      id: 'profile',
      path: ROUTE_PATHS.ACCOUNT_PROFILE,
      iconLeft: 'person-outline',
      title: t('My profile'),
    },
    {
      id: 'chat',
      title: t('Chat'),
      iconLeft: 'chatbubble-outline',
      onClick: handleChatLinkClick,
    },
    {
      id: 'help',
      title: t('Help'),
      iconLeft: 'help-circle-outline',
      path: SUPPORT_URL_TEMPLATES.FAQ.replace('{{langLocale}}', fallbackLocale),
      isNative: true,
      target: '_blank',
    },
    {
      id: 'logout',
      title: t('Log out'),
      iconLeft: 'log-out-outline',
      onClick: handleLogoutClick,
    },
  ];

  const handleItemSelect = (id: string) => {
    const handler = data.find((item) => {
      return item.id === id;
    })?.onClick;
    handler?.();
  };

  return (
    <ContextualMenuWrapper data={data} onItemSelect={handleItemSelect}>
      <div className="h-x5large w-x5large">
        <BrContacts
          cmpSize="small"
          cmpType="person"
          imageUrl={userAccountStore.profile.avatarURL}
        />
      </div>
    </ContextualMenuWrapper>
  );
};

export default observer(UserAvatarMenu);
