import { BrCheckboxProps } from '@root/interfaces/components/BrCheckbox';
import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';

import BrToggle from '../BrToggle';
import Icon from '../Icon';

type Props = BrCheckboxProps & {
  leftText: string;
  iconTooltipCfg?: BrTooltipProps;
  onInfoIconClick?(): void;
};

const BrAutoRechargeToggle = (props: Props) => {
  const { leftText, iconTooltipCfg, onInfoIconClick, ...toggleProps } = props;

  return (
    <div className="flex items-center">
      <BrTooltipWrapper cfg={iconTooltipCfg}>
        <Icon
          onClick={onInfoIconClick}
          width={18}
          height={18}
          disabled={Boolean(iconTooltipCfg)}
          name="information-circle-outline"
        />
      </BrTooltipWrapper>
      <div className="text-body/caption/medium ml-xsmall">{leftText}</div>
      <BrToggle className="ml-small" {...toggleProps} />
    </div>
  );
};

export default BrAutoRechargeToggle;
