import { useRef, useEffect } from 'react';

const useDidUpdate = (effect: () => Function | void, dependencies: Array<unknown>) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return undefined;
    }
    const cleanUpFunc = effect();
    return () => cleanUpFunc && cleanUpFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useDidUpdate;
