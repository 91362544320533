/* eslint-disable import/prefer-default-export */

export const SESSION_STORE_KEY = 'session';
export const IMTU_3DS_PENDING_TXN_ID_STORE_KEY = 'imtu3dsPendingTxnId';
export const CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY = 'callingPlans3dsPendingTxnId';
export const CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY =
  'callingRecharge3dsPendingTxnId';

// we need this to see if the ID was handled (redirected to 3ds page).
// Back-end often provides outdated 3ds status after 3DS action has been taken
export const IMTU_3DS_HANDLED_TXN_ID_STORE_KEY = 'imtu3dsHandledTxnId';

const LOGIN_ERROR_MESSAGES = {
  INVALID_VERIFICATION_CODE: 'Verification code is not valid. Please try again.', // t('Verification code is not valid. Please try again.')
  IP_BLOCKED: 'Your Ip address is blocked. Please contact customer support.', // t('Your Ip address is blocked. Please contact customer support.')
  CREDENTIALS_EXPIRED: 'Your credentials have expired. Please try again.', // t('Your credentials have expired. Please try again.')
  INVALID_PHONE_NUMBER: 'Invalid phone number', // t('Invalid phone number')
  TOO_MANY_LOGIN_ATTEMPTS: 'Too many login attempts. Please try again later.', // t('Too many login attempts. Please try again later.')
  COUNTRY_NOT_SUPPORTED: 'Country not supported', // t('Country not supported')
};

const ERROR_CODE = {
  INVALID_VALUE: 1,
  IP_BLOCKED: 26, // comes with http 500
  PERMISSION_DENIED: 36,
  INVALID_MSISDN: 40,
  TOO_MANY_ATTEMPTS: 56, // comes with http 429
  COUNTRY_NOT_SUPPORTED: 107, // comes with http 451
};

export const LOGIN_ERROR_MESSAGE_CODE_MAP = {
  [ERROR_CODE.INVALID_VALUE]: LOGIN_ERROR_MESSAGES.INVALID_VERIFICATION_CODE,
  [ERROR_CODE.IP_BLOCKED]: LOGIN_ERROR_MESSAGES.IP_BLOCKED,
  [ERROR_CODE.PERMISSION_DENIED]: LOGIN_ERROR_MESSAGES.CREDENTIALS_EXPIRED,
  [ERROR_CODE.INVALID_MSISDN]: LOGIN_ERROR_MESSAGES.INVALID_PHONE_NUMBER,
  [ERROR_CODE.TOO_MANY_ATTEMPTS]: LOGIN_ERROR_MESSAGES.TOO_MANY_LOGIN_ATTEMPTS,
  [ERROR_CODE.COUNTRY_NOT_SUPPORTED]: LOGIN_ERROR_MESSAGES.COUNTRY_NOT_SUPPORTED,
};
