import { makeAutoObservable } from 'mobx';

import { checkRecipientToComboImtuCompatibility } from '@services/api/v1/dtcCallingPlans';

class Store {
  recipientPhone?: string;

  isValidationSuccess?: boolean;

  imtuOfferId?: string;

  cvv = '';

  selectedArAmount?: string;

  isLoading = false;

  constructor(imtuOfferId?: string) {
    makeAutoObservable(this);
    this.imtuOfferId = imtuOfferId;
  }

  get isRecipientPhoneValid() {
    return this.recipientPhone && this.isValidationSuccess;
  }

  get isRecipientPhoneRequired() {
    return Boolean(this.imtuOfferId);
  }

  setRecipientPhone(recipientPhone: string) {
    this.recipientPhone = recipientPhone;
  }

  setValidationSuccess(result: boolean) {
    this.isValidationSuccess = result;
  }

  validateCallingPlanRecipientPhone = async (phoneNumber: string) => {
    if (this.imtuOfferId) {
      this.setIsLoading(true);
      try {
        const isValidResult = await checkRecipientToComboImtuCompatibility({
          offerId: this.imtuOfferId,
          recipientPhone: phoneNumber,
        });
        return isValidResult;
      } catch (err) {
        return false;
      } finally {
        this.setIsLoading(false);
      }
    }
    return false;
  };

  setCvv = (cvv: string) => {
    this.cvv = cvv;
  };

  setIsLoading = (val: boolean) => {
    this.isLoading = val;
  };

  setSelectedArAmount(arAmount: string) {
    this.selectedArAmount = arAmount;
  }
}

export default Store;
