import { makeAutoObservable } from 'mobx';

import { CallingPlanPurchaseGeneralResponse } from '@root/interfaces/contract/dtcImtuCallingPlans/CallingPlanPurchaseDetails';

import { CallingPlanTxnStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import { formatMoneyToText } from '@helpers/money';
import { extractCallingPlanPartFromCallingTransaction } from '@helpers/ImtuCallingTxn';

type CallingPlanTxnInfo = {
  productId: string;
  title: string;
  description: string;
  paymentCardHandleId: string;
  price: string;
  isSubscriptionActive: boolean;
  createdAt: Date;
  isSubscription?: boolean;
  term?: number;
  minutes?: number;
  redirectUrl3Ds?: string;
  transactionId3Ds?: string;
};

class CallingPlanTxn {
  id: string;

  callingPlanInfo: CallingPlanTxnInfo;

  priceTotal?: number;

  priceCurrency?: string;

  status: CallingPlanTxnStatus;

  errorReason?: string;

  constructor(data: CallingPlanPurchaseGeneralResponse) {
    makeAutoObservable(this);

    this.id = data.id;

    const callingPlanTxnInfo = extractCallingPlanPartFromCallingTransaction(data);

    if ('error_reason' in callingPlanTxnInfo) {
      this.errorReason = callingPlanTxnInfo.error_reason;
    }

    if (!callingPlanTxnInfo) {
      throw Error('calling plans contract is broken');
    }

    const { enrollment } = callingPlanTxnInfo;

    if (data.price_info) {
      this.priceTotal = Number(data.price_info.total_price);
      this.priceCurrency = data.price_info.currency_code;
    }

    this.callingPlanInfo = {
      productId: enrollment.id,
      isSubscriptionActive: enrollment.subscribe,
      paymentCardHandleId: enrollment.handle_id,
      title: callingPlanTxnInfo.title,
      description: callingPlanTxnInfo.description,
      createdAt: new Date(data.created_at),
      term: callingPlanTxnInfo?.active_plan_info?.term
        ? callingPlanTxnInfo.active_plan_info.term
        : undefined,
      minutes: callingPlanTxnInfo?.active_plan_info?.minutes
        ? callingPlanTxnInfo.active_plan_info.minutes
        : undefined,
      price: enrollment.price,
      redirectUrl3Ds: callingPlanTxnInfo.redirect_url_3ds,
      transactionId3Ds: callingPlanTxnInfo.transaction_id_3ds,
    };

    this.status = callingPlanTxnInfo.purchase_status;
  }

  get priceTotalText() {
    if (this.priceTotal && this.priceCurrency) {
      return formatMoneyToText(this.priceTotal, this.priceCurrency);
    }

    return undefined;
  }

  get priceCallingPlanText() {
    if (this.callingPlanInfo.price && this.priceCurrency) {
      return formatMoneyToText(Number(this.callingPlanInfo.price), this.priceCurrency);
    }

    return undefined;
  }
}

export default CallingPlanTxn;
