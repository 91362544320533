/* eslint-disable import/prefer-default-export */
import { CallingPlanType } from '@root/interfaces/contract/dtcImtuCallingPlans/Offers';

export const CALLING_PLANS_TYPES: Record<string, CallingPlanType> = {
  SAVING_PASS: 'T',
  BASIC: 'B',
  UNLIMITED: 'U',
  MVP: 'D',
  INTERNATIONAL: 'I',
};
