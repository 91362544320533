import { makeAutoObservable } from 'mobx';

export interface ImtuCarrierPromoParams {
  id: string;
  description: string;
  title: string;
  timeStart: number;
  timeEnd: number;
}

class ImtuCarrierPromoClass {
  id: string;

  description: string;

  title: string;

  dateStart: Date;

  dateEnd: Date;

  constructor(data: ImtuCarrierPromoParams) {
    makeAutoObservable(this);

    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.dateStart = new Date(data.timeStart);
    this.dateEnd = new Date(data.timeEnd);
  }
}

export default ImtuCarrierPromoClass;
