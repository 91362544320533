import { ImtuStatus } from '@root/interfaces/MobileTopUp';

import { CallingPlanOrderStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

// eslint-disable-next-line import/prefer-default-export
export const IMTU_STATUSES: { [key in 'SUCCESS' | 'QUEUED' | 'FAILED']: ImtuStatus } = {
  SUCCESS: 'success',
  QUEUED: 'queued',
  FAILED: 'failed',
};

type ImtuTxnOrderStatusMap = Record<ImtuStatus, CallingPlanOrderStatus>;

export const imtuPlanTxnOrderStatusMap: ImtuTxnOrderStatusMap = {
  success: 'Success',
  queued: 'Queued',
  failed: 'Fail',
  refunded: 'Success', // can't be refunded during txn so it falls back to success
  scheduled: 'Success', // can't be scheduled during txn so it falls back to success
};
