import { useParams } from 'react-router-dom';

import CallingPlanReceipt from '@components/modules/CallingPlanReceipt';
import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

interface UrlParams {
  id?: string;
}

const CallingPlansReceiptPage = () => {
  const { id: txnId } = useParams<UrlParams>();

  return (
    <BrTemplateWithSidebar>
      {txnId && <CallingPlanReceipt txnId={txnId} />}
    </BrTemplateWithSidebar>
  );
};

export default CallingPlansReceiptPage;
