import React, { useState } from 'react';

import { BrFilterProps } from '@root/interfaces/BrFilter';

import FilterItem from './FilterItem';

const BrFilter = <T extends string>(props: BrFilterProps<T>) => {
  const {
    options,
    defaultValue,
    color = 'White',
    shouldFallbackToDefault,
    isMultiSelect,
    onItemSelect,
  } = props;

  const [values, setValues] = useState<T[]>(defaultValue ? [defaultValue] : []);

  const singleSelectionValues = options
    .filter((option) => {
      return option.isSingleSelectionOnly;
    })
    .map((option) => {
      return option.value;
    });

  const handleItemSelect = ({
    value,
    isSingleSelectionOnly,
  }: {
    value: T;
    isSingleSelectionOnly: boolean;
  }) => {
    let newValues = [];
    if (values.includes(value)) {
      // remove already selected value
      newValues = values.filter((item) => {
        return item !== value;
      });
    } else if (isSingleSelectionOnly) {
      // select only this value
      newValues = [value];
    } else if (isMultiSelect) {
      // add selected value
      newValues = [...values, value].filter((item) => {
        // deselect singleSelection entities
        return !singleSelectionValues.includes(item);
      });
    } else {
      // // select only this value
      newValues = [value];
    }

    // fallback to default value
    if (!newValues.length && shouldFallbackToDefault && defaultValue) {
      newValues = [defaultValue];
    }

    setValues(newValues);
    onItemSelect(newValues);
  };

  return (
    <div className="flex space-x-small">
      {options.map((option) => {
        return (
          <FilterItem
            key={String(option.value)}
            {...option}
            color={color}
            isActive={values.includes(option.value)}
            onSelect={handleItemSelect}
          />
        );
      })}
    </div>
  );
};

export default BrFilter;
