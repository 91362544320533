import React from 'react';
import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';
import TransactionHistory from '@components/modules/TransactionHistory';

const TransactionsHistoryPage = () => {
  return (
    <BrTemplateWithSidebar>
      <TransactionHistory />
    </BrTemplateWithSidebar>
  );
};

export default TransactionsHistoryPage;
