const BASE_PATHS = {
  BASE: '/',
  ACCOUNT: '/account',
  ACCOUNT_PROFILE: '/account/profile',
  TRANSACTIONS_HISTORY: '/history',
  TRANSACTION_DETAILS: '/history',
  IMTU_RECEIPT: '/imtu-receipt',
  CALLING_PLAN_RECEIPT: '/calling-plan-receipt',
  CALLING_PLAN_COMPLETED_ORDER: '/calling-plan-completed-order',
  CALLING_PLAN_CANCEL_SUB_SUCCESS: '/calling-plan-cancel-sub-success',
  SEND_MONEY: '/send-money',
  SEND_MONEY_RECEIPT: '/send-money-receipt',
  DOCUMENTS_UPLOADING: '/documents-uploading',
  CALLING: '/calling',
  CALLING_AUTO_RECHARGE: '/calling-auto-recharge',
  CALLING_RECHARGE_RECEIPT: '/calling-recharge-receipt',
  CALLING_PLANS: '/calling-plans',
  IMTU_BASE: '/top-up',
  IMTU: '/top-up/new',
  LOGIN: '/login',
  LOGIN_MODAL: '#/login',
  MT_MODAL: '#/money-transfer',
  ACC_DELETE_MODAL: '#/delete-account',
  LOCATOR: '/locator', // for testing purposes, delete after accomplishing money flow
};

export default BASE_PATHS;
