/* eslint-disable react/no-array-index-key */
import React, { memo } from 'react';

import { TestId } from '@root/interfaces/components/Common';

import Item, { ItemProps } from './Item';

import { FilterType } from '../../Store';

export interface TransactionItem extends ItemProps {
  type: FilterType;
}

interface Props {
  title: string;
  data: TransactionItem[];
  dataTestPrefix?: TestId;
  onClick?: (id: string, type: FilterType) => void;
  onReceiptClick?: () => void;
}

const TransactionsList = (props: Props) => {
  const { title, data, onClick, dataTestPrefix } = props;

  return (
    <div>
      <div className="text-body/primary/demibold mb-middle">{title}</div>

      <div className="space-y-middle">
        {data.map((item, index) => (
          <Item
            key={item.id}
            {...item}
            onClick={
              item.type === FilterType.Imtu && !item.isPending ? onClick : undefined
            }
            dataTestId={`${dataTestPrefix}-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default memo(TransactionsList);
