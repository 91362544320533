import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

interface SubTotalItem {
  title: string;
  text: string;
}

interface Props {
  total: string;
  classNames?: string;
  subTotalDetails?: SubTotalItem[];
}

const OrderSummarySection = (props: Props) => {
  const { total, subTotalDetails, classNames } = props;

  const { t } = useTranslation();

  return (
    <div className={normalizeStringCompound(['space-y-small m-0', classNames])}>
      <div className="text-body/callout/default">{t('Order summary')}</div>
      {subTotalDetails?.map((item) => {
        return (
          <div
            key={item.title}
            className="flex justify-between items-center text-body/caption/default text-support-colors/highlights space-x-xsmall"
          >
            <div className="w-1/2 text-left">{item.title}</div>
            <div className="w-1/2 text-right">{item.text}</div>
          </div>
        );
      })}
      <div className="flex justify-between text-body/primary/demibold text-on-colors/on-primary space-x-default">
        <div>{t('Your total')}</div>
        <div>{total}</div>
      </div>
    </div>
  );
};

export default OrderSummarySection;
