import React, { PropsWithChildren } from 'react';

import { Icon, Flag } from '@components/common';

import { ContextMenuItemPropsLanguage } from '@root/interfaces/components/BrContextMenu';

import { normalizeStringCompound } from '@utils/string';

export interface Props<T> extends ContextMenuItemPropsLanguage<T> {
  onSelect?(id: T): void;
}

const itemBaseClass = 'menu-item';

const MenuItemLanguage = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { id, title, description, countryCode, isActive, isDisabled, onSelect } = props;

  const itemClassNames = normalizeStringCompound([
    `${itemBaseClass}`,
    isDisabled ? `${itemBaseClass}-disabled` : undefined,
  ]);

  const handleSelect = () => {
    if (!isDisabled) {
      onSelect?.(id);
    }
  };

  return (
    <li key={String(id)}>
      <button onClick={handleSelect} className={itemClassNames}>
        <div className="icon-container">
          <Flag
            className="w-default h-default overflow-hidden rounded-full flex-shrink-0 self-center"
            code={countryCode}
          />
        </div>
        <div className="text-container">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
        {isActive && (
          <div className="icon-container">
            <Icon width={16} height={16} name="checkmark-circle" />
          </div>
        )}
      </button>
    </li>
  );
};

export default MenuItemLanguage;
