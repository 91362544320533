import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  isActive?: boolean;
  className?: string;
  isReplaceChildren?: boolean;
}

// reused ald Spinner
const BrClickBlockerOverlay: FC<React.PropsWithChildren<Props>> = (props) => {
  const { isActive, children, className, isReplaceChildren } = props;

  const containerClassNames = normalizeStringCompound([
    'w-full relative',
    children ? 'h-full' : undefined,
    isActive ? 'cursor-not-allowed pointer-events-none' : undefined,
    className,
  ]);

  return (
    <div className={containerClassNames} aria-hidden="true">
      {isActive && isReplaceChildren ? null : children}
    </div>
  );
};

export default BrClickBlockerOverlay;
