/* eslint-disable @typescript-eslint/indent */
import { RefObject, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import InputMask from 'react-input-mask';

import Yup from '@utils/validation/yup';
import { normalizeStringCompound } from '@utils/string';

import useModal from '@root/hooks/useModal';

import CountryCode from '@root/interfaces/CountryCode';
import BrInputProps from '@root/interfaces/components/BrInput';
import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import { CVV_INPUT_MASK } from '@root/constants/inputMasks';

import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';
import BrCard from '@components/common/BrCard';
import BrButton from '@components/common/BrButton';
import BrModal from '@components/common/BrModal';
import BrClickBlockerOverlay from '@components/common/BrClickBlockerOverlay';
import { useIsMobile } from '@components/common/MediaQueryMatchers';
import { getZipPostalCodeValidationFn } from '@components/common/PaymentCardBillingAddressForm/helpers';
import BrAdvancedInput, { BrAdvancedInputRef } from '@components/common/BrAdvancedInput';
import {
  ZIP_POSTAL_MAX_LENGTH,
  zipFieldLabelDefault,
  zipFieldLableMap,
  zipFieldMaskDefault,
  zipFieldMaskMap,
  zipFieldTypeDefault,
  zipFieldTypeMap,
  zipFiledInputModeDefault,
  zipFiledInputModeMap,
} from '@components/common/PaymentCardBillingAddressForm/constants';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { IconNames } from '@root/interfaces/components/Icon';

import BrCvvModalContent from './components/BrCvvModalContent';

export interface BrPaymentCardReducedDetailsFormValues {
  cardNumber: string;
  expDateString: string;
  cvv: string;
  zip: string;
}

interface FormProps<T> {
  values: T;
  submitBtnText: string;
  countryCode: CountryCode;
  dataTestPrefixId: string;
  className?: string;
  handleId?: string;
  extraValidation?: {
    errorText: string;
    conditionFunc(): boolean;
  };
  shouldValidateOnMount?: boolean;
  hasCancelBtn?: boolean;
  isEdit?: boolean;
  isLoading?: boolean;
  isUpdatingCard?: boolean;
  isDeletingCard?: boolean;
  isAddingCard?: boolean;
  onSubmit(values: T, actions?: FormikHelpers<T>): Promise<void> | void;
  onClose?(): void;
  onRemove(): void;
}

const EXP_DATE_MASK = 'MM / yy';

type IconCfg = { iconName: IconNames; iconClassNames: string };

const ICON_CLEAR_CFG: IconCfg = {
  iconName: 'close_-_x_-_times_fill',
  iconClassNames: 'text-support-colors/highlights',
};

const getIconCfg = (value: string, isFocused?: boolean) => {
  if (value && isFocused) {
    return ICON_CLEAR_CFG;
  }
  return undefined;
};

const getIconRightConfig = (
  formik: FormikProps<BrPaymentCardReducedDetailsFormValues>,
  fieldName: keyof BrPaymentCardReducedDetailsFormValues,
  isFocused?: boolean,
) => {
  return isFocused ? getIconCfg(formik.values[fieldName], isFocused) : undefined;
};

const handleOnIconRightClick = (
  formik: FormikProps<BrPaymentCardReducedDetailsFormValues>,
  fieldName: keyof BrPaymentCardReducedDetailsFormValues,
  ref: RefObject<BrAdvancedInputRef>,
) => {
  if (formik.values[fieldName] !== '') {
    formik.setFieldValue(fieldName, '');
    ref.current?.inputRef.current?.focus();
  }
};

const BrPaymentCardReducedDetailsForm = (
  props: FormProps<BrPaymentCardReducedDetailsFormValues>,
) => {
  const {
    className,
    countryCode,
    submitBtnText,
    values,
    extraValidation,
    isEdit,
    isLoading = false,
    isDeletingCard = false,
    isUpdatingCard = false,
    isAddingCard = false,
    hasCancelBtn,
    onSubmit,
    onRemove,
    onClose,
  } = props;

  const { t } = useTranslation();

  const {
    modalHeader,
    modalContent,
    isModalOpen,
    setModalHeader,
    setModalContent,
    showModal,
    closeModal,
  } = useModal();

  const isMobile = useIsMobile();

  const expDateInputRef = useRef<BrAdvancedInputRef>(null);
  const cardNumberInputRef = useRef<BrAdvancedInputRef>(null);
  const zipInputRef = useRef<BrAdvancedInputRef>(null);
  const cvvInputRef = useRef<BrAdvancedInputRef>(null);

  type FocusedFields = {
    zip: boolean;
    cardNumber: boolean;
    cvv: boolean;
    expDateString: boolean;
  };

  const [focusedFields, setFocusedFields] = useState<Partial<FocusedFields>>({
    zip: false,
    cardNumber: false,
    cvv: false,
    expDateString: false,
  });

  useOnClickOutside(
    () => {
      setFocusedFields({});
    },
    expDateInputRef.current?.inputWrapperRef || { current: undefined },
    cardNumberInputRef.current?.inputWrapperRef || { current: undefined },
    zipInputRef.current?.inputWrapperRef || { current: undefined },
    cvvInputRef.current?.inputWrapperRef || { current: undefined },
  );

  const showCvvModal = () => {
    setModalHeader(t('What is a CVV?'));
    setModalContent(<BrCvvModalContent onClose={closeModal} />);
    showModal();
  };

  const validationSchema = {
    cardNumber: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cardNumber().required();
    }),
    expDateString: Yup.string().expirationDateString().required(),
    zip: getZipPostalCodeValidationFn(countryCode),
    cvv: Yup.lazy(() => {
      return isEdit ? Yup.string() : Yup.string().cvv().required();
    }),
  };

  const renderZipField = (formik: FormikProps<BrPaymentCardReducedDetailsFormValues>) => {
    return (
      <InputMask
        id="zip"
        type={zipFieldTypeMap[countryCode] || zipFieldTypeDefault}
        mask={zipFieldMaskMap[countryCode] || zipFieldMaskDefault}
        maskChar=""
        inputMode={zipFiledInputModeMap[countryCode] || zipFiledInputModeDefault}
        required
        {...formik.getFieldProps('zip')}
        onFocus={() => {
          setFocusedFields({ ...{}, zip: true });
        }}
      >
        {(inputProps: BrInputProps) => {
          const iconRightConfig = getIconRightConfig(formik, 'zip', focusedFields.zip);
          return (
            <BrAdvancedInput
              {...inputProps}
              onWhat="surface"
              topLabel={zipFieldLableMap[countryCode] || zipFieldLabelDefault}
              className="flex-grow ml-0"
              maxLength={ZIP_POSTAL_MAX_LENGTH}
              placeholder={t('Enter {{zip-post-code}}', {
                'zip-post-code': zipFieldLableMap[countryCode] || zipFieldLabelDefault,
              })}
              hasError={Boolean(
                formik.getFieldMeta('zip').error && formik.getFieldMeta('zip').touched,
              )}
              errorText={formik.getFieldMeta('zip').error}
              ref={zipInputRef}
              iconRight={iconRightConfig?.iconName}
              iconRightClassNames={iconRightConfig?.iconClassNames}
              onIconClickRight={() => {
                handleOnIconRightClick(formik, 'zip', zipInputRef);
              }}
              onChange={formik.getFieldProps('zip').onChange}
              value={formik.getFieldProps('zip').value}
            />
          );
        }}
      </InputMask>
    );
  };

  return (
    <>
      <BrClickBlockerOverlay isActive={isLoading}>
        <Formik
          initialValues={values}
          onSubmit={onSubmit}
          enableReinitialize
          validationSchema={Yup.object().shape(validationSchema)}
        >
          {(formik) => {
            const isSubmitBtnDisabled =
              !formik.dirty ||
              (extraValidation
                ? !formik.isValid || !extraValidation.conditionFunc()
                : !formik.isValid);

            // Avoid changing tooltip text on the fly since it looks weird on UI
            const tooltipsConfigs: (BrTooltipProps | undefined)[] = [
              formik.dirty && formik.isValid
                ? {
                    place: 'top',
                    content: extraValidation?.errorText,
                  }
                : undefined,
              !formik.dirty && formik.isValid
                ? {
                    place: 'top',
                    content: isEdit
                      ? t('Please change your payment card details')
                      : t('Please add your payment card'),
                  }
                : undefined,
              formik.dirty && !formik.isValid
                ? {
                    place: 'top',
                    content: t('Please verify the fields'),
                  }
                : undefined,
            ];

            return (
              <BrCard className={normalizeStringCompound([className, 'overflow-hidden'])}>
                <div className="flex mb-default">
                  <div className="text-body/callout/default mr-auto">
                    {isEdit ? t('Edit payment card') : t('Add payment card')}
                  </div>

                  {hasCancelBtn && (
                    <BrButton onClick={onClose} text={t('Cancel')} cmpType="link" />
                  )}
                </div>
                <Form>
                  <div className="space-y-middle">
                    <BrTooltipWrapper
                      cfg={{
                        place: 'bottom',
                        content: t('Card number cannot be edited'),
                        anchorSelect: '#cardNumber',
                      }}
                    >
                      <InputMask
                        id="cardNumber"
                        mask={isEdit ? '*****' : '9999 9999 9999 9999'}
                        maskChar=""
                        inputMode="numeric"
                        disabled={isEdit}
                        className="test"
                        data-tooltip-id="test"
                        required
                        {...formik.getFieldProps('cardNumber')}
                        onFocus={() => {
                          setFocusedFields({
                            ...{},
                            cardNumber: true,
                          });
                        }}
                      >
                        {(inputProps: BrInputProps) => {
                          const iconRightConfig = getIconRightConfig(
                            formik,
                            'cardNumber',
                            focusedFields.cardNumber,
                          );
                          return (
                            <BrAdvancedInput
                              {...inputProps}
                              onWhat="surface"
                              topLabel={t('Card number')}
                              className="flex-grow"
                              placeholder={t('Enter card number')}
                              isDisabled={isEdit}
                              hasError={Boolean(
                                formik.getFieldMeta('cardNumber').error &&
                                  formik.getFieldMeta('cardNumber').touched,
                              )}
                              errorText={formik.getFieldMeta('cardNumber').error}
                              ref={cardNumberInputRef}
                              iconRight={iconRightConfig?.iconName}
                              iconRightClassNames={iconRightConfig?.iconClassNames}
                              onIconClickRight={() => {
                                handleOnIconRightClick(
                                  formik,
                                  'cardNumber',
                                  cardNumberInputRef,
                                );
                              }}
                            />
                          );
                        }}
                      </InputMask>
                    </BrTooltipWrapper>
                    <div className="flex space-x-middle w-full">
                      <InputMask
                        id="expDateString"
                        mask={EXP_DATE_MASK.replace(/[a-z]/gi, '9')}
                        maskChar=""
                        inputMode="numeric"
                        required
                        {...formik.getFieldProps('expDateString')}
                        onFocus={() => {
                          setFocusedFields({
                            ...{},
                            expDateString: true,
                          });
                        }}
                      >
                        {(inputProps: BrInputProps) => {
                          const iconRightConfig = getIconRightConfig(
                            formik,
                            'expDateString',
                            focusedFields.expDateString,
                          );
                          return (
                            <BrAdvancedInput
                              {...inputProps}
                              onWhat="surface"
                              topLabel={t('Expiration date')}
                              className="flex-grow basis-1/2"
                              placeholder={t('MM / YY')}
                              hasError={Boolean(
                                formik.getFieldMeta('expDateString').error &&
                                  formik.getFieldMeta('expDateString').touched,
                              )}
                              errorText={formik.getFieldMeta('expDateString').error}
                              ref={expDateInputRef}
                              iconRight={iconRightConfig?.iconName}
                              iconRightClassNames={iconRightConfig?.iconClassNames}
                              onIconClickRight={() => {
                                handleOnIconRightClick(
                                  formik,
                                  'expDateString',
                                  expDateInputRef,
                                );
                              }}
                            />
                          );
                        }}
                      </InputMask>
                      {isEdit ? (
                        <div className="flex-grow basis-1/2">
                          {renderZipField(formik)}
                        </div>
                      ) : (
                        <InputMask
                          id="cvv"
                          mask={CVV_INPUT_MASK}
                          maskChar=""
                          inputMode="numeric"
                          required
                          {...formik.getFieldProps('cvv')}
                          onFocus={() => {
                            setFocusedFields({ ...{}, cvv: true });
                          }}
                        >
                          {(inputProps: BrInputProps) => {
                            const iconRightConfig = getIconRightConfig(
                              formik,
                              'cvv',
                              focusedFields.cvv,
                            );
                            return (
                              <BrAdvancedInput
                                {...inputProps}
                                onWhat="surface"
                                topLabel={t('CVV')}
                                className="flex-grow basis-1/2"
                                type="password"
                                iconRight={
                                  formik.values.cvv
                                    ? iconRightConfig?.iconName
                                    : 'information-circle-outline'
                                }
                                iconRightClassNames={iconRightConfig?.iconClassNames}
                                placeholder={isMobile ? t('CVV') : t('Enter CVV')}
                                hasError={Boolean(
                                  formik.getFieldMeta('cvv').error &&
                                    formik.getFieldMeta('cvv').touched,
                                )}
                                ref={cvvInputRef}
                                errorText={formik.getFieldMeta('cvv').error}
                                onIconClickRight={
                                  formik.values.cvv
                                    ? () =>
                                        handleOnIconRightClick(formik, 'cvv', cvvInputRef)
                                    : showCvvModal
                                }
                              />
                            );
                          }}
                        </InputMask>
                      )}
                    </div>
                    {!isEdit && renderZipField(formik)}
                  </div>
                  <div className="mt-default">
                    <BrTooltipWrapper cfg={tooltipsConfigs}>
                      <BrButton
                        className="w-full"
                        type="submit"
                        text={submitBtnText}
                        state={isUpdatingCard || isAddingCard ? 'loading' : 'default'}
                        disabled={isSubmitBtnDisabled}
                      />
                    </BrTooltipWrapper>
                    {isEdit && (
                      <BrButton
                        className="w-full mt-small"
                        cmpType="red-text"
                        state={isDeletingCard ? 'loading' : 'default'}
                        text={t('Delete card')}
                        // TODO: handleId || '1'
                        onClick={onRemove}
                      />
                    )}
                  </div>
                </Form>
              </BrCard>
            );
          }}
        </Formik>
      </BrClickBlockerOverlay>
      {/* CVV Modal */}
      <BrModal
        header={modalHeader}
        isOpen={isModalOpen}
        onClose={closeModal}
        hasCloseButton
      >
        {modalContent}
      </BrModal>
    </>
  );
};

export default BrPaymentCardReducedDetailsForm;
