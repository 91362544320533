import { makeAutoObservable } from 'mobx';

import { fetchImtuPromosFromCarriersV2 } from '@services/api/v1/dtcImtuK2';
import { showToast } from '@services/toasts';

import ImtuPromoV2Class from '@root/models/ImtuPromoV2Class';

class ImtuPromosV2Store {
  isLoading = false;

  promosV2: ImtuPromoV2Class[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  fetchPromos = async () => {
    this.isLoading = true;

    try {
      const imtuPromosV2ResRes = await fetchImtuPromosFromCarriersV2();
      this.promosV2 = imtuPromosV2ResRes.all_promos.map((item) => {
        return new ImtuPromoV2Class(item);
      });
    } catch (err) {
      showToast.error(err);
    } finally {
      this.isLoading = false;
    }
  };
}

export default ImtuPromosV2Store;
