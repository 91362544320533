import React from 'react';
import { useTranslation } from 'react-i18next';

import BrButton from '@components/common/BrButton';

import { ImtuStep } from '../../constants';

interface Props {
  step: ImtuStep;
  onClick?(step: ImtuStep): void;
}

const EditButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { step, onClick } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    onClick?.(step);
  };

  return (
    <BrButton
      cmpType="link"
      className="capitalize"
      onClick={handleClick}
      text={t('edit')}
    />
  );
};

export default EditButton;
