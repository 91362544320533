import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';
import useStore from '@root/hooks/useStore';

import { LoginStore, UserAccountStore } from '@root/stores';

import { Logo } from '@components/common';
import BrSpinner from '@components/common/BrSpinner';

import {
  parseAuthDefaultCountryFromQuery,
  parsePostLoginRedirectUrlQueryParam,
} from '@helpers/urlQuery';

import PhoneLogin from './containers/PhoneLogin';
import SmsCodeVerification from './containers/SmsCodeVerification';
import Header from './containers/Header';

import BackButton from './components/BackButton';

import { LOGIN_PATHS } from './constants';

interface Props {
  onComplete: (postLoginRedirectUrl?: string) => void;
  headerComponent?: JSX.Element;
}

const MODULE_DATA_TEST_PREFIX = 'login';

const Login: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onComplete, headerComponent } = props;

  const loginStore: LoginStore = useStore('LoginStore');
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const handleComplete = () => {
    onComplete(loginStore.postLoginRedirectUrl);
  };

  useDidMount(async () => {
    const parsedAuthDefaultCountry = parseAuthDefaultCountryFromQuery();
    if (parsedAuthDefaultCountry) {
      loginStore.setDefaultCountry(parsedAuthDefaultCountry);
    }
    const postLoginRedirectUrl = parsePostLoginRedirectUrlQueryParam();
    if (postLoginRedirectUrl) {
      loginStore.setPostLoginRedirectUrl(postLoginRedirectUrl);
    }
  });

  return (
    <>
      {(loginStore.isLoading || userAccountStore.isLoading) && <BrSpinner />}
      <Header>{headerComponent || <BackButton containerClassName="mb-4" />}</Header>

      <Logo className="w-32 mx-auto mb-6 mt-4 text-red-400" />

      <Switch>
        <Route
          path={LOGIN_PATHS.BASE}
          exact
          render={() => <PhoneLogin dataTestPrefix={MODULE_DATA_TEST_PREFIX} />}
        />
        <Route
          path={LOGIN_PATHS.PHONE_CODE_VERIFICATION}
          render={() => (
            <SmsCodeVerification
              onComplete={handleComplete}
              dataTestPrefix={MODULE_DATA_TEST_PREFIX}
            />
          )}
        />
      </Switch>
    </>
  );
};

export default observer(Login);
