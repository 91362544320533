import { makeAutoObservable } from 'mobx';

import { getCallingPlans } from '@services/api/v1/dtcCallingPlans';
import { fetchImtuTxns } from '@services/api/v1/dtcImtuK2';

import CountryCode from '@root/interfaces/CountryCode';

import CallingPlan from '@root/models/CallingPlans/CallingPlan';
import { getSortByKeyFn, getSortForKey } from '@utils/array';

class CallingPlansStore {
  activePlans: CallingPlan[] = [];

  // back-end's available plans - do not match our UI in reality
  private availablePlansFromResponse: CallingPlan[] = [];

  nonPurchasablePlans: CallingPlan[] = [];

  private recentUsedCountryCodes: CountryCode[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getSelectedPlanById(id: string) {
    return this.userSelectablePlans.find((plan) => {
      return plan.planId === id;
    });
  }

  fetchPlans = async () => {
    this.setIsLoading(true);
    try {
      const [plansRes, imtuTxnsRes] = await Promise.all([
        getCallingPlans(),
        fetchImtuTxns(),
      ]);

      this.activePlans = plansRes.activePlans.sort(getSortForKey('isManageable', true));
      this.availablePlansFromResponse = plansRes.availablePlans;
      this.nonPurchasablePlans = plansRes.nonPurchasablePlans;
      this.recentUsedCountryCodes = imtuTxnsRes.map((imtuTxn) => {
        return imtuTxn.recipientCountryCode;
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  };

  // based on latest used Imtu country codes
  get suggestedPlans() {
    return this.availablePlansFromResponse.filter((plan) => {
      return plan.destinationCountries.some((destinationCountry) => {
        return this.recentUsedCountryCodes.includes(destinationCountry);
      });
    });
  }

  // not suggested + non-purchasable
  get availablePlans() {
    const suggestedPlanIds = this.suggestedPlans.map((plan) => {
      return plan.planId;
    });
    return this.availablePlansFromResponse
      .filter((plan) => {
        return !suggestedPlanIds.includes(plan.planId);
      })
      .sort(getSortByKeyFn('titleFull'));
  }

  // just all plans
  get allPlans() {
    return [
      ...this.activePlans,
      ...this.availablePlansFromResponse,
      ...this.suggestedPlans,
      ...this.nonPurchasablePlans,
    ];
  }

  // not active plans
  get inactivePlans() {
    return [...this.availablePlansFromResponse, ...this.nonPurchasablePlans];
  }

  // all plans except non-purchasable
  get userSelectablePlans() {
    return [...this.availablePlansFromResponse, ...this.activePlans];
  }

  get destinationCountryCodes(): CountryCode[] {
    const countryCodesAggregated = this.inactivePlans.map((plan) => {
      return plan.destinationCountries;
    });

    const uniqueCountryCodes = Array.from(new Set(countryCodesAggregated.flat()));

    return uniqueCountryCodes;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  get isSavingsPassActive() {
    return this.activePlans.some((plan) => {
      return plan.isSavingsPass;
    });
  }
}

export default CallingPlansStore;
