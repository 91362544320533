import Divider from '@components/common/BrReceipt/components/Divider';

import TopSection from './components/TopSection';
import OrderSummarySection from './components/OrderSummarySection';
import DetailsSection from './components/DetailsSection';
import ImtuProductTitleSection from './components/ImtuProductTitleSection';

export type ProductDetail = {
  title: string;
  text: string;
  actionBtnText?: string;
  onActionBtnClick?(): void;
};

export interface BrReceiptProps {
  total: string;
  productTitle: string;
  productDetails: ProductDetail[];
  paymentDetails: ProductDetail[];
  subTotalDetails?: ProductDetail[];
  imtuProductTitle?: string;
  imtuProductDetails?: ProductDetail[];
  productType?: string;
}

const BrReceipt = (props: BrReceiptProps) => {
  const {
    total,
    productTitle,
    productType,
    productDetails,
    paymentDetails,
    subTotalDetails,
    imtuProductTitle,
    imtuProductDetails,
  } = props;

  return (
    <div className="border-0 rounded-default bg-color/primary overflow-hidden mb-xlarge">
      <div className="py-default space-y-xlarge">
        <TopSection productTitle={productTitle} productType={productType} />
        <Divider />
        <div className="flex flex-col px-default space-y-default">
          <div className={imtuProductTitle ? 'space-y-default' : 'space-y-small'}>
            <DetailsSection data={productDetails} />
            {imtuProductTitle && imtuProductDetails && (
              <>
                <Divider />
                <ImtuProductTitleSection title={imtuProductTitle} />
                <Divider />
                <DetailsSection data={imtuProductDetails} />
                <Divider />
              </>
            )}
            {paymentDetails && <DetailsSection data={paymentDetails} />}
          </div>
          <Divider />
          <OrderSummarySection subTotalDetails={subTotalDetails} total={total} />
        </div>
      </div>
    </div>
  );
};

export default BrReceipt;
