import firebase from 'firebase';

import { PurchaseImtuOptions } from '@root/interfaces/Analytics';
import CountryCode from '@root/interfaces/CountryCode';

import { PURCHASE_ITEM } from './constants';

// eslint-disable-next-line import/prefer-default-export
export const getGaPurchaseImtuParams = ({
  transactionId,
  item,
  amount,
  userCountryCode,
  options,
}: {
  transactionId: string;
  item: firebase.analytics.Item;
  amount: number;
  userCountryCode: CountryCode;
  options: PurchaseImtuOptions;
}) => {
  const { paymentType } = options;

  // FIXME: For some reason not all data fields which are available in GA present in FirebaseAnalyticsTypes types
  const extendedItem: Dictionary<undefined | string | number | object> = {
    ...item,
    price_in_usd: amount,
    // in local currency
    // price: localAmount || amount,
    // FIXME: For some reason when localAmount is assigned to the "price" field then the value of the "price_in_usd" field also becomes equal to localAmount in the Big Query
    price: amount,
    // revenue means price * quantity
    item_revenue_in_usd: amount,
    // item_revenue: localAmount || amount,
    // FIXME: For some reason when localAmount is assigned to the "item_revenue" field then the value of the "item_revenue_in_usd" field also becomes equal to localAmount in the Big Query
    item_revenue: amount,
  };

  const purchaseParams: firebase.analytics.EventParams = {
    affiliation: PURCHASE_ITEM.BRAND,
    currency: PURCHASE_ITEM.CURRENCY,
    items: [extendedItem],
    value: amount,
    transaction_id: transactionId,
    event_category: '', // TODO:
  };

  // TODO: Purchase reporting with a dedicated logPurchase API does not accept custom parameters which are currently needed
  //  for querying most popular POC subPayers on the BMB side; later on the SQL query on the BMB side should be updated
  // analytics().logPurchase(purchaseParams)
  //   .catch((e) => {
  //     console.log("Cannot track GA purchase event", purchaseParams, e);
  //   });

  return {
    // Official params
    ...purchaseParams,
    userCountryCode,
    item_name: item.item_name, // ex: Claro - carrier name
    item_variant: item.item_variant, // ex: GT, MX - CountryCode
    // FIXME: We want to have that but it's not included into the FirebaseAnalyticsTypes.PurchaseEventParameters type
    payment_type: paymentType,
  };
};
