import React from 'react';

import BrCarrier from '@components/common/BrCarrier';
import Flag from '@components/common/Flag';
import Icon from '@components/common/Icon';

import { BrCardPromotionPropsDetailsContentLargeProps } from '@root/interfaces/components/BrCardPromotion';

const LargeContentWithDetails: React.FC<BrCardPromotionPropsDetailsContentLargeProps> = (
  props,
) => {
  const {
    imageUrl,
    countryCode,
    promoTextShort,
    carrierName,
    countryName,
    carrierCode,
    promoTextExtra,
    promoTextLong,
  } = props;

  return (
    <>
      <div
        className="flex flex-col justify-between h-[163px]"
        style={{
          backgroundImage: `linear-gradient(178.61deg, rgba(0, 0, 0, 0.77) 5.57%, rgba(0, 0, 0, 0.66) 19.28%, rgba(0, 0, 0, 0) 50%), url(${imageUrl})`,
          objectFit: 'cover',
        }}
      >
        <div className="px-default pt-default space-y-xsmall">
          <div className="flex flex-row space-x-middle">
            <Flag
              className="w-x5large h-x5large overflow-hidden rounded-full flex-shrink-0 self-center"
              code={countryCode}
            />
            <BrCarrier size="Medium" carrierCode={carrierCode} />
          </div>
          <div className="flex flex-row text-body/caption/medium text-on-colors/on-secondary space-x-small">
            <span>{countryName}</span>
            <span>{carrierName}</span>
          </div>
        </div>
      </div>
      <div className="space-y-small p-default bg-color/surface">
        <div className="text-headers/header-4 text-on-colors/on-primary">
          {promoTextShort}
        </div>
        <div className="flex flex-row items-center space-x-small text-on-colors/on-warning-variant">
          <Icon
            width={12}
            height={12}
            name="egift-solid"
            className="w-default h-default flex-shrink-0 justify-center"
          />
          <div className="text-body/callout/default line-clamp-1">{promoTextLong}</div>
        </div>
        <div className="text-body/footnote/default text-on-colors/on-warning-variant">
          {promoTextExtra}
        </div>
      </div>
    </>
  );
};

export default LargeContentWithDetails;
