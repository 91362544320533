import { normalizeStringCompound } from '@utils/string';

import { BrRadioGroupProps } from '@root/interfaces/components/BrRadio';

import BrAmountRadioButton from './components/BrAmountRadioButton';

const BrRechargeAmountsSelector = (props: BrRadioGroupProps) => {
  const { options, value, className, ...restProps } = props;

  return (
    <div
      className={normalizeStringCompound([
        'grid grid-cols-2 gap-small lg:grid-cols-3',
        className,
      ])}
    >
      {options.map((item) => (
        <BrAmountRadioButton
          checked={value === item.value || undefined}
          key={item.value}
          {...restProps}
          {...item}
        />
      ))}
    </div>
  );
};

export default BrRechargeAmountsSelector;
