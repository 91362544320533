import Lottie from 'lottie-react-web';

import { CallingPlanOrderStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import successAnimation from './animations/successful-order.json';
import failAnimation from './animations/failed-order.json';
import queuedAnimation from './animations/queued-order.json';
import receiptAnimation from './animations/receipt.json';
import subscribedAnimation from './animations/subscribed.json';
import canceledSubscritionAnimation from './animations/canceled-subscription.json';

const statusIconMap: { [statusKey in CallingPlanOrderStatus]: React.ReactNode } = {
  'Success': (
    <Lottie options={{ animationData: successAnimation, loop: false }} ariaRole="img" />
  ),
  'Fail': (
    <Lottie options={{ animationData: failAnimation, loop: false }} ariaRole="img" />
  ),
  'Queued': (
    <Lottie options={{ animationData: queuedAnimation, loop: false }} ariaRole="img" />
  ),
  'Receipt': (
    <Lottie options={{ animationData: receiptAnimation, loop: false }} ariaRole="img" />
  ),
  'Failed variant': (
    <Lottie options={{ animationData: failAnimation, loop: false }} ariaRole="img" />
  ),
  'Subscribed': (
    <Lottie
      options={{ animationData: subscribedAnimation, loop: false }}
      ariaRole="img"
    />
  ),
  'Canceled subscription': (
    <Lottie
      options={{ animationData: canceledSubscritionAnimation, loop: false }}
      ariaRole="img"
    />
  ),
};

interface Props {
  state: CallingPlanOrderStatus;
  className?: string;
}

const BrOrderStatusIllustration = (props: Props) => {
  const { state, className } = props;

  const illustration = statusIconMap[state];

  return <div className={className}>{illustration}</div>;
};

export default BrOrderStatusIllustration;
