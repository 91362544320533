import React from 'react';

import BrSkeleton from '@components/common/BrSkeleton';
import BrCard from '@components/common/BrCard';

const ReceiptSkeleton = () => {
  return (
    <div className="flex flex-col w-full items-center space-y-default">
      <BrCard variant="without-bg">
        <div className="flex flex-col items-center space-y-small">
          <BrSkeleton className="w-[149px] h-[149px] rounded-full" />
          <BrSkeleton className="w-[250px] h-[61px] rounded-default" />
        </div>
      </BrCard>
      <BrSkeleton className="w-full h-[366px] rounded-default" />
    </div>
  );
};

export default ReceiptSkeleton;
