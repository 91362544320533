import { useParams } from 'react-router-dom';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import CallingPlanOrderStatus from '@components/modules/CallingPlanOrderStatus';

interface UrlParams {
  id?: string;
}

const CallingPlansOrderStatusPage = () => {
  const { id: txnId } = useParams<UrlParams>();

  return (
    <BrTemplateWithSidebar>
      {txnId && <CallingPlanOrderStatus txnId={txnId} />}
    </BrTemplateWithSidebar>
  );
};

export default CallingPlansOrderStatusPage;
