import React, { FC, useEffect } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import useStore from '@root/hooks/useStore';

import MaintenanceStore from '@root/stores/MaintenanceStore';
import { UserAccountStore } from '@root/stores';

import { MaintenanceCodes } from '@root/constants/maintenance';

import MaintenanceModal from './components/MaintenanceModal';

import shouldMaintenanceModalOpen from './helpers';

const HealthChecker: FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;

  const location = useLocation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const maintenanceStore = useLocalStore(() => new MaintenanceStore());

  useEffect(() => {
    if (!maintenanceStore.isLoading) {
      maintenanceStore.getAppMaintenanceStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {children}
      {maintenanceStore.isUnderMaintenance &&
        maintenanceStore.maintenanceInfo?.map((maintenanceItem) => {
          const isClosable = [
            MaintenanceCodes.Funding,
            MaintenanceCodes.SignUp,
            MaintenanceCodes.Calling,
          ].includes(maintenanceItem.type);

          const isOpen = shouldMaintenanceModalOpen(
            maintenanceItem.type,
            location.pathname,
          );

          return (
            userAccountStore.currentLanguage && (
              <MaintenanceModal
                isOpen={isOpen}
                message={maintenanceItem.body[userAccountStore.currentLanguage]}
                title={maintenanceItem.title[userAccountStore.currentLanguage]}
                key={maintenanceItem.type}
                iconSrc={maintenanceItem.icon}
                isClosable={isClosable}
              />
            )
          );
        })}
    </>
  );
};

export default observer(HealthChecker);
