import React, { PropsWithChildren } from 'react';

import { normalizeStringCompound } from '@utils/string';

import {
  ContextMenuItemProps,
  MenuProps,
} from '@root/interfaces/components/BrContextMenu';

import MenuItem from './MenuItem';
import MenuItemLanguage from './MenuItemLanguage';
import MenuItemLink from './MenuItemLink';

import './styles.scss';

const baseCls = 'br-context-menu';

const BrContextMenu = <T,>(props: PropsWithChildren<MenuProps<T>>) => {
  const { value, data, where = 'On Dark', onItemSelect } = props;

  const wrapperClassNames = normalizeStringCompound([
    baseCls,
    `${baseCls}-${where === 'On Dark' ? 'on-dark' : 'on-white'}`,
  ]);

  return (
    <ul className={wrapperClassNames}>
      {data.map((item) => {
        const itemProps: ContextMenuItemProps<T> = {
          ...item,
          isActive: item.id === value,
        };
        if ('countryCode' in itemProps) {
          return (
            <MenuItemLanguage
              key={String(item.id)}
              {...itemProps}
              onSelect={onItemSelect}
            />
          );
        }
        if ('path' in itemProps) {
          return (
            <MenuItemLink key={String(item.id)} {...itemProps} onSelect={onItemSelect} />
          );
        }
        return <MenuItem key={String(item.id)} {...itemProps} onSelect={onItemSelect} />;
      })}
    </ul>
  );
};

export default BrContextMenu;
