import React, { useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import BASE_PATHS from '@root/routes/paths';

import useDidUpdate from '@root/hooks/useDidUpdate';
import useDidMount from '@root/hooks/useDidMount';
import useBrModal from '@root/hooks/useBrModal';

import { externalUrlRegexp } from '@root/constants/regexpPatterns';

import CallingRechargeStore from '@root/stores/CallingRechargeStore';
// TODO: move to root/stores
import CallingPlansStore from '@components/modules/CallingPlans/CallingPlansStore';

import { RouteLeavingGuard } from '@components/common';

import AutoRechargeToggle from '@components/common/CallingAutoRechargeToggle';
import CallingRechargeAmountList from '@components/common/CallingRechargeAmountList';
import BrModalConfirmationContent from '@components/common/BrModalConfirmationContent';
import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrButton from '@components/common/BrButton';

import Store from './Store';
import AutoRechargeSkeleton from './Skeleton';

const AutoRecharge = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const callingRechargeStore = useLocalStore(() => new CallingRechargeStore());
  const callingPlansStore = useLocalStore(() => new CallingPlansStore());
  const store = useLocalStore(() => new Store());

  const savingsPassWarningModalContent = (
    onConfirm: () => void,
    onDecline: () => void,
  ) => (
    <BrModalConfirmationContent
      title={t('Turning off Auto-recharge')}
      dismissText={t('Cancel')}
      confirmText={t('Turn Off')}
      onConfirm={onConfirm}
      onDecline={onDecline}
    >
      {t(
        'Auto-recharge must be turned on to keep your Savings Pass active. Are you sure you want to turn off the auto-recharge?',
      )}
    </BrModalConfirmationContent>
  );
  const {
    open: showSavingsPassWarningModal,
    close: closeSavingsPassWarningModal,
  } = useBrModal({
    content: savingsPassWarningModalContent,
    hasCloseButton: false,
  });

  const DATA_TEST_ID_PREFIX = `auto-recharge`;

  useDidMount(async () => {
    await callingRechargeStore.getCallingInfo();
    await callingPlansStore.fetchPlans();
  });

  const isAutoRechargeEnabled = useMemo(() => {
    return (
      callingRechargeStore.isAutoRechargeRecommended ||
      callingRechargeStore.isAutoRechargeSet
    );
  }, [
    callingRechargeStore.isAutoRechargeRecommended,
    callingRechargeStore.isAutoRechargeSet,
  ]);

  useDidUpdate(() => {
    store.setSelectedAmount(callingRechargeStore.paymentInfo?.arAmount);
  }, [callingRechargeStore.paymentInfo?.arAmount]);

  useDidUpdate(() => {
    store.setAutoRechargeEnabled(isAutoRechargeEnabled);
  }, [isAutoRechargeEnabled]);

  const handleAutoRechargeValueChange = (value: boolean) => {
    if (value && callingRechargeStore.paymentInfo) {
      callingRechargeStore.setupAutoRechargeAmount(
        callingRechargeStore.paymentInfo.defaultAutoRechargeAmount,
      );
    } else {
      callingRechargeStore.disableAutoRecharge();
    }

    store.setAutoRechargeEnabled(value);
    if (!value) {
      store.setSelectedAmount(undefined);
      store.setHasUnsavedChanges(false);
    }
  };

  const handleAutoRechargeUpdate = (value: number) => {
    callingRechargeStore.setupAutoRechargeAmount(value, () => {
      history.push(BASE_PATHS.ACCOUNT);
    });
  };

  const handleAmountSelect = (value: string) => {
    store.setSelectedAmount(value);
    store.setAutoRechargeEnabled();
    store.setHasUnsavedChanges();
  };

  const handleAutoRechargeToggleClick = async (value: boolean) => {
    if (callingPlansStore.isSavingsPassActive && !value) {
      const isConfirmed = await showSavingsPassWarningModal();
      closeSavingsPassWarningModal();
      if (!isConfirmed) {
        return;
      }
    }

    handleAutoRechargeValueChange(value);
  };

  const handleArUpdateConfirmation = () => {
    if (store.selectedAmount) {
      handleAutoRechargeUpdate(store.selectedAmount);
      store.setHasUnsavedChanges(false);
    }
  };

  const navigateBack = () => {
    store.setHasUnsavedChanges(false);
    history.goBack();
  };

  const arActiveDescription = t(
    'Choose the amount that will be added to your account whenever your balance falls below {{amount}}.',
    {
      amount: `${callingRechargeStore.paymentInfo?.currencySymbol}${callingRechargeStore.paymentInfo?.thresholdAmount} ${callingRechargeStore.paymentInfo?.currency}`,
    },
  );

  const arDisabledDescription = t(
    'Turn auto-recharge on, then choose an auto-recharge amount.',
  );

  return (
    <>
      <RouteLeavingGuard
        title={t('Do you want to save changes?')}
        when={store.hasUnsavedChanges}
        shouldBlockNavigation={(nextLocation) => {
          return store.hasUnsavedChanges || externalUrlRegexp.test(nextLocation.pathname); // pathname includes https?:// or www.
        }}
        buttonConfig={[
          {
            label: t('Yes'),
            type: 'OK',
            onClick: handleArUpdateConfirmation,
          },
          {
            label: t('No'),
            type: 'Cancel',
            onClick: navigateBack,
          },
        ]}
      />
      {callingRechargeStore.isAnythingLoading || callingPlansStore.isLoading ? (
        <AutoRechargeSkeleton />
      ) : (
        <div className="flex flex-col">
          <p className="text-headers/header-5 text-center mb-middle">
            {t('Choose an auto-recharge amount and never run out of talk time')}
          </p>

          <div className="w-full">
            <CallingRechargeAmountList
              value={store.selectedAmount}
              options={callingRechargeStore.paymentInfo?.creditCardTiers || []}
              onChange={handleAmountSelect}
              className="mx-auto"
              dataTestId={`${DATA_TEST_ID_PREFIX}-select-recharge`}
            />
          </div>

          <div className="justify-center">
            <AutoRechargeToggle
              value={store.isAutoRechargeEnabled}
              onChange={handleAutoRechargeToggleClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-toggler`}
            />
          </div>
          <div>
            <p className="text-body/footnote/demibold text-support-colors/highlights text-center mb-xlarge mt-xsmall">
              {store.isAutoRechargeEnabled ? arActiveDescription : arDisabledDescription}
            </p>
          </div>
        </div>
      )}
      <BrBottomControlsWrapper>
        <BrButton
          className="w-full"
          onClick={handleArUpdateConfirmation}
          disabled={!store.hasUnsavedChanges}
          dataTestId={`${DATA_TEST_ID_PREFIX}-save`}
          text={t('Save changes')}
          state={
            callingRechargeStore.isAnythingLoading || callingPlansStore.isLoading
              ? 'loading'
              : 'default'
          }
        />
      </BrBottomControlsWrapper>
    </>
  );
};

export default observer(AutoRecharge);
