import CountryCode from '@root/interfaces/CountryCode';

import { ASSETS_URL } from '@services/api/v1/constants/cdnAndOthers';

export const getCountryBackgroundImgSrc = (
  countryCode: CountryCode,
  variantIndex = 0,
) => {
  return `${ASSETS_URL.COUNTRY_IMAGES}/${countryCode}/${variantIndex}.jpg`;
};

export const getCountryImgUrlByCountryCode = (code: CountryCode) => {
  return `${ASSETS_URL.COUNTRY_FLAGS}/${code}.svg`;
};
