import qs from 'qs';

const useCallingRechargeTxnParams = () => {
  const qsParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });
  const txnParams =
    'txnParams' in qsParams ? JSON.parse(String(qsParams.txnParams)) : undefined;
  if (
    txnParams &&
    'oldBalance' in txnParams &&
    'newBalance' in txnParams &&
    'cardHandleId' in txnParams
  ) {
    return {
      oldBalance: Number(txnParams.oldBalance),
      newBalance: Number(txnParams.newBalance),
      cardHandleId: String(txnParams.cardHandleId),
      promoAmount: 'promoAmount' in txnParams ? Number(txnParams.promoAmount) : undefined,
    };
  }

  return undefined;
};

export default useCallingRechargeTxnParams;
