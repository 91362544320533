import { useEffect, useLayoutEffect, useState } from 'react';

interface Params {
  isOpen?: boolean;
  inputWrapper?: HTMLDivElement | null;
  dropdown?: HTMLDivElement | null;
}

const useDropdownOpenDirection = (params: Params) => {
  const { isOpen, inputWrapper, dropdown } = params;

  const [dropdownDirection, setDropdownDirection] = useState<'down' | 'up'>('down');

  const calculateOpenDirection = () => {
    if (isOpen && inputWrapper) {
      const inputWrapperRect = inputWrapper.getBoundingClientRect();

      if (inputWrapperRect && dropdown) {
        const availableSpaceBelowDropdown =
          document.documentElement.clientHeight - inputWrapperRect.bottom;

        const availableSpaceAboveDropdown = inputWrapperRect.top;

        const dropdownFullHeight = dropdown.clientHeight;

        const isDropdownHeightIsGreaterThenAvailableSpaceAboveAndBelow =
          dropdownFullHeight > availableSpaceAboveDropdown &&
          dropdownFullHeight > availableSpaceBelowDropdown;

        if (isDropdownHeightIsGreaterThenAvailableSpaceAboveAndBelow) {
          setDropdownDirection('down');
          return undefined;
        }

        setDropdownDirection(
          dropdownFullHeight > availableSpaceBelowDropdown ? 'up' : 'down',
        );
        return undefined;
      }
    }
    return undefined;
  };

  useLayoutEffect(() => {
    calculateOpenDirection();
  }, [isOpen, inputWrapper, dropdown]);

  useEffect(() => {
    window.addEventListener('scroll', calculateOpenDirection, true);

    return () => {
      window.removeEventListener('scroll', calculateOpenDirection);
    };
  }, [isOpen, inputWrapper, dropdown]);

  return dropdownDirection;
};

export default useDropdownOpenDirection;
