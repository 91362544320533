import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getQueryString } from '@utils/url';

type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

interface WizardParams<T> {
  basePath: string;
  steps: Enum<T>;
  initialStep?: number;
}

export default function useWizard<T>(params: WizardParams<T>) {
  const { steps, basePath, initialStep } = params;
  const [filledSteps, setFilledSteps] = useState<number[]>([]);

  const stepPaths = Object.keys(steps)
    .filter((key) => Number.isNaN(parseInt(key, 10)))
    .map((item) => `/${item.toLowerCase()}`);

  const stepsLength = stepPaths.length;

  const history = useHistory();
  const location = useLocation();

  const currentStepPath = `/${location.pathname.split(basePath)[1].split('/')[1]}`;
  const currentStepIndex = stepPaths.findIndex((item) => item === currentStepPath);

  const isLastStep = stepsLength === currentStepIndex + 1;

  const completePrevSteps = (currentStep: number) => {
    setFilledSteps([
      -1,
      ...Array.from({ length: currentStep }).map((item, index) => index),
    ]);
  };

  /**
   * Handle all the steps navigation.
   * Sometimes you don't want to leave redirection in the history so use a flag for that
   * @param step
   * @param replaceHistory
   */
  const redirectToStep = (
    step: number,
    replaceHistory?: boolean,
    persistQueryParams = true,
  ) => {
    completePrevSteps(step);

    const newPath = `${basePath}${stepPaths[step]}${
      persistQueryParams ? getQueryString() : ''
    }`;
    if (replaceHistory) {
      history.replace(newPath);
    } else {
      history.push(newPath);
    }
  };

  const setInitialStep = (stepTo: number) => {
    redirectToStep(stepTo, true);
  };

  /* eslint-disable @typescript-eslint/no-shadow */
  const redirectToNextStep = (params?: {
    persistQueryParams?: boolean;
    replaceHistory?: boolean;
  }) => {
    redirectToStep(
      currentStepIndex + 1,
      params?.replaceHistory,
      params?.persistQueryParams,
    );
  };
  /* eslint-disable @typescript-eslint/no-shadow */

  /**
   * if some steps are skipped - redirect to the first step.
   * if it is not a repeat/prefill transaction mode
   */
  useEffect(() => {
    const hasSkippedSteps = !filledSteps.includes(currentStepIndex - 1);
    if (hasSkippedSteps) {
      redirectToStep(0, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   * Mount / Unmount hook
   */
  useEffect(() => {
    setInitialStep(initialStep ?? 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const availableSteps = filledSteps.map((step) => {
    return step + 1;
  });

  return {
    stepPaths,
    currentStepPath,
    currentStepIndex,
    stepsLength,
    redirectToStep,
    setInitialStep,
    availableSteps,
    redirectToNextStep,
    isLastStep,
  };
}
