import React from 'react';
import { observer, useLocalStore } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';
import useDidUpdate from '@root/hooks/useDidUpdate';

import PaymentCardStore from '@root/stores/PaymentCardStore';

import ImtuTxnDetailsLooperStore from '@components/modules/ImtuReceipt/ImtuTxnDetailsLooperStore';

import Receipt from './components/Receipt';
import BottomControls from './components/BottomControls';
import ReceiptSkeleton from './components/ReceiptSkeleton';

interface Props {
  txnId: string;
}

const ImtuReceipt: React.FunctionComponent<Props> = (props: Props) => {
  const { txnId } = props;

  const imtuTxnDetailsLooperStore = useLocalStore(() => new ImtuTxnDetailsLooperStore());
  const paymentCardStore = useLocalStore(() => new PaymentCardStore());

  useDidMount(() => {
    if (txnId) {
      imtuTxnDetailsLooperStore.updateTxnStatusInLoop(txnId);
    }
  });

  useDidUpdate(() => {
    const handleEditableCardRetrieval = async (cardId: string) => {
      try {
        await paymentCardStore.getEditableCardByHandleId(cardId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('unhandled error', err);
      }
    };
    if (imtuTxnDetailsLooperStore.txn) {
      handleEditableCardRetrieval(imtuTxnDetailsLooperStore.txn.paymentCardId);
    }
  }, [imtuTxnDetailsLooperStore.txn]);

  return (
    <div className="justify-center">
      {imtuTxnDetailsLooperStore.txn ? (
        <>
          <Receipt
            txnStatus={imtuTxnDetailsLooperStore.txn.status}
            countryCode={imtuTxnDetailsLooperStore.txn.recipientCountryCode}
            recipientPhoneNumber={imtuTxnDetailsLooperStore.txn.recipientPhoneNumber}
            carrier={imtuTxnDetailsLooperStore.txn.carrier}
            product={imtuTxnDetailsLooperStore.txn.product}
            paymentInfo={paymentCardStore.editableCard}
            txnErrorCode={imtuTxnDetailsLooperStore.txn.errorCode}
          />
          <BottomControls />
        </>
      ) : (
        <ReceiptSkeleton />
      )}
    </div>
  );
};

export default observer(ImtuReceipt);
