import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  isSelected: boolean;
  promoText?: string;
}

const SelectRechargeOption: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, isSelected, promoText } = props;

  const classNames = normalizeStringCompound([
    'border border-transparent hover:border-color/error px-small py-xsmall rounded-small transition',
    'text-body/primary/bold',
    isSelected ? 'bg-color/error text-white' : undefined,
  ]);

  const bonusClassNames = normalizeStringCompound([
    'text-body/footnote/demibold uppercase absolute pt-2',
    promoText ? 'text-color/error' : 'text-gray-400',
  ]);

  return (
    <div className="relative h-[65px] whitespace-nowrap">
      <div className={classNames}>{children}</div>
      {isSelected && <div className={bonusClassNames}>{promoText}</div>}
    </div>
  );
};

export default SelectRechargeOption;
