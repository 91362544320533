import PATHS from '@root/routes/paths';

import CallingPlans from '@components/modules/CallingPlans';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

const CallingPlansPage = () => {
  return (
    <BrTemplateWithSidebar>
      <CallingPlans basePath={PATHS.CALLING_PLANS} />
    </BrTemplateWithSidebar>
  );
};

export default CallingPlansPage;
