import { FC } from 'react';
import { Helmet } from 'react-helmet';

export interface ThemeInjectorProps {
  isBrTheme?: boolean;
}

const ThemeInjector: FC<ThemeInjectorProps> = (props) => {
  const { isBrTheme } = props;

  return isBrTheme ? (
    <Helmet>
      <meta name="theme-color" content="#f2f2f6" />
      <style>{'body{background: #f2f2f6;}'}</style>
    </Helmet>
  ) : (
    <Helmet>
      <meta name="theme-color" content="#ffffff" />
    </Helmet>
  );
};

export default ThemeInjector;
