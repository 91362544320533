import { ForwardedRef, ReactNode, forwardRef } from 'react';

import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import BrDrawer from '../BrDrawer';

interface Props {
  title?: string;
  searchValue?: string;
  hasSearch?: boolean;
  selectedOption?: BrOptionProps;
  renderMobileSearchInput?: ReactNode;
  isOpen?: boolean;
  onAnimationEnd?(): void;
  onClose?(): void;

  addonBottom?: React.ReactNode;

  overlay?: React.ReactNode;
}

const Drawer = forwardRef(
  (props: Props, ref: ForwardedRef<{ drawerRef: React.RefObject<HTMLDivElement> }>) => {
    const {
      renderMobileSearchInput,

      title,
      isOpen,
      onClose,
      onAnimationEnd,
      addonBottom,
      overlay,
    } = props;

    return (
      <BrDrawer
        isOpen={isOpen}
        ref={ref}
        onClose={onClose}
        onAnimationEnd={onAnimationEnd}
        title={title}
        addonTop={renderMobileSearchInput}
        addonBottom={addonBottom}
      >
        {overlay}
      </BrDrawer>
    );
  },
);

export default Drawer;
