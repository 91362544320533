import getSymbolFromCurrency from 'currency-symbol-map';
import { parsePhoneNumber } from 'libphonenumber-js';

import ImtuTransaction from '@root/models/ImtuTransaction';
import CallingRechargeTransactionClass from '@root/models/CallingRechargeTransactionClass';

import { getCallingApiErrorMessage } from '@services/axios/helpers';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';

import { getFormattedAmount } from '@helpers/money';

import { TransactionItem } from './components/TransactionsList';

import { FilterType } from './Store';

// TODO: review this map - it is mostly related to Money Transfer Statuses
const DISPLAY_STATUSES = {
  SUCCESS: 'Success', // t('Success')
  CANCELLED: 'Cancelled', // t('Cancelled')
  AUTHORIZED: 'Authorized', // t('Authorized')
  PAYER_REJECTED: 'Payer Rejected', // t('Payer Rejected')
  PENDING_CANCELLATION: 'Pending Cancellation', // t('Pending Cancellation')
  FAILED: 'Failed', // t('Failed')
  PURCHASED: 'Purchased', // t('Purchased')
  EXPIRED: 'Expired', // t('Expired')
  PAYMENT: 'Payment', // t('Payment')
  NOT_REDEEMED: 'Not Redeemed', // t('Not Redeemed')
  PENDING_FUNDS: 'Pending funds', // t('Pending funds')
  PENDING: 'Pending', // t('Pending')
  REVIEW: 'Review', // t('Review')
  READY: 'Ready', // t('Ready')
  REFUNDED: 'Refunded', // t('Refunded')
  PROCESSING: 'Processing', // t('Processing')
  FUNDED: 'Funded', // t('Funded')
  REDEEMED: 'Recharged', // t('Recharged')
  UPDATING: 'Updating', // t('Updating')
  WAITING: 'Waiting', // t('Waiting')
  COMPLETED: 'Completed', // t('Completed)
};

const ImtuStatusToDisplayStatusMap: { [key in ImtuStatus]: string } = {
  success: 'Ready', // t('Ready'),
  refunded: 'Refunded', // t('Refunded'),
  scheduled: 'Scheduled', // t('Scheduled'),
  queued: 'Queued', // t('Queued'),
  failed: 'Failed', // t('Failed')
};

const DISPLAY_TYPES = {
  MONET_TRANSFER: 'Money transfer', // t('Money transfer')
  MOBILE_TOP_UP: 'Mobile Top-Up', // t('Mobile Top-Up')
  CALLING_BALANCE_PROMOTION: 'Promotion', // t('Promotion')
  CALLING_BALANCE_REFUND: 'Refund', // t('Refund')
  CALLING_BALANCE_RECHARGE: 'Calling balance recharge', // t('Calling balance recharge')
  CALLING_PLAN: 'Calling plan', // t('Calling plan')
};

export const convertImtuTransactionsToListItems = (
  data: ImtuTransaction[],
): TransactionItem[] => {
  return data.map((item) => {
    const amount = `${
      getSymbolFromCurrency(item.senderCurrency) || ''
    }${getFormattedAmount(item.senderAmount)} ${item.senderCurrency}`;

    return {
      type: FilterType.Imtu,
      id: item.id,
      recipient: parsePhoneNumber(item.recipientId)?.formatInternational() || '',
      date: item.submitDate,
      status: ImtuStatusToDisplayStatusMap[item.status],
      errorText: item.errorCode ? getCallingApiErrorMessage(item.errorCode) : undefined,
      isPending: item.status === 'queued',
      isFailed: item.status === 'failed',
      amount,
      description: DISPLAY_TYPES.MOBILE_TOP_UP,
    };
  });
};

const getCallingRechargeDisplayType = (item: CallingRechargeTransactionClass) => {
  switch (true) {
    case item.isPromotion:
      return DISPLAY_TYPES.CALLING_BALANCE_PROMOTION;
    case item.isRefund:
      return DISPLAY_TYPES.CALLING_BALANCE_REFUND;
    case item.isCallingPlan:
      return DISPLAY_TYPES.CALLING_PLAN;
    default:
      return DISPLAY_TYPES.CALLING_BALANCE_RECHARGE;
  }
};

export const convertCallingRechargesToListItems = (
  data: CallingRechargeTransactionClass[],
): TransactionItem[] => {
  return data.map((item) => {
    let amount = '';
    if (item.amount >= 0) {
      amount = `${item.currencySymbol}${getFormattedAmount(item.amount)} ${
        item.currency
      }`; // $5 USD
    } else {
      amount = `-${item.currencySymbol}${getFormattedAmount(Math.abs(item.amount))} ${
        item.currency
      }`; // -$5 USD
    }

    return {
      // Calling recharge used as fallback here
      type: item.isCallingPlan ? FilterType.CallingPlans : FilterType.CallingRecharge,
      id: item.id,
      displayType: getCallingRechargeDisplayType(item),
      date: item.date,
      status: DISPLAY_STATUSES.COMPLETED,
      amount,
    };
  });
};
