import React from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';

import { normalizeStringCompound } from '@utils/string';

import { LanguageLocale } from '@root/interfaces/Languages';
import CountryCode from '@root/interfaces/CountryCode';

import { GENERAL_TOC_URL_TPL } from '@root/constants/storeUrls';
import {
  getImtuTocDisplayUrl,
  getImtuTocPdfUrl,
  getImtuTocShortTextTpl,
} from './helpers';

interface Props {
  countryCode: CountryCode;
  languageLocale: LanguageLocale;
  className?: string;
}

const ImtuTermsBlock = (props: Props) => {
  const { countryCode, languageLocale, className } = props;

  const { t } = useTranslation();

  const classNames = normalizeStringCompound([className, 'text-body/footnote/default']);

  return (
    <div className={classNames}>
      <div className="text-support-colors/highlights">
        <p>
          <Linkify
            componentDecorator={(href, _, key) => (
              <a
                href={href}
                key={key}
                target="_blank"
                className="text-on-colors/on-primary"
                rel="noreferrer"
              >
                {getImtuTocDisplayUrl({ languageLocale })}
              </a>
            )}
          >
            {t(getImtuTocShortTextTpl({ countryCode }), {
              url: GENERAL_TOC_URL_TPL.replace('{{langLocale}}', languageLocale),
            })}
          </Linkify>
        </p>
        <a
          rel="noreferrer"
          target="_blank"
          className="inline-flex mt-small text-body/footnote/default text-on-colors/on-primary"
          href={getImtuTocPdfUrl(languageLocale)}
        >
          {t('View additional MTU service terms')}
        </a>
      </div>
    </div>
  );
};

export default ImtuTermsBlock;
