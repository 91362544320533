import React, { FC, PropsWithChildren } from 'react';

import BrButton from '../BrButton';

interface Props {
  title?: string;
  dismissText: string;
  confirmText: string;
  onConfirm: () => void;
  onDecline: () => void;
}

const BrModalConfirmationContent: FC<PropsWithChildren<Props>> = ({
  title,
  children,
  dismissText,
  confirmText,
  onDecline,
  onConfirm,
}) => {
  return (
    <>
      {title && <div className="text-headers/header-3 pb-middle">{title}</div>}
      <div className="text-body/primary/default pb-default">{children}</div>
      <div className="flex space-x-default">
        <BrButton
          className="flex-grow basis-1/2"
          cmpType="gray"
          onClick={onDecline}
          text={dismissText}
        />
        <BrButton
          className="flex-grow basis-1/2"
          onClick={onConfirm}
          text={confirmText}
        />
      </div>
    </>
  );
};

export default BrModalConfirmationContent;
