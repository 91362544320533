import React, { useMemo } from 'react';

import { FilterItemProps } from '@root/interfaces/BrFilter';

import BrButton from '@components/common/BrButton';

const FilterItem = <T extends string>(props: FilterItemProps<T>) => {
  const {
    value,
    title,
    color,
    isActive,
    isSingleSelectionOnly = false,
    onSelect,
  } = props;

  const handleItemSelect = () => {
    return onSelect({ value, isSingleSelectionOnly });
  };

  const btnCmpType = useMemo(() => {
    if (isActive) {
      return 'solid';
    }
    if (color === 'Gray') {
      return 'gray';
    }
    return 'white';
  }, [isActive, color]);

  return (
    <BrButton cmpType={btnCmpType} size="small" text={title} onClick={handleItemSelect} />
  );
};

export default FilterItem;
