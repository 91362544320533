import React, { FC } from 'react';
import toast, { Toast } from 'react-hot-toast';

import { normalizeStringCompound } from '@utils/string';

import Icon from '../Icon';

const Toaster: FC<React.PropsWithChildren<Toast>> = (props) => {
  const { id, message, visible, type } = props;

  const handleDismiss = () => {
    toast.dismiss(id);
  };

  const statusImgBg = {
    success: 'bg-green-400',
    error: 'bg-red-400',
    blank: 'bg-yellow-400',
    custom: '',
    loading: '',
  };

  const statusImgClassNames = normalizeStringCompound([
    'flex items-center justify-center w-18 flex-shrink-0',
    statusImgBg[type],
  ]);

  const statusImg = {
    success: <Icon name="Checkmark" className="text-3xl text-black" />,
    error: <Icon name="WarningWithBorder" className="text-3xl text-white" />,
    blank: <Icon name="Info" className="text-3xl text-black" />,
    custom: '',
    loading: '', // TODO - add appropriate status icon
  };

  return visible ? (
    <div className="flex bg-white shadow-full w-full mb-4 relative text-left">
      <div className={statusImgClassNames}>{statusImg[type]}</div>
      <div className="flex justify-between pr-5 pl-8 py-8 flex-grow">
        <div>{message}</div>
        <Icon name="Cross" className="text-black ml-10 text-xl" onClick={handleDismiss} />
      </div>
    </div>
  ) : null;
};

export default Toaster;
