import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { OptionProps } from '@root/interfaces/components/Select';

interface Props extends OptionProps {
  onClick: (option: OptionProps) => void;
  className?: string;
  isActive?: boolean;
}

const Option: FC<React.PropsWithChildren<Props>> = (props) => {
  const { onClick, dataTestId, className, isActive, ...option } = props;

  const handleClick = () => {
    onClick(option);
  };

  return (
    <button
      className={normalizeStringCompound([
        'min-h-[44px] h-full w-full px-middle flex items-center text-left disabled:text-support-colors/highlights text-on-colors/on-primary hover:md:bg-color/primary-variant transition-colors duration-150 text-headers/header-6 disabled:hover:bg-transparent rounded-small md:rounded-none',
        className,
        isActive
          ? 'bg-color/secondary-variant text-white hover:!bg-color/secondary-variant disabled:text-white [&]:disabled:hover:bg-color/secondary-variant'
          : '',
      ])}
      onClick={handleClick}
      data-test-id={dataTestId}
      disabled={option.isDisabled}
    >
      {option.label || option.text}
    </button>
  );
};

export default Option;
