import React, { ReactElement, ReactNode, Children, cloneElement } from 'react';
import { v4 as uuid } from 'uuid';

import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import BrTooltip from '.';

interface TooltipWrapperProps {
  cfg?: BrTooltipProps | (BrTooltipProps | undefined)[];
  children: ReactNode;
}

const BrTooltipWrapper: React.FC<TooltipWrapperProps> = (props) => {
  const { cfg, children } = props;

  const tooltipsConfigs = Array.isArray(cfg) ? cfg : [cfg];

  // Check if the child is single a valid React element
  const child = Children.only(children) as ReactElement;

  const id = child.props.id || `tooltip-wrapper-generated-id-${uuid()}`;

  // Clone the child and add id prop if it doesn't have one
  const childWithId = child.props.id ? child : cloneElement(child, { id });

  const tooltips = tooltipsConfigs.map((cfgItem) => {
    const isTooltipVisible =
      cfg && (cfgItem?.defaultIsOpen || child.props.isDisabled || child.props.disabled);
    return (
      isTooltipVisible &&
      cfgItem && (
        <BrTooltip
          key={cfgItem.id}
          {...cfgItem}
          anchorSelect={cfgItem.anchorSelect || `#${id}`}
        />
      )
    );
  });

  return (
    <>
      {childWithId}
      {tooltips}
    </>
  );
};

export default BrTooltipWrapper;
