import React from 'react';
import Lottie from 'lottie-react-web';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BrSpinnerLogo } from '@src/static/assets/images/br-spinner-logo.svg';

import animation from './liquid_b.json';

const ProcessingOrder = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="relative h-[96px] w-[96px] mb-xxlarge">
        <Lottie options={{ animationData: animation }} ariaRole="img" height={96} />
        <div className="bg-red-400 absolute inset-center z-30 w-[64px] h-[64px] rounded-full">
          <BrSpinnerLogo className="w-full h-full inline-block" />
        </div>
      </div>
      <div className="relative z-30 text-center">
        <div className="text-headers/header-3 text-on-colors/on-secondary mb-xxxlarge">
          <div>{t("We're processing")}</div>
          {t('your order')}
        </div>
        <div className="text-body/primary/default text-on-colors/on-secondary">
          {t('Please wait...')}
        </div>
      </div>
    </>
  );
};

export default ProcessingOrder;
