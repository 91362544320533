export type CallingPlanTxnStatus = 'success' | 'queued' | 'failed';

// TODO: this must be a part of a BrOrderStatus component
export type CallingPlanOrderStatus =
  | 'Success'
  | 'Receipt'
  | 'Fail'
  | 'Failed variant'
  | 'Queued'
  | 'Subscribed'
  | 'Canceled subscription';

// eslint-disable-next-line import/prefer-default-export
export const CALLING_PLANS_STATUSES: {
  [key in 'SUCCESS' | 'QUEUED' | 'FAILED']: CallingPlanTxnStatus;
} = {
  SUCCESS: 'success',
  QUEUED: 'queued',
  FAILED: 'failed',
};

type CallingPlanTxnOrderStatusMap = Record<CallingPlanTxnStatus, CallingPlanOrderStatus>;

export const callingPlanTxnOrderStatusMap: CallingPlanTxnOrderStatusMap = {
  success: 'Success',
  queued: 'Queued',
  failed: 'Fail',
};
