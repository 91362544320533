import React, { useState } from 'react';

import ImtuPromoV2Class from '@root/models/ImtuPromoV2Class';

import BrCardPromotion from '@components/common/BrCardPromotion';

import PromoDetailsModal from '../DetailsModal/PromoDetailsModal';

interface Props {
  data: ImtuPromoV2Class[];
}

const ImtuPromosSection: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data } = props;

  const [selectedPromo, setSelectedPromo] = useState<ImtuPromoV2Class | undefined>(
    undefined,
  );

  const handlePromoSelect = (value: string) => {
    const promoDetails = data.find((item) => {
      return item.id === value;
    });
    if (promoDetails) {
      setSelectedPromo(promoDetails);
    }
  };

  const handlePromoDetailsClose = () => {
    setSelectedPromo(undefined);
  };

  return (
    <>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-middle items-start">
        {data.map((item) => {
          return (
            <BrCardPromotion
              key={item.id}
              size="Small"
              imageUrl={item.imageUrl}
              carrierName={item.carrier.name}
              carrierCode={item.carrier.code}
              countryCode={item.countryCode}
              promoTextLong={item.description}
              onClickProps={{
                id: item.id,
                onClick: handlePromoSelect,
              }}
            />
          );
        })}
      </div>
      <PromoDetailsModal
        isOpen={Boolean(selectedPromo)}
        onRequestClose={handlePromoDetailsClose}
        carrierLogoUrl={selectedPromo?.carrier.logoUrl}
        countryCode={selectedPromo?.countryCode}
        promoDescription={selectedPromo?.description}
        promoEndDate={selectedPromo?.promoEndDate}
        promoTitle={selectedPromo?.title}
      />
    </>
  );
};

export default ImtuPromosSection;
