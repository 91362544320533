import { makeAutoObservable } from 'mobx';

import { validatePromoCode } from '@services/api/v1/calling';
import { showToast } from '@services/toasts';

import CallingPromoClass from '@root/models/CallingPromoClass';

import { ERROR_MESSAGE } from '../../constants';

class Store {
  selectedAmount: number;

  isAutoRechargeEnabled = false;

  isArDescriptionModalOpen = false;

  isArTurnOfConfirmationModalOpen = false;

  promoInfo?: CallingPromoClass;

  appliedPromoCode?: string;

  isPromoValidationLoading = false;

  constructor({
    isAutoRechargeEnabled,
    defaultSelectedAmount,
  }: {
    isAutoRechargeEnabled: boolean;
    defaultSelectedAmount: number;
  }) {
    this.selectedAmount = defaultSelectedAmount;
    this.isAutoRechargeEnabled = isAutoRechargeEnabled;
    makeAutoObservable(this);
  }

  get validatedPromoCode() {
    return this.promoInfo?.code;
  }

  get arPromoBonusPercent() {
    return this.promoInfo?.isAutoRechargePromo ? this.promoInfo?.percentage : undefined;
  }

  setIsAutoRechargeEnabled = (value: boolean) => {
    this.isAutoRechargeEnabled = value;
  };

  showArTurnOffConfirmationModal = () => {
    this.isArTurnOfConfirmationModalOpen = true;
  };

  closeArTurnOffConfirmationModal = () => {
    this.isArTurnOfConfirmationModalOpen = false;
  };

  setSelectedAmount = (value: number | string) => {
    this.selectedAmount = Number(value);
  };

  showArDescriptionModal = () => {
    this.isArDescriptionModalOpen = true;
  };

  closeArDescriptionModal = () => {
    this.isArDescriptionModalOpen = false;
  };

  setAppliedPromoCode = (value?: string) => {
    this.appliedPromoCode = value;
  };

  resetPromo = () => {
    this.promoInfo = undefined;
  };

  getPromoInfoByCode = async ({
    code,
    isArPromo,
  }: {
    code: string;
    isArPromo?: boolean;
  }) => {
    if (this.selectedAmount) {
      this.isPromoValidationLoading = !isArPromo; // do not block UI when hidden auto-recharge promo is validating
      try {
        this.promoInfo = await validatePromoCode(this.selectedAmount, code, isArPromo);
      } catch (err) {
        if (!isArPromo) {
          showToast.error(ERROR_MESSAGE.INVALID_PROMO);
        }
        this.resetPromo();
        this.setAppliedPromoCode(undefined);
      } finally {
        this.isPromoValidationLoading = false;
      }
    }
  };
}

export default Store;
