import React from 'react';
import { Img } from 'react-image';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { Icon } from '@components/common';
import BrCard from '@components/common/BrCard';

import CarrierLogoGeneric from '@components/modules/Imtu/components/CarrierLogoGeneric';

interface Props extends TestIdDataAttr {
  data: ImtuCarrierClass;
  isActive?: boolean;
  onClick: (e: React.MouseEvent, value: string) => void;
}

const BrCarrierCard: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, onClick } = props;

  const handleClick = (e: React.MouseEvent) => {
    onClick(e, data.code);
  };

  return (
    <button onClick={handleClick} className="w-full">
      <BrCard className="flex flex-row flex-shrink-0 w-full items-center space-x-middle lg:hover:shadow-md">
        <div className=" flex flex-shrink-0 items-center overflow-hidden h-[64px]">
          <Img
            className="w-[64px] h-[64px] rounded-full"
            src={data.logoUrl}
            unloader={<CarrierLogoGeneric className="w-xlarge h-xlarge" />}
          />
        </div>
        <div className="text-left flex-1 text-body/primary/default">
          <div className="line-clamp-3">{data.name}</div>
        </div>
        <div className="flex justify-end h-full">
          <Icon
            className="text-color/black"
            width={16}
            height={16}
            name="chevron-forward-outline"
          />
        </div>
      </BrCard>
    </button>
  );
};

export default BrCarrierCard;
