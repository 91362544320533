import { makeAutoObservable } from 'mobx';

import { getRechargeHistory } from '@services/api/v1/calling';
import { showToast } from '@services/toasts';

import CallingRechargeTransactionClass from '@root/models/CallingRechargeTransactionClass';

// Contains Calling Recharge, Promotion and Calling Plan types of transactions
class FundingHistoryStore {
  transactions: CallingRechargeTransactionClass[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getTransactions = async () => {
    try {
      this.isLoading = true;

      this.transactions = await getRechargeHistory();
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.isLoading = false;
    }
  };

  // one day back-end decided to mix calling plans into calling recharge history
  // so now we have this
  get callingPlanTransactions() {
    return this.transactions.filter((txn) => {
      return txn.isCallingPlan;
    });
  }

  get callingRechargeTransactions() {
    return this.transactions.filter((txn) => {
      return !txn.isCallingPlan;
    });
  }

  getTxnById(txnId: string) {
    return this.transactions.find((item) => {
      return item.id === txnId;
    });
  }
}

export default FundingHistoryStore;
