import qs from 'qs';

import {
  AUTH_REDIRECT_URL,
  IMTU_CARRIER_ID,
  IMTU_PHONE_NUMBER,
  IMTU_PRODUCT_ID,
  AUTH_DEFAULT_COUNTRY,
} from '@root/constants/queryParams';

import CountryCode from '@root/interfaces/CountryCode';

import { formatPhoneToE164 } from '@helpers/phone';

export const parsePostLoginRedirectUrlQueryParam = (href?: string): string | null => {
  const hrefToParse = href || window.location.href;
  const parsedQuery = qs.parse(hrefToParse.slice(hrefToParse.indexOf('?')), {
    ignoreQueryPrefix: true,
  });

  return parsedQuery[AUTH_REDIRECT_URL] ? String(parsedQuery[AUTH_REDIRECT_URL]) : null;
};

// TODO: replace properties with constants from constants/queryParams.ts
export interface ImtuTransactionQueryParams {
  phoneNumber?: string;
  carrierId?: string;
  productId?: string;
}

/**
 * Examples:
 * https://account.bossrevolution.com/top-up/new/setup?language=en&productId=765673&carrierId=Movistar&phoneNumber=+522323232323
 * http://localhost:3000/top-up/new/setup?language=en&productId=765673&carrierId=Movistar&phoneNumber=+522323232323
 * http://localhost:3000/top-up-redesign/new/setup?language=en&productId=765673&carrierId=Movistar&phoneNumber=+522323232323
 * @param href
 */
export const parseImtuTransactionQueryParams = (
  href?: string,
): ImtuTransactionQueryParams | null => {
  const hrefToParse = href || window.location.href;
  const parsedQuery = qs.parse(hrefToParse.slice(hrefToParse.indexOf('?')), {
    ignoreQueryPrefix: true,
  });

  const paramsToParse = [IMTU_PHONE_NUMBER, IMTU_CARRIER_ID, IMTU_PRODUCT_ID];

  let result = null;
  if (paramsToParse.some((param) => Boolean(parsedQuery[param]))) {
    result = Object.fromEntries([
      ...paramsToParse.map((param) => {
        return [param, parsedQuery[param]];
      }),
    ]);
  }

  if (result?.[IMTU_PHONE_NUMBER]) {
    result[IMTU_PHONE_NUMBER] = formatPhoneToE164(result[IMTU_PHONE_NUMBER]);
  }

  return result;
};

export const parseAuthDefaultCountryFromQuery = (href?: string): CountryCode | null => {
  const hrefToParse = href || window.location.href;

  const parsedQuery = qs.parse(hrefToParse.slice(hrefToParse.indexOf('?')), {
    ignoreQueryPrefix: true,
  });

  return (parsedQuery[AUTH_DEFAULT_COUNTRY] as CountryCode) || null;
};
