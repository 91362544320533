import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns-tz';

import { normalizeStringCompound } from '@utils/string';

import { Button, Flag, Icon } from '@components/common';

import CountryCode from '@root/interfaces/CountryCode';
import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { FilterType } from '../../Store';

export interface ItemProps extends TestIdDataAttr {
  id: string;
  recipient?: string;
  displayType?: string;
  date: Date;
  status: string;
  amount: string;
  description?: string;
  isPending?: boolean;
  isFailed?: boolean;
  errorText?: string | null;
  countryCode?: CountryCode;
  type: FilterType;
}

interface Props extends ItemProps {
  className?: string;
  onClick?: (id: string, type: FilterType) => void;
  onReceiptClick?: () => void;
}

const Item = (props: Props) => {
  const {
    id,
    className,
    recipient = '',
    displayType = '',
    date,
    amount,
    status,
    description = '',
    countryCode,
    dataTestId,
    type,
    errorText,
    isFailed = false,
    onReceiptClick,
    onClick,
  } = props;

  const { t } = useTranslation();

  const handleCardClick = () => {
    if (onClick) {
      onClick(id, type);
    }
  };

  const classNames = normalizeStringCompound([
    'block rounded-xl w-full text-left bg-color/surface p-default',
    className,
  ]);

  // TODO: not sure about naming this const
  const recipientFullNameWithFallback = recipient
    ? t('To {{recipient}}', { recipient })
    : t('International Money Transfer');

  const renderInner = (
    <>
      <div className="flex">
        <div className="flex-1 mb-xxsmall">
          <div className="text-body/primary/demibold mb-xsmall" data-recording-sensitive>
            {[FilterType.CallingRecharge, FilterType.CallingPlans].includes(type)
              ? displayType
              : recipientFullNameWithFallback}
          </div>
          <div className="text-body/caption/medium mb-small">
            {format(date, `PP '${t('at')}' h:mm a`)}
          </div>
          <div className="flex items-center mb-small">
            {countryCode && (
              <Flag
                code={countryCode}
                className="rounded-full overflow-hidden w-xlarge h-xlarge mr-small"
              />
            )}
            <span className="text-body/primary/default text-support-colors/highlights">
              {description}
            </span>
          </div>
        </div>
        <div>
          <div className="flex flex-col h-full justify-between items-end">
            <div className="text-body/primary/demibold flex">
              <span>{amount}</span>
              {onClick && (
                <Icon name="forward_-_right" className="ml-small w-large h-large" />
              )}
            </div>
            {onReceiptClick && (
              <Button
                shape="text"
                size="lg"
                onClick={onReceiptClick}
                dataTestId={`${dataTestId}-print-receipt`}
              >
                {t('Print confirmation')}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="text-body/primary/default">
        <div>
          {/* TODO: expired and cancelled statuses must be in red color by design */}
          <div
            className={isFailed ? 'text-color/error' : 'text-color/success'}
          >{`${status}${errorText ? `: ${t(errorText)}` : ''}`}</div>
        </div>
      </div>
    </>
  );

  return onClick ? (
    <button
      className={classNames}
      onClick={handleCardClick}
      data-test-id={`${dataTestId}-details`}
    >
      {renderInner}
    </button>
  ) : (
    <div className={classNames}>{renderInner}</div>
  );
};

export default Item;
