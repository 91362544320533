import { makeAutoObservable } from 'mobx';

import { showToast } from '@services/toasts';

import PaymentCardConfig from '@root/models/PaymentCardConfig';

import {
  AddPaymentCardRequest,
  AddPaymentCardWithoutBillingRequest,
  UpdatePaymentCardRequest,
  UpdatePaymentCardWithouBillingRequest,
} from '@root/interfaces/contract/PaymentCards';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import {
  addPaymentCard,
  fetchPaymentCardsConfig,
  removePaymentCardByHandleId,
  updatePaymentCardByHandleId,
  fetchPaymentCardByHandleId,
} from '@services/api/v1/paymentCards';
import PaymentCardClass from '@root/models/PaymentCardClass';

class PaymentCardStore {
  isConfigLoading = false;

  isCardDataLoading = false;

  isDeletingCard = false;

  isAddingCard = false;

  isUpdatingCard = false;

  config?: PaymentCardConfig;

  editableCard: PaymentCardClass | null = null;

  issuer?: PaymentCardIssuerKeys;

  constructor() {
    makeAutoObservable(this);
  }

  getPaymentCardConfigs = async () => {
    this.setIsConfigLoading(true);
    try {
      this.config = await fetchPaymentCardsConfig();
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
    } finally {
      this.setIsConfigLoading(false);
    }
  };

  submitNewCard = async (
    values: AddPaymentCardRequest | AddPaymentCardWithoutBillingRequest,
  ) => {
    this.setIsAddingCard(true);
    try {
      const res = await addPaymentCard(values);
      return res;
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
      throw err;
    } finally {
      this.setIsAddingCard(false);
    }
  };

  updateCard = async (
    values: UpdatePaymentCardRequest | UpdatePaymentCardWithouBillingRequest,
  ) => {
    this.setIsUpdatingCard(true);
    try {
      return await updatePaymentCardByHandleId(values);
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
      throw err;
    } finally {
      this.setIsUpdatingCard(false);
    }
  };

  getEditableCardByHandleId = async (id: string) => {
    this.setIsCardDataLoading(true);
    try {
      const res = await fetchPaymentCardByHandleId(id);
      this.editableCard = res;
    } finally {
      this.setIsCardDataLoading(false);
    }
  };

  getCardIssuer = (cardNumber: string) => {
    return this.config?.getIssuerKeyByCardNumber(cardNumber);
  };

  removeCardByHandleId = async (id: string) => {
    this.setIsDeletingCard(true);
    try {
      await removePaymentCardByHandleId(id);
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
      throw err;
    } finally {
      this.setIsDeletingCard(false);
    }
  };

  setIsCardDataLoading = (value: boolean) => {
    this.isCardDataLoading = value;
  };

  setIsConfigLoading = (value: boolean) => {
    this.isConfigLoading = value;
  };

  setIsDeletingCard = (value: boolean) => {
    this.isDeletingCard = value;
  };

  setIsAddingCard = (value: boolean) => {
    this.isAddingCard = value;
  };

  setIsUpdatingCard = (value: boolean) => {
    this.isUpdatingCard = value;
  };

  get isAnythingLoading() {
    return (
      this.isAddingCard ||
      this.isDeletingCard ||
      this.isUpdatingCard ||
      this.isCardDataLoading ||
      this.isConfigLoading
    );
  }

  setIssuer = (value: PaymentCardIssuerKeys | undefined) => {
    this.issuer = value;
  };

  clearEditableCard = () => {
    this.editableCard = null;
  };
}

export default PaymentCardStore;
