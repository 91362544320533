import React from 'react';
import { useTranslation } from 'react-i18next';
import { UserAccountStore } from '@root/stores';
import useStore from '@root/hooks/useStore';

import { getFallbackLocaleForLegalUrls } from '@helpers/locale';
import { GENERAL_TOC_URL_TPL, PRIVACY_POLICY_URL_TPL } from '@root/constants/storeUrls';

const FooterSection: React.FC<{}> = () => {
  const { t } = useTranslation();
  const userAccountStore: UserAccountStore = useStore('UserAccountStore');
  const fallbackLocale = getFallbackLocaleForLegalUrls(
    userAccountStore.currentLanguageLocale,
  );

  const footemLinks = [
    {
      id: 'x1',
      text: t('Terms and Conditions'),
      to: GENERAL_TOC_URL_TPL.replace('{{langLocale}}', fallbackLocale),
      target: '_blank',
    },
    {
      id: 'x2',
      text: t('Privacy Policy'),
      to: PRIVACY_POLICY_URL_TPL.replace('{{langLocale}}', fallbackLocale),
      target: '_blank',
    },
  ];

  return (
    <div className="lg:fixed bottom-0 left-0 right-0">
      <div className="md:br-container br-container-x-gutter lg:pl-[var(--br-sidebar-width)] text-center pb-xlarge space-y-small">
        <div className="space-x-middle">
          {footemLinks.map((item, index) => (
            <React.Fragment key={item.id}>
              {Boolean(index) && (
                <span className="border-r border-solid border-on-colors/on-primary-variant text-body/footnote/default" />
              )}
              <a
                href={item.to}
                target={item.target}
                className="text-body/footnote/default"
              >
                {item.text}
              </a>
            </React.Fragment>
          ))}
        </div>
        <p className="text-body/caption-2/default text-support-colors/highlights">
          {t('Copyright © {{currentYear}} IDT Corporation. All rights reserved.', {
            currentYear: new Date().getFullYear(),
          })}
        </p>
      </div>
    </div>
  );
};

export default FooterSection;
