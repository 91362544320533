import UserAccountProfile from '@components/modules/UserAccountProfile';
import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

const UserAccountProfilePage = () => {
  return (
    <BrTemplateWithSidebar>
      <UserAccountProfile />
    </BrTemplateWithSidebar>
  );
};

export default UserAccountProfilePage;
