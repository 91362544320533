import BrSkeleton from '@components/common/BrSkeleton';

const AccountOptionButtonSkeleton = () => {
  return (
    <>
      <BrSkeleton className="h-[98px] rounded-default " />
      <BrSkeleton className="h-[98px] rounded-default " />
      <BrSkeleton className="h-[98px] rounded-default " />
      <BrSkeleton className="h-[98px] rounded-default " />
    </>
  );
};

export default AccountOptionButtonSkeleton;
