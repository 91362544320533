import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { CallingPlanTxnStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import { ImtuStatus } from '@root/interfaces/MobileTopUp';

import { normalizeStringCompound } from '@utils/string';

import BrBadge, { BrBadgeProps } from '../BrBadge';
import BrButton from '../BrButton';

type CompletedOrderProduct = {
  title: string;
  status?: CallingPlanTxnStatus | ImtuStatus;
};

interface Props {
  expirationTerm?: number;
  isProcessing?: boolean;
  descriptionText?: string;
  products: (CompletedOrderProduct | undefined)[];
  onViewReceiptClick?(): void;
}

const statusBrBadgeMap: {
  [statusKey in CallingPlanTxnStatus | ImtuStatus]: {
    classType: BrBadgeProps['classType'];
    text: string;
  };
} = {
  success: {
    classType: 'success-variant',
    text: 'Active', // t('Active')
  },
  failed: {
    classType: 'error-variant',
    text: 'Failed', // t('Failed')
  },
  queued: {
    classType: 'warning-variant',
    text: 'Queued', // t('Queued')
  },
  // action is not supported so the case is irrelevant
  scheduled: {
    classType: 'success-variant',
    text: 'Scheduled', // t('Scheduled')
  },
  // action is not supported so the case is irrelevant
  refunded: {
    classType: 'success-variant',
    text: 'Refunded', // t('Refunded')
  },
};

const BrCallingPlanCompletedOrder: FC<PropsWithChildren<Props>> = (props) => {
  const {
    expirationTerm,
    isProcessing,
    descriptionText,
    children,
    products,
    onViewReceiptClick,
  } = props;

  const { t } = useTranslation();

  const isComboPlan = products?.length > 1;

  const productsToRender = products
    .filter((item): item is CompletedOrderProduct => {
      return Boolean(item);
    })
    .map((product) => (
      <div key={product.title} className="flex">
        <span className="text-body/callout/default pr-xsmall line-clamp-2">
          {product.title}
        </span>
        {product.status && (
          <span className="leading-none">
            <BrBadge
              classType={statusBrBadgeMap[product.status].classType}
              styleType="less-contrast"
              text={t(statusBrBadgeMap[product.status].text)}
            />
          </span>
        )}
      </div>
    ));

  return (
    <div className="flex flex-col items-center space-y-middle">
      <div className="flex flex-col items-center py-xsmall space-y-small">
        {isComboPlan && (
          <BrBadge
            iconLeft="sparkles"
            classType="attention"
            styleType="less-contrast"
            text={t('Combo plan')}
          />
        )}

        {isComboPlan ? (
          <div className="border-solid border border-support-colors/lines rounded-middle p-middle space-y-small">
            {productsToRender}
          </div>
        ) : (
          productsToRender
        )}

        {isProcessing && (
          <div
            className={normalizeStringCompound([
              'text-body/callout/default text-center',
              isComboPlan ? 'mt-small' : 'mb-xsmall',
            ])}
          >
            {t('Processing')}
          </div>
        )}
      </div>

      {expirationTerm && (
        <div className="text-body/caption/default mb-middle text-center text-support-colors/highlights">
          <span className="mr-small">{t('Subscription')}</span>
          {t('Every {{expirationTerm}} days', {
            expirationTerm,
          })}
        </div>
      )}
      {children && <div className="w-full">{children}</div>}
      <div className="text-body/caption-2/default max-w-[200px] text-center text-support-colors/highlights ">
        {descriptionText}
      </div>
      {onViewReceiptClick && (
        <BrButton
          onClick={onViewReceiptClick}
          cmpType="link"
          size="small"
          text={t('View receipt')}
        />
      )}
    </div>
  );
};

export default BrCallingPlanCompletedOrder;
