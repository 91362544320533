import React, { FC, useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';

import useDidMount from '@root/hooks/useDidMount';
import useDidUpdate from '@root/hooks/useDidUpdate';
import useStore from '@root/hooks/useStore';
import { UserAccountStore } from '@root/stores';

import CallingPlansStore from '@components/modules/CallingPlans/CallingPlansStore';

import Status from './components/Status';
import OrderStatusSkeleton from './components/OrderStatusSkeleton';

import CallingTxnDetailsLooperStore from './CallingTxnDetailsLooperStore';

import useRedirect3DsCallingPlans from './useRedirect3DsCallingPlans';

interface Props {
  txnId: string;
  dataTestPrefix?: string;
}

const CallingPlanOrderStatus: FC<React.PropsWithChildren<Props>> = (props) => {
  const { txnId } = props;

  const callingPlanTnTxnDetailsLooperStore = useLocalStore(
    () => new CallingTxnDetailsLooperStore(),
  );
  const callingPlansStore = useLocalStore(() => new CallingPlansStore());

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  useDidMount(() => {
    if (txnId) {
      callingPlanTnTxnDetailsLooperStore.updateTxnStatusInLoop(txnId);
    }
  });

  useDidUpdate(() => {
    if (
      callingPlanTnTxnDetailsLooperStore.callingPlanTxn?.status === 'success' &&
      !callingPlansStore.isLoading
    ) {
      callingPlansStore.fetchPlans();
    }
  }, [callingPlanTnTxnDetailsLooperStore.callingPlanTxn?.status]);

  const currentPlan = useMemo(() => {
    return callingPlansStore.activePlans?.find((item) => {
      return (
        item.productId ===
        callingPlanTnTxnDetailsLooperStore.callingPlanTxn?.callingPlanInfo.productId
      );
    });
  }, [callingPlansStore.activePlans]);

  const { callingPlanTxn, imtuTxn } = callingPlanTnTxnDetailsLooperStore;

  useRedirect3DsCallingPlans(callingPlanTxn, imtuTxn);

  /* eslint-disable @typescript-eslint/indent */
  const receiptFavoriteNumbersCfg = useMemo(() => {
    return currentPlan?.planId &&
      currentPlan?.maxFavoriteNumbers &&
      currentPlan.destinations[0].countryCode
      ? {
          planId: currentPlan.planId,
          maxFavoriteNumbers: currentPlan.maxFavoriteNumbers,
          countryCode: currentPlan.destinations[0].countryCode,
        }
      : undefined;
  }, [currentPlan]);
  /* eslint-enable @typescript-eslint/indent */

  return (
    <>
      {callingPlanTxn ? (
        <Status
          txn={callingPlanTxn}
          imtuTxn={imtuTxn}
          userCountryOfOrigin={userAccountStore.userCountryOfOrigin}
          receiptFavoriteNumbersCfg={receiptFavoriteNumbersCfg}
        />
      ) : (
        <OrderStatusSkeleton />
      )}
    </>
  );
};

export default observer(CallingPlanOrderStatus);
