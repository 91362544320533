import * as Yup from 'yup';
import { addYears, isFuture } from 'date-fns';
import i18next from '@nodeModules/i18next';

import US_STATES from '@root/constants/usStates';
import CA_PROVINCES from '@root/constants/caProvinces';
import { onlyDigitsRegexp } from '@root/constants/regexpPatterns';
import { MIN_DIAL_CODE_LENGTH } from '@root/constants/phone';
import { LEGAL_AGE_YEARS, MIN_AGE_DATE_YEAR } from '@root/constants/financeProfile';
import {
  CARD_CVV_MAX_LENGTH,
  CARD_CVV_MIN_LENGTH,
} from '@root/constants/paymentCards/paymentCardsData';

import { parseDateFromExpDateString } from '@helpers/paymentCards';
import isValidPaymentCard from '@helpers/validations/paymentCardValidator';

import { FORMS_ERROR_TEXTS } from '@root/constants/formErrors';

import { FORM_ERROR_MESSAGE, ZIP_CODE_LENGTH, ZIP_CODE_FIVE_ZEROS } from './constants';

const nameFieldRegexp = /^[a-zéáóúüñí\s'-]+$/i; // with support of spanish special characters
const usStateCodeRegexp = new RegExp(`(${US_STATES.join('|')})$`, 'i'); // US states not case sensitive
const usStateAndCaProvinceCodeRegexp = new RegExp(
  `(${[...US_STATES, ...CA_PROVINCES].join('|')})$`,
  'i',
);
const digitsOnlyRegexp = new RegExp(onlyDigitsRegexp);
const canadaProviceCodeRegexp = new RegExp(/^[a-zA-Z0-9]{3}\s?[a-zA-Z0-9]{3}$/);
export const ukPostCodeRegexp = new RegExp(
  // brought from BR.com UK classic website
  /^([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)$/,
);

const maxLegalAgeDate = addYears(new Date(), -LEGAL_AGE_YEARS);
const minAgeDate = new Date(MIN_AGE_DATE_YEAR, 1, 1);

const CVV_LABEL = 'CVV'; // t('CVV');
const ZIP_LABEL = 'ZIP code'; // t('ZIP code')

// schemas
const baseStringSchema = Yup.string().trim().max(254);
const onlyLettersStringScheme = Yup.string()
  .trim()
  .matches(
    nameFieldRegexp,
    (inputLabelObj) =>
      `${FORM_ERROR_MESSAGE.ONLY_LETTERS_QUOTES_HYPHEN_ALLOWED} in ${inputLabelObj.label}`,
  );
const usStateCode = Yup.string()
  .trim()
  .length(2)
  .matches(usStateCodeRegexp, FORM_ERROR_MESSAGE.INVALID_STATE_CODE);

const usStateAndCaProvinceCode = Yup.string()
  .trim()
  .length(2, FORM_ERROR_MESSAGE.INVALID_LENGTH_STATE_PROVINCE_REGION_CODE)
  .matches(
    usStateAndCaProvinceCodeRegexp,
    FORM_ERROR_MESSAGE.UNKNOWN_STATE_PROVINCE_REGION_CODE,
  );

const baseEmail = Yup.string().trim().email(FORM_ERROR_MESSAGE.INVALID_EMAIL);

const internationalPhoneNumber = Yup.string()
  .trim()
  .min(MIN_DIAL_CODE_LENGTH, FORM_ERROR_MESSAGE.EMPTY_ERROR_MESSAGE)
  .test('internationalPhoneNumber', FORM_ERROR_MESSAGE.INVALID_PHONE_NUMBER, (value) => {
    return Boolean(value?.includes('+'));
  });

const cardNumber = Yup.string().test(
  'cardNumber',
  i18next.t(FORMS_ERROR_TEXTS.INVALID_CARD_NUMBER),
  (val) => (val ? isValidPaymentCard(val.replace(/\s/g, '')) : true),
);

const expirationDateString = Yup.string().test(
  'expDateString',
  i18next.t(FORMS_ERROR_TEXTS.INVALID_EXPIRATION_DATE),
  (val) => {
    const newDate = parseDateFromExpDateString(val);
    if (newDate) {
      return isFuture(newDate);
    }
    return false;
  },
);

const cvv = Yup.string()
  .label(CVV_LABEL)
  .min(CARD_CVV_MIN_LENGTH)
  .max(CARD_CVV_MAX_LENGTH);

const dateOfBirthLegal = Yup.date()
  .min(minAgeDate, () => {
    return i18next.t(FORM_ERROR_MESSAGE.DOB_MIN, {
      yearsValue: MIN_AGE_DATE_YEAR,
    });
  })
  .max(maxLegalAgeDate, () => {
    return i18next.t(FORM_ERROR_MESSAGE.DOB_MAX_LEGAL, {
      yearsValue: LEGAL_AGE_YEARS,
    });
  });

const zipCode = Yup.string()
  .label(ZIP_LABEL)
  .trim()
  .length(ZIP_CODE_LENGTH)
  .matches(digitsOnlyRegexp, FORM_ERROR_MESSAGE.MUST_BE_DIGITS_ONLY)
  .test('zipCodeInValidRange', FORM_ERROR_MESSAGE.INVALID_ZIP_CODE, (zipCodeValue) => {
    return zipCodeValue !== ZIP_CODE_FIVE_ZEROS;
  });

const postalCodeCa = Yup.string()
  .trim()
  .matches(canadaProviceCodeRegexp, FORM_ERROR_MESSAGE.INVALID_ZIP_POSTAL_CODE);

const postCodeUk = Yup.string()
  .trim()
  .matches(ukPostCodeRegexp, FORM_ERROR_MESSAGE.INVALID_POST_CODE);

// methods. Update yup.d.ts after adding a new method
Yup.addMethod<Yup.StringSchema>(Yup.string, 'base', () => baseStringSchema);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'letters', () => onlyLettersStringScheme);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'usStateCode', () => usStateCode);
Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'usStateAndCaProvinceCode',
  () => usStateAndCaProvinceCode,
);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'baseEmail', () => baseEmail);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'zip', () => zipCode);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'postalCode', () => postalCodeCa);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'postCode', () => postCodeUk);
Yup.addMethod<Yup.DateSchema>(Yup.date, 'dateOfBirthLegal', () => dateOfBirthLegal);
Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'internationalPhoneNumber',
  () => internationalPhoneNumber,
);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'cardNumber', () => cardNumber);
Yup.addMethod<Yup.StringSchema>(
  Yup.string,
  'expirationDateString',
  () => expirationDateString,
);
Yup.addMethod<Yup.StringSchema>(Yup.string, 'cvv', () => cvv);

// Looks like to complete BRP-1258 and localise all the error messages we will have to  add it manually
// here is a list of standard messages of Yup https://github.com/jquense/yup/blob/master/src/locale.ts
Yup.setLocale({
  mixed: {
    required: () => {
      return i18next.t(FORM_ERROR_MESSAGE.REQUIRED);
    },
  },
  string: {
    max: () => {
      return i18next.t(FORM_ERROR_MESSAGE.MAX_LENGTH_LIMIT);
    },
  },
});

export default Yup;
