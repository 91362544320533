import { BrCheckboxProps } from '@root/interfaces/components/BrCheckbox';

import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

const BrToggle: React.FC<BrCheckboxProps> = (props) => {
  const { name, className, labelText, descriptionText, ...restProps } = props;

  const hasDescriptionSection = labelText || descriptionText;

  return (
    <div className={normalizeStringCompound(['br-toggle', className])}>
      <label htmlFor={name} className="br-toggle--label">
        <input type="checkbox" className="br-toggle--input" id={name} {...restProps} />
        <div className="br-toggle--control-wrapper">
          <div className="br-toggle--control">
            <div className="br-toggle--control-circle" />
          </div>
          {hasDescriptionSection && (
            <div className="br-toggle--description">
              {labelText && <div className="br-toggle--label-text">{labelText}</div>}
              {descriptionText && (
                <div className="br-toggle--description-text">{descriptionText}</div>
              )}
            </div>
          )}
        </div>
      </label>
    </div>
  );
};

export default BrToggle;
