/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { Icon, PromoCodeForm } from '@components/common';
import BrPriceInfo from '@components/common/BrPriceInfo';
import BrCard from '@components/common/BrCard';
import BrOfferCard from '@components/common/BrOfferCard';
import BrButton from '@components/common/BrButton';

import BrAlert from '@components/common/BrAlert';

import { ProductSubtype } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';

import { ImtuStep } from '../../constants';

import RecipientInfo from './RecipientInfo';
import CarrierInfo from './CarrierInfo';

const planSubTypeMap: { [key in ProductSubtype]: 'Data' | 'Bundle' | 'Top Up' } = {
  'Mobile Bundle': 'Bundle', // t('Bundle')
  'Mobile Data': 'Data', // t('Data')
  'Mobile Top Up': 'Top Up', // t('Top Up')}
};

interface Props {
  countryCode: CountryCode;
  recipientPhoneNumber?: string;
  carrier?: ImtuCarrierClass;
  product?: ImtuProductClass;
  promoInfo?: ImtuPromoOfferDetailsClass;
  isLoading?: boolean;
  currentStepIndex?: number;
  onPromoApply?(promoCode: string): void;
  onPromoChange?(): void;
  onEditStepSelect?(selectedStep: ImtuStep): void;
}

const ImtuTxnInfoSection: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    countryCode,
    recipientPhoneNumber,
    carrier,
    product,
    promoInfo,
    isLoading,
    currentStepIndex,
    onPromoApply,
    onPromoChange,
    onEditStepSelect,
  } = props;

  const { t } = useTranslation();

  const rowHeaderClassNames = ' capitalize text-body/primary/demibold';

  const handleEditStepSelect = (stepToEdit: ImtuStep) => {
    onEditStepSelect?.(stepToEdit);
  };

  const handleApplyPromo = async (value: string) => {
    onPromoApply?.(value);
  };

  const handlePromoCodeFormValuesChange = () => {
    onPromoChange?.();
  };

  const isCarrierSectionVisible =
    (recipientPhoneNumber && currentStepIndex === ImtuStep.Carrier) ||
    (currentStepIndex && currentStepIndex >= ImtuStep.Plan);
  const isPromoSectionVisible = currentStepIndex === ImtuStep.Summary;

  return (
    <>
      {isCarrierSectionVisible ? (
        <BrCard className="space-y-middle mb-default">
          {/* TODO: replace to ListItem with type Recipient info https://www.figma.com/design/K8DBHEsr9QXbXIATpi0us1/BR6-**-Design-System?node-id=382-14803&node-type=frame&t=rEyZrZIgE2zFceb3-0 */}
          <RecipientInfo
            countryCode={countryCode}
            phone={recipientPhoneNumber as string}
            step={ImtuStep.Phone}
            onEdit={handleEditStepSelect}
          />
          {carrier && (
            <CarrierInfo
              logoUrl={carrier.logoUrl}
              name={carrier.name}
              step={ImtuStep.Carrier}
              onEdit={handleEditStepSelect}
              className="pt-middle border-t border-support-colors/lines"
            />
          )}
        </BrCard>
      ) : null}

      {product && (
        <BrOfferCard
          id={product.classId}
          planTypeText={planSubTypeMap[product.subType]}
          promoBadgeText={product.carrierPromo?.title}
          title={product.name}
          onEditBtnClick={() => handleEditStepSelect(ImtuStep.Plan)}
        >
          {product.carrierPromo?.description && (
            <BrAlert
              header={t('Promotion details')}
              icon="egift-solid"
              text={product.carrierPromo?.description}
              classType="warning-variant"
              styleType="with-header"
              className="mb-middle !whitespace-normal"
            />
          )}
          {product.description && (
            <div className="space-y-small">
              <div className="flex space-x-xsmall">
                <Icon name="product-fill" width={14} height={14} />
                <div className="text-body/callout/default">{t('Product Details')}</div>
              </div>
              <div className="whitespace-break-spaces text-body/callout/default text-on-colors/on-surface">
                {product.description}
              </div>
            </div>
          )}
        </BrOfferCard>
      )}

      {product?.senderAmount ? (
        <BrCard>
          <BrPriceInfo
            isExpandedDefault
            extraFields={
              product?.fee !== undefined
                ? [
                    { title: t('Subtotal'), valueText: product.senderBaseAmountText },
                    { title: t('Service Fee'), valueText: product.feeText || '' },
                  ]
                : undefined
            }
            totalText={
              promoInfo?.priceTotalDiscountedText || product?.senderTotalAmountText
            }
          />

          {isPromoSectionVisible && (
            <div className="flex justify-center mt-small">
              <BrButton
                hasIconLeft
                iconName="sparkles"
                cmpType="link"
                cmpStyle="default"
                size="small"
                text={t('Top up promo code')}
              />
            </div>
          )}
        </BrCard>
      ) : null}

      {/* TODO: old promo section, should be removed after implementing new one */}
      {isPromoSectionVisible && (
        <BrCard>
          <div className="py-default">
            <div className={rowHeaderClassNames}>{t('promo code')}</div>
            <div className="pt-2">
              <PromoCodeForm
                onFormValuesChange={handlePromoCodeFormValuesChange}
                onSubmit={handleApplyPromo}
                initialValues={{
                  promoCode: promoInfo?.promoId || '',
                }}
                placeholder={t('Enter promo code')}
                isApplied={Boolean(promoInfo)}
                isLoading={isLoading}
              />
            </div>
          </div>
          {promoInfo && (
            <div className="flex flex-row justify-between text-xl lg:text-lg pt-2">
              <div className="pr-2">{promoInfo.description}</div>
              <div className="flex-shrink-0 w-32 lg:w-30 text-right text-blue-400 whitespace-nowrap mt-auto">{`- ${promoInfo.discountText}`}</div>
            </div>
          )}
        </BrCard>
      )}
    </>
  );
};

export default ImtuTxnInfoSection;
