import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import CountryCode from '@root/interfaces/CountryCode';

import { promoTermsText } from '../../constants';

import DetailsModal from './index';

interface Props extends React.ComponentProps<typeof DetailsModal> {
  countryCode?: CountryCode;
  carrierLogoUrl?: string;
  promoTitle?: string;
  promoDescription?: string;
  promoEndDate?: Date;
}

const PromoDetailsModal: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    testId,
    countryCode,
    carrierLogoUrl,
    promoTitle,
    promoDescription,
    promoEndDate,
    isOpen,
    onRequestClose,
  } = props;

  const { t } = useTranslation();

  return (
    <DetailsModal
      testId={testId}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      countryCode={countryCode}
      carrierLogoUrl={carrierLogoUrl}
    >
      <div className="flex flex-col gap-4">
        <div>
          <div className="text-2xl font-bold">{promoTitle}</div>
          {promoEndDate && (
            <div className="text-gray-400 capitalize">
              {t('expires')} {format(promoEndDate, 'MM/dd/yyyy h:mm a')}
            </div>
          )}
        </div>
        <div>
          <div className="text-gray-400 text-xl md:text-lg">
            {promoDescription?.split('\r\n').map((l) => (
              <p key={l}>{l}</p>
            ))}
          </div>
        </div>
      </div>
      <div className="border-gray-200 border-t my-5" />
      <p className="md:text-sm">{t(promoTermsText)}</p>
    </DetailsModal>
  );
};

export default PromoDetailsModal;
