import { makeAutoObservable } from 'mobx';

import ImtuTransaction from '@root/models/ImtuTransaction';

import { fetchImtuTxns } from '@services/api/v1/dtcImtuK2';
import { showToast } from '@services/toasts';

import { removeDuplicates } from '@utils/array';

const MAX_RECENT_TRANSACTIONS = 3;
const MAX_RECENT_CONTACTS = 6;

// TODO: move to root/stores since it is used across multiple modules
class ImtuHistoryStore {
  transactions: ImtuTransaction[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getTransactions() {
    this.setIsLoading(true);
    try {
      this.transactions = await fetchImtuTxns();
    } catch (err) {
      showToast.error(err.message);
    } finally {
      this.setIsLoading(false);
    }
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  get recentTxns() {
    return this.transactions.slice(0, MAX_RECENT_TRANSACTIONS);
  }

  get recentContactsTxns() {
    return removeDuplicates(this.transactions, 'recipientPhoneNumber').slice(
      0,
      MAX_RECENT_CONTACTS,
    );
  }
}

export default ImtuHistoryStore;
