import React from 'react';

import BrButton from '@components/common/BrButton';

interface Props {
  className?: string;
  isToggled?: boolean;
  onChange?(): void;
}

const ToggleButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { onChange, isToggled, className } = props;

  const handleChange = () => {
    onChange?.();
  };

  return (
    <BrButton
      className={className}
      onClick={handleChange}
      cmpType="text"
      cmpStyle="circle"
      hasIconLeft
      iconName={isToggled ? 'close' : 'menu'}
    />
  );
};

export default ToggleButton;
