import { useEffect, useRef } from 'react';

const useScrollBlock = () => {
  const html = document.documentElement;
  const { body } = document;

  const scroll = useRef(false);

  const blockScroll = () => {
    if (scroll.current) return;

    const scrollBarWidth = window.innerWidth - html.clientWidth;

    const bodyPaddingRight =
      parseInt(window.getComputedStyle(body).getPropertyValue('padding-right'), 10) || 0;

    /**
     1. Fixes a bug in iOS and desktop Safari whereby setting
        `overflow: hidden` on the html/body does not prevent scrolling.
     2. Fixes a bug in desktop Safari where `overflowY` does not prevent
        scroll if an `overflow-x` style is also applied to the body.
     */
    html.style.position = 'relative';
    body.style.position = 'relative';
    html.style.overflow = 'hidden';
    body.style.overflow = 'hidden';
    body.style.paddingRight = `${bodyPaddingRight + scrollBarWidth}px`;

    scroll.current = true;
  };

  const allowScroll = () => {
    if (!body?.style || !scroll.current) return;

    html.style.position = '';
    html.style.overflow = '';
    body.style.position = '';
    body.style.overflow = '';
    body.style.paddingRight = '';

    scroll.current = false;
  };

  useEffect(() => {
    return () => allowScroll();
  }, []);

  return { blockScroll, allowScroll };
};

export default useScrollBlock;
