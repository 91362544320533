// eslint-disable-next-line import/prefer-default-export
export const legacyIconToFileNamesMap = {
  Atm: 'legacy-atm',
  Bank: 'legacy-bank',
  BossMoneyLogo: 'legacy-boss-money-logo',
  CallHistory: 'legacy-call-history',
  Camera: 'legacy-camera',
  Card: 'legacy-card',
  CreditCard: 'legacy-card-credit',
  DebitCard: 'legacy-card-debit',
  PrepaidCard: 'legacy-card-prepaid',
  Cash: 'legacy-cash',
  Chat: 'legacy-chat',
  ChatFilled: 'legacy-chat-filled',
  Checkmark: 'legacy-ic_checkmark',
  ChevronDown: 'legacy-chevron-down',
  ChevronDownAlt: 'legacy-chevron-down-alt',
  ChevronLeft: 'legacy-chevron-left',
  ChevronLeftAlt: 'legacy-chevron-left-alt',
  ChevronRight: 'legacy-chevron-right',
  ChevronRightAlt: 'legacy-chevron-right-alt',
  ChevronUp: 'legacy-chevron-up',
  Clipboard: 'legacy-clipboard',
  Clock: 'legacy-clock',
  ClockAlt: 'legacy-clock-alt',
  CloseCircle: 'legacy-close-circle',
  Contact: 'legacy-contact',
  Cross: 'legacy-ic_close',
  Delete: 'legacy-delete',
  Edit: 'legacy-edit',
  Ellipsis: 'legacy-ellipsis',
  Eyeglass: 'legacy-eyeglass',
  Forward: 'legacy-forward',
  GetLocation: 'legacy-get-location',
  HomeDelivery: 'legacy-home-delivery',
  Key: 'legacy-key',
  Lock: 'legacy-lock',
  Logout: 'legacy-logout',
  Mail: 'legacy-mail',
  Mentions: 'legacy-mentions',
  Menu: 'legacy-menu',
  MobileTopUp: 'legacy-mobile-top-up',
  MoneyTransfer: 'legacy-money-transfer',
  OpenCircle: 'legacy-open-circle',
  Phone: 'legacy-phone',
  Plus: 'legacy-plus',
  PlusAlt: 'legacy-plus-alt',
  SendMessage: 'legacy-send',
  Smile: 'legacy-smile',
  Store: 'legacy-store',
  StoreAlt: 'legacy-store-alt',
  SupportCircle: 'legacy-support-circle',
  SupportCircleAlt: 'legacy-ic_help',
  UsdCircle: 'legacy-usd-circle',
  UsdCircleAlt: 'legacy-usd-circle-alt',
  UserIdCard: 'legacy-id-card',
  UserCircle: 'legacy-user-circle',
  UserCircleAlt: 'legacy-user-circle-alt',
  Video: 'legacy-video',
  Wallet: 'legacy-wallet',
  WalletAlt: 'legacy-wallet-alt',
  Warning: 'legacy-warning',
  Repeat: 'legacy-repeat',
  WarningWithBorder: 'legacy-warningWithBorder',
  Info: 'legacy-info',
  Language: 'legacy-language',
  ExchangeIcon: 'legacy-ic-exchange',
  ChevronDownIcon: 'legacy-ic-chevron-down',
  EraseIcon: 'legacy-ic-erase',
  SearchIcon: 'legacy-ic-search',
  AttentionIcon: 'legacy-ic-attention',
  CloseIcon: 'legacy-ic-close',
  ImageThumb: 'legacy-image-thumb',
  ArrowUp: 'legacy-arrow-up',
  Loading: 'legacy-ic-loading',
  PencilEdit: 'legacy-ic_edit',
  ChevronBack: 'legacy-chevron-back',
  AutoRechargeIcon: 'legacy-auto-recharge',
  BossBSolid: 'legacy-bossB-solid',
  StarOutline: 'legacy-star-outline',
  CloseXSolid: 'legacy-close-x-solid',
};
