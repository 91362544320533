/**
 * @param amount
 * @param maxDecimal default 2
 * @param maxZeros default 2
 * @returns example: 123,456,789.12
 */
export const getFormattedAmount = (
  amount?: number | string,
  maxDecimal = 2,
  maxZeros = 2,
) => {
  const numberAmount = Number(amount);

  if (Number.isNaN(numberAmount)) {
    return '0';
  }

  let formattedAmount = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: maxDecimal,
  }).format(numberAmount);

  const split = formattedAmount.split('.');

  if (split[1]) {
    let decimal = split[1].substring(0, maxDecimal);

    const missingDecimals = maxDecimal - decimal.length;

    if (missingDecimals < maxZeros) {
      decimal = decimal.concat(Array(missingDecimals).fill(0).join(''));
    } else {
      decimal = decimal.concat(Array(maxZeros).fill(0).join(''));
    }

    formattedAmount = `${split[0]}.${decimal}`;
  }

  return formattedAmount;
};

/**
 * Default money formatting
 *
 * 1 -> 1.00 USD
 * 35.5323 -> 35.53 USD
 *
 * @param amount
 * @param currencyText - USD, CAD etc.
 */
export const formatMoneyToText = (amount: number, currencyText: string) => {
  return `${amount.toFixed(2)} ${currencyText}`;
};
