import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import PATHS from '@root/routes/paths';

import BrBottomControlsWrapper from '@components/common/BrBottomControlsWrapper';
import BrButton from '@components/common/BrButton';

interface Props {
  dataTestPrefix?: string;
}

const Receipt: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { dataTestPrefix } = props;

  const { t } = useTranslation();
  const history = useHistory();

  const handleHistoryButtonClick = () => {
    history.push(PATHS.TRANSACTIONS_HISTORY);
  };

  const handleReceiptClick = () => {
    window.print();
  };

  const handleSendAnotherTopupBtnClick = () => {
    history.push(PATHS.IMTU);
  };

  return (
    <BrBottomControlsWrapper className="print:hidden">
      <div className="w-full items-center justify-center space-y-middle">
        <BrButton
          onClick={handleSendAnotherTopupBtnClick}
          text={t('send another top-up')}
          className="first-letter-uppercase w-full"
          dataTestId={`${dataTestPrefix}-next-btn`}
        />
        <BrButton
          className="first-letter-uppercase w-full"
          cmpType="text"
          hasIconLeft
          iconName="time-outline"
          onClick={handleHistoryButtonClick}
          dataTestId={`${dataTestPrefix}-next-btn`}
          text={t('see transaction history')}
        />
        <BrButton
          cmpType="text"
          hasIconLeft
          iconName="print-outline"
          className="first-letter-uppercase w-full"
          onClick={handleReceiptClick}
          dataTestId={`${dataTestPrefix}-next-btn`}
          text={t('print confirmation')}
        />
      </div>
    </BrBottomControlsWrapper>
  );
};

export default observer(Receipt);
