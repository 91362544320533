import React from 'react';

// Custom icons exclusive for this component
import { ReactComponent as NotFoundIcon } from '@src/static/assets/images/not-found.svg';
import { ReactComponent as NoWifiIcon } from '@src/static/assets/images/no-wifi.svg';
import { ReactComponent as NoSubscriptionsIcon } from '@src/static/assets/images/no-subscriptions.svg';
import { ReactComponent as NoDestinationsIcon } from '@src/static/assets/images/no-destination.svg';
import { ReactComponent as NonMobileIcon } from '@src/static/assets/images/non-mobile.svg';

import { normalizeStringCompound } from '@utils/string';

type cmpType = 'notFound' | 'noWiFi' | 'noSubscriptions' | 'noDestination' | 'nonMobile';

interface Props {
  type: cmpType;
  onWhat?: 'background' | 'surface';
}

const iconsMap: Record<cmpType, React.ReactNode> = {
  notFound: <NotFoundIcon />,
  noWiFi: <NoWifiIcon />,
  noSubscriptions: <NoSubscriptionsIcon />,
  noDestination: <NoDestinationsIcon />,
  nonMobile: <NonMobileIcon />,
};

const BrIllustration: React.FunctionComponent<Props> = (props: Props) => {
  const { type, onWhat = 'background' } = props;

  const wrapperClassNames = normalizeStringCompound([
    onWhat === 'background' ? 'text-color/surface' : 'text-color/background',
  ]);

  return <div className={wrapperClassNames}>{iconsMap[type]}</div>;
};

export default BrIllustration;
