import { IconNames } from '@root/interfaces/components/Icon';

import { iconNameFromUrlWithoutExtensionRegexp } from '@root/constants/regexpPatterns';

const urlToIconNameMap: Record<string, IconNames> = {
  smart_phone_icon: 'phone-portrait-outline',
  globe: 'globe',
};

// eslint-disable-next-line import/prefer-default-export
export const convertDestinationUrlToIconName = (
  url?: string,
): IconNames | undefined | null => {
  if (!url) {
    return null;
  }

  const urlIconNameWithoutExtension = url.match(
    iconNameFromUrlWithoutExtensionRegexp,
  )?.[1];
  if (urlIconNameWithoutExtension) {
    return urlToIconNameMap[urlIconNameWithoutExtension];
  }

  return null;
};
