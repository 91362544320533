import React from 'react';

import PATHS from '@root/routes/paths';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import Calling from '@components/modules/Calling';

const CallingPage = () => {
  return (
    <BrTemplateWithSidebar>
      <Calling basePath={PATHS.CALLING} />
    </BrTemplateWithSidebar>
  );
};

export default CallingPage;
