import React from 'react';
import { format, isPast } from 'date-fns';
import { useTranslation } from 'react-i18next';

import PaymentCardClass from '@root/models/PaymentCardClass';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import VisaImgSrc from '@src/static/assets/images/cc-visa.png';
import MasterCardImgSrc from '@src/static/assets/images/cc-mastercard.png';

import { PAYMENT_CARD_BRANDS } from '@root/constants/paymentCards/paymentCardIssuers';
import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';

import ProfilePaymentCardsItem from './ProfilePaymentCardsItem';

interface Props {
  data: PaymentCardClass[];
  onSelect(id: string): void;
  dataTestPrefix?: string;
}

const ProfilePaymentCards: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { data, onSelect, dataTestPrefix } = props;

  const { t } = useTranslation();

  const paymentCardName = {
    V: PAYMENT_CARD_BRANDS.VISA,
    MC: PAYMENT_CARD_BRANDS.MASTERCARD,
    AE: PAYMENT_CARD_BRANDS.AMERICAN_EXPRESS,
    D: PAYMENT_CARD_BRANDS.DISCOVER,
  };

  // TODO improve solution with img
  const ccImg: { [key in PaymentCardIssuerKeys]: React.ReactNode } = {
    V: <img src={VisaImgSrc} alt={paymentCardName.V} className="w-10" />,
    MC: <img src={MasterCardImgSrc} alt={paymentCardName.MC} className="w-10" />,
    AE: null,
    D: null,
  };

  const paymentCardsList = data.map((item) => ({
    id: item.handleId,
    icon: item.paySource ? ccImg[item.paySource] : null,
    title: item.paySource ? paymentCardName[item.paySource] : null,
    number: item.maskedNumber.toUpperCase(),
    holder: item.accountHolder || t('Your Name'),
    expDate: item.expDate,
  }));

  return (
    <>
      {paymentCardsList.map((item, index) => (
        <div key={item.id}>
          <ProfilePaymentCardsItem
            id={item.id}
            image={item.icon}
            title={item.title}
            text={[
              item.number,
              item.holder,
              <span
                className={isPast(item.expDate) ? 'text-color/error' : ''}
                key={item.id}
              >
                {format(item.expDate, CARD_EXP_DATE_FORMAT)}
              </span>,
              isPast(item.expDate) && (
                <span className="text-color/error" key={item.id}>
                  {t('This card is expired')}
                </span>
              ),
            ]}
            buttonText={t('Edit card')}
            onButtonClick={onSelect}
            dataTestId={`${dataTestPrefix}-payment-card-edit-btn-${index}`}
          />
        </div>
      ))}
    </>
  );
};

export default ProfilePaymentCards;
