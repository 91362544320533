import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterItemCfg } from '@root/interfaces/BrFilter';
import CallingPlan from '@root/models/CallingPlans/CallingPlan';

type Filters = 'all' | 'subscription' | 'oneTime' | 'combo';

interface FilterCfgOption extends FilterItemCfg<Filters> {
  filterFn(plans: CallingPlan): boolean;
}

const useCallingPlansFilter = () => {
  const { t } = useTranslation();

  const [values, setValues] = useState<Filters[]>(['all']);

  const filtersCfg: FilterCfgOption[] = [
    {
      title: t('All'),
      value: 'all',
      isSingleSelectionOnly: true,
      filterFn: (plan) => {
        return true;
      },
    },
    {
      title: t('Subscriptions'),
      value: 'subscription',
      filterFn: (plan) => {
        return plan.isSubscriptionType;
      },
    },
    {
      title: t('One time plans'),
      value: 'oneTime',
      filterFn: (plan) => {
        return !plan.isSubscriptionType;
      },
    },
    {
      title: t('Combo plans'),
      value: 'combo',
      filterFn: (plan) => {
        return plan.isCombo;
      },
    },
  ];

  const handleFilterSelect = (newValues: Filters[]) => {
    setValues(newValues);
  };

  const filterFnsPipe = (data: CallingPlan[]): CallingPlan[] => {
    const fns = filtersCfg
      .filter((cfgItem) => {
        return values.includes(cfgItem.value);
      })
      .map((cfgItem) => {
        return cfgItem.filterFn;
      });

    const result = data.filter((plan) => {
      return fns.some((fn) => {
        return fn(plan);
      });
    });

    return result;
  };

  const filterOptions = filtersCfg.map((filterCfgItem) => {
    return {
      title: filterCfgItem.title,
      value: filterCfgItem.value,
      isSingleSelectionOnly: filterCfgItem.isSingleSelectionOnly,
    };
  });

  return {
    filterOptions,
    filterFnsPipe,
    handleFilterSelect,
  };
};

export default useCallingPlansFilter;
