import React from 'react';
import Lottie from 'lottie-react-web';

import animation from './blue-circle-loader.json';

const Loading = () => {
  return <Lottie options={{ animationData: animation }} ariaRole="img" height={64} />;
};

export default Loading;
