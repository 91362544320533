import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
}

const DisclaimerWrapper: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;

  return (
    <div
      className={normalizeStringCompound([
        'text-body/footnote/default text-support-colors/highlights',
        className,
      ])}
    >
      {children}
    </div>
  );
};

export default DisclaimerWrapper;
