import { normalizeStringCompound } from '@utils/string';

import { BrRadioProps } from '@root/interfaces/components/BrRadio';

import './styles.scss';

const BrAmountRadioButton = (props: BrRadioProps) => {
  const { id, className, label, ...restProps } = props;

  return (
    <div className={normalizeStringCompound(['br-amount-radio-item', className])}>
      <label htmlFor={id}>
        <input type="radio" className="sr-only" {...restProps} id={id} />
        <div className="br-amount-radio-item--button">{label}</div>
      </label>
    </div>
  );
};

export default BrAmountRadioButton;
