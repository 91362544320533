import React from 'react';
import { useParams } from 'react-router-dom';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import CallingRechargeReceipt from '@components/modules/CallingRechargeReceipt';

interface UrlParams {
  id: string;
}

const CallingRechargeReceiptPage = () => {
  const { id } = useParams<UrlParams>();

  return (
    <BrTemplateWithSidebar>
      <CallingRechargeReceipt txnId={id} />
    </BrTemplateWithSidebar>
  );
};

export default CallingRechargeReceiptPage;
