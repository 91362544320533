import BrSkeleton from '@components/common/BrSkeleton';

const PlansListSkeleton = () => {
  return (
    <div className="flex flex-col space-y-xxlarge">
      <div className="flex flex-col space-y-small">
        <BrSkeleton className="w-[128px] h-[22px] rounded-default" />
        <BrSkeleton className="w-full h-[47px] rounded-default" />
      </div>
      <div className="flex flex-col space-y-middle ">
        <BrSkeleton className="w-[128px] h-[20px] rounded-default" />
        <BrSkeleton className="w-full h-[180px] md:h-[144px] rounded-default" />
        <div className="overflow-x-auto br-container-x-gutter py-default">
          <div className="flex space-x-small h-[32px]">
            <BrSkeleton className="w-[30px] rounded-small" />
            <BrSkeleton className="w-[120px] rounded-small" />
            <BrSkeleton className="w-[120px] rounded-small" />
            <BrSkeleton className="w-[120px] rounded-small" />
          </div>
        </div>
        <BrSkeleton className="w-[120px] h-[22px] rounded-default" />
        <BrSkeleton className="w-full h-[180px] md:h-[144px] rounded-default" />
        <BrSkeleton className="w-full h-[180px] md:h-[144px] rounded-default" />
        <BrSkeleton className="w-full h-[180px] md:h-[144px] rounded-default" />
        <BrSkeleton className="w-full h-[180px] md:h-[144px] rounded-default" />
        <BrSkeleton className="w-full h-[180px] md:h-[144px] rounded-default" />
      </div>
    </div>
  );
};

export default PlansListSkeleton;
