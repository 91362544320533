/* eslint-disable @typescript-eslint/indent */
import {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Formik, Form } from 'formik';

import BrAdvancedInput, { BrAdvancedInputRef } from '@components/common/BrAdvancedInput';

import { FormConfig } from '@root/interfaces/components/Form';
import CountryCode from '@root/interfaces/CountryCode';
import BrInputProps from '@root/interfaces/components/BrInput';

import {
  getPhoneDialCodeByCountryCode,
  getPhoneInfoByNumber,
  getPhoneMaskByCountryCode,
  isValidInternationalPhoneNumber,
} from '@helpers/phone';

import { normalizeStringCompound } from '@utils/string';
import Yup from '@utils/validation/yup';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { getIconCfg, ICON_CLEAR_CFG } from '../../helpers';

interface Props {
  phone?: string;
  countryCode: CountryCode;
  className?: string;
  onSubmit?(value: string): void;

  onChange?(params: { phoneNumber: string }): void;
  onValidation?(isValid: boolean): void;
}

const CLEARED_VALUE = '';

const PhoneForm = forwardRef((props: Props, ref) => {
  const { phone, countryCode, onSubmit, onChange, className, onValidation } = props;

  const { t } = useTranslation();

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [isBlured, setIsBlured] = useState(false);

  const hiddenSubmitButtonRef = useRef<HTMLButtonElement>(null);
  const brAdvancedInputRef = useRef<BrAdvancedInputRef>(null);

  useOnClickOutside(() => {
    setIsBlured(true);
  }, brAdvancedInputRef.current?.inputWrapperRef || { current: undefined });

  useImperativeHandle(ref, () => {
    return {
      submit: () => {
        hiddenSubmitButtonRef.current?.click();
      },
    };
  });

  const initialValues = {
    phone: phone || `${getPhoneDialCodeByCountryCode(countryCode)}`,
  };

  const formInitialProps: FormConfig<{ phone: string }> = useMemo(
    () => ({
      enableReinitialize: true,
      initialValues,
      validationSchema: Yup.object().shape({
        phone: Yup.string()
          .ensure()
          .label('phone')
          .test(
            'is valid phone number',
            t('The number that you entered does not look like a valid phone number'),
            (value: string) => {
              if (value) {
                onValidation?.(isValidInternationalPhoneNumber(value));
                return isValidInternationalPhoneNumber(value);
              }
              onValidation?.(false);
              return false;
            },
          )
          .test(
            'does phone belongs to country',
            t('Wrong country number'),
            (value: string) => {
              onValidation?.(
                countryCode === getPhoneInfoByNumber(value).country &&
                  isValidInternationalPhoneNumber(value),
              );
              return countryCode === getPhoneInfoByNumber(value).country;
            },
          ),
      }),
      onSubmit: (values: { phone: string }) => {
        onSubmit?.(values.phone);
      },
      validateOnMount: true,
    }),
    [countryCode, phone],
  );

  const MASK_PREFIX = '+';
  const phoneNumberInputMask = `+${getPhoneMaskByCountryCode(countryCode)}`;

  useLayoutEffect(() => {
    setIsInitialRender(true);
  }, [countryCode]);

  return (
    <Formik {...formInitialProps}>
      {(formik) => {
        const iconRightCfg =
          formik.isValid || isInitialRender
            ? undefined
            : getIconCfg(formik.values.phone, Boolean(formik.errors.phone), isBlured);

        const handleOnIconClickRight =
          iconRightCfg?.iconName === ICON_CLEAR_CFG.iconName &&
          !isBlured &&
          formik.values.phone !== CLEARED_VALUE &&
          formik.values.phone !== MASK_PREFIX
            ? () => {
                formik.resetForm({
                  values: { phone: CLEARED_VALUE },
                });
                brAdvancedInputRef.current?.inputRef.current?.focus();
              }
            : undefined;

        const handleOnFocus = () => {
          setIsBlured(false);
          setIsInitialRender(false);
          formik.setTouched({ phone: true });
          formik.validateForm();
        };

        const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          setIsInitialRender(false);
          formik.setFieldValue('phone', e.target.value);
          onChange?.({
            phoneNumber: e.target.value,
          });
        };

        const hasSuccess =
          !isInitialRender &&
          formik.isValid &&
          formik.touched.phone &&
          Boolean(formik.values.phone.replaceAll('+', ''));

        return (
          <Form className={normalizeStringCompound(['w-full', className])}>
            <InputMask
              mask={phoneNumberInputMask}
              maskChar=""
              inputMode="numeric"
              prefix={MASK_PREFIX}
              onFocus={handleOnFocus}
              {...formik.getFieldProps('phone')}
              onChange={handleOnChange}
            >
              {(inputProps: BrInputProps) => (
                <BrAdvancedInput
                  {...inputProps}
                  placeholder={t('Enter the phone number')}
                  ref={brAdvancedInputRef}
                  iconRight={iconRightCfg?.iconName}
                  iconRightClassNames={iconRightCfg?.iconClassNames}
                  onIconClickRight={handleOnIconClickRight}
                  hasSuccess={hasSuccess}
                  onWhat="surface"
                />
              )}
            </InputMask>
            {/* Hidden submit button - submit triggers from the outside of the form */}
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button ref={hiddenSubmitButtonRef} type="submit" className="hidden" />
          </Form>
        );
      }}
    </Formik>
  );
});

export default PhoneForm;
