import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BASE_PATHS from '@root/routes/paths';

// Didn't find elegant way to use nested paths as variables.
// It will lead to importing the stuff from modules
const pathToTitleMap: Record<string, string> = {
  '/': '', // you can use this for testing in storybook, but do not forget to delete test name
  // Calling plans
  [BASE_PATHS.CALLING_PLANS]: 'Calling plans', // t('Calling Plans'),
  [`${BASE_PATHS.CALLING_PLAN_RECEIPT}/:id`]: 'Receipt', // t('Receipt'),
  [`${BASE_PATHS.CALLING_PLANS}/setup`]: 'Select calling plan', // t('Select calling plan'),
  [`${BASE_PATHS.CALLING_PLANS}/summary`]: 'Order confirmation', // t('Order confirmation'),
  [`${BASE_PATHS.CALLING_PLANS}/manage`]: 'Plan management', // t('Order confirmation'),
  [`${BASE_PATHS.CALLING}/recharge`]: 'Recharge Balance', // t('Recharge Balance')
  [`${BASE_PATHS.CALLING_AUTO_RECHARGE}`]: 'Manage auto-recharge', // t('Manage auto-recharge')')
  [BASE_PATHS.TRANSACTIONS_HISTORY]: 'Transaction history', // t('Transaction history')
  [BASE_PATHS.ACCOUNT_PROFILE]: 'My Profile', // t('My Profile')
  [BASE_PATHS.CALLING_RECHARGE_RECEIPT]: 'recharge balance', // t('recharge balance')
};
function getTitleFromPath(pathname: string) {
  // Check for static paths first
  if (pathToTitleMap[pathname]) {
    return pathToTitleMap[pathname];
  }

  const entryFoundByDynamicParam = Object.entries(pathToTitleMap).find(([path]) => {
    const regex = path.replace(/:[^/]+/g, '([^/]+)'); // Replace :dynamicParam with a regex
    return new RegExp(`^${regex}$`).exec(pathname);
  });

  return entryFoundByDynamicParam?.[1];
}

const usePageTitle = (): { pageTitle: string | undefined } => {
  const location = useLocation();
  const { t } = useTranslation();

  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    const title = getTitleFromPath(location.pathname);
    setPageTitle(t(title));
  }, [location, t]);

  return { pageTitle };
};

export default usePageTitle;
