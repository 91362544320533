import { useEffect } from 'react';

import storageInterface from '@services/storage';
import { CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

import CallingPlanTxn from '@root/models/CallingPlans/CallingPlanTxn';
import ImtuTransaction from '@root/models/ImtuTransaction';

const useRedirect3DsCallingPlans = (
  callingPlanTxn?: CallingPlanTxn,
  imtuTxn?: ImtuTransaction,
) => {
  useEffect(() => {
    if (
      !imtuTxn &&
      callingPlanTxn?.callingPlanInfo.redirectUrl3Ds &&
      callingPlanTxn?.callingPlanInfo.transactionId3Ds
    ) {
      storageInterface.setItem(
        CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY,
        callingPlanTxn.callingPlanInfo.transactionId3Ds,
      );
      window.location.href = callingPlanTxn.callingPlanInfo.redirectUrl3Ds;
    }
  }, [
    callingPlanTxn?.callingPlanInfo.redirectUrl3Ds,
    callingPlanTxn?.callingPlanInfo.transactionId3Ds,
    imtuTxn,
  ]);
};

export default useRedirect3DsCallingPlans;
