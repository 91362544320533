import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { normalizeStringCompound } from '@utils/string';

import BrDropdown, { BrDropdownRef } from '../BrDropdown';

import { isScrolledToBottom } from '../BrScrollShadowWrapper/useBrScrollShadowWrapperLogic';

interface Props {
  className?: string;
  dropdownOpenDirection?: 'up' | 'down';
  dropdownMaxHeight?: number;
  isOpen?: boolean;
  style?: React.CSSProperties;
  addonBottom?: React.ReactNode;
  onAnimationEnd?(): void;
  overlay?: React.ReactNode;
  paddingVariant?: 'small' | 'default';
}

const DEFAULT_SELECT_DROPDOWN_MAX_HEIGHT = 300;

const Dropdown = forwardRef<HTMLDivElement | undefined, Props>((props, ref) => {
  const {
    isOpen,
    addonBottom,
    dropdownMaxHeight = DEFAULT_SELECT_DROPDOWN_MAX_HEIGHT,
    onAnimationEnd,
    className,
    dropdownOpenDirection = 'down',
    style,
    overlay,
    paddingVariant,
  } = props;

  const [isAddonBottomTopShadowVisible, setIsAddonBottomTopShadowVisible] = useState(
    false,
  );

  const dropdownRef = useRef<BrDropdownRef>(null);

  useImperativeHandle(ref, () => {
    return dropdownRef.current?.dropdownEl ?? undefined;
  });

  const handleOnScroll = (e: Event) => {
    if (e.target) {
      setIsAddonBottomTopShadowVisible(!isScrolledToBottom(e.target as HTMLElement));
    }
  };

  useEffect(() => {
    const { current: dropdown } = dropdownRef;
    if (
      addonBottom &&
      dropdown?.dropdownEl &&
      dropdown.scrollableEl &&
      dropdown.wrapperEl
    ) {
      if (isOpen) {
        dropdown.scrollableEl.scrollTop = 0;
      }
      const isShadowVisible = Boolean(
        dropdown.scrollableEl.children[0].clientHeight > dropdown.wrapperEl.clientHeight,
      );
      setIsAddonBottomTopShadowVisible(isShadowVisible);

      dropdown.scrollableEl.addEventListener('scroll', handleOnScroll);

      return () => {
        dropdown.scrollableEl?.removeEventListener('scroll', handleOnScroll);
      };
    }
    return undefined;
  }, [isOpen, dropdownRef, addonBottom]);

  const dropdownAddonBottom = Boolean(addonBottom) && (
    <div
      className={normalizeStringCompound([
        'h-[86px] -ml-default -mr-default flex-shrink-0 relative z-10 rounded-default overflow-hidden transition-shadow duration-50',
        isAddonBottomTopShadowVisible ? 'shadow-Shadows/Apple' : '',
      ])}
    >
      <div className="p-default bg-color/primary">{addonBottom}</div>
    </div>
  );

  return (
    <BrDropdown
      isOpen={isOpen}
      ref={dropdownRef}
      style={{ maxHeight: dropdownMaxHeight, ...style }}
      dropdownMaxHeight={dropdownMaxHeight}
      onAnimationEnd={onAnimationEnd}
      className={normalizeStringCompound([className])}
      paddingVariant={paddingVariant}
      dropdownOpenDirection={dropdownOpenDirection}
      dropdownAddonBottom={dropdownAddonBottom}
    >
      {overlay}
    </BrDropdown>
  );
});

export default Dropdown;
