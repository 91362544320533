/* eslint-disable @typescript-eslint/indent */
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import CountryCode from '@root/interfaces/CountryCode';
import { BrAdvancedInputProps } from '@root/interfaces/components/BrAdvancedInput';

import BrAdvancedInput, { BrAdvancedInputRef } from '@components/common/BrAdvancedInput';
import BrButton from '@components/common/BrButton';

import Yup from '@utils/validation/yup';

import {
  formatPhoneToE164,
  getPhoneInfoByNumber,
  getPhoneMaskByCountryCode,
  isValidInternationalPhoneNumber,
} from '@helpers/phone';

import useOnClickOutside from '@root/hooks/useOnClickOutside';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';

interface Props {
  countryCode: CountryCode;
  phoneNumber: string;
  isLoading?: boolean;
  phoneNumbers?: string[];
  onPhoneSubmit(phoneNumber: string): void;
  onPhoneDelete(phoneItem: Record<string, string>): void;
  onPhoneEdit({
    oldPhoneNumber,
    newPhoneNumber,
  }: {
    oldPhoneNumber: string;
    newPhoneNumber: string;
  }): void;
  onEditIconClick?(id?: string): void;
  isDisabled?: boolean;
  formId: string;
}

const ClEARED_VALUE = ''; // the value we set on clearing the input
const MASK_PREFIX = '+';

type IconCfg = Pick<BrAdvancedInputProps, 'iconRight' | 'iconRightClassNames'>;

const ICON_EDIT_CFG: Pick<BrAdvancedInputProps, 'iconRight' | 'iconRightClassNames'> = {
  iconRight: 'edit',
  iconRightClassNames: 'text-color/black',
};

const ICON_CLEAR_CFG: IconCfg = {
  iconRight: 'close_-_x_-_times_fill',
  iconRightClassNames: 'text-support-colors/highlights',
};

type RightIconCfg = IconCfg & Pick<BrAdvancedInputProps, 'onIconClickRight'>;

const BrFavoriteNumberForm: React.FunctionComponent<Props> = (props) => {
  const {
    countryCode,
    phoneNumber,
    isLoading,
    phoneNumbers,
    onPhoneSubmit,
    onPhoneEdit,
    onPhoneDelete,
    onEditIconClick,
    isDisabled,
    formId,
  } = props;

  const { t } = useTranslation();

  const [isPhoneInputDisabled, setPhoneInputDisabled] = useState<boolean>(
    Boolean(isDisabled),
  );

  const formikRef = useRef<
    FormikProps<{
      phoneNumber: string;
    }>
  >(null);

  useEffect(() => {
    setPhoneInputDisabled(Boolean(isDisabled));
    formikRef.current?.setErrors({});
  }, [isDisabled]);

  const [isBlured, setIsBlured] = useState(false);
  const [isStartedEditing, setIsStartedEditing] = useState(false);
  const [, forceUpdate] = useState(uuid());

  const brAdvancedInputRef = useRef<BrAdvancedInputRef>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const isActive = !isDisabled;

  useOnClickOutside(
    () => {
      if (isActive) {
        setIsBlured(true);
        onEditIconClick?.(undefined);
        if (phoneNumber) {
          formikRef.current?.setValues({ phoneNumber });
        } else {
          onPhoneDelete({ [formId]: phoneNumber });
        }
        formikRef.current?.resetForm();
      }
    },
    brAdvancedInputRef.current?.inputWrapperRef || { current: undefined },
    submitBtnRef,
  );

  useEffect(() => {
    setPhoneInputDisabled(Boolean(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    if (isActive) {
      brAdvancedInputRef.current?.inputRef.current?.focus();
    }
  }, [isActive]);

  useEffect(() => {
    forceUpdate(uuid());
    setIsStartedEditing(true);
  }, []);

  // copied from legacy phone form
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .ensure()
      .label('phoneNumber')
      .test(
        'is valid phone number',
        t('The number that you entered does not look like a valid phone number'),
        (value: string) => {
          if (value === ClEARED_VALUE || value === MASK_PREFIX) {
            return true;
          }
          if (value) {
            return isValidInternationalPhoneNumber(value);
          }
          return false;
        },
      )
      .test(
        'is existing phone number',
        t('The number that you entered is added already'),
        (value: string) => {
          if (value && phoneNumbers && !isBlured && !isStartedEditing) {
            return !phoneNumbers.includes(formatPhoneToE164(value));
          }
          return true;
        },
      )
      .test(
        'does phone belongs to country',
        t('Wrong country number'),
        (value: string) => {
          if (value === MASK_PREFIX || value === ClEARED_VALUE) {
            return true;
          }
          return countryCode === getPhoneInfoByNumber(value).country;
        },
      ),
  });

  const initialValues = {
    phoneNumber: phoneNumber || ClEARED_VALUE,
  };

  const handleFormSubmit = async (values: typeof initialValues) => {
    try {
      if (phoneNumber) {
        await onPhoneEdit({
          oldPhoneNumber: formatPhoneToE164(phoneNumber),
          newPhoneNumber: formatPhoneToE164(values.phoneNumber),
        });
      } else {
        await onPhoneSubmit(formatPhoneToE164(values.phoneNumber));
      }
      setPhoneInputDisabled(true);
    } catch (err) {
      setPhoneInputDisabled(false);
    }
  };

  const handlePhoneDeleteClick = () => {
    onPhoneDelete({ [formId]: phoneNumber });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormSubmit}
      enableReinitialize
      innerRef={formikRef}
    >
      {(formik) => {
        const isFormValid = formik.isValid && formik.touched.phoneNumber;
        const clearIconCfg = {
          ...ICON_CLEAR_CFG,
          onIconClickRight: () => {
            formik.setValues({
              phoneNumber: ClEARED_VALUE,
            });
            brAdvancedInputRef.current?.inputRef.current?.focus();
          },
        };
        const iconRightCfgClear: RightIconCfg | undefined =
          isFormValid && !isStartedEditing ? undefined : clearIconCfg;

        const iconRightCfgEdit: RightIconCfg = {
          ...ICON_EDIT_CFG,
          onIconClickRight: () => {
            setPhoneInputDisabled(false);
            onEditIconClick?.(formId);
            setIsStartedEditing(true);
            setTimeout(() => {
              brAdvancedInputRef.current?.inputRef.current?.focus();
            });
          },
        };

        const tooltipText =
          formik.values.phoneNumber && formik.errors.phoneNumber
            ? t('Please provide a valid phone number')
            : t('Please provide a phone number');

        const iconRightCfg = isPhoneInputDisabled ? iconRightCfgEdit : iconRightCfgClear;

        const phoneNumberInputMask = `+${getPhoneMaskByCountryCode(countryCode)}`;

        const isSubmitBtnVisible = !isPhoneInputDisabled;

        const hasSuccess =
          formik.isValid &&
          formik.touched.phoneNumber &&
          !isStartedEditing &&
          !isDisabled &&
          Boolean(formik.values.phoneNumber.replaceAll(MASK_PREFIX, ClEARED_VALUE));

        return (
          <Form className="flex flex-col space-y-middle">
            <InputMask
              mask={phoneNumberInputMask}
              maskChar=""
              inputMode="numeric"
              prefix={MASK_PREFIX}
              {...formik.getFieldProps('phoneNumber')}
              onFocus={() => {
                setIsBlured(false);
                formik.getFieldHelpers('phoneNumber').setTouched(true);
              }}
              onChange={(e) => {
                formik.getFieldProps('phoneNumber').onChange(e);
                setIsStartedEditing(false);
              }}
            >
              {() => (
                <BrAdvancedInput
                  topLabel={t('Phone number')}
                  name="phoneNumber"
                  placeholder={t('Enter the phone number')}
                  inputMode="numeric"
                  hasError={Boolean(formik.errors.phoneNumber)}
                  hasSuccess={hasSuccess}
                  errorText={formik.errors.phoneNumber}
                  actionBtnIcon="trash"
                  isDisabled={isPhoneInputDisabled}
                  {...(formik.values.phoneNumber === ClEARED_VALUE ||
                  formik.values.phoneNumber === MASK_PREFIX ||
                  (isBlured && !isPhoneInputDisabled)
                    ? undefined
                    : iconRightCfg)}
                  onActionBtnClick={handlePhoneDeleteClick}
                  ref={brAdvancedInputRef}
                />
              )}
            </InputMask>
            {isSubmitBtnVisible && (
              <BrTooltipWrapper
                cfg={{
                  place: 'bottom',
                  content: tooltipText,
                }}
              >
                <BrButton
                  ref={submitBtnRef}
                  state={isLoading ? 'loading' : 'default'}
                  type="submit"
                  cmpType="purple"
                  size="small"
                  text={t('Save phone number')}
                  className="w-full"
                  disabled={Boolean(
                    isPhoneInputDisabled ||
                      formik.errors.phoneNumber ||
                      !formik.values.phoneNumber,
                  )}
                />
              </BrTooltipWrapper>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default BrFavoriteNumberForm;
