import { LanguageLocale } from '@root/interfaces/Languages';

import { Logo } from '@components/common';
import BrLink from '@components/common/BrLink';

interface Props {
  className?: string;
  languageLocale: LanguageLocale;
  target?: string;
}

const LogotypeLink = (props: Props) => {
  const { className, languageLocale, target } = props;

  return (
    <BrLink
      className={className}
      title="Logo"
      to={`https://www.bossrevolution.com/${languageLocale}`}
      isNative
      target={target}
    >
      <Logo className="inline-flex h-xxxlarge" />
    </BrLink>
  );
};

export default LogotypeLink;
