import BrSkeleton from '@components/common/BrSkeleton';

const TransactionHistorySkeleton = () => {
  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto br-container-x-gutter py-default">
        <div className="flex space-x-small h-[32px]">
          <BrSkeleton className="w-[34px] rounded-small" />
          <BrSkeleton className="w-[70px] rounded-small" />
          <BrSkeleton className="w-[70px] rounded-small" />
          <BrSkeleton className="w-[70px] rounded-small" />
        </div>
      </div>
      <div className="space-y-middle">
        <div className="flex flex-col">
          <BrSkeleton className="w-[250px] h-[22px] rounded-default" />
        </div>
        <div className="flex flex-col space-y-middle ">
          <BrSkeleton className="w-full h-[112px] rounded-default" />
          <BrSkeleton className="w-full h-[112px] rounded-default" />
          <BrSkeleton className="w-full h-[112px] rounded-default" />
          <BrSkeleton className="w-full h-[112px] rounded-default" />
          <BrSkeleton className="w-full h-[112px] rounded-default" />
        </div>
      </div>
    </div>
  );
};

export default TransactionHistorySkeleton;
