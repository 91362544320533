import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import { ReactComponent as VisaIcon } from '../images/visa.svg';
import { ReactComponent as MasterCardIcon } from '../images/mastercard.svg';
import { ReactComponent as DiscoverIcon } from '../images/discover.svg';
import { ReactComponent as AmExIcon } from '../images/american-express.svg';
import { ReactComponent as DefaultPaymentCardIcon } from '../images/default.svg';

const iconsMap: Record<PaymentCardIssuerKeys, React.ReactNode> = {
  MC: <MasterCardIcon />,
  V: <VisaIcon />,
  AE: <AmExIcon />,
  D: <DiscoverIcon />,
};

interface Props {
  className?: string;
  paySource?: PaymentCardIssuerKeys;
}

const BrPaymentCardIssuerImg = (props: Props) => {
  const { className, paySource } = props;

  return (
    <div className={className}>
      {iconsMap[paySource as PaymentCardIssuerKeys] ?? <DefaultPaymentCardIcon />}
    </div>
  );
};

export default BrPaymentCardIssuerImg;
