import { makeAutoObservable } from 'mobx';

import { fetchPromoInfo } from '@services/api/v1/dtcImtuK2';

import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { FetchPromoInfoServiceReqData } from '@services/api/v1/dtcImtuK2/helpers';
import { showToast } from '@services/toasts';

import { ERROR_MESSAGE } from './constants';

class SendImtuTransactionStore {
  isLoading = false;

  promoInfo?: ImtuPromoOfferDetailsClass;

  constructor() {
    makeAutoObservable(this);
  }

  getPromoInfo = async (params: FetchPromoInfoServiceReqData) => {
    this.setIsLoading(true);
    try {
      const promoInfo = await fetchPromoInfo(params);
      this.promoInfo = promoInfo;
    } catch (err) {
      showToast.error(ERROR_MESSAGE.INVALID_PROMO);
    } finally {
      this.setIsLoading(false);
    }
  };

  reset() {
    this.promoInfo = undefined;
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };
}

export default SendImtuTransactionStore;
