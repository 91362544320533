import { TransitionEvent, forwardRef, useImperativeHandle, useRef } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { BrDropdownProps } from '@root/interfaces/components/BrDropdown';

import BrScrollShadowWrapper, {
  BrScrollShadowWrapperRef,
} from '../BrScrollShadowWrapper';
import BrCard from '../BrCard';

import './styles.scss';

export type BrDropdownRef = {
  wrapperEl: HTMLDivElement | null;
  scrollableEl: HTMLDivElement | null;
  dropdownEl: HTMLDivElement | null;
};

const BrDropdown = forwardRef<BrDropdownRef, BrDropdownProps>((props, ref) => {
  const {
    isOpen,
    children,
    className,
    dropdownOpenDirection,
    dropdownMaxHeight,
    onAnimationEnd,
    dropdownAddonBottom,
    style,
    paddingVariant = 'default',
  } = props;

  const dropdownRef = useRef<HTMLDivElement>(null);
  const brScrollShadowWrapperRef = useRef<BrScrollShadowWrapperRef>(null);

  const dropdownContentClassNames = normalizeStringCompound([
    'br-dropdown-content absolute w-full scale-y-0 transform rounded-default bg-white opacity-0 shadow-Shadows/Apple duration-300 ease-in-out [transition-property:transform,opacity] z-10 flex flex-col',
    isOpen
      ? '[.br-select-opened_&]:scale-y-100 [.br-select-opened_&]:opacity-100'
      : 'scale-y-0 opacity-0',
    dropdownOpenDirection === 'up'
      ? 'origin-bottom bottom-xsmall'
      : 'origin-top top-xsmall',
    className,
  ]);

  const scrollWrappersClassNames = normalizeStringCompound([
    paddingVariant === 'small'
      ? '-ml-middle -mr-middle px-middle'
      : '-ml-default -mr-default px-default',
  ]);

  const handleOnTransitionEnd = (e: TransitionEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onAnimationEnd?.();
    }
  };

  const isSmallPadding = paddingVariant === 'small';

  useImperativeHandle(ref, () => ({
    wrapperEl: brScrollShadowWrapperRef.current?.wrapperEl ?? null,
    scrollableEl: brScrollShadowWrapperRef.current?.scrollableEl ?? null,
    dropdownEl: dropdownRef.current,
  }));

  return (
    <div
      className={normalizeStringCompound([
        'br-dropdown relative',
        isSmallPadding ? 'br-dropdown-small-pad' : '',
        dropdownAddonBottom ? 'br-dropdown-with-addon-bottom' : '',
      ])}
      style={style}
    >
      <div
        ref={dropdownRef}
        className={dropdownContentClassNames}
        onTransitionEnd={handleOnTransitionEnd}
        style={{ maxHeight: dropdownMaxHeight }}
      >
        <BrCard variant={paddingVariant} className="flex flex-col !pb-0">
          <BrScrollShadowWrapper
            className={scrollWrappersClassNames}
            hasShadows={false}
            ref={brScrollShadowWrapperRef}
          >
            {children}
          </BrScrollShadowWrapper>
          {dropdownAddonBottom}
        </BrCard>
      </div>
    </div>
  );
});

export default BrDropdown;
