import { normalizeStringCompound } from '@utils/string';

import './styles.scss';

interface Props {
  classNames?: string;
}

const Divider = (props: Props) => {
  const { classNames } = props;

  return (
    <div
      className={normalizeStringCompound(['receipt-divider-dashed-border', classNames])}
    />
  );
};

export default Divider;
