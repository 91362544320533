import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface Props extends TestIdDataAttr {
  value?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
  bonusPercentage?: number;
}

const AutoRechargeToggle: FC<React.PropsWithChildren<Props>> = (props) => {
  const { value = false, onChange, className, bonusPercentage, dataTestId } = props;

  const { t } = useTranslation();

  const handleClick = () => {
    onChange(!value);
  };

  const containerClassNames = normalizeStringCompound([
    'px-1 rounded-full w-[54px] h-xxxlarge flex items-center mx-auto',
    value ? 'bg-green-200 justify-end' : 'bg-gray-400 justify-start',
  ]);

  const toggleClassNames = normalizeStringCompound([
    'rounded-full w-[22px] h-[22px] bg-white flex items-center justify-center uppercase text-headers/header-5',
    value ? 'text-color/success' : 'text-support-colors/highlights',
  ]);

  return (
    <div className={className}>
      <button
        onClick={handleClick}
        className={containerClassNames}
        data-test-id={dataTestId}
      >
        <div className={toggleClassNames} />
      </button>
      <div className="text-headers/header-5 text-center first-letter:uppercase">
        {value ? (
          <div className="text-color/success">
            {bonusPercentage && (
              <span>
                {t('{{value}}% bonus active', {
                  value: bonusPercentage,
                })}
                <>,</>
              </span>
            )}
            <span className="block first-letter:uppercase">
              {t('auto-recharge is on')}
            </span>
          </div>
        ) : (
          <div className="text-color/error">
            {bonusPercentage && <span>{t('No bonus')},</span>}
            <span className="block first-letter:uppercase">
              {t('auto-recharge is off')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoRechargeToggle;
