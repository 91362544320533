import React from 'react';
import { useTranslation } from 'react-i18next';

import ImtuTransaction from '@root/models/ImtuTransaction';

import BrContacts from '@components/common/BrContacts';

import { getCountryBackgroundImgSrc } from '@services/cdn';

import { splitPhoneToCodeAndRest } from '@helpers/phone';

import defaultCountryImage from '@src/static/assets/images/default-country-img.png';

interface Props {
  txns: ImtuTransaction[];
  onItemSelect(id: string): void;
}

const RecentContactsSection: React.FC<Props> = (props) => {
  const { txns, onItemSelect } = props;

  const handleItemSelect = (id: string) => {
    onItemSelect(id);
  };

  const { t } = useTranslation();

  const recentContactsList = txns.map((item) => {
    const [codeOrPhone, restOfPhoneOrEmpty] = splitPhoneToCodeAndRest(
      item.recipientPhoneNumber,
      item.recipientCountryCode,
    );
    return (
      <BrContacts
        key={item.id}
        cmpSize="default"
        cmpType="country img"
        promoText={item.carrierPromos?.length ? t('promo') : ''}
        imageUrl={getCountryBackgroundImgSrc(item.recipientCountryCode)}
        fallbackImageUrl={defaultCountryImage}
        countryCode={item.recipientCountryCode}
        name={codeOrPhone}
        surname={restOfPhoneOrEmpty}
        onClickProps={{
          onClick: handleItemSelect,
          id: item.id,
        }}
      />
    );
  });

  return (
    <div className="flex overflow-x-auto br-container-x-gutter pt-default pb-small px-large space-x-middle">
      {recentContactsList}
    </div>
  );
};

export default RecentContactsSection;
