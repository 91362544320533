import { normalizeStringCompound } from '@utils/string';

import Detail from './Detail';

import type { ProductDetail } from '..';

interface Props {
  data: ProductDetail[];
  classNames?: string;
}

const DetailsSection = (props: Props) => {
  const { data, classNames } = props;

  return (
    <div className={normalizeStringCompound(['relative space-y-small', classNames])}>
      {data.map((detail) => (
        <Detail key={detail.title} {...detail} />
      ))}
    </div>
  );
};

export default DetailsSection;
