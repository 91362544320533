import React from 'react';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';

import BrCarrierCard from '@components/modules/Imtu/components/CardItem/BrCarrierCard';

interface Props {
  data: ImtuCarrierClass[];
  selectedItemId?: string;
  onItemSelect?(id: string): void;
}

const CarriersList: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { data, selectedItemId, onItemSelect } = props;

  const handleSelect = (e: React.MouseEvent, id: string) => {
    onItemSelect?.(id);
  };

  return (
    <>
      {data.map((carrier) => {
        return (
          <BrCarrierCard
            key={carrier.code}
            data={carrier}
            isActive={carrier.code === selectedItemId}
            onClick={handleSelect}
          />
        );
      })}
    </>
  );
};

export default CarriersList;
