import { MutableRefObject, forwardRef, useImperativeHandle } from 'react';
import { normalizeStringCompound } from '@utils/string';

import Scrollbar from '../Scrollbar';

import useBrScrollShadowWrapperLogic from './useBrScrollShadowWrapperLogic';

import './styles.scss';

export interface Props {
  className?: string;
  children?: React.ReactNode;
  hasShadows?: boolean;

  scrollNodeRef?: MutableRefObject<HTMLDivElement>;
}

export type BrScrollShadowWrapperRef = {
  wrapperEl: HTMLDivElement | null;
  listEl: HTMLDivElement | null;
  scrollableEl: HTMLDivElement | null;
};

const BrScrollShadowWrapper = forwardRef<BrScrollShadowWrapperRef, Props>(
  (props, ref) => {
    const { className, children, hasShadows = true } = props;

    const {
      scrollShadowWrapperRef,
      topShadowStyles,
      bottomShadowStyles,
      scrollableNodeRef,
      scrollShadowWrapperHeight,
      scrollableContentRef,
    } = useBrScrollShadowWrapperLogic();

    const classNames = normalizeStringCompound([
      `br-scroll-shadow-wrapper relative overflow-hidden`,
      className,
    ]);

    useImperativeHandle(ref, () => {
      return {
        wrapperEl: scrollShadowWrapperRef?.current ?? null,
        listEl: (scrollableNodeRef?.current?.children[0] as HTMLDivElement) ?? null,
        scrollableEl: scrollableNodeRef.current ?? null,
      };
    });

    return (
      <div ref={scrollShadowWrapperRef} className={classNames}>
        {hasShadows && <div className={topShadowStyles} />}
        <Scrollbar
          autoHide={false}
          scrollableNodeProps={{
            ref: scrollableNodeRef,
          }}
          style={{
            height: scrollShadowWrapperHeight,
          }}
        >
          <div ref={scrollableContentRef}>{children}</div>
        </Scrollbar>
        {hasShadows && <div className={bottomShadowStyles} />}
      </div>
    );
  },
);

export default BrScrollShadowWrapper;
