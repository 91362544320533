import React from 'react';
import PATHS from '@root/routes/paths';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import Imtu from '@components/modules/Imtu';

const ImtuPage = () => {
  return (
    <BrTemplateWithSidebar>
      <Imtu basePath={PATHS.IMTU} />
    </BrTemplateWithSidebar>
  );
};

export default ImtuPage;
