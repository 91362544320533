import { useTranslation } from 'react-i18next';

import { STORE_APP_URLS } from '@root/constants/storeUrls';
import CountryCode from '@root/interfaces/CountryCode';

import { SUPPORT_INFO_CONFIG } from '@components/modules/CallingRechargeReceipt/components/Receipt/constants';
import { Icon, Typography } from '@components/common';
import BrBadge from '@components/common/BrBadge';
import { useIsNotMobile } from '@components/common/MediaQueryMatchers';

import {
  getHelpPhonesLandline,
  getHelpPhonesTolAccess,
} from '@components/modules/CallingRechargeReceipt/components/Receipt/helpers';

import { ReactComponent as CallingHelpQrCode } from '@src/static/assets/images/calling-help-qr-code.svg';
import { ReactComponent as AppStoreBadgeIcon } from '@src/static/assets/images/appStoreBadge.svg';
import { ReactComponent as GooglePlayBadgeIcon } from '@src/static/assets/images/googlePlayBadge.svg';

import { getDeviceInfo } from '@helpers/device';

interface Props {
  userCountryOfOrigin: CountryCode;
}

const HowToCallModal: React.FC<Props> = (props) => {
  const { userCountryOfOrigin } = props;

  const { t } = useTranslation();

  const isNotMobile = useIsNotMobile();

  const deviceInfo = getDeviceInfo();

  const appLinksCfg = [
    {
      link: STORE_APP_URLS.APP_STORE.MT_MODAL,
      icon: <AppStoreBadgeIcon className="w-[120px] h-[40px]" />,
      isIos: true,
    },
    {
      link: STORE_APP_URLS.GOOGLE_PLAY.MT_MODAL,
      icon: <GooglePlayBadgeIcon className="w-[120px] h-[40px]" />,
      isAndroid: true,
    },
  ].filter((item) => {
    return (
      isNotMobile ||
      (item.isIos && deviceInfo.isIos) ||
      (item.isAndroid && deviceInfo.isAndroid)
    );
  });

  const renderPhonesList = (stringsArr: { language: string; phone: string }[]) => {
    // tailwind marker: utility doesn't work some reason and there is limitations in styling of markers
    return (
      <ul className="space-y-small">
        {stringsArr.map((item) => {
          return (
            <li
              className="text-headers/header-6 space-x-xsmall flex items-center"
              key={`${item.language}${item.phone}`}
            >
              <Icon name="checkmark" />
              <span className="text-headers/header-6">{`${t(item.language)}: ${
                item.phone
              }`}</span>
            </li>
          );
        })}
      </ul>
    );
  };

  const phonesLandline = getHelpPhonesLandline({ countryCode: userCountryOfOrigin });
  const phonesTolAccess = getHelpPhonesTolAccess({ countryCode: userCountryOfOrigin });

  return (
    <div className="grid space-y-xlarge">
      <div className="flex flex-row space-x-xlarge">
        <div className="space-y-default">
          <div className="space-y-xsmall">
            <BrBadge classType="attention-variant" text={`${t('Option')}  #1`} />
            <div className="text-body/primary/demibold">
              {t(
                'Download the BOSS Revolution app, login with your phone number and start calling using your current balance right away',
              )}
            </div>
          </div>
          <div className="space-x-default flex">
            {appLinksCfg.map((item) => {
              return (
                <div key={item.link}>
                  <Typography.Link
                    isNative
                    target="_blank"
                    to={item.link}
                    className="block"
                  >
                    {item.icon}
                  </Typography.Link>
                </div>
              );
            })}
          </div>
        </div>
        {isNotMobile && (
          <div className="space-y-xsmall">
            <CallingHelpQrCode />
            <div className="text-body/caption/medium text-center">
              {t('Scan to download')}
            </div>
          </div>
        )}
      </div>

      {phonesLandline.length ? (
        <div className="space-y-middle">
          <div className="space-y-xsmall">
            <BrBadge classType="attention-variant" text={`${t('Option')}  #2`} />
            <div className="text-body/primary/demibold">
              {`${t(
                'Use a local access number to call with any landline or mobile phone',
              )}:`}
            </div>
          </div>
          <div>{renderPhonesList(phonesLandline)}</div>
        </div>
      ) : null}

      {phonesTolAccess.length ? (
        <div className="space-y-middle">
          <div className="space-y-xsmall">
            <BrBadge classType="attention-variant" text={`${t('Option')}  #3`} />
            <div className="text-body/primary/demibold">
              {`${t('Choose a BOSS Revolution toll-free access number')}*:`}
            </div>
          </div>
          <div>{renderPhonesList(phonesTolAccess)}</div>
          {SUPPORT_INFO_CONFIG[userCountryOfOrigin] && (
            <div className="text-body/footnote/default">
              <sup>*</sup>
              {t(SUPPORT_INFO_CONFIG[userCountryOfOrigin]?.textTpl, {
                ...SUPPORT_INFO_CONFIG[userCountryOfOrigin]?.variables,
              })}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default HowToCallModal;
