import UserAccount from '@components/modules/UserAccount';
import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

const UserAccountPage = () => {
  return (
    <BrTemplateWithSidebar>
      <UserAccount />
    </BrTemplateWithSidebar>
  );
};

export default UserAccountPage;
