import { CallingPlanOrderStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import { normalizeStringCompound } from '@utils/string';

import BrOrderStatusIllustration from '../BrOrderStatusIllustration';
import BrCard from '../BrCard';

interface Props {
  status: CallingPlanOrderStatus;
  title: string;
  subTitle?: React.ReactNode;
}

const BrOrderStatus = (props: Props) => {
  const { status, title, subTitle } = props;

  const titleClassNames = normalizeStringCompound([
    status === 'Canceled subscription'
      ? 'text-headers/header-4'
      : 'text-headers/header-2',
    'text-on-colors/on-primary mt-small',
  ]);

  return (
    <BrCard variant="without-bg">
      <div className="flex flex-col items-center text-center">
        <BrOrderStatusIllustration className="w-[149px] h-[149px]" state={status} />
        <div className={titleClassNames}>{title}</div>
        {subTitle && (
          <div className="mt-xsmall text-support-colors/highlights text-body/primary/default">
            {subTitle}
          </div>
        )}
      </div>
    </BrCard>
  );
};

export default BrOrderStatus;
