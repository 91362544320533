import React from 'react';
import { useTranslation } from 'react-i18next';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { normalizeStringCompound } from '@utils/string';

import { Icon } from '@components/common';

interface Props extends TestIdDataAttr {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isArEnabled?: boolean;
  isDisabled?: boolean;
}

const AutoRechargeButton: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { isDisabled, isArEnabled, dataTestId, onClick } = props;

  const { t } = useTranslation();

  const btnClassNames = normalizeStringCompound([
    'flex flex-col items-center',
    isArEnabled ? 'text-color/success' : 'text-support-colors/highlights',
  ]);

  const descriptionClassNames = normalizeStringCompound([
    'text-headers/header-5 first-letter:uppercase',
    isArEnabled ? 'text-color/success' : 'text-color/error',
  ]);

  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={btnClassNames}
      data-test-id={dataTestId}
    >
      <div className="w-[48px] h-[48px]">
        <Icon
          name="AutoRechargeIcon"
          viewBox="0 0 56 56"
          width=""
          height=""
          hasNoWrapper
        />
      </div>
      <div className={descriptionClassNames}>
        {isArEnabled ? t('auto-recharge is on') : t('auto-recharge is off')}
      </div>
    </button>
  );
};

export default AutoRechargeButton;
