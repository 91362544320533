import React from 'react';

import Destinations from '@components/common/BrDestinations';

import {
  CallingPlanDestination,
  CallingPlanFootnote,
} from '@root/models/CallingPlans/CallingPlan';

interface Props {
  destinations: CallingPlanDestination[];
  footNotes: CallingPlanFootnote[];
}

const DestinationsExpandableList: React.FunctionComponent<Props> = (props: Props) => {
  const { destinations, footNotes } = props;

  const mappedDestinations = destinations.map((destination) => {
    return {
      countryCode: destination.countryCode,
      description: destination.description,
      descriptionIcon: destination.descriptionIconUrl || '',
      carrierImages: destination.imageUrls?.map((carrierImageUrl) => {
        return {
          src: carrierImageUrl,
          alt: 'carrier image', // we don't have carrier code here
        };
      }),
    };
  });

  return <Destinations data={mappedDestinations} footnotes={footNotes} />;
};

export default DestinationsExpandableList;
