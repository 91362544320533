import { makeAutoObservable } from 'mobx';

import { defaultMainFeatures } from '@root/constants/appConfig';

import CountryCode from '@root/interfaces/CountryCode';
import {
  CountryAliasesMap,
  CountryMainFeaturesMap,
  FeaturedCountries,
  ImtuFeaturedCountriesMap,
  MainFeatures,
} from '@root/interfaces/appConfig';

import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';

import { remoteConfigApi } from '@services/firebase';
import { logError } from '@services/logging';
import { getIsLoggedInStatusFromStorage } from '@services/auth';
import { getCallingPaymentInfo } from '@services/api/v1/calling';
import { getMergedConfig } from '@helpers/appConfig';

interface AppConfig {
  countryMainFeaturesMap?: CountryMainFeaturesMap;
  countryAliasesMap?: CountryAliasesMap;
  imtuFeaturedCountriesMap?: ImtuFeaturedCountriesMap;
}

class AppConfigStore {
  // remote MT feature is always ON for US to display MT Modal
  // we can explicitly disable some features for specific country
  private remoteConfig: AppConfig = {
    countryMainFeaturesMap: undefined,
    countryAliasesMap: undefined,
    imtuFeaturedCountriesMap: undefined,
  };

  private dtcConfig?: CallingPaymentInfoClass;

  private mainFeaturesMapFromCallingConfig?: MainFeatures = undefined;

  userCountryCode?: CountryCode;

  private isRemoteConfigLoading = false;

  private isDtcConfigLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getRemoteConfig = async () => {
    this.setIsRemoteCfgLoading(true);
    try {
      const config = await remoteConfigApi.getConfig();
      this.remoteConfig = config;
      const isUserLoggedIn = getIsLoggedInStatusFromStorage();
      if (isUserLoggedIn) {
        const callingConfig = await getCallingPaymentInfo();
        this.mainFeaturesMapFromCallingConfig = {
          isMtAvailable: callingConfig.isMtAvailable,
          isImtuAvailable: callingConfig.isImtuAvailable,
          isCallingAvailable: callingConfig.isCallingAvailable,
          isCallingPlansAvailable: callingConfig.isCallingPlansAvailable,
        };
      }
    } catch (err) {
      logError({
        error: Error('Error loading remote config'),
        errorInfo: err,
      });
    } finally {
      this.setIsRemoteCfgLoading(false);
    }
  };

  setUserCountryCode(countryCode?: CountryCode) {
    this.userCountryCode = countryCode;
  }

  getDtcConfig = async (): Promise<CallingPaymentInfoClass | undefined> => {
    this.setIsDtcCfgLoading(true);
    try {
      const res = await getCallingPaymentInfo();
      this.dtcConfig = res;
    } catch (err) {
      logError({
        error: Error('Error loading DTC config'),
        errorInfo: err,
      });
    } finally {
      this.setIsDtcCfgLoading(false);
    }

    return this.dtcConfig;
  };

  get availableMainFeaturesCfg(): MainFeatures {
    if (!this.userCountryCode || !this.dtcConfig) {
      return defaultMainFeatures;
    }

    const mainFeaturesFromRemoteConfig = this.remoteConfig.countryMainFeaturesMap?.[
      this.userCountryCode
    ];

    if (this.dtcConfig) {
      const mainFeaturesMapFromCallingConfig: MainFeatures = {
        isMtAvailable: this.dtcConfig.isMtAvailable,
        isImtuAvailable: this.dtcConfig.isImtuAvailable,
        isCallingAvailable: this.dtcConfig.isCallingAvailable,
        isCallingPlansAvailable: this.dtcConfig.isCallingPlansAvailable,
      };
      const mergedConfig = getMergedConfig({
        appConfig: mainFeaturesMapFromCallingConfig,
        firebaseRemoteConfig: mainFeaturesFromRemoteConfig,
      });

      return mergedConfig;
    }

    return mainFeaturesFromRemoteConfig || defaultMainFeatures;
  }

  get countryAliasesCfg() {
    return this.remoteConfig.countryAliasesMap?.countryAliases;
  }

  get imtuFeaturedCountries(): FeaturedCountries['countries'] | undefined {
    if (!this.userCountryCode) {
      return undefined;
    }

    const featuredCountriesFromRemoteConfig = this.remoteConfig
      .imtuFeaturedCountriesMap?.[this.userCountryCode];

    return featuredCountriesFromRemoteConfig?.countries;
  }

  isMtAvailableForCountry = (countryCode?: CountryCode) => {
    if (countryCode === 'US' && this.remoteConfig.countryMainFeaturesMap?.[countryCode]) {
      return this.remoteConfig.countryMainFeaturesMap?.[countryCode]?.isMtAvailable;
    }

    return false;
  };

  setIsRemoteCfgLoading(isLoading: boolean) {
    this.isRemoteConfigLoading = isLoading;
  }

  setIsDtcCfgLoading(isLoading: boolean) {
    this.isDtcConfigLoading = isLoading;
  }

  get isAnyDataLoading() {
    return this.isRemoteConfigLoading || this.isDtcConfigLoading;
  }
}

export default AppConfigStore;
