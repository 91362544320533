import { normalizeStringCompound } from '@utils/string';

import BrButton from '../BrButton';

interface Props {
  className?: string;
  title?: string;
  onClose?(): void;
  onBackBtnClick?(): void;
}

const Header = (props: Props) => {
  const { title, className, onClose, onBackBtnClick } = props;

  const handleOnClose = () => {
    onClose?.();
  };

  const handleOnBackBtnClick = () => {
    onBackBtnClick?.();
  };

  return (
    <div className={normalizeStringCompound(['flex items-center', className])}>
      {onBackBtnClick && (
        <div
          className={normalizeStringCompound(['absolute top-[12px] left-[8px] z-110'])}
        >
          <BrButton
            cmpType="text"
            iconName="chevron-back"
            hasIconLeft
            onClick={handleOnBackBtnClick}
          />
        </div>
      )}
      {title && (
        <div
          className={normalizeStringCompound([
            'text-headers/header-3 mr-xlarge',
            onBackBtnClick ? 'ml-xxxlarge' : '',
          ])}
        >
          {title}
        </div>
      )}
      <div className={normalizeStringCompound(['absolute top-[15px] right-[7px] z-110'])}>
        <BrButton
          cmpType="text"
          cmpStyle="circle"
          iconName="close"
          hasIconLeft
          onClick={handleOnClose}
        />
      </div>
    </div>
  );
};

export default Header;
