/* eslint-disable import/prefer-default-export */

import { charsEquivalentsForAccentsMap } from '@root/constants/specialCharacters';

export const normalizeStringCompound = (
  strings: Array<string | undefined | null>,
  divider = ' ',
) =>
  strings.reduce((accum, string, index) => {
    if (index === 0 && string) {
      return string;
    }

    return string ? `${accum}${divider}${string}`.trim() : accum;
  }, '') || '';

export const stripAllNonNumericChars = (value: string) => value.replace(/\D/g, '');
export const stripAllNumericChars = (value: string) => value.replace(/[0-9]/g, '');

export const stripLastDot = (value: string) => value.replace(/\.$/, '');

export const capitalizeFirstLetterInEachWord = (str: string) => {
  return str.replace(
    /\w\S*/g,
    (txt: string) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase(),
  );
};

export const stripNonAsciiCharacters = (str: string) => {
  const stripNonAsciiRegexp = new RegExp(/[\u{0080}-\u{FFFF}]/gu);
  return str.replace(stripNonAsciiRegexp, '');
};

export function replaceAccentCharacters(str: string): string {
  let res = '';
  for (let i = 0, l = str.length; i < l; i += 1) {
    const char = str[i];
    res += charsEquivalentsForAccentsMap[char] || char;
  }
  return res;
}
