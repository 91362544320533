import React from 'react';

import ThemeInjector from '@components/modules/Theme/ThemeInjector';

import { Grid } from '@components/common';

const OneScreenTemplate: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;
  return (
    <div className="bg-color/background min-h-screen w-full flex items-center">
      <ThemeInjector />
      <Grid.Container>
        <Grid.Row>
          <Grid.Col offset={{ md: 2, lg: 3 }} span={{ sm: 12, md: 8, lg: 6 }}>
            {children}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </div>
  );
};

export default OneScreenTemplate;
