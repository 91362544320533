import React from 'react';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

import { normalizeStringCompound } from '@utils/string';

interface Props extends TestIdDataAttr {
  leftPart: React.ReactNode;
  middlePart: React.ReactNode;
  rightPart: React.ReactNode;
  value: string;
  isActive?: boolean;
  onClick: (e: React.MouseEvent, value: string) => void;
}

const CardItem: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const { value, leftPart, middlePart, rightPart, dataTestId, isActive, onClick } = props;

  const componentClassName = normalizeStringCompound([
    'flex flex-row flex-shrink-0 w-full items-center justify-between',
    'border rounded-default bg-color/surface p-default space-x-middle hover:border-tap-colors/tap-secondary',
    isActive ? 'shadow-lg border-tap-colors/tap-secondary' : 'border-color/surface',
  ]);

  const handleClick = (e: React.MouseEvent) => {
    onClick(e, value);
  };

  return (
    <button
      className={componentClassName}
      data-test-id={dataTestId}
      onClick={handleClick}
    >
      <div className="bg-gray-200 border-0 flex flex-shrink-0 items-center overflow-hidden rounded-full">
        {leftPart}
      </div>
      <div className="w-full overflow-hidden">{middlePart}</div>
      <div className="h-full">{rightPart}</div>
    </button>
  );
};

export default CardItem;
