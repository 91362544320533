import React from 'react';

import Link from '@components/common/Typography/Link';

// split text with some marker into parts to use it to replace the middle part.
const getTextPartsToReplace = (text: string, markerName: string) => {
  const openingTag = `{${markerName}}`;
  const closingTag = `{/${markerName}}`;
  const replaceTag = '||';
  const parts = text
    .replace(openingTag, replaceTag)
    .replace(closingTag, replaceTag)
    .split(replaceTag);
  const hasOnlyOneReplacementPart = parts.length === 3;
  const leftPart = text.split(openingTag)[0];
  const rightPart = text.split(closingTag)[1];
  return {
    parts,
    leftPart,
    rightPart,
    hasOnlyOneReplacementPart,
  };
};

interface TextWithHighLightProps {
  text: string;
  markerName: string;
}

export const TextWithHighLight: React.FC<TextWithHighLightProps> = (props) => {
  const { text, markerName } = props;
  const { parts, leftPart, rightPart, hasOnlyOneReplacementPart } = getTextPartsToReplace(
    text,
    markerName,
  );

  if (hasOnlyOneReplacementPart) {
    return (
      <>
        {parts.map((part) => {
          if ([leftPart, rightPart].includes(part)) {
            return part;
          }
          return (
            <span key={part} className="whitespace-nowrap font-bold text-accent2-100">
              {part}
            </span>
          );
        })}
      </>
    );
  }

  return <>{text}</>;
};

interface TextWithClassNamesProps {
  text: string;
  markerName: string;
  classNames: string;
}

export const TextWithClassNames: React.FC<TextWithClassNamesProps> = (props) => {
  const { text, markerName, classNames } = props;
  const { parts, leftPart, rightPart, hasOnlyOneReplacementPart } = getTextPartsToReplace(
    text,
    markerName,
  );

  if (hasOnlyOneReplacementPart) {
    return (
      <>
        {parts.map((part) => {
          if ([leftPart, rightPart].includes(part)) {
            return part;
          }
          return (
            <span key={part} className={classNames}>
              {part}
            </span>
          );
        })}
      </>
    );
  }

  return <>{text}</>;
};

interface TextWithLinkProps {
  text: string;
  markerName: string;
  href: string;
}

export const TextDecoratedWithLink: React.FC<TextWithLinkProps> = (props) => {
  const { text, markerName, href } = props;
  const { parts, leftPart, rightPart, hasOnlyOneReplacementPart } = getTextPartsToReplace(
    text,
    markerName,
  );

  if (hasOnlyOneReplacementPart) {
    return (
      <>
        {parts.map((part) => {
          if ([leftPart, rightPart].includes(part)) {
            return part;
          }
          return (
            <Link
              key={part}
              to={href}
              className="text-blue-400 underline opacity-100"
              isNative
            >
              {part}
            </Link>
          );
        })}
      </>
    );
  }

  return <>{text}</>;
};
