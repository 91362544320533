import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { Icon } from '@components/common';

interface Props {
  title?: string;
  description?: string[];
  className?: string;
}

const CallingAutoRechargePromoInfo: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, description, className } = props;

  return (
    <div className={normalizeStringCompound(['text-xl', className])}>
      {title && <div className="text-green-400 font-bold mb-10">{title}</div>}
      {description && (
        <div>
          {description.map((item) => (
            <div key={item} className="mb-5">
              <Icon name="Checkmark" className="text-green-400 mr-4" />
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CallingAutoRechargePromoInfo;
