import ReactDOM from 'react-dom';
import { useRef, useState } from 'react';

import BrModal from '@components/common/BrModal';

interface Params {
  content: (
    confirm: () => void,
    decline: () => void,
    close: () => void,
  ) => React.ReactNode;
  hasCloseButton?: boolean;
}

const useBrModal = (params: Params) => {
  const { content, hasCloseButton } = params;

  const [isDialogMounted, setIsDialogMounted] = useState<boolean>();
  const ref = useRef(false);
  const modalRef = useRef<React.Ref<ReactModal> | undefined>(null);

  const modalRoot = useRef<HTMLDivElement>();

  let confirmAction: (value: boolean | PromiseLike<boolean>) => void;

  const createModalRoot = () => {
    modalRoot.current = document.createElement('div');

    return modalRoot.current;
  };

  const close = async () => {
    if (modalRoot.current) {
      confirmAction(false);
      ReactDOM.unmountComponentAtNode(modalRoot.current);
      setIsDialogMounted(false);
    }
  };

  const open = () => {
    return new Promise<boolean>((confirm) => {
      ref.current = true;
      confirmAction = confirm;

      const handleConfirm = () => {
        confirmAction(true);
      };

      const handleDecline = () => {
        confirmAction(false);
      };

      ReactDOM.render(
        <BrModal onClose={close} isOpen hasCloseButton={hasCloseButton}>
          {content(handleConfirm, handleDecline, close)}
        </BrModal>,
        createModalRoot(),
      );
      setIsDialogMounted(true);
    });
  };

  return {
    open,
    close,
    modalRef,
    isDialogMounted,
  };
};

export default useBrModal;
