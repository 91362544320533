import { useMemo } from 'react';

import { replaceAccentCharacters } from '@utils/string';

import {
  BrOptionProps,
  OptGroupProps,
  isOptGroupProps,
} from '@root/interfaces/components/BrSelect';

type FilterParams = {
  item: BrOptionProps;
  searchValue: string;
  onSearch?: ((option: BrOptionProps, value: string) => boolean) | undefined;
};

interface Params {
  options: (BrOptionProps | OptGroupProps)[];
  searchValue: string;
  isOpen: boolean;
  isClosingRef: React.MutableRefObject<boolean>;
  currentOption?: BrOptionProps;
  onSearch?: ((option: BrOptionProps, value: string) => boolean) | undefined;
}

const filter = (params: FilterParams) => {
  const { item, searchValue, onSearch } = params;
  const normalizedSourceString = replaceAccentCharacters(item.text.toLowerCase());
  const normalizedQueryString = replaceAccentCharacters(searchValue.toLowerCase());
  return onSearch
    ? onSearch(item, searchValue)
    : normalizedSourceString.includes(normalizedQueryString);
};

const hasPutAtTheTop = (
  selectedVal: string | undefined,
  val: string,
  isClosing: boolean,
) => {
  return selectedVal === val && !isClosing;
};

const useOptionsToRender = (params: Params) => {
  const { options, searchValue, isOpen, isClosingRef, currentOption, onSearch } = params;

  return useMemo(() => {
    return options.reduce((acc, item) => {
      if (isOptGroupProps(item)) {
        const optGroupOptions = item.options.reduce((optGroupOpts, opt) => {
          const shouldInclude = filter({ item: opt, searchValue, onSearch });
          const hasToPutAtTheTop = hasPutAtTheTop(
            currentOption?.value,
            opt.value,
            isClosingRef.current,
          );

          if (shouldInclude) {
            return hasToPutAtTheTop ? [opt, ...optGroupOpts] : [...optGroupOpts, opt];
          }

          return optGroupOpts;
        }, [] as BrOptionProps[]);

        return [
          ...acc,
          ...(searchValue ? optGroupOptions : [{ ...item, options: optGroupOptions }]),
        ];
      }

      if (filter({ item, searchValue, onSearch })) {
        const hasToPutAtTheTop = hasPutAtTheTop(
          currentOption?.value,
          item.value,
          isClosingRef.current,
        );

        return hasToPutAtTheTop ? [item, ...acc] : [...acc, item];
      }

      return acc;
    }, [] as (BrOptionProps | OptGroupProps)[]);
  }, [options, searchValue, isOpen, isClosingRef.current]);
};

export default useOptionsToRender;
