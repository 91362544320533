/* eslint-disable import/prefer-default-export */

export const ERROR_MESSAGE = {
  BALANCE_INFO_MISSED: 'Calling balance info not available', // t('Calling balance info not available')
  INVALID_PROMO: 'Promo code is not valid', // t('Promo code is not valid')
};

// TODO: get rid of wizard logic since there are no steps left
export enum Steps {
  Recharge,
}
