import React, { PropsWithChildren } from 'react';

import { Icon } from '@components/common';

import { ContextMenuItemPropsDefault } from '@root/interfaces/components/BrContextMenu';

interface Props<T> extends ContextMenuItemPropsDefault<T> {
  onSelect?(id: T): void;
}

const itemBaseClass = 'menu-item';

const MenuItem = <T,>(props: PropsWithChildren<Props<T>>) => {
  const { id, title, description, iconLeft, iconRight, isDisabled, onSelect } = props;

  const handleSelect = () => {
    onSelect?.(id);
  };

  return (
    <li key={String(id)}>
      <button onClick={handleSelect} className={itemBaseClass} disabled={isDisabled}>
        {iconLeft && (
          <div className="icon-container">
            <Icon name={iconLeft} />
          </div>
        )}
        <div className="text-container">
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
        {iconRight && (
          <div className="icon-container">
            <Icon name={iconRight} />
          </div>
        )}
      </button>
    </li>
  );
};

export default MenuItem;
