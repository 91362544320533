import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useErrorBoundary from 'use-error-boundary';

import useStore from '@root/hooks/useStore';

import { UserAccountStore } from '@root/stores';

import { logError } from '@services/logging';

const ErrorCatcher: FC<React.PropsWithChildren<unknown>> = (props) => {
  const { children } = props;

  const { t } = useTranslation();

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const { ErrorBoundary, didCatch } = useErrorBoundary({
    onDidCatch: (error, errorInfo) => {
      logError({
        error,
        errorInfo,
        userInfo: userAccountStore.profile,
      });
    },
  });

  return (
    <ErrorBoundary>
      {didCatch ? <div>{t('Something went wrong')}</div> : children}
    </ErrorBoundary>
  );
};

export default ErrorCatcher;
