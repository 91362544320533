import React from 'react';
import { Img } from 'react-image';

import BrCarrier from '@components/common/BrCarrier';

import { BrCardPromotionPropsContentSmallProps } from '@root/interfaces/components/BrCardPromotion';

import defaultCountryImage from '@src/static/assets/images/default-country-img.png';

const SmallContent: React.FC<BrCardPromotionPropsContentSmallProps> = (props) => {
  const { imageUrl, carrierName, countryName, carrierCode, promoTextLong } = props;

  const imageClassnames = 'w-full h-[90px] border-0 rounded-middle object-cover';

  return (
    <>
      <Img
        className={imageClassnames}
        src={imageUrl}
        unloader={
          <img className={imageClassnames} src={defaultCountryImage} alt={countryName} />
        }
        alt=""
      />
      <div className="space-y-small">
        <div className="flex flex-row space-x-small">
          <BrCarrier size="Small" carrierCode={carrierCode} />
          <div className="space-y-xsmall">
            <div className="text-body/footnote/demibold text-black">{carrierName}</div>
            <div className="text-body/caption-2/demibold text-support-colors/secondary-text">
              {countryName}
            </div>
          </div>
        </div>
        <div className="text-body/callout/default text-on-colors/on-primary line-clamp-2">
          {promoTextLong}
        </div>
      </div>
    </>
  );
};

export default SmallContent;
