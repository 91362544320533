import React, { FC } from 'react';
import { NavLink as ReactRouterLink } from 'react-router-dom';

import { TypographyLinkProps } from '@root/interfaces/components/typography/Link';

const BrLink: FC<React.PropsWithChildren<TypographyLinkProps>> = (props) => {
  const {
    id,
    className,
    children,
    isNative,
    to,
    activeClassName,
    dataTestId,
    target,
    onClick,
    ...rest
  } = props;

  return isNative && typeof to === 'string' ? (
    <a
      id={id}
      href={to}
      className={className}
      data-test-id={dataTestId}
      target={target}
      onClick={onClick}
    >
      {children}
    </a>
  ) : (
    <ReactRouterLink
      to={to}
      className={className}
      activeClassName={activeClassName}
      data-test-id={dataTestId}
      target={target}
      onClick={onClick}
      {...rest}
    >
      {children}
    </ReactRouterLink>
  );
};

export default BrLink;
