import React, { FC } from 'react';

import { ButtonProps } from '@root/interfaces/components/Button';

import BrButton from '@components/common/BrButton';

type OmittedProps = 'onClick' | 'shape' | 'size' | 'outlined' | 'style';

interface Props extends Omit<ButtonProps, OmittedProps> {
  isSelected: boolean;
  onClick: (value: string) => void;
  value: string;
}

const TabOption: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, value, isSelected, onClick, ...rest } = props;

  const handleClick = () => {
    onClick(value);
  };

  return (
    <BrButton
      size="small"
      cmpType={isSelected ? 'solid' : 'white'}
      onClick={handleClick}
      {...rest}
    >
      {children}
    </BrButton>
  );
};

export default TabOption;
