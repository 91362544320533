import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';

import BrButton from '@components/common/BrButton';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import BrPaymentCardIssuerImg from '../BrPaymentCardIssuerImg';

interface Props {
  cardType: string;
  maskedNumber: string;
  className?: string;
  isExpired?: boolean;
  paySource: PaymentCardIssuerKeys;
  onEditClick(): void;
}

const BrSelectInput = (props: Props) => {
  const { cardType, maskedNumber, className, paySource, onEditClick, isExpired } = props;

  const { t } = useTranslation();

  return (
    <div
      className={normalizeStringCompound([
        'flex bg-color/surface items-center rounded-default p-default space-x-middle z-20 relative',
        className,
      ])}
    >
      <div className="w-full space-y-xxsmall">
        <div className="flex items-center space-x-middle">
          <BrPaymentCardIssuerImg paySource={paySource} className="w-xlarge" />
          <div className="flex flex-grow text-body/callout/default space-x-xsmall">
            <div>{cardType}</div>
            <div>{maskedNumber}</div>
          </div>
        </div>
        {isExpired && (
          <div className="text-color/error text-body/footnote/default">
            {t('Card expired')}
          </div>
        )}
      </div>

      <BrButton cmpType="link" text={t('Edit')} onClick={onEditClick} />
    </div>
  );
};

export default BrSelectInput;
