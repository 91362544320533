import React, { FC, PropsWithChildren } from 'react';

import BrButton from '@components/common/BrButton';

import { ButtonProps } from '@root/interfaces/components/Button';
import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface ProfilePaymentCardsItemProps extends TestIdDataAttr {
  id: string;
  title: string | null;
  errorText?: React.ReactNode;
  image: React.ReactNode;
  text: Array<React.ReactNode>;
  buttonText: string;
  buttonProps?: Omit<ButtonProps, 'shape' | 'size' | 'onClick'>;
  onButtonClick: (id: string) => void;
}

const ProfilePaymentCardsItem: FC<PropsWithChildren<ProfilePaymentCardsItemProps>> = (
  props,
) => {
  const {
    id,
    title,
    image,
    text,
    buttonText,
    onButtonClick,
    buttonProps,
    dataTestId,
  } = props;

  const handleClick = () => {
    onButtonClick(id);
  };

  return (
    <div className="space-y-small">
      <div className="space-y-xsmall">
        <div aria-hidden="true">{image}</div>
        <h6 className="text-body/callout/demibold" data-recording-sensitive>
          {title}
        </h6>
        {/* eslint-disable react/no-array-index-key */}
        {text.map((item, index) =>
          item ? (
            <p key={index} className="text-headers/header-6" data-recording-sensitive>
              {item}
            </p>
          ) : null,
        )}
      </div>
      <BrButton
        cmpType="link"
        {...buttonProps}
        onClick={handleClick}
        dataTestId={dataTestId}
      >
        {buttonText}
      </BrButton>
    </div>
  );
};

export default ProfilePaymentCardsItem;
