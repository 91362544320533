import { IconNames } from '@root/interfaces/components/Icon';
import CountryCode from '@root/interfaces/CountryCode';

import { normalizeStringCompound } from '@utils/string';

import BrLink from '@components/common/BrLink';
import Icon from '@components/common/Icon';
import Flag from '@components/common/Flag';
import BrBadge from '@components/common/BrBadge';

import { t } from 'i18next';

import './styles.scss';

export interface MenuItemProps {
  id: string;
  title: string;
  description?: string;
  isActive?: boolean;
  isExpanded?: boolean;
  isDisabled?: boolean;
  isNew?: boolean;
  menuUrl?: string;
  iconName?: IconNames;
  highlightedUrls?: string[];
  countryCode?: CountryCode;
  onClick?(): void;
  target?: string;
  items?: MenuItemProps[];
}

const BASIC_CLASS = 'br-menu-item';

const MenuItem = (props: MenuItemProps) => {
  const {
    title,
    description,
    isActive,
    menuUrl,
    isNew,
    items,
    isExpanded,
    isDisabled,
    onClick,
    iconName,
    countryCode,
    target,
  } = props;

  const handleItmClick = () => {
    onClick?.();
  };

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    isActive && !countryCode ? `${BASIC_CLASS}-active` : null,
  ]);

  const iconArrowClassNames = normalizeStringCompound([
    'w-default',
    'transition-transform duration-150',
    isExpanded ? 'rotate-180' : undefined,
  ]);

  const itemHtml = (
    <>
      {iconName && (
        <Icon className="w-[22px] mr-middle" width={22} height={22} name={iconName} />
      )}
      {countryCode && (
        <Flag
          className="w-[22px] h-[22px] overflow-hidden rounded-full flex-shrink-0 mr-middle self-center"
          code={countryCode}
        />
      )}
      <div className="flex flex-1 flex-row">
        <div>
          <div className="text-body/callout/default">
            {title}
            {isNew && (
              <BrBadge
                classType="black"
                styleType="default"
                className="ml-small"
                text={`${t('New')} 🎉`}
              />
            )}
          </div>
          {description && (
            <div className="text-body/footnote/default text-support-colors/highlights">
              {description}
            </div>
          )}
        </div>
        {items && (
          <div className="ml-small flex">
            <Icon
              className={iconArrowClassNames}
              name="bottom_-_down"
              width={16}
              height={16}
            />
          </div>
        )}
      </div>
      {isActive && countryCode && (
        <div className="ml-middle flex">
          <Icon
            className="w-default text-on-colors/on-primary"
            name="checkmark-circle"
            width={16}
            height={16}
          />
        </div>
      )}
    </>
  );

  return !isDisabled && menuUrl ? (
    <BrLink to={menuUrl} isNative className={classNames} target={target}>
      {itemHtml}
    </BrLink>
  ) : (
    <button className={classNames} onClick={handleItmClick} disabled={isDisabled}>
      {itemHtml}
    </button>
  );
};

export default MenuItem;
