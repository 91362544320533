import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';
import { BrowserRouter } from 'react-router-dom';
import { configure } from 'mobx';
import GoogleTagManager from 'react-gtm-module';
import 'react-tooltip/dist/react-tooltip.css';

// import * as serviceWorker from './serviceWorker';
import brazeAPI from '@services/braze';
import stores from '@root/stores/createdStores';

import Seo from '@components/modules/Seo';

import ErrorCatcher from './ErrorCatcher';

import '@theme/fonts.scss';
import '@theme/base.scss';
// for avoid css styles specificity issue
import App from './App';
import registerAppServiceWorker from './sw-register';

if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
  const googleTagManagerConfig = {
    gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  };

  GoogleTagManager.initialize(googleTagManagerConfig);
}

if (process.env.REACT_APP_MIMIC_LOGGER === 'active') {
  // load mimic
  // eslint-disable-next-line global-require
  require('mimic'); // se theme/base.scss also
}

if (process.env.NODE_ENV !== 'production') {
  // for braze debugging
  // @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  window.notifications = brazeAPI.app;
}

// TODO: rewrite all the modules to pass this check
configure({
  enforceActions: 'never',
});

ReactDOM.render(
  <Provider {...stores}>
    <BrowserRouter>
      <Seo />
      <ErrorCatcher>
        <App />
      </ErrorCatcher>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
registerAppServiceWorker(); // register custom (not default CRA) service worker
