import { makeAutoObservable } from 'mobx';
import qs from 'qs';

import {
  getCallingPaymentInfo,
  setAutoRecharge,
  addAmountToBalance,
} from '@services/api/v1/calling';
import { showToast } from '@services/toasts';
import { trackSentCallingRechargeTransaction } from '@services/analytics';
import storageInterface from '@services/storage';
import { CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

import { API_ERROR_MSG } from '@root/constants/errorMessages';

import CallingPaymentInfoClass from '@root/models/CallingPaymentInfoClass';
import CallingRechargeReceiptClass from '@root/models/CallingRechargeReceiptClass';
import PaymentCardClass from '@root/models/PaymentCardClass';

import CountryCode from '@root/interfaces/CountryCode';

import { ERROR_MESSAGE } from '@components/modules/Calling/constants';

class CallingRechargeStore {
  paymentInfo?: CallingPaymentInfoClass;

  selectedPaymentCard?: PaymentCardClass;

  rechargeReceipt?: CallingRechargeReceiptClass;

  isPaymentInfoLoading = false;

  isRechargeLoading = false;

  isAutoRechargeLoading = false;

  isCallingAvailabilityModalVisible = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isAutoRechargeSet() {
    return Boolean(this.paymentInfo?.arAmount);
  }

  get isAutoRechargeAvailable() {
    return Boolean(this.paymentInfo?.isAutoRechargeAvailable);
  }

  get isAutoRechargeRecommended() {
    return Boolean(this.paymentInfo?.isAutoRechargeRecommended);
  }

  get isAnythingLoading() {
    return (
      this.isPaymentInfoLoading || this.isRechargeLoading || this.isAutoRechargeLoading
    );
  }

  setIsPaymentInfoLoading = (value: boolean) => {
    this.isPaymentInfoLoading = value;
  };

  setIsRechargeLoading = (value: boolean) => {
    this.isRechargeLoading = value;
  };

  setIsAutoRechargeLoading = (value: boolean) => {
    this.isAutoRechargeLoading = value;
  };

  setSelectedPaymentCard = (card?: PaymentCardClass) => {
    this.selectedPaymentCard = card;
  };

  getCallingInfo = async () => {
    this.setIsPaymentInfoLoading(true);
    try {
      this.paymentInfo = await getCallingPaymentInfo();

      if (!this.paymentInfo.isCallingAvailable) {
        this.showCallingAvailabilityModal();
      }
    } catch (err) {
      showToast.error(err.message);
      this.showCallingAvailabilityModal();
      throw err;
    } finally {
      this.setIsPaymentInfoLoading(false);
    }
  };

  showCallingAvailabilityModal = () => {
    this.isCallingAvailabilityModalVisible = true;
  };

  hideCallingAvailabilityModal = () => {
    this.isCallingAvailabilityModalVisible = false;
  };

  setupAutoRechargeAmount = async (amount: number, onAfterUpdate?: () => void) => {
    if (this.paymentInfo) {
      this.setIsAutoRechargeLoading(true);
      try {
        await setAutoRecharge({
          amount,
          currencyDivisor: this.paymentInfo.divisor,
          currency: this.paymentInfo.currency,
        });

        await this.getCallingInfo();

        if (onAfterUpdate) {
          onAfterUpdate();
        }
      } catch (err) {
        showToast.error(err.message);
      } finally {
        this.setIsAutoRechargeLoading(false);
      }
    } else {
      showToast.error(ERROR_MESSAGE.BALANCE_INFO_MISSED);
    }
  };

  disableAutoRecharge = async () => {
    // 0 amount to deactivate auto recharge
    await this.setupAutoRechargeAmount(0);
  };

  submitTransaction = async ({
    amount,
    isAutoRechargeEnabled,
    paymentCardId,
    userCountryCode,
    validatedPromoCode,
    tnxId3Ds,
  }: {
    amount: number;
    isAutoRechargeEnabled: boolean;
    paymentCardId: string;
    userCountryCode: CountryCode;
    validatedPromoCode?: string;
    tnxId3Ds?: string;
  }) => {
    if (this.paymentInfo && paymentCardId) {
      this.setIsRechargeLoading(true);
      try {
        const queryStringForReturnUrl = qs.stringify({
          resubmit3DsParams: JSON.stringify({
            amount,
            isAutoRechargeEnabled,
            paymentCardId,
            validatedPromoCode,
          }),
        });
        const res = await addAmountToBalance({
          amount,
          currencyDivisor: this.paymentInfo.divisor,
          currency: this.paymentInfo.currency,
          handleId: paymentCardId,
          promoCode: validatedPromoCode,
          returnUrl3Ds: queryStringForReturnUrl,
          tnxId3Ds,
        });

        if ('status' in res) {
          storageInterface.setItem(
            CALLING_RECHARGE_3DS_PENDING_TXN_ID_STORE_KEY,
            String(res.transaction_id_3ds),
          );
          window.location.href = res.redirect_url_3ds || '';
          return;
        }

        this.rechargeReceipt = res;

        // update only if auto-recharge is recommended
        if (this.isAutoRechargeRecommended && isAutoRechargeEnabled) {
          await this.setupAutoRechargeAmount(this.paymentInfo.defaultAutoRechargeAmount);
        }

        trackSentCallingRechargeTransaction({
          amount,
          isAutoRechargeEnabled,
          cardHandleId: paymentCardId,
          userCountryCode,
          promoCode: validatedPromoCode,
        });

        await this.getCallingInfo();
      } catch (err) {
        switch (true) {
          case err !== API_ERROR_MSG.APP_DEFAULT:
            showToast.error(err);
            break;
          default:
            showToast.error(API_ERROR_MSG.APP_IMTU_CALLING_TRANSACTION_FAILED);
        }
        throw err;
      } finally {
        this.setIsRechargeLoading(false);
      }
    } else {
      showToast.error(ERROR_MESSAGE.BALANCE_INFO_MISSED);
    }
  };
}

export default CallingRechargeStore;
