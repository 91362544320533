import { Img } from 'react-image';
import { normalizeStringCompound } from '@utils/string';

import { getCountryImgUrlByCountryCode } from '@services/cdn';
import { getCountryNameByCode } from '@services/countryList';

import CountryCode from '@root/interfaces/CountryCode';
import { IconNames } from '@root/interfaces/components/Icon';

import Icon from '../Icon';

import './styles.scss';

interface Props {
  cmpVariant: 'Squared' | 'Rounded' | 'No Padding';
  hasIconLeft?: boolean;
  hasIconRight?: boolean;
  iconLeftName?: IconNames;
  iconLeftCountryCode?: CountryCode;
  iconRightName?: IconNames;
  iconRightClassNames?: string;
  text?: React.ReactNode;
  secondaryText?: React.ReactNode;
  onClick?(): void;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const cmpVariantClsNamesMap: Record<Props['cmpVariant'], string> = {
  'Squared': 'squared',
  'Rounded': 'rounded',
  'No Padding': 'no-padding',
};

const BASIC_CLASS = 'br-dropdown-item';

const BtnCountryIcon: React.FC<{ countryCode: CountryCode }> = ({ countryCode }) => {
  return (
    <Img
      src={getCountryImgUrlByCountryCode(countryCode)}
      alt={getCountryNameByCode(countryCode)}
      width={20}
      height={20}
      className="rounded-full overflow-hidden object-cover h-large w-large"
    />
  );
};

const BrDropdownItem = (props: Props) => {
  const {
    cmpVariant,
    hasIconRight,
    hasIconLeft,
    iconLeftCountryCode,
    iconLeftName,
    iconRightName,
    iconRightClassNames,
    text,
    secondaryText,
    onClick,
    isSelected,
    isDisabled,
  } = props;

  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${cmpVariantClsNamesMap[cmpVariant]}`,
    'space-x-small w-full',
  ]);

  const getIconLeft = () => {
    if (iconLeftName) {
      return <Icon name={iconLeftName} />;
    }
    if (iconLeftCountryCode) {
      return <BtnCountryIcon countryCode={iconLeftCountryCode} />;
    }
    return undefined;
  };

  return (
    <button disabled={isDisabled} className={classNames} onClick={onClick}>
      {hasIconLeft && getIconLeft()}
      <div
        className={normalizeStringCompound([
          'space-x-small text-headers/header-6 text-on-colors/on-primary flex-grow flex justify-between',
          isSelected ? 'text-body/callout/demibold' : '',
          isDisabled ? '!text-support-colors/highlights' : '',
        ])}
      >
        <span>{text}</span>
        {secondaryText && <span>{secondaryText}</span>}
      </div>
      <div
        className={normalizeStringCompound([
          'w-large h-large flex items-center justify-center',
          iconRightClassNames,
        ])}
      >
        {hasIconRight && iconRightName && (
          <Icon
            name={iconRightName}
            className={iconRightClassNames}
            width={16}
            height={16}
          />
        )}
      </div>
    </button>
  );
};

export default BrDropdownItem;
