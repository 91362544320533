import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikConfig, FormikHelpers } from 'formik';

import { Form, Icon } from '@components/common';

import Yup from '@utils/validation/yup';

import {
  PromoCodeFormProps,
  PromoCodeFormValues,
} from '@root/interfaces/components/PromoCodeForm';

const MIN_LENGTH_OF_CODE = 1;
const EMPTY_ERROR_MESSAGE = '';

const PromoCodeForm = (props: PromoCodeFormProps) => {
  const {
    initialValues,
    initialErrors,
    placeholder,
    label,
    isApplied,
    isLoading,
    dataTestPrefix,
    className,
    addonAfter,
    size = 'md',
    onFormValuesChange,
    onSubmit,
  } = props;

  let isMounted: boolean;

  const { t } = useTranslation();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    isMounted = true;

    return () => {
      isMounted = false;
    };
  }, []);

  const shouldValidateFieldOnChange = false;

  const handleFormSubmit = async (
    values: PromoCodeFormValues,
    { setValues }: FormikHelpers<PromoCodeFormValues>,
  ) => {
    await onSubmit(values.promoCode);

    if (isMounted) {
      setValues({ promoCode: '' }, shouldValidateFieldOnChange);
    }
  };

  const validationSchema = Yup.object().shape({
    promoCode: Yup.string()
      .min(MIN_LENGTH_OF_CODE, EMPTY_ERROR_MESSAGE)
      .required(t(EMPTY_ERROR_MESSAGE)),
  });

  const formInitializeProps: FormikConfig<{ promoCode: string }> = {
    enableReinitialize: true,
    initialValues: {
      promoCode: '',
      ...initialValues,
    },
    validateOnBlur: false,
    onSubmit: handleFormSubmit,
    initialErrors,
    validationSchema,
    initialStatus: {
      initialValues,
      initialErrors,
    },
  };

  const inputAddonAfter = !addonAfter ? (
    <Icon name="Checkmark" className="mr-4 text-green-400" />
  ) : (
    addonAfter
  );

  const addonAfterCmp = isApplied ? (
    inputAddonAfter
  ) : (
    <Form.Button
      shape="text"
      className="mr-4 group"
      disabled={isLoading}
      dataTestId={`${dataTestPrefix}-button-apply`}
    >
      <span className="group-focus-visible:focus-ring font-medium">
        {isLoading ? `${t('Checking')}...` : t('Apply')}
      </span>
    </Form.Button>
  );

  const handleFormValuesChange = () => {
    onFormValuesChange?.();
  };

  return (
    <Form config={formInitializeProps} className={className}>
      <Form.Field
        label={label}
        placeholder={placeholder}
        onChangeCapture={handleFormValuesChange}
        name="promoCode"
        type="text"
        addonAfter={addonAfterCmp}
        disabled={isLoading}
        dataTestId={`${dataTestPrefix}-input`}
        size={size}
      />
    </Form>
  );
};

export default PromoCodeForm;
