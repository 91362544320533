import qs from 'qs';

import storageInterface from '@services/storage';
import { CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

export const get3DsCallingPlansParamsFromQuery = (query?: string) => {
  const qsParams = qs.parse(query || window.location.search, {
    ignoreQueryPrefix: true,
  });
  const query3DsParams =
    'resubmit3DsParams' in qsParams
      ? JSON.parse(String(qsParams.resubmit3DsParams))
      : undefined;

  const tnxId3Ds = storageInterface.getItem(CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY);
  storageInterface.removeItem(CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY);

  if (
    query3DsParams &&
    tnxId3Ds &&
    'cardHandleId' in query3DsParams &&
    'planId' in query3DsParams &&
    'shouldSubscribe' in query3DsParams
  ) {
    return {
      cardHandleId: query3DsParams.cardHandleId,
      planId: query3DsParams.planId,
      shouldSubscribe: query3DsParams.shouldSubscribe,
      transactionId3Ds: tnxId3Ds,
    };
  }

  return undefined;
};

export const getCallingPlanIdFromQuery = (query?: string) => {
  const { callingPlanId } = qs.parse(query || window.location.search, {
    ignoreQueryPrefix: true,
  });

  if (callingPlanId) {
    return String(callingPlanId);
  }

  return undefined;
};
