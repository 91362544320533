import qs from 'qs';

import { dtcCallingPlansApi } from '@services/axios';

import { DTC_CALLING_PLANS_ENDPOINTS } from '@services/api/v1/constants/dtcCallingPlans';

import {
  convertToActiveCallingPlan,
  convertToCallingPlan,
  convertToNonPurchasableCallingPlan,
} from '@services/api/v1/dtcCallingPlans/helpers';

import { DtcCallingPlansOffersResponse } from '@root/interfaces/contract/dtcImtuCallingPlans/Offers';
import { CallingPlanContactResponse } from '@root/interfaces/contract/dtcImtuCallingPlans/Contacts';
import { RecipientCompatibilityResponse } from '@root/interfaces/contract/dtcImtuCallingPlans/RecipientCompatibility';
import {
  CallingPlanPurchaseGeneralResponse,
  CallingPlanQueuedResponse,
  ComboCallingPlanQueuedResponse,
} from '@root/interfaces/contract/dtcImtuCallingPlans/CallingPlanPurchaseDetails';

import CallingPlanContact from '@root/models/CallingPlans/CallingPlanContact';

import { formatPhoneToE164 } from '@helpers/phone';

import { API_ERROR_MSG } from '@root/constants/errorMessages';
import { CallingPlanTxnStatus } from '@root/constants/CallingPlans/callingPlansStatuses';

import BASE_PATHS from '@root/routes/paths';

import { CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';
import { extractCallingPlanPartFromCallingTransaction } from '@helpers/ImtuCallingTxn';

export interface CallingPlanPurchaseParams {
  cardHandleId: string;
  planId: string;
  shouldSubscribe: boolean;
  cvvCode?: string;
  transactionId3Ds?: string;
  returnUrl3Ds?: string;
}

export interface ComboPlanPurchaseParams extends CallingPlanPurchaseParams {
  comboPlanPromoId: string;
  comboPlanImtuOfferId: string;
  comboPlanRecipientPhoneNumber: string;
}

export interface SavingsPassCallingPlanPurchaseParams extends CallingPlanPurchaseParams {
  arParams?: {
    amount: number;
    currency: string;
    currency_divisor: number;
  };
}

interface AddContactParams {
  planId: string;
  phoneNumber: string;
}

interface DeleteContactParams {
  planId: string;
  phoneNumber: string;
}

interface RecipientToComboImtuCompatibilityParams {
  recipientPhone: string;
  offerId: string;
}

export interface CancelSubscriptionParams {
  productId: string;
  txnId: string;
}

const apiCalls = {
  getCallingPlans: () =>
    dtcCallingPlansApi.get<DtcCallingPlansOffersResponse>(
      `${DTC_CALLING_PLANS_ENDPOINTS.OFFERS}?installation_id=123qwe`,
    ),
  getTxnDetails: (txnId: string) =>
    dtcCallingPlansApi.get<CallingPlanPurchaseGeneralResponse>(
      `${DTC_CALLING_PLANS_ENDPOINTS.PURCHASES}/${txnId}`,
    ),
  getContacts: (planId: string) =>
    dtcCallingPlansApi.get<CallingPlanContactResponse>(
      `${DTC_CALLING_PLANS_ENDPOINTS.GET_CONTACTS}?${qs.stringify({ plan_id: planId })}`,
    ),
  addContact: (params: AddContactParams) =>
    dtcCallingPlansApi.post<{}>(DTC_CALLING_PLANS_ENDPOINTS.ADD_CONTACT, {
      plan_id: params.planId,
      destination_numbers: [params.phoneNumber],
    }),
  checkRecipientToComboImtuCompatibility: (
    params: RecipientToComboImtuCompatibilityParams,
  ) =>
    dtcCallingPlansApi.get<RecipientCompatibilityResponse>(
      `${DTC_CALLING_PLANS_ENDPOINTS.MTU_RECIPIENT_COMPATIBILITY}?${qs.stringify({
        recipient_msisdn: params.recipientPhone,
        offer_id: params.offerId,
      })}`,
    ),
  deleteContact: (params: DeleteContactParams) =>
    dtcCallingPlansApi.delete<{}>(DTC_CALLING_PLANS_ENDPOINTS.DELETE_CONTACT, {
      data: {
        plan_id: params.planId,
        destination_number: params.phoneNumber,
      },
    }),
  cancelSubscription: (params: CancelSubscriptionParams) =>
    dtcCallingPlansApi.delete<{}>(DTC_CALLING_PLANS_ENDPOINTS.CANCEL_SUBSCRIPTION, {
      data: {
        product_code: params.productId,
        transaction_id: params.txnId,
      },
    }),
  doPurchase: ({
    cardHandleId,
    planId,
    cvvCode,
    shouldSubscribe,
    transactionId3Ds,
    returnUrl3Ds,
  }: CallingPlanPurchaseParams) =>
    dtcCallingPlansApi.post<CallingPlanQueuedResponse>(
      DTC_CALLING_PLANS_ENDPOINTS.PURCHASES,
      {
        calling_plan_request: {
          cvv: Number(cvvCode),
          handle_id: cardHandleId,
          subscribe: shouldSubscribe,
          installation_id: navigator.userAgent || navigator.vendor,
          offer_id: planId,
          return_url: `${window.location.origin}${BASE_PATHS.CALLING_PLANS}/${CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY}?${returnUrl3Ds}`,
          ...(transactionId3Ds ? { transaction_id_3ds: transactionId3Ds } : undefined),
        },
      },
    ),
  doSavingsPassPurchase: ({
    cardHandleId,
    planId,
    cvvCode,
    shouldSubscribe,
    arParams,
    returnUrl3Ds,
    transactionId3Ds,
  }: SavingsPassCallingPlanPurchaseParams) =>
    dtcCallingPlansApi.post<CallingPlanQueuedResponse>(
      DTC_CALLING_PLANS_ENDPOINTS.PURCHASES,
      {
        calling_plan_request: {
          auto_recharge_info: arParams,
          cvv: Number(cvvCode),
          handle_id: cardHandleId,
          subscribe: shouldSubscribe,
          installation_id: navigator.userAgent || navigator.vendor,
          offer_id: planId,
          return_url: `${window.location.origin}${BASE_PATHS.CALLING_PLANS}/${CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY}?${returnUrl3Ds}`,
          ...(transactionId3Ds ? { transaction_id_3ds: transactionId3Ds } : undefined),
        },
      },
    ),
  doComboPlanPurchase: ({
    cardHandleId,
    planId,
    cvvCode,
    comboPlanImtuOfferId,
    comboPlanRecipientPhoneNumber,
    comboPlanPromoId,
    shouldSubscribe,
  }: ComboPlanPurchaseParams) =>
    dtcCallingPlansApi.post<ComboCallingPlanQueuedResponse>(
      DTC_CALLING_PLANS_ENDPOINTS.PURCHASES,
      {
        calling_plan_request: {
          cvv: Number(cvvCode),
          handle_id: cardHandleId,
          installation_id: navigator.userAgent || navigator.vendor,
          offer_id: planId,
          return_url: `${window.location.origin}${BASE_PATHS.CALLING_PLANS}/${CALLING_PLANS_3DS_PENDING_TXN_ID_STORE_KEY}`,
          subscribe: shouldSubscribe,
        },
        imtu_request: {
          cvv: Number(cvvCode),
          handle_id: cardHandleId,
          installation_id: '123qwe',
          offer_id: comboPlanImtuOfferId,
          orig_recipient_number: formatPhoneToE164(comboPlanRecipientPhoneNumber),
          recipient_msisdn: formatPhoneToE164(comboPlanRecipientPhoneNumber),
        },
        promo_id: comboPlanPromoId,
      },
    ),
};

export const getCallingPlans = async () => {
  const res = await apiCalls.getCallingPlans();
  return {
    activePlans: res.data.active_plans.map(convertToActiveCallingPlan),
    availablePlans: res.data.available_plans.map(convertToCallingPlan),
    nonPurchasablePlans: res.data.non_purchasable_plans.map(
      convertToNonPurchasableCallingPlan,
    ),
  };
};

export const fetchCallingPlanTxnDetails = async (txnId: string) => {
  const res = await apiCalls.getTxnDetails(txnId);
  return res.data;
};

export const fetchCallingPlansContacts = async (planId: string) => {
  const res = await apiCalls.getContacts(planId);
  return res.data.map((item) => {
    return new CallingPlanContact({
      phoneNumber: item.number,
      isEditable: item.is_editable,
      isEditableOnce: item.is_editable_once,
    });
  });
};

export const addContactToPlan = async (params: AddContactParams) => {
  const res = await apiCalls.addContact(params);
  return res.data;
};

export const deleteContactFromPlan = async (params: DeleteContactParams) => {
  const res = await apiCalls.deleteContact(params);
  return res.data;
};

export interface CallingPlanPurchaseInfo {
  txnId: string;
  planTitle: string;
  planDescription: string;
  price: number;
  currency: string;
  cardHandleId: string;
  status: CallingPlanTxnStatus;
  errorReason?: string;
}

export const purchaseCallingPlan = async (
  params: CallingPlanPurchaseParams,
): Promise<CallingPlanPurchaseInfo> => {
  const res = await apiCalls.doPurchase(params);

  const txn = res.data.items[0].calling_plan_tx;

  return {
    txnId: res.data.id,
    planTitle: txn.title,
    planDescription: txn.description,
    price: Number(txn.enrollment.price),
    currency: txn.enrollment.currency_code,
    cardHandleId: txn.enrollment.handle_id,
    status: txn.purchase_status,
    errorReason: res.data.items[0].fail_reason || undefined,
  };
};

// Be sure the auto recharge amount is multiplied to divisor. 10$ is 1000
export const purchaseSavingsPassCallingPlan = async (
  params: SavingsPassCallingPlanPurchaseParams,
): Promise<CallingPlanPurchaseInfo> => {
  const res = await apiCalls.doSavingsPassPurchase(params);

  const txn = res.data.items[0].calling_plan_tx;

  return {
    txnId: res.data.id,
    planTitle: txn.title,
    planDescription: txn.description,
    price: Number(txn.enrollment.price),
    currency: txn.enrollment.currency_code,
    cardHandleId: txn.enrollment.handle_id,
    status: txn.purchase_status,
    errorReason: res.data.items[0].fail_reason || undefined,
  };
};

export const purchaseComboPlan = async (
  params: ComboPlanPurchaseParams,
): Promise<CallingPlanPurchaseInfo> => {
  const res = await apiCalls.doComboPlanPurchase(params);

  const txn = extractCallingPlanPartFromCallingTransaction(res.data);

  if (txn) {
    return {
      txnId: res.data.id,
      planTitle: txn.title,
      planDescription: txn.description,
      price: Number(txn.enrollment.price),
      currency: txn.enrollment.currency_code,
      cardHandleId: txn.enrollment.handle_id,
      status: txn.purchase_status,
      errorReason: res.data.items[0].fail_reason || undefined,
    };
  }

  throw new Error(API_ERROR_MSG.APP_DEFAULT);
};

export const checkRecipientToComboImtuCompatibility = async (
  params: RecipientToComboImtuCompatibilityParams,
) => {
  try {
    const res = await apiCalls.checkRecipientToComboImtuCompatibility(params);
    return !res.data.incompatible_reason;
  } catch (err) {
    return false;
  }
};

export const cancelCallingPlanSubscription = async (params: CancelSubscriptionParams) => {
  const res = await apiCalls.cancelSubscription(params);
  return res.data;
};
