import { useParams } from 'react-router-dom';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import CallingPlanCancelSubSuccess from '@components/modules/CallingPlanCancelSubSuccess';

interface UrlParams {
  id?: string;
}

const CallingPlansCancelSubSuccessPage = () => {
  const { id: planId } = useParams<UrlParams>();

  return (
    <BrTemplateWithSidebar>
      {planId && <CallingPlanCancelSubSuccess planId={planId} />}
    </BrTemplateWithSidebar>
  );
};

export default CallingPlansCancelSubSuccessPage;
