import BrButton from '@components/common/BrButton';

interface Props {
  title: string;
  text: string;
  actionBtnText?: string;
  classNames?: string;
  onActionBtnClick?(): void;
}

const Detail = (props: Props) => {
  const { title, text, actionBtnText, classNames, onActionBtnClick } = props;

  return (
    <div className={classNames}>
      <div className="text-body/caption/default text-support-colors/highlights mb-small">
        {title}
      </div>
      <div>
        <span className="text-body/callout/default">
          {text}{' '}
          {actionBtnText && (
            <BrButton
              cmpType="link"
              size="default"
              text={actionBtnText}
              onClick={onActionBtnClick}
              className="inline-flex !p-0"
            />
          )}
        </span>
      </div>
    </div>
  );
};

export default Detail;
