import { FunctionComponent } from 'react';

import Menu from '@components/common/BrMenu/Menu';

import { MenuItemProps } from './MenuItem';

import './styles.scss';

const BASIC_CLASS = 'br-menu';

interface Props {
  data: MenuItemProps[];
}

const BrMenu: FunctionComponent<Props> = (props) => {
  const { data } = props;

  return (
    <div className={BASIC_CLASS}>
      <Menu data={data} />
    </div>
  );
};

export default BrMenu;
