import React from 'react';

import Icon from '@components/common/Icon';

type ListType = 'active' | 'suggested' | 'available' | 'ineligible';

interface Props {
  text: string;
  type: ListType;
}

const iconSizeProps = { width: 16, height: 16 };

const IconMap: Record<ListType, React.ReactNode> = {
  active: (
    <Icon {...iconSizeProps} name="checkmark-circle" className="text-color/success" />
  ),
  suggested: (
    <Icon {...iconSizeProps} name="thumb-up-fill" className="text-color/attention" />
  ),
  available: (
    <Icon {...iconSizeProps} name="cart" className="text-color/secondary-variant" />
  ),
  ineligible: (
    <Icon {...iconSizeProps} name="close" className="text-on-colors/on-primary-variant" />
  ),
};

const ListTitle: React.FC<Props> = (props) => {
  const { text, type } = props;
  return (
    <div className="flex items-center space-x-xsmall">
      {IconMap[type]}
      <span className="text-body/callout/default text-on-colors/on-primary">{text}</span>
    </div>
  );
};

export default ListTitle;
