import React from 'react';
import { Img } from 'react-image';

import { ReactComponent as UserIcon } from '@src/static/assets/images/icons/legacyIcons/legacy-user-circle.svg';
// TODO: not sure about this icon - discuss with designer
import { ReactComponent as LoaderIcon } from '@src/static/assets/images/icons/legacyIcons/legacy-repeat.svg';

interface Props {
  avatarUrl: string | null;
  altText?: string;
}

const UserAvatar = (props: Props) => {
  const { avatarUrl, altText = 'user avatar' } = props;

  return (
    <div className="rounded-full h-full w-full group-focus-visible:focus-ring">
      <div className="h-full w-full rounded-full overflow-hidden bg-gray-200">
        {avatarUrl && (
          <Img
            src={avatarUrl}
            className="w-full h-full object-cover"
            alt={altText}
            key={avatarUrl}
            loader={
              // loader image - is shown when new image is uploading
              <div className="w-full h-full flex items-center justify-center">
                <LoaderIcon className="w-1/2 h-1/2 animate-spin" />
              </div>
            }
            unloader={
              // fallback image in case we couldn't be loaded an actual image
              <div className="w-full h-full">
                <UserIcon className="w-full h-full" />
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default UserAvatar;
