import React from 'react';

import { Icon } from '@components/common';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  name: string;
  discountText: string;
  descriptionText: string;
  className?: string;
  onCancelPromoClick(): void;
}

const PromoRow: React.FunctionComponent<Props> = (props: Props) => {
  const { name, discountText, descriptionText, className, onCancelPromoClick } = props;

  return (
    <div
      className={normalizeStringCompound([
        'text-body/callout/default text-support-colors/highlights space-y-xsmall bg-color/warning-variant animate-pulse-bg-once',
        className,
      ])}
    >
      <div className="flex items-center space-x-small">
        <div className="flex w-full justify-between">
          <div>{name}</div>
          <div>{discountText}</div>
        </div>
        <button
          className="flex items-center w-large text-on-colors/on-primary"
          onClick={onCancelPromoClick}
        >
          <Icon name="close_-_x_-_times_fill" />
        </button>
      </div>
      <div className="flex flex-row text-body/caption-2/default space-x-xsmall">
        <Icon name="egift-solid" />
        <div>{descriptionText}</div>
      </div>
    </div>
  );
};

export default PromoRow;
