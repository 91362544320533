import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalStore, observer } from 'mobx-react';

import ContactsStore from '@components/modules/BrFavoriteNumbers/ContactsStore';

import CountryCode from '@root/interfaces/CountryCode';

import useDidMount from '@root/hooks/useDidMount';

import { showBrToast, showToast } from '@services/toasts';

import BrCallingPlansFavoriteNumberCard from '@components/common/BrCallingPlansFavoriteNumberCard';
import BrClickBlockerOverlay from '@components/common/BrClickBlockerOverlay';
import BrSkeleton from '@components/common/BrSkeleton';
import { logError } from '@services/logging';

interface Props {
  planId: string;
  maxFavoriteNumbers: number;
  countryCode: CountryCode;
}

const ADD_CONTACT_ERROR =
  'The number you added is not eligible for the calling plan. Please try again with a different number.'; // t('The number you added is not eligible for the calling plan. Please try again with a different number.')

const BrFavoriteNumbers: React.FunctionComponent<Props> = (props: Props) => {
  const { planId, maxFavoriteNumbers, countryCode } = props;

  const { t } = useTranslation();

  const contactsStore = useLocalStore(() => new ContactsStore(planId));

  useDidMount(() => {
    if (maxFavoriteNumbers) {
      contactsStore.getContacts();
    }
  });

  const handleContactAddClick = async (phoneNumber: string) => {
    try {
      await contactsStore.addContactAndRefresh(phoneNumber);
      const isContactFound = contactsStore.contacts.find(
        (contact) => contact.phoneNumber === phoneNumber,
      );
      if (isContactFound) {
        showBrToast.small(`${t('The phone number has been saved')} ✅`);
      } else {
        showToast.error(t('The phone number has not been added. Please try again'));
        logError({
          error: Error(`The phone number ${phoneNumber} has not been added`),
          errorInfo: JSON.stringify(contactsStore.contacts),
        });
      }
    } catch (err) {
      showToast.error(t(ADD_CONTACT_ERROR));
      throw err;
    }
  };

  const handleContactDeleteClick = async (phoneNumber: string) => {
    try {
      await contactsStore.deleteContactAndRefresh(phoneNumber);
      showBrToast.small(`${t('The phone number was deleted')} ✅`);
    } catch (err) {
      showToast.error(err);
    }
  };

  const handleContactEditClick = async ({
    oldPhoneNumber,
    newPhoneNumber,
  }: {
    oldPhoneNumber: string;
    newPhoneNumber: string;
  }) => {
    try {
      await contactsStore.deleteContact(oldPhoneNumber);
      await contactsStore.addContactAndRefresh(newPhoneNumber);
      showBrToast.small(`${t('The phone number was updated')} ✅`);
    } catch (err) {
      // refresh contacts in case unexpected error occurs on add call
      await contactsStore.getContacts();
      showToast.error(t(ADD_CONTACT_ERROR));
      throw err;
    }
  };

  return (
    <BrClickBlockerOverlay isActive={contactsStore.isAnythingLoading}>
      {contactsStore.isLoadingContacts ? (
        <BrSkeleton className="h-[98px] rounded-middle" />
      ) : (
        <BrCallingPlansFavoriteNumberCard
          countryCode={countryCode}
          maxPhoneNumbers={maxFavoriteNumbers}
          phoneNumbers={contactsStore.phoneNumbers}
          onPhoneSubmit={handleContactAddClick}
          onPhoneDelete={handleContactDeleteClick}
          onPhoneEdit={handleContactEditClick}
          isLoading={contactsStore.isAnythingLoading}
        />
      )}
    </BrClickBlockerOverlay>
  );
};

export default observer(BrFavoriteNumbers);
