import {
  CallingPlanPurchaseGeneralResponse,
  CallingPlanTxError,
  CallingPlanTxQueued,
  CallingPlanTxSuccess,
  ImtuTxError,
  ImtuTxQueued,
  ImtuTxSuccess,
} from '@root/interfaces/contract/dtcImtuCallingPlans/CallingPlanPurchaseDetails';

type ImtuTxnData = ImtuTxSuccess | ImtuTxQueued | ImtuTxError | undefined;
export const extractImtuPartFromCallingTransaction = (
  data: CallingPlanPurchaseGeneralResponse,
): ImtuTxnData => {
  // @ts-ignore - bug in typescript
  return data.items.find((item): item is ImtuTxnData => {
    return item.product_type === 'imtu';
  })?.mtu_tx as ImtuTxnData;
};

type CallingPlanTxnData = CallingPlanTxSuccess | CallingPlanTxQueued | CallingPlanTxError;
export const extractCallingPlanPartFromCallingTransaction = (
  data: CallingPlanPurchaseGeneralResponse,
): CallingPlanTxnData => {
  // @ts-ignore - bug in typescript
  return data.items.find((item): item is CallingPlanTxnData => {
    return item.product_type === 'calling_plan';
  }).calling_plan_tx as CallingPlanTxnData;
};
