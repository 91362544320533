import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { getFormattedAmount } from '@helpers/money';

interface Props {
  amount: number;
  currencySymbol: string;
  currency: string;
  title: string;
  className?: string;
}

const CallingBalanceInfo: FC<React.PropsWithChildren<Props>> = (props) => {
  const { amount, currencySymbol, currency, title, className } = props;

  return (
    <div className={normalizeStringCompound(['text-center font-bold', className])}>
      <h5 className="text-headers/header-5 mb-small">{title}</h5>
      <div className="text-display/display-2">
        {`${currencySymbol}${getFormattedAmount(amount)}`}
        <span className="text-body/primary/demibold align-top">{currency}</span>
      </div>
    </div>
  );
};

export default CallingBalanceInfo;
