// eslint-disable-next-line import/prefer-default-export
export const DTC_CALLING_PLANS_ENDPOINTS = {
  OFFERS: 'store/v1/offers/calling-plans',
  MTU_RECIPIENT_COMPATIBILITY: 'store/v1/offers/mtu/compatible',
  PURCHASES: '/store/v1/purchases',
  ADD_CONTACT: '/call-top-up/calltopupapi/adddestinationnumbers',
  DELETE_CONTACT: '/call-top-up/calltopupapi/deletedestinationnumber',
  CANCEL_SUBSCRIPTION: '/call-top-up/calltopupapi/cancelplansubscription',
  GET_CONTACTS: '/call-top-up/calltopupapi/getdestinationnumbers',
};
