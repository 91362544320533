import React from 'react';
import { useTranslation } from 'react-i18next';

import BrBadge from '@components/common/BrBadge';
import BrButton from '@components/common/BrButton';
import BrContacts from '@components/common/BrContacts';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  imageUrl: string;
  imageFallbackUrl: string;
  topText: string;
  priceText: string;
  denomText?: string;
  badgeText?: string;
  hasSeparateLine?: boolean;
  additionalText?: string;
  onClickProps?: {
    id: string;
    onClick(id: string): void;
  };
}

const ContactActivity: React.FC<Props> = (props) => {
  const {
    imageUrl,
    imageFallbackUrl,
    topText,
    priceText,
    badgeText,
    hasSeparateLine,
    denomText,
    additionalText,
    onClickProps,
  } = props;

  const { t } = useTranslation();

  const handleOnClick = () => {
    onClickProps?.onClick(onClickProps.id);
  };

  return (
    <div
      className={normalizeStringCompound([
        'flex items-center space-x-middle',
        hasSeparateLine ? 'pb-middle border-b border-support-colors/lines' : '',
      ])}
    >
      <BrContacts
        cmpSize="small"
        cmpType="person"
        promoText={badgeText ? t('Promo') : undefined}
        imageUrl={imageUrl}
        fallbackImageUrl={imageFallbackUrl}
      />
      <div className="space-y-xsmall !mr-auto">
        <div className="text-body/caption/medium">{topText}</div>
        <div className="flex items-center space-x-xsmall">
          <div className="text-body/caption-2/demibold">{priceText}</div>
          {badgeText && (
            <BrBadge
              classType="warning"
              iconLeft="gift"
              text={badgeText}
              styleType="default"
            />
          )}
        </div>
        <div className="text-body/caption/default text-color/black/60">{denomText}</div>
        <div className="text-body/footnote/default text-support-colors/highlights">
          {additionalText}
        </div>
      </div>
      <BrButton
        cmpStyle="circle"
        size="default"
        type="button"
        cmpType="gray"
        hasIconLeft
        iconName="send-top-up-outline"
        className="!text-color/secondary"
        onClick={handleOnClick}
      />
    </div>
  );
};

export default ContactActivity;
