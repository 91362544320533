import React, { FC, PropsWithChildren } from 'react';

import { Icon } from '@components/common';

import { IconNames } from '@root/interfaces/components/Icon';
import BrInputProps from '@root/interfaces/components/BrInput';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';

import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import { normalizeStringCompound } from '@utils/string';

import BrButton, { BrButtonProps } from '../BrButton';
import BrAdvancedInput from '../BrAdvancedInput';

type BtnAlign = 'start' | 'end';

export interface BrCardButtonProps extends BrButtonProps {
  btnAlign?: BtnAlign;
  btnTooltipCfg?: BrTooltipProps;
}

const btnAlignClassNamesMap: Record<BtnAlign, string> = {
  start: 'justify-start',
  end: 'justify-end',
};

const BrCardFunctionalityIcon: React.FC<{ iconName: IconNames; className?: string }> = (
  props,
) => {
  const { iconName, className } = props;

  return (
    <Icon
      className={`brCardFunctionality-icon ${className}`}
      name={iconName}
      width={16}
      height={16}
    />
  );
};

interface Props {
  inputs?: BrInputProps[];
  buttons?: BrCardButtonProps[];
  title?: string;
  text?: string;
  iconColor?: string;
  iconName?: IconNames;
}

const BrCardFunctionality: FC<PropsWithChildren<Props>> = ({
  inputs = [],
  buttons = [],
  title,
  text,
  iconName,
  iconColor,
  children,
}) => {
  const inputElements = inputs.map((input) => (
    <div key={input.id}>
      <BrAdvancedInput {...input} />
    </div>
  ));

  const buttonElements = buttons.map((button) => {
    // destruct to avoid polluting button with rest props
    const { btnTooltipCfg, btnAlign, ...rest } = button;
    const buttonComponent = (
      <BrTooltipWrapper cfg={btnTooltipCfg}>
        <BrButton {...rest} />
      </BrTooltipWrapper>
    );

    const buttonClassNames = normalizeStringCompound([
      'flex',
      btnAlign ? btnAlignClassNamesMap[btnAlign] : undefined,
    ]);

    if (btnAlign) {
      return (
        <div key={button.id || button.text} className={buttonClassNames}>
          {buttonComponent}
        </div>
      );
    }

    return buttonComponent;
  });

  return (
    <div className="br-card-functionality bg-color/attention-variant rounded-middle p-middle space-y-middle flex flex-col">
      <div className="flex flex-col space-y-small">
        <div className="flex flex-row space-x-small">
          {iconName && (
            <BrCardFunctionalityIcon
              iconName={iconName}
              className={iconColor || 'text-color/attention'}
            />
          )}
          {title && <div className="text-body/callout/default">{title}</div>}
        </div>
        {text && (
          <div className="text-body/footnote/default block whitespace-break-spaces">
            {text}
          </div>
        )}
      </div>
      {/* RecipientPhoneForm etc. */}
      {children}
      {/* simple inputs and buttons in case if it is needed */}
      {inputElements}
      {buttonElements}
    </div>
  );
};

export default BrCardFunctionality;
