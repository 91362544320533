import React, { FC, PropsWithChildren } from 'react';

import { normalizeStringCompound } from '@utils/string';
import { IconNames } from '@root/interfaces/components/Icon';
import { Icon } from '@components/common';

import './styles.scss';

const BASIC_CLASS = 'br-badge';

export interface BrBadgeProps {
  // eslint-disable-next-line prettier/prettier
  classType?:'warning' | 'warning-variant' | 'success' | 'success-variant' | 'attention' | 'attention-variant' | 'error' | 'error-variant' | 'neutral'  | 'black';
  // eslint-disable-next-line prettier/prettier
  styleType?: 'default' | 'less-contrast' | 'tail-right' | 'tail-left' | 'less-contrast-tail-right' | 'tail-right-half' | 'tail-left-half' | 'less-contrast-tail-right-half';
  iconLeft?: IconNames;
  iconRight?: IconNames;
  className?: string;
  text?: string;
}

const BadgeIcon: React.FC<{ iconName: IconNames }> = ({ iconName }) => {
  return <Icon className="btn-icon" name={iconName} width={12} height={12} />;
};

const BrBadge: FC<PropsWithChildren<BrBadgeProps>> = ({
  children,
  classType,
  styleType,
  text,
  iconLeft,
  iconRight,
  className,
}) => {
  const classNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${classType}`,
    styleType === 'default' ? undefined : `${BASIC_CLASS}-${styleType}`,
    className,
  ]);

  return (
    <div className={classNames}>
      {iconLeft && <BadgeIcon iconName={iconLeft} />}
      <span>{text || children}</span>
      {iconRight && <BadgeIcon iconName={iconRight} />}
    </div>
  );
};

BrBadge.defaultProps = {
  classType: 'neutral',
  styleType: 'default',
  text: '',
};

export default BrBadge;
