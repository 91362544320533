import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  ForwardedRef,
  useImperativeHandle,
} from 'react';

import { normalizeStringCompound, replaceAccentCharacters } from '@utils/string';

import { SelectProps, OptionProps } from '@root/interfaces/components/Select';

import useNavigation from '@root/hooks/useNavigation';
import useOnClickOutside from '@root/hooks/useOnClickOutside';

import { useIsDesktop } from '../MediaQueryMatchers';

import OptionList from './OptionList';
import Icon from '../Icon';
import Dropdown from '../Dropdown';
import Input from '../Input';

export type SelectRef = {
  setSelectIsOpen(val: boolean): void;
  isOpen: boolean;
  drawerRef: React.RefObject<HTMLDivElement>;
};

export type SelectForwardRef = ForwardedRef<SelectRef>;

const Select = forwardRef((props: SelectProps, ref: SelectForwardRef) => {
  const {
    value,
    onChange,
    onFocus,
    options,
    disabled,
    className,
    inputClassName,
    hasSearch,
    addonBefore,
    children,
    size,
    dataTestId,
    isOpenForced,
    isOpen: isOpened,
    skin,
    componentRef,
    dropdownClassName,
    dropdownAddonTop,
    dropdownAddonBottom,
    onSelectOverridden,
    optionBtnClassName,
    drawerContainerClassName,
    drawerContentWrapperClassName,
    isDrawerChildrenVisible,
    placeholder,
    onClose,
    onOpen,
    clickOutsideIgnoreRefs = [],
    isInert,
    minDropdownBottomOffset,
    ...rest
  } = props;

  const valueOption =
    typeof value === 'string' ? options.find((item) => item.value === value) : value;

  const [currentOption, setCurrentOption] = useState(valueOption);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const [isPrevOpened, setIsPrevOpened] = useState<boolean | undefined>(false);

  if (isPrevOpened !== isOpened) {
    setIsPrevOpened(isOpened);
    setIsOpen(Boolean(isOpened));
  }

  const dropdownRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>();

  const isDesktopResolution = useIsDesktop();

  const ignoredElemRefs = [dropdownRef, drawerRef, ...clickOutsideIgnoreRefs];

  const getExposedSelectApi = () => ({
    setSelectIsOpen(val: boolean) {
      setIsOpen(val);
    },
    isOpen,
    drawerRef,
  });

  useImperativeHandle(ref, getExposedSelectApi);

  useImperativeHandle(componentRef, getExposedSelectApi);

  const handleClickOutside = () => {
    if (!isInert) {
      setIsFocused(false);
      setIsOpen(false);
      onClose?.();
    }
  };

  useOnClickOutside(handleClickOutside, ...ignoredElemRefs);

  // update selected value when the value or the options have changed
  useEffect(() => {
    setCurrentOption(valueOption);
  }, [value, options, valueOption]);

  useEffect(() => {
    setSearchValue('');
    if (isOpen && isDesktopResolution) {
      inputRef.current?.focus();
    }
  }, [isDesktopResolution, isOpen]);

  useEffect(() => {
    if (isFocused) {
      setIsOpen(true);
    }
  }, [isFocused]);

  const handleSelectFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isDesktopResolution) {
      setIsFocused(true);
    } else {
      setIsOpen(true);
    }
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleSelectClick = () => {
    setIsOpen(true);
    onOpen?.();
    inputRef.current?.focus();
  };

  const handleOptionSelect = (option: OptionProps) => {
    if (onSelectOverridden) {
      onSelectOverridden(option.value);
      return undefined;
    }
    setCurrentOption(option);
    setIsOpen(false);
    onChange?.(option.value);
    return undefined;
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredOptions = useMemo(() => {
    return options.filter((item) => {
      const normalizedSourceString = replaceAccentCharacters(item.text.toLowerCase());
      const normalizedQueryString = replaceAccentCharacters(searchValue.toLowerCase());
      return normalizedSourceString.includes(normalizedQueryString);
    });
  }, [searchValue, options]);

  const optionsToRender = useMemo(() => {
    return hasSearch ? filteredOptions : options;
  }, [hasSearch, filteredOptions, options]);

  const inputClassNames = normalizeStringCompound([
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
    'focus-within:border-blue-400 truncate',
    inputClassName,
  ]);

  const arrowClassNames = normalizeStringCompound([
    'ml-4',
    disabled ? 'cursor-not-allowed' : 'cursor-pointer',
  ]);

  const handleAddonBeforeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  const selectAddonBefore = (
    <button
      onClick={handleAddonBeforeClick}
      className="flex h-full w-full items-center"
      tabIndex={-1}
    >
      {addonBefore}
      <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} className={arrowClassNames} />
    </button>
  );

  const dropdownList = (
    <OptionList
      options={optionsToRender}
      onSelect={handleOptionSelect}
      dataTestId={`${dataTestId}-option`}
      optionBtnClassName={optionBtnClassName}
    />
  );

  useNavigation(dropdownRef, 'button.block', [optionsToRender, isFocused]);

  const handleInputRef = (compRef: HTMLInputElement | null) => {
    if (compRef) {
      inputRef.current = compRef;
    }
  };

  return (
    <Dropdown
      isOpen={isOpenForced || isOpen}
      containerRef={dropdownRef}
      ref={drawerRef}
      overlay={dropdownList}
      className={className}
      containerClassName={drawerContainerClassName}
      size={size}
      skin={skin}
      dropdownClassName={dropdownClassName}
      drawerContentWrapperClassName={drawerContentWrapperClassName}
      isChildrenVisible={isDrawerChildrenVisible}
      {...{ dropdownAddonTop, dropdownAddonBottom, minDropdownBottomOffset }}
    >
      {typeof children === 'function' ? (
        children(Boolean(isOpen), handleSelectClick)
      ) : (
        <Input
          readOnly={!hasSearch}
          value={isOpen && hasSearch ? searchValue : currentOption?.text || ''}
          className={inputClassNames}
          disabled={disabled}
          onFocus={handleSelectFocus}
          onClick={handleSelectClick}
          onChange={hasSearch ? handleSearch : undefined}
          addonBefore={selectAddonBefore}
          size={size}
          ref={handleInputRef}
          dataTestId={`${dataTestId}-input`}
          autoComplete="nope"
          placeholder={placeholder}
          {...rest}
        />
      )}
    </Dropdown>
  );
});

export default Select;
