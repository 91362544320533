// eslint-disable-next-line import/no-named-as-default
import { ReactNode, RefObject } from 'react';

// eslint-disable-next-line import/no-named-as-default
import BrInputProps from './BrInput';
import { TestIdDataAttr } from './Common';

import type { BrDropdownPaddingVariant } from './BrDropdown';

export interface BrOptionProps extends TestIdDataAttr {
  value: string;
  text: string;
  label?: React.ReactNode;
  isDisabled?: boolean;
  isSelected?: boolean;
  className?: string;
  addonTextLeft?: string;
}

export type OptGroupProps = {
  id: string;
  label: React.ReactNode;
  options: BrOptionProps[];
};

export const isOptGroupProps = (x: unknown): x is OptGroupProps => {
  return typeof x === 'object' && x !== null && 'options' in x;
};

type BrSelectRenderPropParams = {
  isOpened: boolean;
  onClick: () => Promise<void>;
  searchValue: string;
  onSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export type BrSelectRenderProp = (params: BrSelectRenderPropParams) => React.ReactNode;

export type BrSelectChildrenType = React.ReactElement | BrSelectRenderProp;

type RenderItem = {
  option: BrOptionProps;
  isActive: boolean;
  onSelect: (options: BrOptionProps) => void;
};
export interface BrSelectProps extends Omit<BrInputProps, 'value' | 'onChange'> {
  hasSearch?: boolean;
  value?: BrOptionProps | string;
  className?: string;
  dropdownClassName?: string;
  dropdownPaddingVariant?: BrDropdownPaddingVariant;
  drawerTitleText?: string;
  options: (BrOptionProps | OptGroupProps)[];
  selectButtonAddonBefore?: ReactNode;
  notFoundText?: string;
  isOpened?: boolean;
  isDisabled?: boolean;
  isAllowUnselect?: boolean;
  onChange?(value?: string): void;
  onFocus?(event: React.ChangeEvent<HTMLInputElement>): void;
  onBlur?(event: React.ChangeEvent<HTMLInputElement>): void;
  onOpen?(): void;
  onClose?(): void;
  onAnimationEnd?(): void;
  onSearch?(option: BrOptionProps, value: string): boolean;
  children?: BrSelectChildrenType;
  renderItem?(item: RenderItem, itemIdx: number): JSX.Element;
  dropdownAddonBottom?: React.ReactNode;
  drawerAddonBottom?: React.ReactNode;
  dropdownMaxHeight?: number;
  ignoredElemsRefs?: RefObject<HTMLDivElement | undefined>[];
  renderOverlay?(params: {
    options: (BrOptionProps | OptGroupProps)[];
    searchValue: string;
    selectedOption?: BrOptionProps;
    onItemSelect?: (option: BrOptionProps) => Promise<void>;
  }): React.ReactNode;
  onClickOutside?(): Promise<void>;
}

export default BrSelectProps;
