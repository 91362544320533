import qs from 'qs';

import { dtcImtuK2Axios } from '@services/axios';
import { DTC_IMTU_K2_ENDPOINTS } from '@services/api/v1/constants/dtcImtuK2';

import {
  DtcImtuK2OffersResponse,
  OffersEndpointRequest,
} from '@root/interfaces/contract/dtcImtuK2/Offers';
import {
  DtcImtuK2TransactionsResponse,
  TransactionsEndpointRequest,
} from '@root/interfaces/contract/dtcImtuK2/Transactions';
import {
  DtcImtuK2PromoInfoResponse,
  PromoInfoEndpointRequest,
} from '@root/interfaces/contract/dtcImtuK2/PromoInfo';
import {
  DtcImtuK2PurchaseResponse,
  PurchaseEndpointRequest,
} from '@root/interfaces/contract/dtcImtuK2/Purchase';
import {
  FetchPromoInfoServiceReqData,
  ImtuOffersServiceData,
  SendImtuTxnServiceData,
  getCarriersReqParams,
  getOffersReqParams,
  getPromoInfoReqParams,
  getTransactionsReqParams,
  getTxnDetailsReqParams,
  convertOfferToImtuProductClassParams,
  getAllTxnsReqParams,
} from '@services/api/v1/dtcImtuK2/helpers';
import { DtcImtuK2ConfigResponse } from '@root/interfaces/contract/dtcImtuK2/Config';
import { DtcImtuK2PromosResponse } from '@root/interfaces/contract/dtcImtuK2/Promos';
import { DtcImtuK2Transaction } from '@root/interfaces/contract/dtcImtuK2/ImtuK2Transaction';
import { DtcImtuK2PromosV2Response } from '@root/interfaces/contract/dtcImtuK2/PromosV2';

import ImtuCarrierClass from '@root/models/ImtuCarrierClass';
import ImtuProductClass from '@root/models/ImtuProductClass';
import ImtuTransaction from '@root/models/ImtuTransaction';
import ImtuPromoOfferDetailsClass from '@root/models/ImtuPromoOfferDetailsClass';

import { logMessage } from '@services/logging';

const checkAndLogCorruptedImtuTxn = (txnFromRes: DtcImtuK2Transaction) => {
  // TODO: remove this log if you don't see these logs in graylog for 12 days
  if (!txnFromRes.pricing_information || !txnFromRes.carrier) {
    const message = `imtu txn id:${txnFromRes.txid} has corrupted fields: ${
      txnFromRes.pricing_information ? '' : 'pricing_information'
    }, ${txnFromRes.carrier ? '' : 'carrier'}`;
    logMessage(message);
  }
};

const apiCalls = {
  getConfig: () =>
    dtcImtuK2Axios.get<DtcImtuK2ConfigResponse>(DTC_IMTU_K2_ENDPOINTS.CONFIG),
  getPromos: () =>
    dtcImtuK2Axios.get<DtcImtuK2PromosResponse>(DTC_IMTU_K2_ENDPOINTS.PROMOS),
  getPromosV2: () =>
    dtcImtuK2Axios.get<DtcImtuK2PromosV2Response>(DTC_IMTU_K2_ENDPOINTS.PROMOS_v2),
  getOffers: (params: OffersEndpointRequest) =>
    dtcImtuK2Axios.get<DtcImtuK2OffersResponse>(
      `${DTC_IMTU_K2_ENDPOINTS.OFFERS}?${qs.stringify(params)}`,
    ),
  getCarrierPromoOffers: (carrierPromoId: string) =>
    dtcImtuK2Axios.get<DtcImtuK2OffersResponse>(
      `${DTC_IMTU_K2_ENDPOINTS.CARRIER_PROMO_OFFERS}?${qs.stringify({
        carrier_promo_id: carrierPromoId,
      })}`,
    ),
  getTransactions: (params?: TransactionsEndpointRequest) =>
    dtcImtuK2Axios.get<DtcImtuK2TransactionsResponse>(
      `${DTC_IMTU_K2_ENDPOINTS.TRANSACTIONS}?${qs.stringify(params)}`,
    ),
  getPromoInfo: (params: PromoInfoEndpointRequest) =>
    dtcImtuK2Axios.get<DtcImtuK2PromoInfoResponse>(
      `${DTC_IMTU_K2_ENDPOINTS.PROMO_CHECK}/${params.OfferID}?${qs.stringify(params)}`,
    ),
  doPurchase: (params: PurchaseEndpointRequest) =>
    dtcImtuK2Axios.post<DtcImtuK2PurchaseResponse>(DTC_IMTU_K2_ENDPOINTS.PURCHASES, {
      ...params,
    }),
};

export const fetchImtuConfig = async () => {
  const res = await apiCalls.getConfig();
  return res.data;
};

// deprecated. We switched to v2/promos. To be deleted when IMTU flow is finished
export const fetchImtuPromosFromCarriers = async () => {
  const promosRes = await apiCalls.getPromos();
  return promosRes.data;
};

export const fetchImtuPromosFromCarriersV2 = async () => {
  const promosRes = await apiCalls.getPromosV2();
  return promosRes.data;
};

export const fetchImtuCarriers = async (recipientPhone: string) => {
  const carriersReqParams = getCarriersReqParams(recipientPhone);
  const carriersRes = await apiCalls.getOffers(carriersReqParams);
  const mappedCarriers = carriersRes.data.all_carriers.map((item) => {
    return new ImtuCarrierClass(item);
  });
  return {
    carriers: mappedCarriers,
    detectedCarrier: carriersRes.data.carrier
      ? new ImtuCarrierClass(carriersRes.data.carrier)
      : undefined,
  };
};

export const fetchImtuOffers = async (params: ImtuOffersServiceData) => {
  const offersReqParams = getOffersReqParams(params);
  const offersRes = await apiCalls.getOffers(offersReqParams);
  const mappedOffers = offersRes.data.offers.map((item) => {
    return new ImtuProductClass(convertOfferToImtuProductClassParams(item));
  });
  return mappedOffers;
};

export const fetchImtuCarrierPromoOffers = async (carrierPromoId: string) => {
  const offersRes = await apiCalls.getCarrierPromoOffers(carrierPromoId);
  const mappedOffers = offersRes.data.offers.map((item) => {
    return new ImtuProductClass(convertOfferToImtuProductClassParams(item));
  });
  return mappedOffers;
};

export const fetchImtuTxns = async () => {
  const allTxnsRequestParams = getAllTxnsReqParams();
  const txnsRes = await apiCalls.getTransactions(allTxnsRequestParams);
  const mappedTxns = txnsRes.data.transactions.map((item) => {
    // TODO: remove this log if you don't see these logs in graylog for 12 days
    checkAndLogCorruptedImtuTxn(item);

    return new ImtuTransaction(item);
  });
  return mappedTxns;
};

export const fetchImtuTxnDetails = async (txnId: string) => {
  const txnDetailsRequestParams = getTxnDetailsReqParams(txnId);
  const res = await apiCalls.getTransactions(txnDetailsRequestParams);

  const txnFromRes = res.data.transactions[0];

  // TODO: remove this log if you don't see these logs in graylog for 12 days
  checkAndLogCorruptedImtuTxn(txnFromRes);

  const txn = new ImtuTransaction(txnFromRes);
  return txn;
};

export const fetchPromoInfo = async (params: FetchPromoInfoServiceReqData) => {
  const promoInfoReqParams = getPromoInfoReqParams(params);
  const { data } = await apiCalls.getPromoInfo(promoInfoReqParams);

  if (data.phone_number_issue?.reason) {
    throw Error(data.phone_number_issue.reason);
  }

  const {
    offer,
    offer: { promo_offer },
  } = data;
  const offerDiscounted = new ImtuPromoOfferDetailsClass({
    offerId: offer.offer_id,
    promoId: promo_offer.promo_id,
    priceTotal: Number(offer.price.price),
    priceTotalDiscounted: Number(promo_offer.price),
    fee: promo_offer.fees ? Number(promo_offer.fees) : 0,
    discount: Number(promo_offer.discount),
    priceCurrency: promo_offer.currency_code,
    description: promo_offer.tagline,
  });
  return offerDiscounted;
};

export const sendImtuTxn = async (params: SendImtuTxnServiceData) => {
  const sendImtuReqParams = getTransactionsReqParams(params);
  const res = await apiCalls.doPurchase(sendImtuReqParams);
  return res.data;
};
