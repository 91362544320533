import { makeAutoObservable } from 'mobx';

import { fetchCallingPlanTxnDetails } from '@services/api/v1/dtcCallingPlans';

import { CALLING_PLANS_STATUSES } from '@root/constants/CallingPlans/callingPlansStatuses';

import delay from '@utils/delay';

import CallingPlanTxn from '@root/models/CallingPlans/CallingPlanTxn';
import ImtuTransaction from '@root/models/ImtuTransaction';

import { extractImtuPartFromCallingTransaction } from '@helpers/ImtuCallingTxn';

const TXN_DETAILS_REQUEST_DELAY = 2000;
const TXN_DETAILS_MAX_ATTEMPTS = 30; // for theoretical edge case when transaction doesn't appear

// TODO:  Replace callingPlanTxn and imtuTxn with human readable classes
class CallingTxnDetailsLooperStore {
  callingPlanTxn?: CallingPlanTxn;

  imtuTxn?: ImtuTransaction;

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setTxns(txn?: CallingPlanTxn, imtuTxn?: ImtuTransaction) {
    this.callingPlanTxn = txn;
    this.imtuTxn = imtuTxn;
  }

  updateTxnStatusInLoop = async (
    txnId: string,
    attemptsCounter = 0,
  ): Promise<undefined> => {
    this.setIsLoading(true);
    try {
      const callingTxnDetailsRes = await fetchCallingPlanTxnDetails(txnId);

      const CallingPlansTxn = new CallingPlanTxn(callingTxnDetailsRes);
      const imtuTxnData = extractImtuPartFromCallingTransaction(callingTxnDetailsRes);
      const imtuTxn = imtuTxnData ? new ImtuTransaction(imtuTxnData) : undefined;
      this.setTxns(CallingPlansTxn, imtuTxn);

      if (
        CallingPlansTxn?.status === CALLING_PLANS_STATUSES.QUEUED ||
        imtuTxn?.status === CALLING_PLANS_STATUSES.QUEUED
      ) {
        throw new Error('retry');
      }

      return undefined;
    } catch (err) {
      if (attemptsCounter > TXN_DETAILS_MAX_ATTEMPTS) {
        return undefined;
      }

      await delay(TXN_DETAILS_REQUEST_DELAY);
      return await this.updateTxnStatusInLoop(txnId, attemptsCounter + 1);
    } finally {
      this.setIsLoading(false);
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };
}

export default CallingTxnDetailsLooperStore;
