import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/common';
import { normalizeStringCompound } from '@utils/string';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface Props extends TestIdDataAttr {
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const EditPhotoButton: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, id, onChange, className, dataTestId } = props;
  const { t } = useTranslation();

  const classNames = normalizeStringCompound([
    'block rounded-full overflow-hidden relative cursor-pointer focus-within:focus-ring',
    className,
  ]);

  return (
    <label htmlFor={id} className={classNames} aria-label={t('Profile avatar')}>
      {children}
      <div className="absolute inset-0 flex flex-col items-center justify-center bg-black-200 bg-opacity-50 text-white">
        <Icon name="Camera" className="text-4xl mt-1" />
        <div className="text-md mt-1">{t('Edit')}</div>
      </div>
      <input
        id={id}
        type="file"
        className="sr-only"
        onChange={onChange}
        data-test-id={dataTestId}
      />
    </label>
  );
};

export default EditPhotoButton;
