import { FC } from 'react';
import { Img } from 'react-image';

import CountryCode from '@root/interfaces/CountryCode';

import { ASSETS_URL } from '@services/api/v1/constants/cdnAndOthers';

import Skeleton from '../Skeleton';

interface Props {
  code?: CountryCode;
  className?: string;
  customSkeleton?: JSX.Element;
}

const Flag: FC<React.PropsWithChildren<Props>> = (props) => {
  const { className, code, customSkeleton } = props;

  return (
    <div className={className}>
      {code && (
        <Img
          key={code} // ey fixes the infinite loader bug on quick url change
          src={`${ASSETS_URL.COUNTRY_FLAGS}/${code.toUpperCase()}.svg`}
          alt={code}
          className="object-cover w-full h-full"
          loader={customSkeleton ?? <Skeleton />}
          loading="lazy"
          unloader={
            <div className="w-full h-full bg-gray-300 flex items-center justify-center text-sm leading-none">
              {code.toUpperCase()}
            </div>
          }
        />
      )}
    </div>
  );
};
export default Flag;
