import React, { FC } from 'react';

import BrButton from '@components/common/BrButton';

import { TestIdDataAttr } from '@root/interfaces/components/Common';

interface Props extends TestIdDataAttr {
  title: string;
  value: string;
  editButtonText: string;
  onEdit: () => void;
}

const GeneralUserInfoItem: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, value, editButtonText, onEdit, dataTestId } = props;

  return (
    <>
      <h5 className="text-headers/header-5">{title}</h5>
      <div data-recording-sensitive>{value}</div>
      <BrButton size="default" cmpType="link" onClick={onEdit} dataTestId={dataTestId}>
        {editButtonText}
      </BrButton>
    </>
  );
};

export default GeneralUserInfoItem;
