/* eslint-disable import/prefer-default-export */

import { MainFeatures } from '@root/interfaces/appConfig';

// Default values. Real ones we get from Firebase remote config
export const defaultMainFeatures: MainFeatures = {
  isMtAvailable: false,
  isCallingAvailable: false,
  isImtuAvailable: false,
  isCallingPlansAvailable: false,
};
