interface Props {
  text: string;
  className?: string;
}

const Title = (props: Props) => {
  const { className, text } = props;

  return (
    <div className={className}>
      <span className="text-body/caption/default text-on-colors/on-primary-variant">
        {text}
      </span>
    </div>
  );
};

export default Title;
