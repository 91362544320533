import React, { PropsWithChildren } from 'react';

import { Icon } from '@components/common';

import { ContextMenuItemPropsLink } from '@root/interfaces/components/BrContextMenu';
import BrLink from '@components/common/BrLink';

interface Props<T> extends ContextMenuItemPropsLink<T> {
  onSelect?(id: T): void;
}

const itemBaseClass = 'menu-item';

const MenuItemLink = <T,>(props: PropsWithChildren<Props<T>>) => {
  const {
    id,
    path,
    title,
    description,
    iconLeft,
    iconRight,
    isDisabled,
    isNative,
    onSelect,
    target,
  } = props;

  const handleSelect = () => {
    onSelect?.(id);
  };

  return (
    <li key={String(id)}>
      <button onClick={handleSelect} className={itemBaseClass} disabled={isDisabled}>
        {iconLeft && (
          <div className="icon-container">
            <Icon name={iconLeft} />
          </div>
        )}
        <BrLink to={path} className="text-container" isNative={isNative} target={target}>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </BrLink>
        {iconRight && (
          <div className="icon-container">
            <Icon name={iconRight} />
          </div>
        )}
      </button>
    </li>
  );
};

export default MenuItemLink;
