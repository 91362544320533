/* eslint-disable import/prefer-default-export */
import hotToast from 'react-hot-toast';
import i18n from '../i18n';

export enum ToastType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success',
}

export type BrToastType =
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'small'
  | 'medium'
  | 'large';

export const brToastTypes: Record<string, BrToastType> = {
  error: 'error',
  warning: 'warning',
  success: 'success',
  info: 'info',
  small: 'small',
  medium: 'medium',
  large: 'large',
};

function toast(
  this: { type: ToastType },
  messageKey: string,
  messageKeyVariables?: Dictionary<string>,
) {
  const message = i18n.t(messageKey, messageKeyVariables);

  switch (this.type) {
    case ToastType.Success:
      return hotToast.success(message, { id: message });

    case ToastType.Error:
      // eslint-disable-next-line no-console
      console.error(message);
      return hotToast.error(message, { id: message });

    default:
    case ToastType.Warning:
      return hotToast(message, { id: message });
  }
}

function brToast(
  this: { type: BrToastType },
  messageKey: string,
  messageKeyVariables?: Dictionary<string>,
) {
  const message = i18n.t(messageKey, messageKeyVariables);

  switch (this.type) {
    case brToastTypes.success:
      return hotToast.success(message, {
        id: message,
        className: this.type,
      });

    case brToastTypes.error:
      // eslint-disable-next-line no-console
      console.error(message);
      return hotToast.error(message, {
        id: message,
        className: this.type,
      });

    default:
      return hotToast(message, {
        id: message,
        className: this.type,
      });
  }
}

export const dismissToast = (toastId?: string) => hotToast.dismiss(toastId);

export const showToast = {
  error: toast.bind({ type: ToastType.Error }),
  warning: toast.bind({ type: ToastType.Warning }),
  success: toast.bind({ type: ToastType.Success }),
};

export const showBrToast = {
  error: brToast.bind({ type: brToastTypes.error }),
  warning: brToast.bind({ type: brToastTypes.warning }),
  success: brToast.bind({ type: brToastTypes.success }),
  info: brToast.bind({ type: brToastTypes.info }),
  small: brToast.bind({ type: brToastTypes.small }),
  medium: brToast.bind({ type: brToastTypes.medium }),
  large: brToast.bind({ type: brToastTypes.large }),
};
