import React from 'react';

import { legacyIconToFileNamesMap } from '@components/common/Icon/constants';

import IconProps from '@root/interfaces/components/Icon';

type Props = Pick<IconProps, 'width' | 'height' | 'viewBox' | 'name'>;

const SvgComponent: React.FunctionComponent<Props> = (props) => {
  // eslint-disable-next-line no-empty-pattern
  const { width, height, viewBox, name } = props;

  const isLegacyIcon =
    name.includes('legacy-') || Object.keys(legacyIconToFileNamesMap).includes(name);
  const spriteFileName = isLegacyIcon ? 'legacy-sprite.svg' : 'br-sprite.svg';

  // this ts rule make no sense. using Record<string, string> is inconvenient
  // @ts-ignore
  const mappedIconName = legacyIconToFileNamesMap?.[name] || name;

  return (
    <svg {...{ width, height, viewBox }}>
      <use
        href={`${window.location.origin}/sprites/${spriteFileName}?v=${process.env.REACT_APP_VERSION}#${mappedIconName}`}
      />
    </svg>
  );
};

export default SvgComponent;
