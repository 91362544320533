/* eslint-disable import/prefer-default-export */
import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';

export const getFullCardTypeName = (type?: PaymentCardTypeShort) => {
  switch (true) {
    case type === PaymentCardTypeShort.CC:
      return 'Credit card'; // t('Credit card')
    case type === PaymentCardTypeShort.DC:
      return 'Debit card'; // t('Debit card)
    case type === PaymentCardTypeShort.PP:
      return 'Prepaid card'; // t('Prepaid card')
    default:
      return 'Unknown card'; // t('Unknown card')
  }
};
