import { makeAutoObservable } from 'mobx';

import { fetchImtuTxnDetails } from '@services/api/v1/dtcImtuK2';
import storageInterface from '@services/storage';
import { IMTU_3DS_HANDLED_TXN_ID_STORE_KEY } from '@services/auth/constants';

import ImtuTransaction from '@root/models/ImtuTransaction';

import { IMTU_STATUSES } from '@root/constants/mobileTopUp/imtuStatuses';

import delay from '@utils/delay';

const TXN_DETAILS_REQUEST_DELAY = 1000;
const TXN_DETAILS_MAX_ATTEMPTS = 30; // for theoretical edge case when transaction doesn't appear

class ImtuTxnDetailsLooperStore {
  txn?: ImtuTransaction;

  constructor() {
    makeAutoObservable(this);
  }

  setTxn(txn: ImtuTransaction) {
    this.txn = txn;
  }

  updateTxnStatusInLoop = async (
    txnId: string,
    attemptsCounter = 0,
  ): Promise<undefined> => {
    try {
      const imtuTxnDetailsRes = await fetchImtuTxnDetails(txnId);
      this.setTxn(imtuTxnDetailsRes);

      // checks if 3DS redirect is required
      if (
        this.txn?.id &&
        this.txn.id !== storageInterface.getItem(IMTU_3DS_HANDLED_TXN_ID_STORE_KEY) &&
        imtuTxnDetailsRes.errorCode === '3ds_pending' &&
        imtuTxnDetailsRes.redirectUrl
      ) {
        storageInterface.setItem(IMTU_3DS_HANDLED_TXN_ID_STORE_KEY, this.txn.id);
        window.location.href = imtuTxnDetailsRes.redirectUrl;
      }

      if (imtuTxnDetailsRes.status === IMTU_STATUSES.QUEUED) {
        throw new Error('retry');
      }

      return undefined;
    } catch (err) {
      if (attemptsCounter > TXN_DETAILS_MAX_ATTEMPTS) {
        return undefined;
      }

      await delay(TXN_DETAILS_REQUEST_DELAY);
      return this.updateTxnStatusInLoop(txnId, attemptsCounter + 1);
    }
  };
}

export default ImtuTxnDetailsLooperStore;
