import React, { useEffect } from 'react';

import { UserAccountStore } from '@root/stores';

import LogotypeLink from '@components/common/BrLogotypeLink';

import useStore from '@root/hooks/useStore';

import { getFallbackLocaleForLegalUrls } from '@helpers/locale';

import BrMobileMenu from './containers/BrMobileMenu';

interface Props {
  isOpen?: boolean;
}

const BrMobileNavigationMenu: React.FunctionComponent<Props> = (props: Props) => {
  const { isOpen } = props;

  useEffect(() => {
    if (isOpen) {
      document.documentElement.classList.add('overflow-hidden');
    } else {
      document.documentElement.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  const userAccountStore: UserAccountStore = useStore('UserAccountStore');

  const fallbackLocale = getFallbackLocaleForLegalUrls(
    userAccountStore.currentLanguageLocale,
  );

  return (
    <div className={`br-mobile-menu--wrapper br-container ${isOpen ? 'isOpen' : ''}`}>
      <div className="br-mobile-menu--cover" />
      <div className="br-mobile-menu--logo">
        <div className="br-container relative h-0">
          <div className="pt-small pb-small mr-[28px] bg-color/background">
            <LogotypeLink
              className="br-mobile--logo inline-block h-xxxlarge"
              languageLocale={fallbackLocale}
              target="_blank"
            />
          </div>
        </div>
      </div>

      <div className="br-container--menu">
        <div className="br-container">
          <BrMobileMenu />
        </div>
      </div>
    </div>
  );
};

export default BrMobileNavigationMenu;
