import { MainFeatures } from '@root/interfaces/appConfig';

type MergeConfigParams = {
  appConfig: MainFeatures;
  firebaseRemoteConfig?: MainFeatures;
};

/* eslint-disable import/prefer-default-export */
export const getMergedConfig = (params: MergeConfigParams): MainFeatures => {
  // remote config rewrites app config
  const mergedConfig = {
    ...params.appConfig,
    ...params.firebaseRemoteConfig,
  };
  return mergedConfig;
};
