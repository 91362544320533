/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { forwardRef, Ref } from 'react';

import { normalizeStringCompound } from '@utils/string';

import { Icon } from '@components/common';

import BrInputProps from '@root/interfaces/components/BrInput';

import './styles.scss';

const BASIC_CLASS = 'br-input';

const BrInput = forwardRef((props: BrInputProps, ref: Ref<HTMLInputElement>) => {
  const {
    value,
    name,
    id,
    onWhat = 'background',
    className,
    placeholder,
    type = 'text',
    align = 'left',
    prepend,
    append,
    addonBefore,
    dataTestId,
    ariaLabel,
    iconLeft,
    iconRight,
    customRightIcon,
    iconRightClassNames,
    isReadOnly,
    isDisabled,
    isSecureContent = false,
    hasAutoFocus,
    onChange,
    onFocus,
    onBlur,
    onClick,
    onIconClickLeft,
    onIconClickRight,
    iconRightTabIndex,
    ...rest
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event);
    }
  };

  const handleWrapperClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleIconClickLeft = () => {
    onIconClickLeft?.();
  };

  const handleIconClickRight = () => {
    onIconClickRight?.();
  };

  const wrapperClassNames = normalizeStringCompound([
    BASIC_CLASS,
    isDisabled ? `${BASIC_CLASS}-disabled` : undefined,
    onWhat === 'background' ? `${BASIC_CLASS}-on-background` : undefined,
    onWhat === 'surface' ? `${BASIC_CLASS}-on-surface` : undefined,
    className,
  ]);
  const inputClassNames = normalizeStringCompound([
    align === 'center' ? 'text-center' : '',
  ]);

  return (
    <div
      className={wrapperClassNames}
      onClick={handleWrapperClick}
      data-test-id={`${dataTestId}-dropdown`}
    >
      {addonBefore}
      {append && (
        <span className={`${BASIC_CLASS}-addon ${BASIC_CLASS}-addon-prepend`}>
          {append}
        </span>
      )}
      {iconLeft ? (
        <Icon
          name={iconLeft}
          className={`${BASIC_CLASS}-icon ${BASIC_CLASS}-icon-left`}
          onClick={handleIconClickLeft}
        />
      ) : null}
      <input
        ref={ref}
        id={id}
        value={value}
        type={type}
        name={name}
        className={inputClassNames}
        placeholder={placeholder}
        disabled={isDisabled}
        data-test-id={dataTestId}
        aria-label={ariaLabel}
        data-recording-ignore={isSecureContent ? 'mask' : 'none'}
        autoFocus={hasAutoFocus}
        readOnly={isReadOnly}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      {iconRight && !customRightIcon ? (
        <Icon
          name={iconRight}
          className={normalizeStringCompound([
            `${BASIC_CLASS}-icon ${BASIC_CLASS}-icon-right`,
            iconRightClassNames,
          ])}
          onClick={handleIconClickRight}
          tabIndex={iconRightTabIndex}
          width={16}
          height={16}
        />
      ) : null}
      {!iconRight && customRightIcon}
      {prepend && (
        <span className={`${BASIC_CLASS}-addon ${BASIC_CLASS}-addon-append`}>
          {prepend}
        </span>
      )}
    </div>
  );
});

export default BrInput;
