import { useTranslation } from 'react-i18next';

import BrBadge from '@components/common/BrBadge';
import BrContacts from '@components/common/BrContacts';

import { formatPhone } from '@helpers/phone';

import { ImtuStep } from '@components/modules/Imtu/constants';

import CountryCode from '@root/interfaces/CountryCode';

import EditButton from '../EditButton';

interface Props {
  countryCode: CountryCode;
  phone: string;
  onEdit(step: ImtuStep): void;
  step: ImtuStep;
}

const RecipientInfo = (props: Props) => {
  const { countryCode, phone, onEdit, step } = props;

  const { t } = useTranslation();

  return (
    <div className="flex items-center">
      <div className="mr-middle">
        <BrContacts cmpType="flag" countryCode={countryCode} cmpSize="default" />
      </div>
      <div className="flex items-center w-full">
        <div className="mr-xsmall space-y-xsmall flex-grow">
          <BrBadge
            text={t('Recipient')}
            classType="success-variant"
            styleType="default"
          />
          <div className="text-body/primary/demibold">
            {formatPhone(phone, countryCode)}
          </div>
        </div>
        <EditButton onClick={onEdit} step={step} />
      </div>
    </div>
  );
};

export default RecipientInfo;
