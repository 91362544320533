import React from 'react';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import CallingAutoRecharge from '@components/modules/CallingAutoRecharge';

const CallingAutoRechargePage = () => {
  return (
    <BrTemplateWithSidebar>
      <CallingAutoRecharge />
    </BrTemplateWithSidebar>
  );
};

export default CallingAutoRechargePage;
