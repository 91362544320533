import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import BrRechargeAmountsSelector from '@components/common/BrRechargeAmountsSelector';
import BrAutoRechargeToggle from '@components/common/BrAutoRechargeToggle';
import BrCard from '@components/common/BrCard';
import BrModal from '@components/common/BrModal';
import BrButton from '@components/common/BrButton';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';

import useModal from '@root/hooks/useModal';

import { BrRadioOption } from '@root/interfaces/components/BrRadio';
import BrAlert from '../BrAlert';

interface Props {
  data: BrRadioOption[];
  defaultAmount?: number;
  onAmountSelect(value: string): void;
}

const BrSavingsPassSection = (props: Props) => {
  const { data, defaultAmount, onAmountSelect } = props;

  const [amount, setAmount] = useState(defaultAmount);

  const {
    isModalOpen,
    hasCloseButton,
    showModal,
    closeModal,
    modalHeader,
    modalContent,
    setModalContent,
    setModalHeader,
    setHasCloseButton,
  } = useModal();

  const { t } = useTranslation();
  const isDesktopResolution = useIsDesktop();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(Number(e.target.value));
    onAmountSelect(e.target.value);
  };

  const enableAutoRechargeModalContent = (
    <>
      <div className="text-body/primary/default text-on-colors/on-primary">
        {t('Auto-recharge must be turned on to subscribe to BOSS Savings Pass.')}
      </div>
      <BrButton
        className="w-full mt-default"
        text={t('I understand')}
        onClick={closeModal}
      />
    </>
  );

  const arTooltipText = t(
    'Never get off in the middle of a call. If your balance falls below 3.00 USD your account will automatically be recharged by 10.00 USD or change it to a different amount in settings.',
  );

  const autoRechargeInfoModalContent = (
    <div className="text-body/primary/default text-on-colors/on-primary">
      {arTooltipText}
    </div>
  );

  const handleOnInfoIconClick = () => {
    if (!isDesktopResolution) {
      setModalHeader(t('Auto-recharge'));
      setHasCloseButton(true);
      setModalContent(autoRechargeInfoModalContent);
      showModal();
    }
  };

  const handleOnToggleClick = () => {
    setModalHeader(t('Auto-recharge'));
    setHasCloseButton(false);
    setModalContent(enableAutoRechargeModalContent);
    showModal();
  };

  useEffect(() => {
    setAmount(defaultAmount);
  }, [defaultAmount]);

  return (
    <>
      <BrCard>
        <div className="flex space-x-small justify-between items-center mb-small">
          <div className="text-body/primary/demibold text-on-colors/on-primary">
            {t('Recharge amounts')}
          </div>
          <BrAutoRechargeToggle
            checked
            leftText={t('Auto-recharge')}
            iconTooltipCfg={
              /* eslint-disable @typescript-eslint/indent */
              isDesktopResolution
                ? {
                    place: 'bottom',
                    content: arTooltipText,
                  }
                : undefined
              /* eslint-enable @typescript-eslint/indent */
            }
            onClick={handleOnToggleClick}
            onInfoIconClick={handleOnInfoIconClick}
          />
        </div>
        <div className="text-body/footnote/default text-support-colors/highlights mb-middle">
          {t(
            'Select an amount you would like to be added to your account when your balance falls below 3.00 USD.',
          )}
        </div>
        <BrRechargeAmountsSelector
          value={amount}
          onChange={handleOnChange}
          options={data}
          name="recharge-amounts"
        />

        <BrAlert
          classType="warning-variant"
          className="w-full mt-middle !whitespace-normal"
          text={t(
            'Further subscription charges will be deducted from your Calling Balance',
          )}
        />
      </BrCard>

      <BrModal
        header={modalHeader}
        isOpen={isModalOpen}
        onClose={closeModal}
        hasCloseButton={hasCloseButton}
      >
        {modalContent}
      </BrModal>
    </>
  );
};

export default BrSavingsPassSection;
