import React, { useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { Tooltip, TooltipRefProps } from 'react-tooltip';

import { BrTooltipProps } from '@root/interfaces/components/BrTooltip';

import { normalizeStringCompound } from '@utils/string';

import useDefaultOpenTooltipState from './useDefaultOpenTooltipState';

import './styles.scss';

const BASIC_CLASS = 'br-tooltip';

const BrTooltip: React.FC<BrTooltipProps> = (props) => {
  const {
    color,
    defaultIsOpen: isDefaultOpen,
    delayHide,
    className,
    anchorSelect,
  } = props;

  const ref = useRef<TooltipRefProps>(null);

  const portalEl = useMemo(() => {
    const PORTAL_EL_ID = 'tooltip-portal';
    const createPortalEl = (): HTMLDivElement => {
      const el = document.createElement('div');
      el.setAttribute('id', PORTAL_EL_ID);
      document.body.appendChild(el);
      return el;
    };

    return document.getElementById(PORTAL_EL_ID) || createPortalEl();
  }, []);

  const cmpClassNames = normalizeStringCompound([
    BASIC_CLASS,
    `${BASIC_CLASS}-${color}`,
    className,
  ]);

  const defaultOpenTooltipState = useDefaultOpenTooltipState({
    isDefaultOpen,
    delayHide,
  });

  // Portal needed because react-tooltip doesn't handle overflow nicely
  return createPortal(
    <Tooltip
      {...props}
      delayHide={delayHide}
      anchorSelect={anchorSelect}
      className={cmpClassNames}
      ref={ref}
      /* eslint-disable @typescript-eslint/indent */
      {...(defaultOpenTooltipState
        ? {
            isOpen: defaultOpenTooltipState.isOpen,
            hidden: defaultOpenTooltipState.isDisabled,
          }
        : undefined)}
      /* eslint-enable @typescript-eslint/indent */
    />,
    portalEl,
  );
};

BrTooltip.defaultProps = {
  color: 'Default',
};

export default BrTooltip;
