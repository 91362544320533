import { BrOptionProps, OptGroupProps } from '@root/interfaces/components/BrSelect';
import CountryCode from '@root/interfaces/CountryCode';

import BrFastAccessCountries from '../BrFastAccessCountries';
import Countries from './CountryList';
import Title from './Title';

interface Props {
  className?: string;
  fastAccessSectionData?: { label?: string; options: CountryCode[] };
  data: (BrOptionProps | OptGroupProps)[];
  selectedOption?: BrOptionProps;
  onOptionSelect?(option: BrOptionProps): void;
  onFastAccessBtnClick?(code: CountryCode): void;
}

const BrCountryList: React.FC<Props> = (props) => {
  const {
    fastAccessSectionData,
    data,
    className,
    onOptionSelect,
    onFastAccessBtnClick,
    selectedOption,
  } = props;

  const handleOnFastAccessBtnClick = (code: CountryCode) => {
    onFastAccessBtnClick?.(code);
  };

  return (
    <div className={className}>
      {fastAccessSectionData && (
        <>
          {fastAccessSectionData.label && (
            <Title className="pb-xsmall" text={fastAccessSectionData.label} />
          )}
          <BrFastAccessCountries
            data={fastAccessSectionData.options}
            onItemClick={handleOnFastAccessBtnClick}
            classNames="pt-small"
          />
        </>
      )}
      <Countries
        data={data}
        onOptionSelect={onOptionSelect}
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default BrCountryList;
