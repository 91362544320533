import { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
}

const HeaderContainer: FC<Props> = (props) => {
  const { className, children } = props;

  const classNames = normalizeStringCompound([
    'fixed top-0 left-0 z-110 w-full',
    className,
  ]);

  return <header className={classNames}>{children}</header>;
};

export default HeaderContainer;
