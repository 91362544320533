import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalProps } from '@root/interfaces/components/Modal';

import { Button, Input, Modal } from '@components/common';
import { ButtonProps } from '@root/interfaces/components/Button';

interface Props extends ModalProps {
  onConfirmClick(): void;
}

interface ModalButtonPros extends ButtonProps {
  text: string;
}

const ModalButton: React.FunctionComponent<ModalButtonPros> = (
  props: ModalButtonPros,
) => {
  const { text } = props;

  return (
    <Button {...props} shape="pill" className="w-1/2" isOutlined>
      {text}
    </Button>
  );
};

const AccountDeletionModal: React.FunctionComponent<Props> = (props: Props) => {
  const { isOpen, onRequestClose, onConfirmClick } = props;

  const [confirmationText, setConfirmationText] = useState<string>('');

  const { t } = useTranslation();

  const deleteKeyword = t('Delete');

  useEffect(() => {
    setConfirmationText('');
  }, [isOpen]);

  const handleConfirmationTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmationText(e.target.value);
  };

  const isConfirmationTextMatchingDeleteKeyword = confirmationText === deleteKeyword;

  return (
    <Modal {...props} isOpen={isOpen}>
      <div className="flex flex-col">
        <div className="font-bold text-2xl md:text-xl pb-2 lg:pb-3">
          {t('Account Deletion')}
        </div>
        <div className="text-xl md:text-lg lg:text-md pb-5">
          {t(
            'By clicking “Delete” you instruct IDT to delete your account and all associated account data* that IDT is not legally required to maintain. This action cannot be undone.',
          )}
        </div>
        <div className="flex flex-col gap-5 pb-8 md:pb-7">
          <div className="text-lg md:text-base font-semibold">
            {t('Type “{{deleteKeyword}}” to continue', {
              deleteKeyword,
            })}
          </div>
          <Input value={confirmationText} onChange={handleConfirmationTextChange} />
        </div>
        <div className="flex flex-row justify-between pb-8 md:pb-7 gap-4">
          <ModalButton text={t('Cancel')} onClick={onRequestClose} />
          <ModalButton
            text={t('Delete')}
            color="red"
            disabled={!isConfirmationTextMatchingDeleteKeyword}
            onClick={onConfirmClick}
          />
        </div>
        <div className="md:text-sm lg:text-xs">
          {`*${t(
            'Please note that IDT is required to maintain certain account data to comply with our legal obligations, resolve disputes and enforce our agreements. If your account and account data are deleted you may not be able to purchase or use our products and services.  For more information, see our Privacy Policy.',
          )}`}
        </div>
      </div>
    </Modal>
  );
};

export default AccountDeletionModal;
