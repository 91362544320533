import { Img } from 'react-image';

import { normalizeStringCompound } from '@utils/string';

import { ImtuStep } from '@components/modules/Imtu/constants';

import EditButton from '../EditButton';

interface Props {
  logoUrl: string;
  name: string;
  className?: string;
  step: ImtuStep;
  onEdit(step: ImtuStep): void;
}

const CarrierInfo = (props: Props) => {
  const { logoUrl, name, onEdit, step, className } = props;

  return (
    <div className={normalizeStringCompound(['flex items-center', className])}>
      <Img width={24} height={24} className="rounded-full mr-small" src={logoUrl} />
      <div className="text-body/callout/default flex-grow">{name}</div>
      <EditButton onClick={onEdit} step={step} />
    </div>
  );
};

export default CarrierInfo;
