import { format } from 'date-fns';

import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';
import { PaymentCardTypeShort } from '@root/constants/moneyTransfer/paymentCards';

import type { BrSelectData } from '..';

const mapPaymentCardsToSortedBrSelectOptions = (
  paymentCards: BrSelectData,
  handleId?: string,
): BrOptionProps[] => {
  const creditCards: BrOptionProps[] = [];
  const debitCards: BrOptionProps[] = [];
  const notSupportedCards: BrOptionProps[] = [];
  const expiredCards: BrOptionProps[] = [];
  const selectedCard: BrOptionProps[] = [];

  paymentCards?.forEach((pCard) => {
    const pCardNumAndExpDate = `x${pCard.data.maskedNumber
      .slice(-4)
      .toUpperCase()} ${format(pCard.data.expDate, CARD_EXP_DATE_FORMAT)}`;

    const optionProps = {
      value: pCard.data.handleId,
      text: pCardNumAndExpDate,
      isDisabled: pCard.data.isExpired,
    };

    const isAvailbaleCard = !pCard.data.isExpired && pCard.isSupported;
    if (handleId === pCard.data.handleId) {
      selectedCard.push(optionProps);
    } else if (pCard.data.type === PaymentCardTypeShort.CC && isAvailbaleCard) {
      creditCards.push(optionProps);
    } else if (pCard.data.type === PaymentCardTypeShort.DC && isAvailbaleCard) {
      debitCards.push(optionProps);
    } else if (pCard.data.isExpired) {
      expiredCards.push(optionProps);
    } else {
      notSupportedCards.push(optionProps);
    }
  });

  return [
    ...selectedCard,
    ...creditCards,
    ...debitCards,
    ...expiredCards,
    ...notSupportedCards,
  ];
};

export default mapPaymentCardsToSortedBrSelectOptions;
