import { TransitionEvent, forwardRef } from 'react';

import { normalizeStringCompound } from '@utils/string';

interface Props {
  className?: string;
  children: React.ReactNode;
  onTransitionEnd?: (e: TransitionEvent<HTMLDivElement>) => void;
}

const ContentWrapper = forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { className, children, onTransitionEnd } = props;

    const drawerContentWrapperClassNames = normalizeStringCompound([
      'br-drawer-content-wrapper !absolute bottom-0 flex h-auto w-full translate-y-full transform flex-col duration-300 z-110',
      className,
    ]);

    return (
      <div
        ref={ref}
        onTransitionEnd={onTransitionEnd}
        className={drawerContentWrapperClassNames}
      >
        {children}
      </div>
    );
  },
);

export default ContentWrapper;
