import React from 'react';

import BrCarrier from '@components/common/BrCarrier';
import Flag from '@components/common/Flag';

import { BrCardPromotionPropsContentLargeProps } from '@root/interfaces/components/BrCardPromotion';

const LargeContent: React.FC<BrCardPromotionPropsContentLargeProps> = (props) => {
  const {
    imageUrl,
    countryCode,
    promoTextShort,
    carrierName,
    countryName,
    carrierCode,
    state,
  } = props;

  return (
    <>
      <div
        className="flex flex-col justify-between h-[164px]"
        style={{
          background: `${
            state === 'K2 Carrier Promo image'
              ? 'linear-gradient(180.01deg, rgba(0, 0, 0, 0) 54.49%, rgba(0, 0, 0, 0.66) 82.57%, rgba(0, 0, 0, 0.77) 90.83%), linear-gradient(182.14deg, rgba(40, 40, 40, 0.87) 4.25%, rgba(0, 0, 0, 0) 79.62%)'
              : 'linear-gradient(180.01deg, rgba(0, 0, 0, 0) 54.49%, rgba(0, 0, 0, 0.66) 82.57%, rgba(0, 0, 0, 0.77) 90.83%)'
          }, url(${imageUrl})`,
          objectFit: 'cover',
        }}
      >
        <div className="px-default pt-default space-y-xsmall">
          <div className="flex flex-row space-x-middle">
            <Flag
              className="w-x5large h-x5large overflow-hidden rounded-full flex-shrink-0 self-center"
              code={countryCode}
            />
            <BrCarrier size="Medium" carrierCode={carrierCode} />
          </div>
          <div className="flex flex-row text-body/caption/medium text-on-colors/on-secondary space-x-small">
            <span>{countryName}</span>
            <span>{carrierName}</span>
          </div>
        </div>
        <div className="text-headers/header-4 text-on-colors/on-secondary px-default pb-default">
          {promoTextShort}
        </div>
      </div>
    </>
  );
};

export default LargeContent;
