/* eslint-disable @typescript-eslint/indent */
import React, { ChangeEventHandler, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { IconNames } from '@root/interfaces/components/Icon';

import BrAdvancedInput, { BrAdvancedInputRef } from '@components/common/BrAdvancedInput';
import BrTooltipWrapper from '@components/common/BrTooltip/BrTooltipWrapper';

import Yup from '@utils/validation/yup';

import useOnClickOutside from '@root/hooks/useOnClickOutside';

import {
  formatPhoneToE164,
  getPhoneInfoByNumber,
  getPhoneMaskByCountryCode,
  isValidInternationalPhoneNumber,
} from '@helpers/phone';
import CountryCode from '@root/interfaces/CountryCode';

interface Props {
  onPhoneChange({
    phoneNumber,
    isValid,
  }: {
    phoneNumber: string;
    isValid: boolean;
  }): void;
  externalValidationFn(phoneNumber: string): Promise<boolean>;
  onValidation?(isValid: boolean): void;
}

type IconCfg = { iconName: IconNames; iconClassNames: string };

const ICON_SUCCESS_CFG: IconCfg = {
  iconName: 'checkmark-circle',
  iconClassNames: 'text-color/success',
};

const ICON_CLEAR_CFG: IconCfg = {
  iconName: 'close_-_x_-_times_fill',
  iconClassNames: 'text-support-colors/highlights',
};

const INPUT_ID = 'favorite-phone-number';
const ClEARED_VALUE = ''; // the value we set on clearing the input
const MASK_PREFIX = '+';

const getIconCfg = (phoneNumber: string, hasErrors: boolean, isBlured?: boolean) => {
  if (phoneNumber && !hasErrors) {
    return ICON_SUCCESS_CFG;
  }
  if (phoneNumber && phoneNumber !== MASK_PREFIX && !isBlured) {
    return ICON_CLEAR_CFG;
  }
  return undefined;
};

const RecipientNumberForm: React.FunctionComponent<Props> = (props) => {
  const { onPhoneChange, externalValidationFn, onValidation } = props;

  const [recipientCountryCode, setRecipientCountryCode] = useState<
    CountryCode | undefined
  >();

  const [isBlured, setIsBlured] = useState(false);

  const brAdvancedInputRef = useRef<BrAdvancedInputRef>(null);

  const { t } = useTranslation();

  useOnClickOutside(() => {
    setIsBlured(true);
  }, brAdvancedInputRef.current?.inputWrapperRef || { current: undefined });

  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string()
      .ensure()
      .label('phoneNumber')
      .test('is valid phone number', async (value: string, context: Yup.TestContext) => {
        const isValidInternationalPhoneNumberResult = isValidInternationalPhoneNumber(
          formatPhoneToE164(value),
        );
        if (isValidInternationalPhoneNumberResult) {
          const isExternalValidationSuccess = await externalValidationFn(value);
          onValidation?.(Boolean(isExternalValidationSuccess));
          if (!isExternalValidationSuccess) {
            return context.createError({
              message: t('Recipient ineligible'),
            });
          }

          return isExternalValidationSuccess;
        }
        onValidation?.(false);
        return context.createError({
          message: t(
            'The number that you entered does not look like a valid phone number',
          ),
        });
      }),
  });

  const initialValues = {
    phoneNumber: '',
  };

  const handlePhoneSubmit = (values: typeof initialValues) => {
    if (values.phoneNumber) {
      onPhoneChange({
        phoneNumber: formatPhoneToE164(values.phoneNumber),
        isValid: true,
      });
    }
  };

  const phoneNumberInputMask = `+${getPhoneMaskByCountryCode(recipientCountryCode)}`;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handlePhoneSubmit}
      enableReinitialize
    >
      {(formik) => {
        // custom handler to prevent formik handing outdated value
        const handlePhoneInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
          setRecipientCountryCode(getPhoneInfoByNumber(e.target.value).country);

          e.preventDefault();
          formik.setFieldValue('phoneNumber', e.target.value, false);
          // the hack to force formik validate the new value
          setTimeout(() => {
            formik.validateField('phoneNumber');
          }, 1);
        };

        if (isValidInternationalPhoneNumber(formik.values.phoneNumber)) {
          // to not spam validation error at the first user input
          if (!formik.touched.phoneNumber) {
            formik.setFieldTouched('phoneNumber', true, true);
          }
        }

        onPhoneChange({
          phoneNumber: formik.values.phoneNumber,
          isValid: !formik.errors.phoneNumber,
        });

        const iconRightCfg =
          formik.isValid && formik.touched.phoneNumber
            ? undefined
            : getIconCfg(
                formik.values.phoneNumber,
                Boolean(formik.errors.phoneNumber),
                isBlured,
              );

        const handleOnIconClickRight =
          iconRightCfg?.iconName === ICON_CLEAR_CFG.iconName &&
          !isBlured &&
          formik.values.phoneNumber !== ClEARED_VALUE &&
          formik.values.phoneNumber !== MASK_PREFIX
            ? () => {
                formik.resetForm({
                  values: { phoneNumber: ClEARED_VALUE },
                });
                brAdvancedInputRef.current?.inputRef.current?.focus();
              }
            : undefined;

        return (
          <Form className="flex flex-col space-y-small">
            <BrTooltipWrapper
              cfg={{
                color: 'Attention',
                content: t('Please provide a phone number'),
                place: 'bottom-start',
                anchorSelect: `#${INPUT_ID}`,
                defaultIsOpen: true,
              }}
            >
              <InputMask
                mask={phoneNumberInputMask}
                maskChar=""
                inputMode="numeric"
                prefix={MASK_PREFIX}
                value={formik.values.phoneNumber}
                onChange={handlePhoneInputChange}
                onFocus={() => {
                  setIsBlured(false);
                }}
              >
                {() => (
                  <BrAdvancedInput
                    id={INPUT_ID}
                    name="phoneNumber"
                    topLabel={t('Phone number')}
                    placeholder={t('Enter the phone number')}
                    inputMode="numeric"
                    hasError={
                      Boolean(formik.errors.phoneNumber) &&
                      Boolean(formik.touched.phoneNumber)
                    }
                    errorText={formik.errors.phoneNumber}
                    iconRight={iconRightCfg?.iconName}
                    iconRightClassNames={iconRightCfg?.iconClassNames}
                    onIconClickRight={handleOnIconClickRight}
                    ref={brAdvancedInputRef}
                    hasSuccess={
                      formik.isValid &&
                      formik.touched.phoneNumber &&
                      Boolean(formik.values.phoneNumber.replaceAll('+', ''))
                    }
                  />
                )}
              </InputMask>
            </BrTooltipWrapper>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecipientNumberForm;
