import React from 'react';

interface Props {
  title: string;
}

const ImtuProductTitleSection: React.FunctionComponent<Props> = (props: Props) => {
  const { title } = props;

  return (
    <div className="text-center space-y-small">
      <div className="text-body/callout/default">{title}</div>
    </div>
  );
};

export default ImtuProductTitleSection;
