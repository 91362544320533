import { useEffect, useState } from 'react';

import useDidMount from '@root/hooks/useDidMount';

interface Params {
  isDefaultOpen?: boolean;
  delayHide?: number;
}

// TODO: think on making Attention tooltips as a service like Toast
const HIDE_DELAY_FOR_DEFAULT_OPEN = 5000;

const useDefaultOpenTooltipState = (params: Params) => {
  const { isDefaultOpen, delayHide } = params;

  const [isOpen, setIsOpen] = useState(Boolean(isDefaultOpen));
  const [isDisabled, setIsDisabled] = useState(false);

  const hideTooltip = () => {
    setIsOpen(false);
    setIsDisabled(true);
  };

  useDidMount(() => {
    if (isDefaultOpen) {
      // hide tooltip on click to prevent it to stay when some overlay is opened
      document.body.addEventListener('click', hideTooltip);
      setTimeout(() => {
        hideTooltip();
      }, delayHide || HIDE_DELAY_FOR_DEFAULT_OPEN);
    }
  });

  useEffect(() => {
    return () => {
      document.body.removeEventListener('click', hideTooltip);
    };
  }, []);

  const state = {
    isOpen,
    isDisabled,
  };

  return isDefaultOpen ? state : null;
};

export default useDefaultOpenTooltipState;
