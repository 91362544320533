import { IconNames } from '@root/interfaces/components/Icon';

type IconCfg = { iconName: IconNames; iconClassNames: string };

export const ICON_SUCCESS_CFG: IconCfg = {
  iconName: 'checkmark-circle',
  iconClassNames: 'text-color/success',
};

export const ICON_CLEAR_CFG: IconCfg = {
  iconName: 'close_-_x_-_times_fill',
  iconClassNames: 'text-support-colors/highlights',
};

const MASK_PREFIX = '+';

export const getIconCfg = (
  phoneNumber: string,
  hasErrors: boolean,
  isBlured?: boolean,
) => {
  if (phoneNumber && !hasErrors) {
    return ICON_SUCCESS_CFG;
  }
  if (phoneNumber && phoneNumber !== MASK_PREFIX && !isBlured) {
    return ICON_CLEAR_CFG;
  }
  return undefined;
};
