import { makeAutoObservable } from 'mobx';

export enum FilterType {
  All = 'all',
  Mt = 'mt',
  Imtu = 'imtu',
  CallingRecharge = 'calling-recharge',
  CallingPlans = 'calling-plans',
}

class Store {
  typeFilter: FilterType = FilterType.All;

  constructor() {
    makeAutoObservable(this);
  }

  setTypeFilter(value: string) {
    if (
      FilterType.Mt === value ||
      FilterType.Imtu === value ||
      FilterType.CallingRecharge === value ||
      FilterType.CallingPlans === value
    ) {
      this.typeFilter = value;
    } else {
      this.typeFilter = FilterType.All;
    }
  }
}

export default Store;
