import React from 'react';

import useHandle3dsTxnId from '@root/hooks/useHandle3dsTxnId';

import BrTemplateWithSidebar from '@components/templates/BrTepmplateWithSidebar';

import ImtuReceipt from '@components/modules/ImtuReceipt';

import { IMTU_3DS_PENDING_TXN_ID_STORE_KEY } from '@services/auth/constants';

const ImtuReceiptPage = () => {
  const { txnId } = useHandle3dsTxnId({ storeKey: IMTU_3DS_PENDING_TXN_ID_STORE_KEY });

  return (
    <BrTemplateWithSidebar>
      {txnId && <ImtuReceipt txnId={txnId} />}
    </BrTemplateWithSidebar>
  );
};

export default ImtuReceiptPage;
