import { Languages } from '@root/interfaces/Languages';
import CountryCode from '@root/interfaces/CountryCode';

import { LANGUAGES } from '@root/constants/languages';
import { LanguageOption } from '@root/interfaces/components/LanguageSwitcher';

export const getLanguageCode = (lang?: string): string | undefined => lang?.split('-')[0];

export const getLanguageCodeByAvailable = (lang?: string) => {
  const langCode = getLanguageCode(lang);

  if (langCode && Object.values(Languages).includes(langCode as Languages)) {
    return langCode as Languages;
  }

  return undefined;
};

const filterOutFrLanguage = (
  data: LanguageOption[],
  userCountryOfOrigin?: CountryCode,
) => {
  if (userCountryOfOrigin === 'US') {
    return data.filter((option) => {
      return option.value !== Languages.Fr;
    });
  }
  return data;
};

export const getAvailableLanguageOptions = (userCountryOfOrigin?: CountryCode) => {
  const languagesOptions = [
    { value: Languages.En, text: LANGUAGES.en.name, nativeName: LANGUAGES.en.nativeName },
    { value: Languages.Es, text: LANGUAGES.es.name, nativeName: LANGUAGES.es.nativeName },
    { value: Languages.Fr, text: LANGUAGES.fr.name, nativeName: LANGUAGES.fr.nativeName },
  ];

  return filterOutFrLanguage(languagesOptions, userCountryOfOrigin);
};

export const getLanguageValues = () => {
  return Array.from(
    new Set(
      Object.keys(Languages).map((key) => {
        return key.toLowerCase();
      }),
    ),
  );
};
