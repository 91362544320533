import { makeAutoObservable } from 'mobx';

import CountryCode from '@root/interfaces/CountryCode';

import { fetchImtuConfig } from '@services/api/v1/dtcImtuK2';
import { showToast } from '@services/toasts';

class ImtuConfigStore {
  availableCountries: CountryCode[] = [];

  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  fetchConfig = async () => {
    this.isLoading = true;

    try {
      const imtuConfigRes = await fetchImtuConfig();
      this.availableCountries = imtuConfigRes.country_codes;
    } catch (err) {
      showToast.error(err);
    } finally {
      this.isLoading = false;
    }
  };
}

export default ImtuConfigStore;
