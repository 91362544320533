import { TFunction } from 'i18next';
import { format } from 'date-fns';

import { ProductDetail } from '@components/common/BrReceipt';

import CallingPlanTxn from '@root/models/CallingPlans/CallingPlanTxn';
import ImtuTransaction from '@root/models/ImtuTransaction';

import { filterOutEmptyItems } from '@utils/array';

// fill in case we get this data one day
// const CALLING_PLAN_TYPE = {
//   SUBSCRIPTION: 'Subscription', // t('Subscription')
//   ONE_TIME_PLAN: 'One time plan', // t('One time plan')
// };

const SECTION_TITLES = {
  PRODUCT_DETAILS: 'Product details', // t('Product details')
  VALIDITY: 'Validity', // t('Validity')
  MINUTES: 'Minutes', // t('Minutes')
  PAYMENT_DETAILS: 'Payment details', // t('Payment details')
  SUBSCRIPTION: 'Subscription', // t('Subscription')
  OTHER_DETAILS: 'Other details', // t('Other details')
  RECIPIENT_NUMBER: 'Recipient', // t('Recipient')
};

const tFnStub = (args: unknown) => {
  return String(args);
};

const translateTitles = (data: ProductDetail[], t: TFunction): ProductDetail[] => {
  return data.filter((item) => {
    return {
      ...item,
      title: t(item.title),
    };
  });
};

export const getProductDetailsFromCallingPlanTxn = ({
  callingPlanTxn,
  t = tFnStub,
}: {
  callingPlanTxn: CallingPlanTxn;
  t?: TFunction;
}): ProductDetail[] => {
  const filteredRes = filterOutEmptyItems([
    /* eslint-disable @typescript-eslint/indent */
    callingPlanTxn.callingPlanInfo.description
      ? {
          title: SECTION_TITLES.PRODUCT_DETAILS,
          text: callingPlanTxn.callingPlanInfo.description,
        }
      : undefined,
    callingPlanTxn?.callingPlanInfo.term
      ? {
          title: SECTION_TITLES.VALIDITY,
          text: t('{{number}} Days', { number: callingPlanTxn.callingPlanInfo.term }),
        }
      : undefined,
    callingPlanTxn?.callingPlanInfo.minutes
      ? {
          title: SECTION_TITLES.MINUTES,
          text: t('{{number}} Minutes', {
            number: callingPlanTxn.callingPlanInfo.minutes,
          }),
        }
      : undefined,
    {
      title: SECTION_TITLES.SUBSCRIPTION,
      text: callingPlanTxn?.callingPlanInfo.isSubscriptionActive
        ? t('Active')
        : t('Not active'),
    },

    /* eslint-enable @typescript-eslint/indent */
  ]);

  return translateTitles(filteredRes, t);
};

export const getPaymentAndRestDetailsFromCallingPlanTxn = ({
  callingPlanTxn,
  paymentCard4digitNumber,
  t = tFnStub,
}: {
  callingPlanTxn: CallingPlanTxn;
  paymentCard4digitNumber: string;
  t?: TFunction;
}): ProductDetail[] => {
  return translateTitles(
    [
      {
        title: SECTION_TITLES.PAYMENT_DETAILS,
        text: paymentCard4digitNumber,
      },
      {
        title: SECTION_TITLES.OTHER_DETAILS,
        text: format(callingPlanTxn.callingPlanInfo.createdAt, 'MMM dd, yyyy hh:mm a'),
      },
    ],
    t,
  );
};

export const getProductDetailsFromCallingPlanImtuTxn = ({
  imtuTxn,
  t = tFnStub,
}: {
  imtuTxn?: ImtuTransaction | null;
  t?: TFunction;
}): ProductDetail[] | undefined => {
  if (imtuTxn) {
    const filteredRes = filterOutEmptyItems([
      /* eslint-disable @typescript-eslint/indent */
      imtuTxn.product?.description
        ? {
            title: SECTION_TITLES.PRODUCT_DETAILS,
            text: imtuTxn.product.description,
          }
        : undefined,
      /* eslint-enable @typescript-eslint/indent */
      {
        title: SECTION_TITLES.RECIPIENT_NUMBER,
        text: imtuTxn.recipientPhoneNumber,
      },
    ]);
    return translateTitles(filteredRes, t);
  }
  return undefined;
};

export const getSubtotalDetailsFromCallingPlanTxn = ({
  callingPlanTxn,
  imtuTxn,
}: {
  callingPlanTxn: CallingPlanTxn;
  imtuTxn?: ImtuTransaction | null;
}): ProductDetail[] | undefined => {
  // We need to show this info only if both transactions are success and have this data. Otherwise, this will make no sense
  if (
    imtuTxn &&
    callingPlanTxn.priceTotalText &&
    callingPlanTxn.priceCallingPlanText &&
    imtuTxn.status === 'success' &&
    callingPlanTxn.status === 'success'
  ) {
    return filterOutEmptyItems([
      {
        title: callingPlanTxn.callingPlanInfo.title,
        text: callingPlanTxn.priceCallingPlanText,
      },
      /* eslint-disable @typescript-eslint/indent */
      imtuTxn.product
        ? {
            title: imtuTxn.product.name,
            text: imtuTxn.product.senderTotalAmountText,
          }
        : undefined,
      /* eslint-enable @typescript-eslint/indent */
    ]);
  }
  return undefined;
};
